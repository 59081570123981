import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ReportingDataSql = Loadable(lazy(() => import('views/reporting/Sql')));
const ReportTemplates = Loadable(
  lazy(() => import('views/reporting/Templates'))
);

export const ReportingRoutes = [
  {
    path: `${ALL_APPS.REPORTING.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.REPORTING.id,
          view: ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES,
        }}
      >
        <Navigate to={`${ALL_VIEWS.REPORTING_APP_SQL_QUERIES.path}`} replace />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.REPORTING_APP_SQL_QUERIES.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.REPORTING.id,
          view: ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES,
        }}
      >
        <ReportingDataSql />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.REPORTING_APP_TEMPLATES.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.REPORTING.id,
          view: ALL_VIEW_IDS.REPORTING_APP_TEMPLATES,
        }}
      >
        <ReportTemplates />
      </PermissionsRouteWrapper>
    ),
  },
];
