import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ListResponse,
  QueryParams,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ClientV2PATCHResponse,
  ClientV2PATCHRequest,
  ClientV2ToggleRequest,
  ClientV2ToggleResponse,
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  AsyncJobResponse,
} from 'types/api';
import {
  SupplierQuoteSchema,
  SupplierQuotePOSTSchema,
  SupplierQuotePATCHSchema,
  SupplierQuoteLineSchema,
  SupplierQuoteLinePOSTSchema,
  SupplierQuoteLinePATCHSchema,
} from 'types/sourcing';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSupplierQuotes: builder.query<
      ListResponse<SupplierQuoteSchema>,
      QueryParams<SupplierQuoteSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<SupplierQuoteSchema>,
        QueryParams<SupplierQuoteSchema>
      >((params) =>
        CLIENT_V2_URLS.SUPPLIER_QUOTES(
          parseQueryParams<SupplierQuoteSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: SupplierQuoteSchema) =>
                  ({ type: 'SupplierQuotes', id } as const)
              ),
              { type: 'SupplierQuotes', id: 'LIST' },
            ]
          : [{ type: 'SupplierQuotes', id: 'LIST' }],
    }),
    createSupplierQuote: builder.mutation<
      ClientV2POSTResponse<SupplierQuoteSchema>,
      ClientV2POSTRequest<SupplierQuotePOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<SupplierQuoteSchema>,
        ClientV2POSTRequest<SupplierQuotePOSTSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTES(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'SupplierQuotes', id: 'LIST' }],
    }),
    updateSupplierQuote: builder.mutation<
      ClientV2PATCHResponse<SupplierQuoteSchema>,
      ClientV2PATCHRequest<SupplierQuotePATCHSchema>
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTES(),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error) => [
        ...(result
          ? result.updatedIds.map(
              (id) => ({ type: 'SupplierQuotes', id } as const)
            )
          : []),
        { type: 'SupplierQuoteLines', id: 'LIST' },
      ],
    }),
    archiveSupplierQuoteAction: builder.mutation<
      ClientV2ToggleResponse<SupplierQuoteSchema>,
      ClientV2ToggleRequest<SupplierQuoteSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTES_ARCHIVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuotes', id })),
    }),
    unarchiveSupplierQuoteAction: builder.mutation<
      ClientV2ToggleResponse<SupplierQuoteSchema>,
      ClientV2ToggleRequest<SupplierQuoteSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTES_UNARCHIVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuotes', id })),
    }),
    deleteSupplierQuote: builder.mutation<
      ClientV2DELETEResponse<SupplierQuoteSchema>,
      ClientV2DELETERequest<SupplierQuoteSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTES(),
        method: 'DELETE',
        body: { ids: data.ids },
      }),
      invalidatesTags: [{ type: 'SupplierQuotes', id: 'LIST' }],
    }),
    getSupplierQuoteLines: builder.query<
      ListResponse<SupplierQuoteLineSchema>,
      QueryParams<SupplierQuoteLineSchema>
    >({
      query: (params: QueryParams<SupplierQuoteLineSchema>) =>
        CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES(
          parseQueryParams<SupplierQuoteLineSchema>(params)
        ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: SupplierQuoteLineSchema) =>
                  ({ type: 'SupplierQuoteLines', id } as const)
              ),
              { type: 'SupplierQuoteLines', id: 'LIST' },
            ]
          : [{ type: 'SupplierQuoteLines', id: 'LIST' }],
    }),
    createSupplierQuoteLine: builder.mutation<
      ClientV2POSTResponse<SupplierQuoteLineSchema>,
      ClientV2POSTRequest<SupplierQuoteLinePOSTSchema>
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'SupplierQuoteLines', id: 'LIST' }],
    }),
    updateSupplierQuoteLine: builder.mutation<
      ClientV2PATCHResponse<SupplierQuoteLineSchema>,
      ClientV2PATCHRequest<SupplierQuoteLinePATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: 'SupplierQuotes', id: 'LIST' },
        ...(result
          ? result.updatedIds.map(
              (id) => ({ type: 'SupplierQuoteLines', id } as const)
            )
          : []),
      ],
    }),
    deleteSupplierQuoteLine: builder.mutation<
      ClientV2DELETEResponse<SupplierQuoteLineSchema>,
      ClientV2DELETERequest<SupplierQuoteLineSchema>
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES(),
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: (result) => [
        { type: 'SupplierQuoteLines', id: 'LIST' },
        ...(result?.deletedIds ?? []).map(
          (id) => ({ type: 'SupplierQuoteLines', id: 'LIST' } as const)
        ),
      ],
    }),
    approveSupplierQuoteLineAction: builder.mutation<
      ClientV2ToggleResponse<SupplierQuoteLineSchema>,
      ClientV2ToggleRequest<SupplierQuoteLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES_APPROVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuoteLines', id })),
    }),
    unapproveSupplierQuoteLineAction: builder.mutation<
      ClientV2ToggleResponse<SupplierQuoteLineSchema>,
      ClientV2ToggleRequest<SupplierQuoteLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES_UNAPPROVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuoteLines', id })),
    }),
    voidSupplierQuoteLineAction: builder.mutation<
      ClientV2ToggleResponse<SupplierQuoteLineSchema>,
      ClientV2ToggleRequest<SupplierQuoteLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES_VOID(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuoteLines', id })),
    }),
    unvoidSupplierQuoteLineAction: builder.mutation<
      ClientV2ToggleResponse<SupplierQuoteLineSchema>,
      ClientV2ToggleRequest<SupplierQuoteLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES_UNVOID(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuoteLines', id })),
    }),
    autoMatchSupplierQuoteLineAction: builder.mutation<
      AsyncJobResponse<null>,
      ClientV2ToggleRequest<SupplierQuoteLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES_AUTO_MATCH(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierQuoteLines', id })),
    }),
  }),
});

export const {
  useGetSupplierQuotesQuery,
  useLazyGetSupplierQuotesQuery,
  useCreateSupplierQuoteMutation,
  useUpdateSupplierQuoteMutation,
  useDeleteSupplierQuoteMutation,
  useArchiveSupplierQuoteActionMutation,
  useUnarchiveSupplierQuoteActionMutation,
  useGetSupplierQuoteLinesQuery,
  useCreateSupplierQuoteLineMutation,
  useUpdateSupplierQuoteLineMutation,
  useDeleteSupplierQuoteLineMutation,
  useApproveSupplierQuoteLineActionMutation,
  useUnapproveSupplierQuoteLineActionMutation,
  useVoidSupplierQuoteLineActionMutation,
  useUnvoidSupplierQuoteLineActionMutation,
  usePrefetch,
  useAutoMatchSupplierQuoteLineActionMutation,
} = extendedApiSlice;
