import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { usePusherContext } from 'contexts/PusherContext';
import * as PusherTypes from 'pusher-js';
import {
  useLazyGetJobQuery,
  removeJob,
  addJob,
  ReduxJob,
  useLazyGetJobStatusQuery,
} from 'store/slices/apiV1/utility';
import { AsyncJobStatus } from 'types/api';
import { PusherEvent } from 'pusher-js/types/src/core/connection/protocol/message-types';
import { useDispatch, useSelector } from 'store';
import { useInterval } from 'hooks/useInterval';
import useSnackbar from 'hooks/useSnackbar';

const JOB_STATUS_POLL_INTERVAL = 10 * 1000;

type AsyncRequestContextType = {
  jobsChannel: PusherTypes.Channel;
  handleAddJob: (job: ReduxJob) => void;
  handleRemoveJob: (jobId: string) => void;
  bindChannelRef: MutableRefObject<() => void>;
  unbindChannelRef: MutableRefObject<() => void>;
  jobQueryInfo: any;
};

export const AsyncRequestContext = createContext<AsyncRequestContextType>({
  jobsChannel: {} as PusherTypes.Channel,
  handleAddJob: () => {},
  handleRemoveJob: () => {},
  bindChannelRef: { current: () => {} },
  unbindChannelRef: { current: () => {} },
  jobQueryInfo: {},
});

export const useAsyncRequestContext = () => useContext(AsyncRequestContext);

export const AsyncRequestProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state.utility);
  const jobsRef = useRef(jobs);
  jobsRef.current = jobs;
  const [retrieveData, jobQueryInfo] = useLazyGetJobQuery();
  const [getJobStatus] = useLazyGetJobStatusQuery();
  const { jobsChannel } = usePusherContext();

  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();

  const bindChannelRef = useRef<() => void>(() => {});
  const unbindChannelRef = useRef<() => void>(() => {});

  const handleAddJob = useCallback(
    (job: ReduxJob) => {
      dispatch(addJob(job));
    },
    [dispatch]
  );

  const handleRemoveJob = useCallback(
    (jobId: string) => {
      dispatch(removeJob(jobId));
    },
    [dispatch]
  );

  const handleErrorResponse = useCallback(
    (jobId: string, error: unknown) => {
      const job = jobsRef.current[jobId];
      if (!job) return;
      job.options?.onError?.();
      job.options?.onErrorSnackbar &&
        dispatchErrorSnackbar(job.options.onErrorSnackbar);
      job.reject && job.reject(error);
      handleRemoveJob(jobId);
    },
    [dispatch]
  );

  const handleSuccessResponse = useCallback((jobId: string, data: unknown) => {
    const job = jobsRef.current[jobId];
    if (!job) return;
    const { message, navigateTo, customAction } =
      job.options?.onCompleteSnackbar ?? {};
    message && dispatchSuccessSnackbar(message, navigateTo, customAction);
    job.options?.onSuccess && job.options.onSuccess(data);
    job.resolve && job.resolve(data);
    handleRemoveJob(jobId);
  }, []);

  const handleJobComplete = useCallback(
    async (jobId: string) => {
      const job = jobsRef.current[jobId];
      if (!job) return;

      const { options: { retrieveDataOnCompletion, updateStore } = {} } = job;

      let fetchedData;
      if (retrieveDataOnCompletion) {
        try {
          fetchedData = await retrieveData({
            jobId,
            updateStore,
          }).unwrap();
        } catch (err) {
          handleErrorResponse(jobId, err);
          return;
        }
      }

      if (fetchedData?.status === AsyncJobStatus.error) {
        handleErrorResponse(jobId, fetchedData?.data);
      } else {
        handleSuccessResponse(jobId, fetchedData?.data);
      }
    },
    [retrieveData]
  );

  const handlePusherMessage = useCallback(
    async (__: PusherEvent, eventData) => {
      if (
        Object.keys(jobsRef.current).includes(eventData.job_id) &&
        (eventData.status === AsyncJobStatus.done ||
          eventData.status === AsyncJobStatus.error)
      ) {
        handleJobComplete(eventData.job_id);
      }
      return null;
    },
    [handleJobComplete]
  );

  const bindChannel = useCallback(() => {
    jobsChannel.bind_global(handlePusherMessage);
  }, [jobsChannel, handlePusherMessage]);

  const unbindChannel = useCallback(() => {
    jobsChannel.unbind_global(handlePusherMessage);
  }, [jobsChannel, handlePusherMessage]);

  bindChannelRef.current = bindChannel;
  unbindChannelRef.current = unbindChannel;

  useEffect(() => {
    bindChannelRef.current();
    return () => {
      unbindChannelRef.current();
    };
  }, []);

  const pollJobStatus = useCallback(async () => {
    Object.keys(jobsRef.current).forEach(async (jobId) => {
      try {
        const fetchedData = await getJobStatus({ jobId }).unwrap();
        if (
          fetchedData.status === AsyncJobStatus.done ||
          fetchedData.status === AsyncJobStatus.error
        ) {
          handleJobComplete(jobId);
        }
      } catch (err) {
        handleErrorResponse(jobId, err);
      }
    });
  }, [getJobStatus, handleJobComplete]);

  useInterval({ callback: pollJobStatus, interval: JOB_STATUS_POLL_INTERVAL });

  return (
    <AsyncRequestContext.Provider
      value={{
        jobsChannel,
        handleAddJob,
        handleRemoveJob,
        bindChannelRef,
        unbindChannelRef,
        jobQueryInfo,
      }}
    >
      {children}
    </AsyncRequestContext.Provider>
  );
};
