import { RenderCellWithEditWrapperProps } from 'ui-component/DataGrid/Render/RenderCellWithEditWrapper';
import { Box, Stack, Typography } from '@mui/material';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { IconPencil } from '@tabler/icons-react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';

const CellWithEditWrapper = ({
  params,
  renderMethod,
  permissionScope,
  apiRef,
  disabled,
  getIsDisabled,
  tooltip,
  icon,
}: RenderCellWithEditWrapperProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleStartEdit = ({
    id,
    field,
  }: {
    id: GridRenderCellParams['id'];
    field: GridRenderCellParams['field'];
  }) => {
    if (params.isEditable) {
      apiRef?.current?.startCellEditMode({ id, field });
    }
  };

  const disabledResult = getIsDisabled ? getIsDisabled(params) : false;

  const Icon = icon ?? IconPencil;

  return (
    <Stack
      direction={params.colDef.type === 'number' ? 'row-reverse' : 'row'}
      justifyContent={
        params.colDef.type === 'boolean' ? 'center' : 'flex-start'
      }
      alignItems="center"
      sx={{ width: '100%', height: '100%' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      spacing={0}
    >
      <Box overflow="hidden">
        {renderMethod ? (
          <>{renderMethod(params)}</>
        ) : (
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.formattedValue ?? params.value}
          </Typography>
        )}
      </Box>
      <Box>
        <SegmentIconButton
          color="primary"
          onClick={() =>
            handleStartEdit({ id: params.id, field: params.field })
          }
          size="small"
          permissionScope={permissionScope}
          sx={{
            visibility: isHovered && params.isEditable ? 'visible' : 'hidden',
          }}
          disabled={disabled || disabledResult}
          tooltip={tooltip}
        >
          <Icon stroke="1.5px" width={20} height={20} />
        </SegmentIconButton>
      </Box>
    </Stack>
  );
};

export default CellWithEditWrapper;
