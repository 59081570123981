import React, { useEffect, cloneElement } from 'react';
import { FieldValues } from 'react-hook-form';
import ReactDOM from 'react-dom';
import {
  EditInlineBoxSx,
  EditInlineButtonGroupSx,
  EditInlineSx,
} from 'ui-component/EditInline';
import { RenderMutationCellWithInputProps } from 'ui-component/DataGrid/Render/RenderMutationCellWithInput';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { handleErr } from 'utils/functions';
import { EditInlineStringInput } from 'ui-component/HookFormComponents/InlineEditInputComponents';
import useInlineEditCellHookForm from 'ui-component/DataGrid/Cell/useInlineEditCellHookForm';
import useSnackbar from 'hooks/useSnackbar';
import BaseCellWithInput from 'ui-component/DataGrid/Cell/BaseCellWithInput';
import { Paper } from '@mui/material';

const MutationCellWithInput = ({
  params,
  apiRef,
  InputElement = EditInlineStringInput,
  validation,
  permissionScope,
  useMutation,
  getMutationArgs,
  preSubmit,
  defaultValue,
  additionalInputElementProps,
  tooltip,
  clientV2,
}: RenderMutationCellWithInputProps) => {
  const [mutate, { isLoading }] = useMutation();

  const handleStopEdit = ({
    id,
    field,
  }: {
    id: GridRenderCellParams['id'];
    field: GridRenderCellParams['field'];
  }) => {
    apiRef?.current?.stopCellEditMode({ id, field });
  };

  const handleEditCellValue = ({
    id,
    field,
    data,
  }: {
    id: GridRenderCellParams['id'];
    field: GridRenderCellParams['field'];
    data: FieldValues;
  }) => {
    apiRef?.current?.setEditCellValue({ id, field, value: data[field] });
  };

  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useInlineEditCellHookForm({ params, validation });

  useEffect(() => {
    reset({
      [params.colDef.field]: defaultValue ?? params.value,
    });
  }, [params.value]);

  const clonedInputElement = cloneElement(InputElement, {
    control,
    name: params.colDef.field,
    label: params.colDef.headerName,
    errors,
    size: 'small',
    sx: { ...EditInlineSx, width: '150px' },
    boxSx: EditInlineBoxSx,
    buttonGroupSx: EditInlineButtonGroupSx,
    defaultValue,
    ...(additionalInputElementProps || []),
  });

  const onSubmit = async (data: FieldValues) => {
    try {
      const mutationArgs = getMutationArgs
        ? getMutationArgs(data)
        : {
            id: params.id,
          };
      const payload = preSubmit ? preSubmit(data, params) : data;

      await mutate(
        clientV2
          ? { ...payload, id: params.row.id }
          : { ...mutationArgs, payload }
      ).unwrap();

      handleEditCellValue({ id: params.id, field: params.field, data });
      handleStopEdit({ id: params.id, field: params.field });
      dispatchSuccessSnackbar(`${params.colDef.headerName} updated`);
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        dispatchErrorSnackbar(
          `Failed to update ${params.colDef.headerName}: ${errMessage}`
        );
      });
    }
  };

  // Get cell position for anchoring the popup
  const cellElement = apiRef?.current?.getCellElement(params.id, params.field);
  const cellRect = cellElement?.getBoundingClientRect();

  // Create a portal for the form
  const formContent = (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        zIndex: 1000, // Very high z-index
        position: 'fixed',
        top: cellRect ? `${cellRect.top}px` : '50%',
        left: cellRect ? `${cellRect.left}px` : '50%',
        transform: cellRect ? 'none' : 'translate(-50%, -50%)',
        height: cellRect ? `${cellRect.height}px` : 'auto', // Match cell height
        maxWidth: '90vw',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <BaseCellWithInput
          clonedInputElement={clonedInputElement}
          isLoading={isLoading}
          permissionScope={permissionScope}
          handleStopEdit={() =>
            handleStopEdit({ id: params.id, field: params.field })
          }
          tooltip={tooltip}
        />
      </form>
    </Paper>
  );

  // Return a div for the cell and portal the form to the document body
  return <div>{ReactDOM.createPortal(formContent, document.body)}</div>;
};

export default MutationCellWithInput;
