import { ReactNode, useEffect } from 'react';
import { Box, DialogContentText } from '@mui/material';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { GenericResource, ToggleMutation } from 'types/api';
import useToggleRecords from 'hooks/clientV2/useToggleRecordsDialog';
import {
  ToggleActionStrings,
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';
import { pluralizeNoun } from 'utils/functions';

interface ToggleRecordsDialogProps<T extends GenericResource> {
  ids: string[];
  isCurrentlyTrue: boolean;
  dialogOpen: boolean;
  onClose: () => void;
  recordName: string;
  message?: string;
  additionalContent?: string | ReactNode;
  useToggleTrueMutation: ToggleMutation<T>;
  useToggleFalseMutation: ToggleMutation<T>;
  toggleActionType: ToggleRecordActionTypes;
  onSuccess?: () => void;
}

/**
 * ApproveUnapproveRecordDialog
 * To be used specifically with Client V2 api
 */
const ToggleRecordsDialog = <T extends GenericResource>({
  ids,
  isCurrentlyTrue,
  dialogOpen,
  onClose,
  recordName,
  message,
  additionalContent,
  useToggleTrueMutation,
  useToggleFalseMutation,
  toggleActionType,
  onSuccess,
}: ToggleRecordsDialogProps<T>) => {
  const actionStrings = ToggleActionStrings[toggleActionType];

  const handleSuccess = () => {
    onClose();
    onSuccess && onSuccess();
  };

  const { handleApprove, isLoading, submitError, resetSubmitError } =
    useToggleRecords<T>({
      useToggleTrueMutation,
      useToggleFalseMutation,
      recordName,
      ids,
      isCurrentlyTrue,
      onSuccess: handleSuccess,
      actionStrings: {
        successToggledTrue: actionStrings.successToggledTrue,
        successToggledFalse: actionStrings.successToggledFalse,
      },
    });

  useEffect(() => {
    if (!dialogOpen) {
      resetSubmitError();
    }
  }, [dialogOpen]);

  return (
    <ExtendedDialog
      title={`${
        isCurrentlyTrue
          ? actionStrings.toggleToFalse
          : actionStrings.toggleToTrue
      } ${recordName}`}
      open={dialogOpen}
      onCloseDialog={onClose}
      submitButtonCopy={
        isCurrentlyTrue
          ? actionStrings.toggleToFalse
          : actionStrings.toggleToTrue
      }
      onClickPrimaryButton={handleApprove}
      formSubmitError={submitError}
      isSubmitting={isLoading}
    >
      <DialogContentText>
        {message ||
          `Are you sure you want to ${(isCurrentlyTrue
            ? actionStrings.toggleToFalse
            : actionStrings.toggleToTrue
          ).toLowerCase()} this ${ids.length.toLocaleString(
            'en-US'
          )} ${pluralizeNoun(recordName, ids.length)}?`}
      </DialogContentText>
      {additionalContent &&
        (typeof additionalContent === 'string' ? (
          <DialogContentText sx={{ mt: 2 }}>
            {additionalContent}
          </DialogContentText>
        ) : (
          <Box sx={{ mt: 2 }}>{additionalContent}</Box>
        ))}
    </ExtendedDialog>
  );
};

export default ToggleRecordsDialog;
