import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import { useTypedForm, ValidFormData } from 'hooks/useTypedForm';
import { GenericResource, ClientV2POSTRequest } from 'types/api';
import {
  TypedRecordDialogReturn,
  TypedCreateRecordDialogProps,
} from 'hooks/typedRecordDialogs/types';
import { useSelector } from 'store';
import { ALL_RECORD_TYPES } from 'constants/recordTypes';
import { useCreateStockDocsV2 } from 'hooks/useCreateStockDocsV2';
import { useCreateStockDocumentRelatedObjectMutation } from 'store/slices/clientV2/stockDocuments';
import { Box } from '@mui/material';
import { HookFormDocuments } from 'ui-component/HookFormComponents/HookFormDocuments';
import InlineUploadDocumentsDropZone, {
  UnsavedStockDocument,
} from 'ui-component/StockDocuments/UploadDocuments/InlineUploadDocumentsDropZone';
import _ from 'lodash';
import { ObjectShape } from 'yup/lib/object';
import { Path, PathValue } from 'react-hook-form';
import { DeepPartial } from 'types/index';

const attachmentSchema = Yup.array().of(
  Yup.object({
    file: Yup.mixed().required(),
    documentType: Yup.string().required(),
    notes: Yup.string(),
  })
);

type AttachmentSchema = Yup.InferType<typeof attachmentSchema>;

type MergeSchemas<
  T extends Yup.ObjectSchema<ObjectShape>,
  U
> = T extends Yup.ObjectSchema<infer P> ? Yup.ObjectSchema<P & U> : never;

type WithAttachments<T extends Yup.ObjectSchema<ObjectShape>> = MergeSchemas<
  T,
  { attachments: AttachmentSchema }
>;

export function useTypedCreateRecordDialog<
  TSchema extends Yup.ObjectSchema<ObjectShape>,
  TSubmitData,
  TResponseData extends GenericResource
>(
  props: TypedCreateRecordDialogProps<TSchema, TSubmitData, TResponseData>
): TypedRecordDialogReturn<
  TSchema,
  TSubmitData,
  TResponseData,
  TypedCreateRecordDialogProps<TSchema, TSubmitData, TResponseData>,
  'BaseCreateRecordDialog'
> {
  const validationSchema = useMemo(() => {
    if (props.allowAttachments) {
      const mergedSchema = props.validationSchema.concat(
        Yup.object({
          attachments: attachmentSchema,
        })
      );
      return mergedSchema as unknown as TSchema;
    }
    return props.validationSchema;
  }, [props.validationSchema, props.allowAttachments]);

  const methods = useTypedForm<TSchema>(validationSchema, {
    defaultValues: props.defaultValues,
    mode: 'all',
  });

  const { handleSubmit, reset } = methods;

  const BaseCreateRecordDialog = useMemo(() => {
    const DialogComponent: TypedRecordDialogReturn<
      TSchema extends Yup.ObjectSchema<infer P>
        ? (typeof props)['allowAttachments'] extends true
          ? WithAttachments<TSchema>
          : TSchema
        : never,
      TSubmitData,
      TResponseData,
      TypedCreateRecordDialogProps<TSchema, TSubmitData, TResponseData>,
      'BaseCreateRecordDialog'
    >['BaseCreateRecordDialog'] = ({
      children,
      open,
      onClose,
      dialogProps,
      confirmClose,
      maxWidth = 'sm',
      submitDisabled = false,
    }) => {
      const [submitError, setSubmitError] = useState<string | null>(null);
      const { dispatchSuccessSnackbar } = useSnackbar();
      const navigate = useNavigate();
      const { activeOrgId } = useSelector((state) => state.org);

      const recordTypeDefinition = dialogProps.props.recordType
        ? ALL_RECORD_TYPES[dialogProps.props.recordType]
        : undefined;

      const [create, { isLoading: isLoadingCreate }] =
        dialogProps.props.useCreateMutation();

      const { isLoading: isLoadingCreateStockDoc, createStockDocuments } =
        useCreateStockDocsV2();

      const [
        createStockDocumentRelatedObject,
        { isLoading: isLoadingCreateStockDocumentRelatedObject },
      ] = useCreateStockDocumentRelatedObjectMutation();

      const handleClose = () => {
        onClose();
        reset();
        setSubmitError(null);
      };

      const onSubmit = async (data: ValidFormData<TSchema>) => {
        try {
          const docsToUpload = data.attachments as UnsavedStockDocument[];
          const dataWithoutAttachments = _.omit(
            data,
            'attachments'
          ) as ValidFormData<TSchema>;

          const submitData = {
            ...(dialogProps.props.otherProps || {}),
            ...(dialogProps.props.preSubmit
              ? dialogProps.props.preSubmit(dataWithoutAttachments)
              : dataWithoutAttachments),
          };

          const response = await create(
            submitData as ClientV2POSTRequest<TSubmitData>
          ).unwrap();

          const createdIds = _.get(response, 'createdIds', []);
          const recordId = _.first(createdIds);

          // create/link stock documents
          if (recordTypeDefinition && docsToUpload) {
            const results = await createStockDocuments(
              _.map(docsToUpload, (i) => ({
                file: i.file,
                documentType: i.documentType,
                nonBillable: true,
                notes: i.notes,
                filename: i.file.name,
                processDocument: false,
              }))
            );
            const stockDocIds = _.map(results, 'value.id') as string[];

            const joins = _.map(stockDocIds, (id) => ({
              stockDocument: id,
              relatedObjectId: recordId as string,
              relatedObjectType: recordTypeDefinition.flagshipModel,
              org: activeOrgId ?? undefined,
            }));

            await createStockDocumentRelatedObject(joins).unwrap();
          }

          if (dialogProps.props.onSave) {
            dialogProps.props.onSave(createdIds);
          }

          handleClose();
          dispatchSuccessSnackbar(
            dialogProps.props.overrideSuccessSnackbar ??
              `${dialogProps.props.recordName} Created`
          );

          if (
            dialogProps.props.goToCreatedRecord &&
            dialogProps.props.navigateToPath
          ) {
            navigate(
              `${dialogProps.props.navigateToPath}/${response.createdIds[0]}`
            );
          }
        } catch (err) {
          handleErr(err, (errMessage: string) => {
            setSubmitError(errMessage);
          });
        }
      };

      return (
        <ExtendedDialog
          onSubmit={handleSubmit(onSubmit)}
          formSubmitError={submitError}
          submitButtonCopy={dialogProps.props.submitButtonCopy || 'Save'}
          isSubmitting={
            isLoadingCreate ||
            isLoadingCreateStockDoc ||
            isLoadingCreateStockDocumentRelatedObject
          }
          confirmClose={confirmClose}
          open={open}
          submitDisabled={submitDisabled}
          isForm
          onCloseDialog={handleClose}
          title={
            dialogProps.props.overrideTitle ??
            `Create ${dialogProps.props.recordName}`
          }
          trackingNames={dialogProps.props.trackingNames}
          permissionScope={dialogProps.props.permissionScope}
          maxWidth={maxWidth}
        >
          {children}
          {dialogProps.props.allowAttachments &&
            dialogProps.props.recordType && (
              <Box sx={{ mt: 2 }}>
                <HookFormDocuments
                  name="attachments"
                  label="Attachments"
                  errors={dialogProps.methods.formState.errors}
                  control={dialogProps.methods.control}
                />
                <InlineUploadDocumentsDropZone
                  boxSx={{
                    mt: 2,
                  }}
                  recordType={dialogProps.props.recordType}
                  onDialogCloseWithoutSave={(docs) => {
                    const attachmentsPath = 'attachments' as Path<
                      DeepPartial<ValidFormData<TSchema>>
                    >;
                    const currentAttachments = (dialogProps.methods.getValues(
                      attachmentsPath
                    ) || []) as UnsavedStockDocument[];
                    dialogProps.methods.setValue(attachmentsPath, [
                      ...currentAttachments,
                      ...docs,
                    ] as PathValue<
                      DeepPartial<ValidFormData<TSchema>>,
                      typeof attachmentsPath
                    >);
                  }}
                />
              </Box>
            )}
        </ExtendedDialog>
      );
    };

    return DialogComponent;
  }, [handleSubmit, reset]);

  return {
    methods,
    control: methods.control,
    errors: methods.formState.errors,
    watch: methods.watch,
    dialogProps: {
      props,
      methods,
    },
    BaseCreateRecordDialog,
  };
}
