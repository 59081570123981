import { Stack } from '@mui/material';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { IconAdjustments, IconTrash } from '@tabler/icons-react';
import _ from 'lodash';
import { GridSelectionModel } from '@mui/x-data-grid-premium';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';

type KitScrubbingActionButtonsProps = {
  selectedRows: GridSelectionModel;
  filteredLines: DecoratedKitRequestLine[];
  onAdjustTargetQuantity: (lineIds: string[]) => void;
  onDeleteLines: (lineIds: string[]) => void;
};

const KitScrubbingActionButtons = ({
  selectedRows,
  filteredLines,
  onAdjustTargetQuantity,
  onDeleteLines,
}: KitScrubbingActionButtonsProps) => {
  const noSelectedRows = selectedRows.length === 0;

  const selectedRowObjects = filteredLines.filter((line) =>
    selectedRows.includes(line.id)
  );

  const hasZeroAllocatedRows = selectedRowObjects.some((row) => {
    const totalAllocated = _.sumBy(row.allocations, 'quant');
    return totalAllocated === 0;
  });

  const hasMismatchedQuantities = selectedRowObjects.some((row) => {
    const totalAllocated = _.sumBy(row.allocations, 'quant');
    const totalShipped = _.chain(row.shipmentLines)
      .filter((sl) => !!sl.shipment.shippedAt)
      .sumBy('shippedQuant')
      .value();
    return totalAllocated + totalShipped !== row.minimumQuantity;
  });

  return (
    <Stack direction="row" spacing={2}>
      <SegmentButton
        variant="outlined"
        startIcon={<IconAdjustments />}
        disabled={
          noSelectedRows || !hasMismatchedQuantities || hasZeroAllocatedRows
        }
        onClick={() => {
          onAdjustTargetQuantity(selectedRows as string[]);
        }}
      >
        Adjust Target Quantity
      </SegmentButton>
      <SegmentButton
        variant="outlined"
        color="error"
        startIcon={<IconTrash />}
        disabled={noSelectedRows}
        onClick={() => {
          onDeleteLines(selectedRows as string[]);
        }}
      >
        Delete
      </SegmentButton>
    </Stack>
  );
};

export default KitScrubbingActionButtons;
