import { gridClasses, GridColDef, GridToolbarExport } from '@mui/x-data-grid';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridSelectionModel,
  GridSlotsComponent,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { MutableRefObject, JSX, useState } from 'react';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconColumns, IconDownload, IconFilter } from '@tabler/icons-react';
import ExtendedQuickFilter, {
  ExtendedQuickFilterProps,
} from 'ui-component/DataGrid/ExtendedQuickFilter';
import { defaultPageSize, defaultPageSizeOptions } from 'constants/datagrid';

type SimpleDatagridBaseProps = DataGridPremiumProps & {
  apiRef: MutableRefObject<GridApiPremium>;
  columns: GridColDef[];
  onSelectionModelChange: (selectionModel: GridSelectionModel) => void;
  CustomToolbar?: JSX.Element;
  hideToolbar?: boolean;
  loading?: boolean;
  noRows?: string;
  checkboxSelection?: boolean;
  autoHeight?: boolean;
  handleSetColumnGroup?: (name: string) => void;
  showQuickSearch?: boolean;
  editable?: boolean;
  pagination?: boolean;
};

type SimpleDatagridProps = SimpleDatagridBaseProps &
  (
    | {
        showQuickSearch: false;
        extendedQuickFilterProps?: null;
      }
    | {
        showQuickSearch: true;
        extendedQuickFilterProps: ExtendedQuickFilterProps;
      }
  );

const SimpleDatagrid = ({
  apiRef,
  columns,
  onSelectionModelChange,
  CustomToolbar,
  hideToolbar,
  rows,
  loading = false,
  noRows = 'No records found',
  checkboxSelection = false,
  autoHeight = true,
  showQuickSearch,
  extendedQuickFilterProps,
  editable = false,
  pagination = true,
  ...others
}: SimpleDatagridProps) => {
  const theme = useTheme();

  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const DatagridComponents: Partial<GridSlotsComponent> = {
    Toolbar: () =>
      CustomToolbar || (
        <GridToolbarContainer>
          <Stack spacing={1.5} direction="row" alignItems="center">
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport />
            {showQuickSearch && (
              <ExtendedQuickFilter
                searchText={extendedQuickFilterProps.searchText}
                setSearchText={extendedQuickFilterProps.setSearchText}
                handleChange={extendedQuickFilterProps.handleChange}
                quickSearchFocused={extendedQuickFilterProps.quickSearchFocused}
                setQuickSearchFocused={
                  extendedQuickFilterProps.setQuickSearchFocused
                }
              />
            )}
          </Stack>
        </GridToolbarContainer>
      ),
    LoadingOverlay: LinearProgress,
    ExportIcon: IconDownload,
    OpenFilterButtonIcon: IconFilter,
    ColumnSelectorIcon: IconColumns,
    NoRowsOverlay: () => (
      <Typography sx={{ textAlign: 'center', mt: 4 }} variant="subtitle1">
        {noRows}
      </Typography>
    ),
  };

  return (
    <DataGridPremium
      {...others}
      loading={loading}
      apiRef={apiRef}
      columns={columns}
      experimentalFeatures={editable ? { newEditingApi: true } : {}}
      rows={rows}
      getRowHeight={() => 'auto'}
      autoHeight={autoHeight}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={onSelectionModelChange}
      components={DatagridComponents}
      onCellDoubleClick={(__, event) => {
        event.stopPropagation();
      }}
      disableSelectionOnClick
      pagination={pagination}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={defaultPageSizeOptions}
      sx={{
        '.MuiDataGrid-cell--withRenderer': {
          overflow: 'hidden',
          width: '100%',
        },
        [`.${gridClasses.cell}--editing:last-child`]: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        '.MuiDataGrid-detailPanel': {
          backgroundColor: theme.palette.grey[100],
        },
        '.MuiDataGrid-row--detailPanelExpanded': {
          backgroundColor: theme.palette.grey[100],
        },
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
          boxShadow: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          px: '3px',
        },
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
          {
            py: '8px',
          },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '15px',
        },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
          py: '22px',
        },
      }}
    />
  );
};

export default SimpleDatagrid;
