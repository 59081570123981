import { Stack } from '@mui/system';
import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import { KitRequestLineStatusChip } from 'hooks/useGetDecoratedKitRequestLines/KitRequestLineStatusChips';
import _ from 'lodash';
import { ExtendedGridColDef } from 'types/datagrid';
import { orgPartNoStockLotColumnDef } from 'ui-component/DataGrid/PartColumnDef';
import { kitRequestLineStatusColumnDef } from 'views/kitting/Kit/KitRequestLineTable/columns';
import { StagedChanges } from 'views/kitting/Kit/components/KitScrubbingDialog';
import { KIT_SCRUBBING_COLUMNS } from 'views/kitting/Kit/components/KitScrubbingDialog/constants';

type KitScrubbingStatusChipProps = {
  kitRequestLine: DecoratedKitRequestLine;
  stagedChanges?: StagedChanges[string];
};

export const KitScrubbingStatusChip = ({
  kitRequestLine,
  stagedChanges,
}: KitScrubbingStatusChipProps) => {
  if (stagedChanges?.deleted) {
    return (
      <Stack direction="row" spacing={1}>
        <Chip
          label="Deleted"
          chipcolor={ChipColor.error}
          sx={{ textTransform: 'uppercase' }}
        />
      </Stack>
    );
  }

  if (stagedChanges?.targetQuantity !== undefined) {
    return (
      <Stack direction="row" spacing={1}>
        <Chip
          label="Target Adjusted"
          chipcolor={ChipColor.warning}
          sx={{ textTransform: 'uppercase' }}
        />
      </Stack>
    );
  }

  return <KitRequestLineStatusChip kitRequestLine={kitRequestLine} />;
};

export const makeColumns = ({
  stagedChanges,
}: {
  stagedChanges: StagedChanges;
}): ExtendedGridColDef[] => {
  const columns: ExtendedGridColDef[] = [
    {
      ...kitRequestLineStatusColumnDef,
      field: KIT_SCRUBBING_COLUMNS.STATUS,
      renderCell: (params) => (
        <KitScrubbingStatusChip
          kitRequestLine={params.row}
          stagedChanges={stagedChanges[params.row.id]}
        />
      ),
    },
    {
      ...orgPartNoStockLotColumnDef,
      field: KIT_SCRUBBING_COLUMNS.PART,
    },
    {
      field: KIT_SCRUBBING_COLUMNS.TARGET_QUANTITY,
      headerName: 'Target Quantity',
      description: 'Required quantity for this part',
      type: 'number',
      minWidth: 130,
      flex: 0.5,
      valueGetter: (params) => {
        const changes = stagedChanges[params.row.id];
        return changes?.targetQuantity || params.row.targetQuantity || 0;
      },
    },
    {
      field: KIT_SCRUBBING_COLUMNS.ALLOCATED_QUANTITY,
      headerName: 'Allocated Quantity',
      description: 'Currently allocated quantity',
      type: 'number',
      minWidth: 130,
      flex: 0.5,
      valueGetter: (params) => _.sumBy(params.row.allocations, 'quant'),
    },
  ];

  return columns;
};
