import { useState } from 'react';
import { handleErr, pluralizeNoun } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import { DeleteMutation, GenericResource } from 'types/api';

type UseDeleteRecordsProps<T extends GenericResource> = {
  useClientDeleteMutation: DeleteMutation<T>;
  recordName: string;
  ids: string[];
  onSuccess?: () => void;
};

/**
 * useDeleteRecords
 * To be used specifically with Client V2 api & DeleteDialog
 */
const useDeleteRecord = <T extends GenericResource>({
  useClientDeleteMutation,
  recordName,
  ids,
  onSuccess,
}: UseDeleteRecordsProps<T>) => {
  const [deleteRecord, { isLoading: isLoadingDelete }] =
    useClientDeleteMutation();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();

  const handleDelete = async () => {
    setSubmitError(null);
    try {
      await deleteRecord({ ids }).unwrap();
      dispatchSuccessSnackbar(
        `${ids.length.toLocaleString('en-US')} ${pluralizeNoun(
          recordName,
          ids.length
        )} Deleted`
      );
      if (onSuccess) onSuccess();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return {
    handleDelete,
    isLoading: isLoadingDelete,
    submitError,
    resetSubmitError: () => setSubmitError(null),
  };
};

export default useDeleteRecord;
