import { AlertTitle, Grid, Stack } from '@mui/material';
import { IconAlertTriangle, IconCalendarTime } from '@tabler/icons-react';
import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { SxProps, useTheme } from '@mui/system';
import { KitRequestValidationType } from 'types/kitting';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { formatToShortDate } from 'utils/dates';
import { ValidationAlert } from 'ui-component/ValidationAlert';

type EstimatedShipDateAlertProps = {
  sx?: SxProps;
  estimatedShipDate: string | null;
  savedShipDate: string | null;
  onClick?: () => void;
};

export const EstimatedShipDateAlert = ({
  sx,
  estimatedShipDate,
  savedShipDate,
  onClick,
}: EstimatedShipDateAlertProps) => {
  const theme = useTheme();
  if (estimatedShipDate === savedShipDate) {
    return null;
  }

  return (
    <ValidationAlert
      variant="outlined"
      severity="warning"
      icon={<IconAlertTriangle />}
      color={theme.palette.warning.dark}
      sx={sx}
    >
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={9}>
          <Stack direction="column">
            <AlertTitle sx={{ fontWeight: 700 }}>
              Estimated Ship Date Has Changed
            </AlertTitle>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              <Chip
                variant="filled"
                sx={{ p: 0.5 }}
                label={`Saved: ${
                  savedShipDate ? formatToShortDate(savedShipDate) : 'None'
                }`}
                chipcolor={ChipColor.secondary}
                ChipIcon={IconCalendarTime}
              />
              <Chip
                variant="filled"
                sx={{ p: 0.5 }}
                label={`New: ${
                  estimatedShipDate
                    ? formatToShortDate(estimatedShipDate)
                    : 'None'
                }`}
                chipcolor={ChipColor[KitRequestValidationType.WARNING]}
                ChipIcon={IconCalendarTime}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <SegmentButton
            variant="contained"
            startIcon={<IconCalendarTime />}
            color="warning"
            onClick={onClick}
            fullWidth
            sx={{ color: 'white' }}
          >
            Update Ship Date
          </SegmentButton>
        </Grid>
      </Grid>
    </ValidationAlert>
  );
};

export default EstimatedShipDateAlert;
