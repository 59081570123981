import { createContext, JSX, useContext, useMemo } from 'react';
import {
  KitRequestSchema,
  KitRequestValidation,
  KitRequestValidationType,
} from 'types/kitting';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { useGetKitRequestsQuery } from 'store/slices/clientV2/kitRequests';
import { ALL, Lite } from 'types/api';
import {
  DecoratedKitRequestLine,
  useGetDecoratedKitRequestLines,
} from 'hooks/useGetDecoratedKitRequestLines';
import {
  CalculateKitRequestStatusReturn,
  EstimatedHandlingRequestData,
  estimateHandlingRequestData,
} from 'hooks/useGetDecoratedKitRequestLines/utils';
import {
  useEstimateKitShipDate,
  EstimatedShipDateResult,
} from 'hooks/useEstimateKitShipDate';
import { StockLotQuerySchemaLite } from 'types/inventory';
import _ from 'lodash';

export type KitRequestSubtype = Lite<
  KitRequestSchema,
  | 'approvedBy'
  | 'team'
  | 'productionRun'
  | 'org'
  | 'sourceLocations'
  | 'shipTo'
  | 'customProperties'
>;

type KitRequestValidationsData = {
  validations: KitRequestValidation;
  isLoading: boolean;
  isFetching: boolean;
};

type KitRequestContextType = {
  kitRequest: KitRequestSubtype | null;
  isLoadingKitRequest: boolean;
  stockLots?: StockLotQuerySchemaLite[];
  decoratedKitRequestLines?: DecoratedKitRequestLine[];
  kitRequestStatuses?: CalculateKitRequestStatusReturn;
  isLoadingKitRequestLines: boolean;
  isLoadingAllocations: boolean;
  isLoadingShipmentLines: boolean;
  isLoadingHandlingRequests: boolean;
  isLoadingStockLots: boolean;
  isLoadingDecoratedKitRequestLines: boolean;
  isFetchingDecoratedKitRequestLines: boolean;
  estimatedHandlingRequestData: EstimatedHandlingRequestData;
  estimatedShipDateData: EstimatedShipDateResult;
  validations: KitRequestValidationsData;
};

const KitRequestContext = createContext({} as KitRequestContextType);

export const useKitRequestContext = () => {
  const context = useContext(KitRequestContext);
  if (context === undefined) {
    throw new Error(
      'useKitRequestContext must be used within a KitRequestContext'
    );
  }
  return context;
};

export const KitRequestProvider = ({
  kitRequestId,
  children,
}: {
  kitRequestId: KitRequestSchema['id'] | undefined;
  children: JSX.Element;
}) => {
  const {
    data: { data: [kitRequest] } = { data: [null] },
    isLoading: isLoadingKitRequest,
  } = useServerSideQuery<KitRequestSchema, KitRequestSubtype>(
    useGetKitRequestsQuery,
    {
      filters: [
        {
          field: 'id',
          operator: 'is',
          value: kitRequestId,
        },
      ],
      schema: [
        ALL,
        {
          approvedBy: [],
          team: [],
          productionRun: [],
          org: ['id', 'name'],
          sourceLocations: [],
          shipTo: [],
          customProperties: [],
        },
      ],
    }
  );

  const {
    stockLots,
    decoratedKitRequestLines,
    kitRequestStatuses,
    isLoadingKitRequestLines,
    isLoadingAllocations,
    isLoadingShipmentLines,
    isLoadingHandlingRequests,
    isLoadingStockLots,
    isLoadingDecoratedKitRequestLines,
    isFetchingDecoratedKitRequestLines,
  } = useGetDecoratedKitRequestLines({
    selectedKitRequestIds: kitRequestId ? [kitRequestId] : [],
  });

  const estimatedHandlingRequestData = useMemo(
    () =>
      estimateHandlingRequestData({
        decoratedKitRequestLines,
        shipToLocation: kitRequest?.shipTo,
      }),
    [decoratedKitRequestLines, kitRequest?.shipTo]
  );

  const estimatedShipDateData = useEstimateKitShipDate({
    decoratedKitRequestLines,
    handlingRequestData: estimatedHandlingRequestData,
    shipPartial: kitRequest?.shipPartial,
  });

  const validations = useMemo(() => {
    if (isFetchingDecoratedKitRequestLines) {
      return {
        validations: {
          unavailableParts: {
            validationType: KitRequestValidationType.WARNING,
            lines: [],
          },
          partiallyUnavailableParts: {
            validationType: KitRequestValidationType.WARNING,
            lines: [],
          },
        },
        isLoading: true,
        isFetching: true,
      };
    }

    const completelyUnavailableLines: DecoratedKitRequestLine[] = [];
    const partiallyUnavailableLines: DecoratedKitRequestLine[] = [];

    decoratedKitRequestLines?.forEach((line) => {
      const totalAllocated = _.sumBy(line.allocations, 'quant');
      const totalShipped = _.chain(line.shipmentLines)
        .filter((sl) => !!sl.shipment.shippedAt)
        .sumBy('shippedQuant')
        .value();

      const minimumQuantity = line.minimumQuantity || 0;
      const currentTotal = totalAllocated + totalShipped;
      const minimumAdditionalToAllocate = Math.max(
        minimumQuantity - currentTotal,
        0
      );

      if (minimumAdditionalToAllocate > 0) {
        if (currentTotal === 0) {
          completelyUnavailableLines.push(line);
        } else if (currentTotal < minimumQuantity) {
          partiallyUnavailableLines.push(line);
        }
      }
    });

    return {
      validations: {
        unavailableParts: {
          validationType:
            completelyUnavailableLines.length > 0
              ? KitRequestValidationType.ERROR
              : KitRequestValidationType.WARNING,
          lines: completelyUnavailableLines.map((line) => line.id),
        },
        partiallyUnavailableParts: {
          validationType:
            partiallyUnavailableLines.length > 0
              ? KitRequestValidationType.ERROR
              : KitRequestValidationType.WARNING,
          lines: partiallyUnavailableLines.map((line) => line.id),
        },
      },
      isLoading: false,
      isFetching: false,
    };
  }, [decoratedKitRequestLines, isFetchingDecoratedKitRequestLines]);

  return (
    <KitRequestContext.Provider
      value={{
        kitRequest,
        isLoadingKitRequest,
        stockLots,
        decoratedKitRequestLines,
        kitRequestStatuses,
        isLoadingKitRequestLines,
        isLoadingAllocations,
        isLoadingShipmentLines,
        isLoadingHandlingRequests,
        isLoadingStockLots,
        isLoadingDecoratedKitRequestLines,
        isFetchingDecoratedKitRequestLines,
        estimatedHandlingRequestData,
        estimatedShipDateData,
        validations,
      }}
    >
      {children}
    </KitRequestContext.Provider>
  );
};
