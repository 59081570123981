import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { bindPopper, bindTrigger } from 'material-ui-popup-state/hooks';
import usePartsV2Search, { PartSearchSchemaType } from 'hooks/usePartsV2Search';
import {
  PartTypeFilterIds,
  PartTypeFilters,
} from 'views/parts/PartsV2/Parts/constants';
import PopupState from 'material-ui-popup-state';
import { COLOR_OPTIONS } from 'types';
import { useTheme } from '@mui/material/styles';
import PartV2Cell from 'ui-component/DataGrid/Cell/PartV2Cell';
import {
  OrgPartIcons,
  OrgPartPOSTSchema,
  OrgPartSchema,
  OrgPartSchemaLite,
  OrgPartType,
  PublicPartSchemaLite,
} from 'types/clientV2/parts';
import { RenderPartV2CellProps } from 'ui-component/DataGrid/Render/RenderPartV2Cell';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import {
  useCreateOrgPartMutation,
  useGetOrgPartsQuery,
} from 'store/slices/clientV2/parts';
import SegmentLoadingButton from 'ui-component/Segment/SegmentLoadingButton';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form';
import { ALL, QueryFilterOperators } from 'types/api';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import useServerSideQuery from 'hooks/useServerSideQuery';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { Org } from 'types/org';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { isCofactrUser } from 'utils/userPermissions';
import { useGetUserQuery } from 'store/slices/apiV1/user';

type PartPickerProps = {
  initiallySelectedParts?: OrgPartSchema['id'][] | null;
  onChange: (parts: OrgPartSchemaLite[] | null) => void;
  label?: string;
  multiple?: boolean;
  errors?: FieldErrors;
  orgId?: Org['id'];
  name: string;
  disabled?: boolean;
  confirmOnCreate?: boolean;
  partTypeFiltersToRender?: PartTypeFilterIds[];
};

/**
 * PartV2Picker component for selecting organization parts.
 *
 * @remarks
 * This component provides a flexible part selection interface with optional multi-select,
 * initial value, and blacklisting capabilities.
 *
 * @param {Object} props - The component properties
 * @param {OrgPartSchema['id'][] | null} [props.initiallySelectedParts=null] - Pre-selected part(s) to populate the picker
 *   Only include OrgPartSchema UUIDs
 * @param {function(OrgPartSchemaLite[] | null): void} props.onChange - Callback triggered when part selection changes
 *   Receives the updated array of selected parts or null
 * @param {string} [props.label='Part'] - Custom label for the picker input
 * @param {boolean} [props.multiple=false] - Enable or disable multiple part selection
 * @param {FieldErrors} [props.errors] - Optional validation errors to display
 * @param {string} props.name - Unique identifier for the picker input
 * @param {boolean} [props.disabled=false] - Disable the entire picker input
 * @param {PartTypeFilterIds[]} props.partTypeFiltersToRender - List of Part Type Filters to use, out of Both, Org, or Public
 *
 * @returns {JSX.Element} Rendered part picker component
 *
 * @example
 *
 * <PartV2Picker
 *   name="altParts"
 *   onChange={handlePartChange}
 *   multiple
 * />
 */
const PartV2Picker: FC<PartPickerProps> = ({
  initiallySelectedParts = null,
  onChange,
  label = 'Part',
  multiple = false,
  errors,
  orgId,
  name,
  disabled,
  confirmOnCreate = false,
  partTypeFiltersToRender,
}) => {
  const theme = useTheme();
  const { dispatchErrorSnackbar } = useSnackbar();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [partToConfirm, setPartToConfirm] =
    useState<PartSearchSchemaType | null>(null);

  const errorMessage = _.get(errors, name, undefined)?.message;

  const autocompleteRef = useRef(null);
  const [selectedParts, setSelectedParts] = useState<OrgPartSchemaLite[]>([]);
  const [publicPartIdToBeAdded, setPublicPartIdToBeAdded] = useState<
    string | null
  >(null);

  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const { data: user } = useGetUserQuery();
  const { viewAsStaff } = useSelector((state) => state.user);
  const isCofactr = isCofactrUser({ user, viewAsStaff });

  const selectedPartTypeFilters = useMemo(() => {
    if (org?.onlyOrgPartLibrary && !isCofactr) {
      return {
        [PartTypeFilterIds.ORG]: PartTypeFilters[PartTypeFilterIds.ORG],
      };
    }
    if (partTypeFiltersToRender) {
      return partTypeFiltersToRender.reduce((acc, id) => {
        if (PartTypeFilters[id]) {
          acc[id] = PartTypeFilters[id];
        }
        return acc;
      }, {} as Record<PartTypeFilterIds, (typeof PartTypeFilters)[PartTypeFilterIds]>);
    }
    return PartTypeFilters;
  }, [org?.onlyOrgPartLibrary, partTypeFiltersToRender]);

  useEffect(() => {
    if (org?.onlyOrgPartLibrary) {
      setSelectedPartType(PartTypeFilterIds.ORG);
    } else if (partTypeFiltersToRender?.length) {
      setSelectedPartType(partTypeFiltersToRender[0]);
    }
  }, [org?.onlyOrgPartLibrary, partTypeFiltersToRender]);

  const { data: initialParts, isLoading: isLoadingInitialParts } =
    useServerSideQuery<OrgPartSchema, OrgPartSchemaLite>(
      useGetOrgPartsQuery,
      initiallySelectedParts?.length
        ? {
            schema: [ALL],
            filters: [
              {
                field: 'id',
                operator: QueryFilterOperators.isAnyOf,
                value: initiallySelectedParts,
              },
            ],
            noCache: true,
          }
        : skipToken
    );

  useEffect(() => {
    if (initialParts?.data) {
      setSelectedParts((prevSelectedParts) => [
        ...prevSelectedParts,
        ...initialParts.data,
      ]);
    }
  }, [initialParts]);

  const selectedPartIds = useMemo(
    () =>
      selectedParts.flatMap((part) =>
        [part.id, part.publicPart].filter(Boolean)
      ),
    [selectedParts]
  );

  const [addPartToLibrary, { isLoading: isLoadingAddPartToLibrary }] =
    useCreateOrgPartMutation();

  const {
    selectedPartType,
    setSelectedPartType,
    orgParts,
    publicParts,
    bothParts,
    searchString,
    handleSetSearchString,
  } = usePartsV2Search();

  const currentParts = useMemo(() => {
    switch (selectedPartType) {
      case PartTypeFilterIds.ORG:
        return orgParts.foundOrgParts || [];
      case PartTypeFilterIds.PUBLIC:
        return publicParts.foundPublicParts || [];
      default:
        return bothParts.parts || [];
    }
  }, [
    selectedPartType,
    orgParts.foundOrgParts,
    publicParts.foundPublicParts,
    bothParts.parts,
  ]);

  const isLoadingPartList = useMemo(() => {
    switch (selectedPartType) {
      case PartTypeFilterIds.ORG:
        return orgParts.isLoading;
      case PartTypeFilterIds.PUBLIC:
        return publicParts.isLoading;
      default:
        return bothParts.isLoading;
    }
  }, [
    selectedPartType,
    orgParts.isLoading,
    publicParts.isLoading,
    bothParts.isLoading,
  ]);

  const handleChangeSelectedPartType = (
    _e: SyntheticEvent,
    newValue: PartTypeFilterIds
  ) => {
    if (newValue !== null && newValue !== undefined) {
      setSelectedPartType(newValue);
    }
  };

  const removePart = (part: OrgPartSchemaLite, publicPartId: string | null) => {
    if (multiple) {
      setSelectedParts((prevSelectedParts) => {
        const newSelectedParts = prevSelectedParts.filter(
          (p) => p.id !== part.id && p.id !== publicPartId
        );
        onChange(newSelectedParts);
        return newSelectedParts;
      });
    } else {
      setSelectedParts([]);
      onChange([]);
    }
  };

  const addPart = (part: OrgPartSchemaLite) => {
    setSelectedParts((prevSelectedParts) => {
      const newSelectedParts = multiple ? [...prevSelectedParts, part] : [part];
      onChange(newSelectedParts);
      return newSelectedParts;
    });
  };

  const handleSelectPart = useCallback(
    (part: OrgPartSchemaLite) => {
      const publicPart: OrgPartSchemaLite['publicPart'] | null = _.get(
        part,
        'publicPart',
        null
      );

      if (
        selectedPartIds.includes(part.id) ||
        (publicPart && selectedPartIds.includes(publicPart))
      ) {
        removePart(part, publicPart);
      } else {
        addPart(part);
      }
    },
    [multiple, selectedPartIds, removePart, addPart]
  );

  const handleAddAndSelect = async (part: PartSearchSchemaType) => {
    setPublicPartIdToBeAdded(part.id);
    let newPartId;
    try {
      const newPartData = await addPartToLibrary({
        publicPartId: part.id,
        partType: OrgPartType.OTS,
        ...(orgId ? { org: orgId } : {}),
      } as OrgPartPOSTSchema).unwrap();
      newPartId = newPartData.createdIds[0];
    } catch (err) {
      handleErr(err, (_errMessage: string) => {
        dispatchErrorSnackbar('Error adding part to library');
      });
    } finally {
      setPublicPartIdToBeAdded(null);
    }

    if (newPartId) {
      const newOrgPart = {
        ...part.part,
        publicPart: part.id,
        id: newPartId,
      } as OrgPartSchemaLite;

      handleSelectPart(newOrgPart);
    }
  };

  const value = useMemo(
    () => (multiple ? selectedParts : selectedParts[0] ?? null),
    [multiple, selectedParts]
  );

  return (
    <>
      <PopupState variant="popper" popupId="part-picker-popup">
        {(popupState) => (
          <ClickAwayListener onClickAway={popupState.close}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete<OrgPartSchemaLite, boolean, undefined, false>
                  ref={autocompleteRef}
                  fullWidth
                  multiple={multiple}
                  value={value}
                  options={[]}
                  getOptionLabel={(option) => option?.mpn || ''}
                  disabled={disabled}
                  renderTags={(v, getTagProps) =>
                    v.map((option, index) => {
                      const partType = _.get(
                        option,
                        'partType',
                        OrgPartType.OTS
                      );
                      const Icon = OrgPartIcons[partType];
                      return (
                        <Chip
                          {...getTagProps({ index })}
                          label={option.mpn ?? ''}
                          style={{ textTransform: 'uppercase' }}
                          color="secondary"
                          icon={<Icon />}
                          onDelete={() => {
                            handleSelectPart(option);
                          }}
                        />
                      );
                    })
                  }
                  onChange={(
                    _e: SyntheticEvent,
                    newValue: OrgPartSchemaLite | OrgPartSchemaLite[] | null
                  ) => {
                    if (!newValue) {
                      setSelectedParts([]);
                      onChange([]);
                    }
                  }}
                  popupIcon={
                    <ArrowDropDownIcon
                      {...bindTrigger(popupState)}
                      onClick={(e) => {
                        e.stopPropagation();
                        popupState.isOpen
                          ? popupState.close()
                          : popupState.open();
                      }}
                      sx={{ cursor: 'pointer' }}
                    />
                  }
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={label}
                        onFocus={() => popupState.open()}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        error={!!errorMessage}
                        // This isn't actually a duplicate prop. Note casing of the fields.
                        /* eslint-disable-next-line react/jsx-no-duplicate-props */
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              {isLoadingInitialParts && (
                                <InputAdornment position="start">
                                  <WithSkeleton isLoading>
                                    <Typography>lorem ipsum mpn</Typography>
                                  </WithSkeleton>
                                </InputAdornment>
                              )}
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                      {errorMessage && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-name"
                        >
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                  PopperComponent={() => null}
                  {...bindTrigger(popupState)}
                />
              </Box>
              <Popper
                {...bindPopper(popupState)}
                anchorEl={autocompleteRef.current}
                placement="bottom-start"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 8],
                    },
                  },
                  {
                    name: 'sameWidth',
                    enabled: true,
                    fn: ({ state }) => {
                      state.styles.popper.width = `${state.rects.reference.width}px`;
                      return state;
                    },
                    phase: 'beforeWrite',
                    requires: ['computeStyles'],
                  },
                ]}
                style={{ zIndex: 1300 }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    maxHeight: 500,
                    overflow: 'hidden',
                    minWidth: 'max-content',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ToggleButtonGroup
                      value={selectedPartType}
                      exclusive
                      onChange={handleChangeSelectedPartType}
                      color={COLOR_OPTIONS.success}
                      sx={{ mx: 2, my: 1 }}
                    >
                      {Object.values(selectedPartTypeFilters).map((filter) => (
                        <ToggleButton key={filter.id} value={filter.id}>
                          {filter.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <TextField
                      fullWidth
                      value={searchString}
                      onChange={(e) => handleSetSearchString(e.target.value)}
                      placeholder="Search Parts"
                      size="small"
                    />
                  </Box>
                  <Divider />
                  <Box sx={{ maxHeight: 320, overflow: 'auto' }}>
                    {isLoadingPartList ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          py: 4,
                        }}
                      >
                        <CircularProgress
                          size={40}
                          thickness={2.5}
                          sx={{ color: theme.palette.primary[600] }}
                        />
                      </Box>
                    ) : currentParts.length === 0 ? (
                      <Box
                        sx={{
                          py: 4,
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="subtitle1">
                          {!searchString.trim()
                            ? 'Enter a search term to find parts'
                            : 'No parts found matching your search'}
                        </Typography>
                      </Box>
                    ) : (
                      currentParts.map((row) => {
                        const { part, partDataType, source } = row;
                        const publicPart = _.get(part, 'publicPart', undefined);
                        const isPublicPartDataType =
                          partDataType === PartTypeFilterIds.PUBLIC;
                        const isOnlyPublicPart =
                          source === PartTypeFilterIds.PUBLIC;
                        const isSelected =
                          selectedPartIds.includes(part.id) ||
                          (publicPart && selectedPartIds.includes(publicPart));

                        const props = {
                          orgPart: isPublicPartDataType
                            ? undefined
                            : (part as OrgPartSchemaLite),
                          publicPart: isPublicPartDataType
                            ? (part as PublicPartSchemaLite)
                            : undefined,
                          searchParams: searchString,
                        } as RenderPartV2CellProps;
                        return (
                          <Grid
                            key={row.id}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            sx={{
                              padding: theme.spacing(1, 2),
                              borderBottom: `1px solid ${theme.palette.divider}`,
                              '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                              },
                              '&:last-child': {
                                borderBottom: 'none',
                              },
                            }}
                          >
                            <Grid
                              container
                              item
                              xs={8}
                              justifyContent="flex-start"
                            >
                              <PartV2Cell {...props} />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={4}
                              justifyContent="flex-end"
                            >
                              {isOnlyPublicPart && !isSelected && (
                                <SegmentLoadingButton
                                  variant="outlined"
                                  startIcon={<IconPlus size={16} />}
                                  onClick={async () => {
                                    if (confirmOnCreate) {
                                      setPartToConfirm(row);
                                      setConfirmDialogOpen(true);
                                    } else {
                                      await handleAddAndSelect(row);
                                      !multiple && popupState.close();
                                    }
                                  }}
                                  loading={
                                    isLoadingAddPartToLibrary &&
                                    publicPartIdToBeAdded === row.part.id
                                  }
                                >
                                  Add & Select
                                </SegmentLoadingButton>
                              )}
                              {!isOnlyPublicPart && !isSelected && (
                                <SegmentButton
                                  variant="outlined"
                                  startIcon={<IconCheck size={16} />}
                                  color={isSelected ? 'primary' : 'inherit'}
                                  onClick={() => {
                                    handleSelectPart(
                                      row.part as OrgPartSchemaLite
                                    );
                                    !multiple && popupState.close();
                                  }}
                                >
                                  Select
                                </SegmentButton>
                              )}
                              {isSelected && (
                                <Typography
                                  color={theme.palette.success.main}
                                  sx={{ pr: 2 }}
                                >
                                  Selected
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Box>
                </Paper>
              </Popper>
            </Box>
          </ClickAwayListener>
        )}
      </PopupState>
      {confirmOnCreate && (
        <ExtendedDialog
          open={confirmDialogOpen}
          onCloseDialog={() => {
            setConfirmDialogOpen(false);
            setPartToConfirm(null);
          }}
          title="Confirm Add Part"
          submitButtonCopy="Add & Select"
          onClickPrimaryButton={async () => {
            if (partToConfirm) {
              await handleAddAndSelect(partToConfirm);
              setConfirmDialogOpen(false);
              setPartToConfirm(null);
            }
          }}
        >
          <Typography>
            Are you sure you want to add {partToConfirm?.part.mpn} to your
            library?
          </Typography>
        </ExtendedDialog>
      )}
    </>
  );
};

export default PartV2Picker;
