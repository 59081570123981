import React, { FC, ReactNode, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  DialogProps,
  Alert,
  Stack,
  Box,
} from '@mui/material';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { IconX } from '@tabler/icons-react';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { PermissionScope } from 'types/apps';
import SegmentLoadingButton from 'ui-component/Segment/SegmentLoadingButton';
import { useDeviceContext } from 'hooks/useDeviceContext';
import ConfirmDialog from 'ui-component/shared/ConfirmDialog';
import { ColorOptions } from 'types';

export type DialogTrackingNames = {
  close?: string;
  cancel?: string;
  save?: string;
};

// Omit the title from DialogProps to avoid type conflicts
interface CommonDialogProps extends DialogProps {
  title: string;
  onCloseDialog: () => void;
  trackingNames?: DialogTrackingNames;
  permissionScope?: PermissionScope;
  isSubmitting?: boolean;
  formSubmitError?: string | null;
  cancelButtonCopy?: string;
  submitButtonCopy?: string;
  submitDisabled?: boolean;
  submitButtonColor?: ColorOptions;
  additionalContent?: React.ReactNode;
  overrideComponents?: {
    actions?: ReactNode;
    actionAlert?: ReactNode;
  };
  noActions?: boolean;
  confirmClose?: boolean;
  confirmCloseBodyText?: string;
  hideCloseButton?: boolean;
}

export interface FormDialogProps extends CommonDialogProps {
  isForm: true;
  onSubmit: (e: React.FormEvent) => void;
  onClickPrimaryButton?: never;
  additionalContentAboveForm?: React.ReactNode;
}

export interface NonFormDialogProps extends CommonDialogProps {
  isForm?: false;
  onSubmit?: never;
  onClickPrimaryButton?: () => void;
  additionalContentAboveForm?: never;
}

export type ExtendedDialogProps = FormDialogProps | NonFormDialogProps;

export const ExtendedDialog: FC<ExtendedDialogProps> = ({
  children,
  onCloseDialog,
  title,
  trackingNames,
  permissionScope,
  isSubmitting = false,
  isForm = false,
  onSubmit,
  formSubmitError,
  cancelButtonCopy,
  onClickPrimaryButton,
  submitButtonCopy,
  submitDisabled = false,
  submitButtonColor = 'primary',
  additionalContent,
  additionalContentAboveForm,
  overrideComponents = {},
  fullWidth = true,
  maxWidth = 'sm',
  noActions = false,
  confirmClose,
  confirmCloseBodyText,
  hideCloseButton = false,
  ...rest
}) => {
  const { desktopScreen } = useDeviceContext();

  const dialogContent = <DialogContent>{children}</DialogContent>;

  const additionalDialogContent = additionalContent ? (
    <Box sx={{ px: 3 }}>{additionalContent}</Box>
  ) : (
    <></>
  );

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleClose = () => {
    if (confirmClose) {
      setConfirmDialogOpen(true);
    } else {
      onCloseDialog();
    }
  };

  const defaultActions = (
    <Stack direction={desktopScreen ? 'row' : 'column-reverse'}>
      <SegmentButton
        onClick={handleClose}
        size={desktopScreen ? 'medium' : 'large'}
        color="primary"
        sx={{ mr: 1 }}
        trackingName={trackingNames?.cancel}
        fullWidth={!desktopScreen}
      >
        {cancelButtonCopy || 'Cancel'}
      </SegmentButton>
      <SegmentLoadingButton
        loading={isSubmitting}
        type={isForm ? 'submit' : 'button'}
        {...(onClickPrimaryButton ? { onClick: onClickPrimaryButton } : {})}
        size={desktopScreen ? 'medium' : 'large'}
        color={submitButtonColor}
        variant="contained"
        permissionScope={permissionScope}
        trackingName={trackingNames?.save}
        disabled={submitDisabled}
        fullWidth={!desktopScreen}
      >
        {submitButtonCopy || 'Submit'}
      </SegmentLoadingButton>
    </Stack>
  );

  const actions = overrideComponents?.actions || defaultActions;

  const dialogActions = noActions ? (
    <></>
  ) : (
    <DialogActions sx={{ px: 3 }}>
      <Stack spacing={2} width="100%">
        {overrideComponents?.actionAlert && overrideComponents?.actionAlert}
        {formSubmitError && (
          <Alert severity="error" sx={{ my: 2 }}>
            {formSubmitError}
          </Alert>
        )}
        {desktopScreen && (
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%"
          >
            {actions}
          </Stack>
        )}
        {!desktopScreen && actions}
      </Stack>
    </DialogActions>
  );

  const onSubmitPreventDefault = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit && onSubmit(e);
  };

  if (!rest.open) return null;

  return (
    <>
      {confirmClose && confirmDialogOpen ? (
        <ConfirmDialog
          title="Confirm Close"
          dialogText={
            confirmCloseBodyText ??
            `Are you sure you want to close the ${title} dialog? You will lose all progress.`
          }
          dialogOpen={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => {
            onCloseDialog();
            setConfirmDialogOpen(false);
          }}
          confirmButtonText="Close Dialog"
        />
      ) : null}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        fullScreen={!desktopScreen}
        {...rest}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs>
              {title}
            </Grid>
            {!hideCloseButton && (
              <Grid item xs="auto">
                <SegmentIconButton
                  onClick={handleClose}
                  trackingName={trackingNames?.close}
                  aria-label="Close Dialog"
                >
                  <IconX />
                </SegmentIconButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        {isForm ? (
          <>
            {additionalContentAboveForm && (
              <Box sx={{ px: 3, py: 2 }}>{additionalContentAboveForm}</Box>
            )}
            <form
              onSubmit={onSubmitPreventDefault}
              style={{
                // this style is require to fix issues with scrolling per https://github.com/mui/material-ui/issues/13253
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {dialogContent}
              {dialogActions}
              {additionalDialogContent}
            </form>
          </>
        ) : (
          <>
            {dialogContent}
            {dialogActions}
            {additionalDialogContent}
          </>
        )}
      </Dialog>
    </>
  );
};
