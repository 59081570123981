import { useDispatch } from 'react-redux';
import { AlertProps } from '@mui/material/Alert/Alert';
import { openSnackbar } from 'store/slices/snackbar';
import { SnackbarProps } from 'types/snackbar';
import { useCallback } from 'react';

export type SnackbarDispatchType = {
  message: string;
  color?: AlertProps['color'];
  navigateTo?: SnackbarProps['navigateTo'];
  customAction?: SnackbarProps['customAction'];
};

export default function useSnackbar() {
  const dispatch = useDispatch();

  const dispatchSnackbar = useCallback(
    ({ message, color, navigateTo, customAction }: SnackbarDispatchType) => {
      dispatch(
        openSnackbar({
          open: true,
          message,
          variant: 'alert',
          alert: {
            color,
          },
          close: true,
          navigateTo: navigateTo ?? undefined,
          customAction: customAction ?? undefined,
        })
      );
    },
    [dispatch]
  );

  const dispatchSuccessSnackbar = useCallback(
    (
      message: SnackbarDispatchType['message'],
      navigateTo?: SnackbarDispatchType['navigateTo'],
      customAction?: SnackbarDispatchType['customAction']
    ) =>
      dispatchSnackbar({ message, color: 'success', navigateTo, customAction }),
    [dispatchSnackbar]
  );

  const dispatchErrorSnackbar = useCallback(
    (
      message: SnackbarDispatchType['message'],
      navigateTo?: SnackbarDispatchType['navigateTo'],
      customAction?: SnackbarDispatchType['customAction']
    ) =>
      dispatchSnackbar({ message, color: 'error', navigateTo, customAction }),
    [dispatchSnackbar]
  );

  return {
    dispatchSuccessSnackbar,
    dispatchErrorSnackbar,
  };
}
