import { yupResolver } from '@hookform/resolvers/yup';
import {
  DefaultValues,
  FieldValues,
  UseFormReturn,
  useForm,
  Path,
  PathValue,
} from 'react-hook-form';
import { useState } from 'react';
import {
  ExtendedDialog,
  DialogTrackingNames,
} from 'ui-component/extended/ExtendedDialog';
import * as Yup from 'yup';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { PermissionScope } from 'types/apps';
import { handleErr } from 'utils/functions';
import { useNavigate } from 'react-router-dom';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';
import { Box, DialogProps } from '@mui/material';
import { ALL_RECORD_TYPE_IDS, ALL_RECORD_TYPES } from 'constants/recordTypes';
import InlineUploadDocumentsDropZone, {
  UnsavedStockDocument,
} from 'ui-component/StockDocuments/UploadDocuments/InlineUploadDocumentsDropZone';
import { useSelector } from 'store';
import { useCreateStockDocumentRelatedObjectMutation } from 'store/slices/clientV2/stockDocuments';
import { useCreateStockDocsV2 } from 'hooks/useCreateStockDocsV2';
import { HookFormDocuments } from 'ui-component/HookFormComponents/HookFormDocuments';

type BaseCreateRecordDialogProps<
  TFormValues extends FieldValues = FieldValues & {
    attachments?: UnsavedStockDocument[];
  },
  TSubmitData = object
> = {
  formConstants: {
    [id: string]: {
      id: string;
      label: string;
    };
  };
  validationSchema: Yup.ObjectSchema<any>;
  newRecordDefaults?: Partial<TFormValues>;
  onSave?: (record: object) => void;
  onSaveClientV2?: (recordId: string) => void;
  useCreateMutation: UseMutation<any>;
  preSubmit?: (data: TFormValues) => TSubmitData;
  navigateToPath?: string;
  goToCreatedRecord?: boolean;
  otherProps?: object;
  submitAsPayload?: boolean;
  arrayBody?: boolean;
  trackingNames?: DialogTrackingNames;
  permissionScope?: PermissionScope;
  submitButtonCopy?: string;
  recordType?: ALL_RECORD_TYPE_IDS;
  allowAttachments?: boolean;
};

type CreateRecordDialogProps<
  TFormValues extends FieldValues = FieldValues,
  TSubmitData = any
> = BaseCreateRecordDialogProps<TFormValues, TSubmitData> &
  (
    | {
        overrideTitle: string;
        overrideSuccessSnackbar: string;
        recordName?: string;
      }
    | {
        overrideTitle?: string;
        overrideSuccessSnackbar?: string;
        recordName: string;
      }
  );

export default function useCreateRecordDialog<
  TFormValues extends FieldValues = FieldValues,
  TSubmitData = any
>(props: CreateRecordDialogProps<TFormValues, TSubmitData>) {
  const methods = useForm<TFormValues>({
    defaultValues: props.newRecordDefaults as DefaultValues<TFormValues>,
    resolver: yupResolver(props.validationSchema),
    mode: 'all',
  });

  return {
    methods,
    control: methods.control,
    errors: methods.formState.errors,
    watch: methods.watch,
    dialogProps: {
      props,
      methods,
    },
    BaseCreateRecordDialog: BaseCreateRecordDialog<TFormValues, TSubmitData>,
  };
}

const BaseCreateRecordDialog = <
  TFormValues extends FieldValues & {
    attachments?: UnsavedStockDocument[];
  } = FieldValues & { attachments?: UnsavedStockDocument[] },
  TSubmitData = any
>({
  children,
  open,
  onClose,
  dialogProps,
  confirmClose,
  maxWidth = 'sm',
}: {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  dialogProps: {
    props: CreateRecordDialogProps<TFormValues, TSubmitData>;
    methods: UseFormReturn<TFormValues>;
  };
  confirmClose?: boolean;
  maxWidth?: DialogProps['maxWidth'];
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { activeOrgId } = useSelector((state) => state.org);

  const recordTypeDefinition = dialogProps.props.recordType
    ? ALL_RECORD_TYPES[dialogProps.props.recordType]
    : undefined;

  const [create, { isLoading: isLoadingCreate }] =
    dialogProps.props.useCreateMutation();

  const { isLoading: isLoadingCreateStockDoc, createStockDocuments } =
    useCreateStockDocsV2();

  const [
    createStockDocumentRelatedObject,
    { isLoading: isLoadingCreateStockDocumentRelatedObject },
  ] = useCreateStockDocumentRelatedObjectMutation();

  const handleClose = () => {
    onClose();
    dialogProps.methods.reset();
    setSubmitError(null);
  };

  const onSubmit = async (data: TFormValues) => {
    try {
      const docsToUpload = data.attachments as UnsavedStockDocument[];
      const dataWithoutAttachments = _.omit(data, 'attachments') as TFormValues;
      const record = await create(
        dialogProps.props.arrayBody
          ? dialogProps.props.preSubmit
            ? dialogProps.props.preSubmit(dataWithoutAttachments)
            : [dataWithoutAttachments]
          : {
              ...(dialogProps.props.otherProps || {}),
              ...(dialogProps.props.submitAsPayload
                ? {
                    payload: dialogProps.props.preSubmit
                      ? dialogProps.props.preSubmit(dataWithoutAttachments)
                      : dataWithoutAttachments,
                  }
                : dialogProps.props.preSubmit
                ? dialogProps.props.preSubmit(dataWithoutAttachments)
                : dataWithoutAttachments),
            }
      ).unwrap();
      const recordId = _.get(record, 'id', _.get(record, 'createdIds[0]', ''));

      // create/link stock documents
      if (recordTypeDefinition && docsToUpload) {
        const results = await createStockDocuments(
          _.map(docsToUpload, (i) => ({
            file: i.file,
            documentType: i.documentType,
            nonBillable: true,
            notes: i.notes,
            filename: i.file.name,
            processDocument: false,
          }))
        );
        const stockDocIds = _.map(results, 'value.id');

        const joins = _.map(stockDocIds, (id) => ({
          stockDocument: id,
          relatedObjectId: recordId,
          relatedObjectType: recordTypeDefinition.flagshipModel,
          org: activeOrgId ?? undefined,
        }));

        await createStockDocumentRelatedObject(joins).unwrap();
      }

      if (dialogProps.props.onSave) {
        dialogProps.props.onSave(record as object);
      }
      if (dialogProps.props.onSaveClientV2) {
        dialogProps.props.onSaveClientV2(recordId as string);
      }
      handleClose();
      dispatchSuccessSnackbar(
        dialogProps.props.overrideSuccessSnackbar ??
          `${dialogProps.props.recordName} Created`
      );
      if (
        dialogProps.props.goToCreatedRecord &&
        dialogProps.props.navigateToPath
      ) {
        navigate(`${dialogProps.props.navigateToPath}/${recordId}`);
      }
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return (
    <ExtendedDialog
      onSubmit={dialogProps.methods.handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy={dialogProps.props.submitButtonCopy || 'Save'}
      isSubmitting={
        isLoadingCreate ||
        isLoadingCreateStockDoc ||
        isLoadingCreateStockDocumentRelatedObject
      }
      confirmClose={confirmClose}
      open={open}
      isForm
      onCloseDialog={handleClose}
      title={
        dialogProps.props.overrideTitle ??
        `Create ${dialogProps.props.recordName}`
      }
      trackingNames={dialogProps.props.trackingNames}
      permissionScope={dialogProps.props.permissionScope}
      maxWidth={maxWidth}
    >
      {children}
      {dialogProps.props.allowAttachments && dialogProps.props.recordType && (
        <Box sx={{ mt: 2 }}>
          <HookFormDocuments
            name="attachments"
            label="Attachments"
            errors={dialogProps.methods.formState.errors}
            control={dialogProps.methods.control}
          />
          <InlineUploadDocumentsDropZone
            boxSx={{
              mt: 2,
            }}
            recordType={dialogProps.props.recordType}
            onDialogCloseWithoutSave={(docs) => {
              const currentAttachments =
                dialogProps.methods.getValues(
                  'attachments' as Path<TFormValues>
                ) || [];
              dialogProps.methods.setValue(
                'attachments' as Path<TFormValues>,
                [...currentAttachments, ...docs] as PathValue<
                  TFormValues,
                  Path<TFormValues>
                >
              );
            }}
          />
        </Box>
      )}
    </ExtendedDialog>
  );
};
