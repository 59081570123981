import { apiSlice } from 'store/slices/api';
import { HOST_URL } from 'constants/envConstants';
import { URLS } from 'store/slices/constants/apiV1';
import {
  CreateBugReport,
  CreatePainPoint,
  LinearTicket,
  CreateManagedServicesIssue,
} from 'types/utility';
import { AsyncJobResponse, AsyncJobStatus } from 'types/api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SnackbarDispatchType } from 'hooks/useSnackbar';

type UpdateStore = {
  slice: string;
  endpointName: string;
  args: any;
};

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVersion: builder.query({
      query: () => ({
        url: `${HOST_URL}/appconfig.json`,
      }),
      providesTags: (response) => [{ type: 'Version', id: response?.version }],
    }),
    createBugReport: builder.mutation<LinearTicket, CreateBugReport>({
      query: (body) => ({
        url: URLS.BUG_REPORT,
        method: 'POST',
        body,
      }),
    }),
    createPainPoint: builder.mutation<LinearTicket, CreatePainPoint>({
      query: (body) => ({
        url: URLS.PAIN_POINT,
        method: 'POST',
        body,
      }),
    }),
    createManagedServicesIssue: builder.mutation<
      LinearTicket,
      CreateManagedServicesIssue
    >({
      query: (body) => ({
        url: URLS.MANAGED_SERVICES_ISSUE,
        method: 'POST',
        body,
      }),
    }),
    getJob: builder.query<
      AsyncJobResponse<any>,
      {
        jobId: string;
        updateStore?: UpdateStore;
      }
    >({
      query: ({ jobId }) => URLS.JOBS_ASYNC(jobId),
      async onQueryStarted(
        { jobId, updateStore },
        { dispatch, queryFulfilled, updateCachedData }
      ) {
        if (updateStore) {
          const { slice, endpointName, args } = updateStore;
          const { data: jobResponse } = await queryFulfilled;
          if (jobResponse.status === AsyncJobStatus.done) {
            updateCachedData(() => jobResponse.data);
          }
        }
      },
    }),
    getJobStatus: builder.query<
      Pick<AsyncJobResponse<any>, 'jobId' | 'status'>,
      {
        jobId: string;
      }
    >({
      query: ({ jobId }) => URLS.JOBS_ASYNC(jobId, true),
    }),
    getPrismaticJWT: builder.query<{ token: string }, void>({
      query: () => ({
        url: URLS.PRISMATIC_JWT,
      }),
    }),
    getCustomerApiKey: builder.mutation<
      {
        id: string;
        key: string;
      },
      {
        name: string;
        keyType: string;
        integrationId: string;
      }
    >({
      query: (body) => ({
        url: URLS.CUSTOMER_API_KEYS,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export type ReduxJob = {
  jobId: string;
  resolve?: (value: any) => void;
  reject?: (error: any) => void;
  options: {
    onCompleteSnackbar?: SnackbarDispatchType;
    onErrorSnackbar?: SnackbarDispatchType['message'];
    retrieveDataOnCompletion: boolean;
    updateStore?: UpdateStore;
    delayBeforePollJobStatus?: number;
    onSuccess?: (data: any) => void;
    onError?: () => void;
  };
};

export const initialStateUtility = {
  jobs: {} as {
    [key: string]: ReduxJob;
  },
  showNoPlanBanner: false,
};

const utility = createSlice({
  name: 'utility',
  initialState: initialStateUtility,
  reducers: {
    addJob(state, action) {
      state.jobs[action.payload.jobId] = action.payload;
    },
    removeJob(state, action: PayloadAction<string>) {
      delete state.jobs[action.payload];
    },
    setShowNoPlanBanner(state, action) {
      state.showNoPlanBanner = action.payload;
    },
  },
});

export const {
  useGetVersionQuery,
  useCreateBugReportMutation,
  useCreatePainPointMutation,
  useCreateManagedServicesIssueMutation,
  useGetJobQuery,
  useGetJobStatusQuery,
  useLazyGetJobQuery,
  useLazyGetJobStatusQuery,
  useGetPrismaticJWTQuery,
  useLazyGetPrismaticJWTQuery,
  useGetCustomerApiKeyMutation,
} = extendedApiSlice;

export const { addJob, removeJob, setShowNoPlanBanner } = utility.actions;

export default utility.reducer;
