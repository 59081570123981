import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import App from 'App';
import { BASE_PATH } from 'config';
import { store, persister } from 'store';
import { LicenseInfo } from '@mui/x-license-pro';
import 'assets/scss/style.scss';
import { ENV, IS_LOCAL, IS_REMOTE } from 'constants/envConstants';

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY || '');

if (IS_REMOTE || IS_LOCAL) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: ENV,
    // Records all sessions, but only when integration is added
    // Integration is added conditionally within AuthGuard
    replaysSessionSampleRate: 1,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 5,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={BASE_PATH}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
