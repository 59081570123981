import { Icon, IconCpu } from '@tabler/icons-react';
import CofactrIcon from 'ui-component/CofactrIcon';

type PartIconType = {
  id: PartTypeFilterIds;
  label: string;
  Icon: (() => JSX.Element) | Icon;
};

export enum PartTypeFilterIds {
  BOTH = 'both',
  ORG = 'org',
  PUBLIC = 'public',
}

const ComponentCloudIcon: PartIconType = {
  id: PartTypeFilterIds.PUBLIC,
  label: 'Cofactr Component Cloud',
  Icon: CofactrIcon,
};

const YourPartLibraryIcon: PartIconType = {
  id: PartTypeFilterIds.ORG,
  label: 'Your Part Library',
  Icon: IconCpu,
};

export const PartTypeFilters = {
  [PartTypeFilterIds.BOTH]: {
    id: PartTypeFilterIds.BOTH,
    label: 'Both',
    icons: [YourPartLibraryIcon, ComponentCloudIcon],
  },
  [PartTypeFilterIds.ORG]: {
    id: PartTypeFilterIds.ORG,
    label: 'Your Part Library',
    icons: [YourPartLibraryIcon],
  },
  [PartTypeFilterIds.PUBLIC]: {
    id: PartTypeFilterIds.PUBLIC,
    label: 'Cofactr Component Cloud',
    icons: [ComponentCloudIcon],
  },
};
