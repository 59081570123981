import { useEffect, useMemo, useState } from 'react';
import { PartTypeFilterIds } from 'views/parts/PartsV2/Parts/constants';
import { ALL, DISABLE_PAGE_LIMIT, QueryFilterOperators, UUID } from 'types/api';
import {
  OrgPartSchema,
  OrgPartSchemaLite,
  PublicPartSchema,
  PublicPartSchemaLite,
} from 'types/clientV2/parts';
import {
  useGetOrgPartsQuery,
  useGetPublicPartsQuery,
  useLazyGetOrgPartsQuery,
  useLazyGetPublicPartsQuery,
  usePrefetch,
} from 'store/slices/clientV2/parts';
import _ from 'lodash';
import useServerSideDatagrid from 'hooks/useServerSideDatagrid';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useServerSideLazyQuery } from 'hooks/useServerSideLazyQuery';

export type PartSearchSchemaType = {
  id: UUID;
  part: PublicPartSchemaLite | OrgPartSchemaLite;
  source: PartTypeFilterIds;
  partDataType: PartTypeFilterIds.ORG | PartTypeFilterIds.PUBLIC;
};

export default function usePartsV2Search() {
  const [selectedPartType, setSelectedPartType] = useState<PartTypeFilterIds>(
    PartTypeFilterIds.ORG
  );
  const [searchString, setSearchString] = useState<string>('');
  const [debouncedOrgSearch, setDebouncedOrgSearch] = useState<string>('');
  const [debouncedPublicSearch, setDebouncedPublicSearch] =
    useState<string>('');
  const [isDebouncingPublicSearch, setIsDebouncingPublicSearch] =
    useState<boolean>(false);
  const [isDebouncingOrgSearch, setIsDebouncingOrgSearch] =
    useState<boolean>(false);

  const [
    getLazyGetOrgParts,
    { data: topOrgParts, isLoading: isLoadingTopOrgParts },
  ] = useServerSideLazyQuery<OrgPartSchema, OrgPartSchemaLite>(
    useLazyGetOrgPartsQuery
  );
  const [
    getLazyGetPublicParts,
    { data: topPublicParts, isLoading: isLoadingTopPublicParts },
  ] = useServerSideLazyQuery<PublicPartSchema, PublicPartSchemaLite>(
    useLazyGetPublicPartsQuery
  );

  useEffect(() => {
    setIsDebouncingOrgSearch(true);
    const timer = setTimeout(() => {
      setDebouncedOrgSearch(searchString.trim());
      setIsDebouncingOrgSearch(false);
    }, 100);

    return () => {
      clearTimeout(timer);
      setIsDebouncingOrgSearch(false);
    };
  }, [searchString]);

  useEffect(() => {
    setIsDebouncingPublicSearch(true);
    const timer = setTimeout(() => {
      setDebouncedPublicSearch(searchString.trim());
      setIsDebouncingPublicSearch(false);
    }, 3000); // Debounce value requested by KB

    return () => {
      clearTimeout(timer);
      setIsDebouncingPublicSearch(false);
    };
  }, [searchString]);

  useEffect(() => {
    if (selectedPartType === PartTypeFilterIds.ORG) {
      orgPartSetters.setQuickFilterValues(
        debouncedOrgSearch ? [debouncedOrgSearch] : []
      );
    } else {
      publicPartSetters.setQuickFilterValues(
        debouncedPublicSearch ? [debouncedPublicSearch] : []
      );
    }
  }, [debouncedOrgSearch, debouncedPublicSearch, selectedPartType]);

  const {
    data: foundOrgParts,
    isLoading: isLoadingOrgParts,
    setters: { setSearchSchema: setOrgPartSearchSchema, ...orgPartSetters },
    queryParams: orgPartQueryParams,
  } = useServerSideDatagrid<OrgPartSchema, OrgPartSchemaLite>({
    useResourceHook: useGetOrgPartsQuery,
    usePrefetch,
    prefetchName: 'getOrgParts',
    params: {
      schema: [ALL],
      filters: [],
      searchSchema: [ALL],
      noCache: true,
    },
    skip: selectedPartType !== PartTypeFilterIds.ORG,
  });

  const {
    data: foundPublicParts,
    isLoading: isLoadingPublicParts,
    setters: {
      setSearchSchema: setPublicPartSearchSchema,
      ...publicPartSetters
    },
    queryParams: publicPartQueryParams,
  } = useServerSideDatagrid<PublicPartSchema, PublicPartSchemaLite>({
    useResourceHook: useGetPublicPartsQuery,
    usePrefetch,
    prefetchName: 'getPublicParts',
    params: {
      pageSize: 25,
      schema: [ALL],
      searchSchema: [ALL],
      noCache: true,
    },
    skip:
      !debouncedPublicSearch.trim() ||
      selectedPartType !== PartTypeFilterIds.PUBLIC,
  });

  useEffect(() => {
    setOrgPartSearchSchema([ALL]);
  }, [setOrgPartSearchSchema]);

  useEffect(() => {
    setPublicPartSearchSchema([ALL]);
  }, [setPublicPartSearchSchema]);

  useEffect(() => {
    if (debouncedOrgSearch && selectedPartType === PartTypeFilterIds.BOTH) {
      getLazyGetOrgParts({
        pageSize: 10,
        schema: [ALL],
        searchSchema: [ALL],
        search: debouncedOrgSearch,
      });
    }
  }, [debouncedOrgSearch, getLazyGetOrgParts, selectedPartType]);

  useEffect(() => {
    if (
      debouncedPublicSearch &&
      topOrgParts?.data &&
      selectedPartType === PartTypeFilterIds.BOTH
    ) {
      getLazyGetPublicParts({
        pageSize: 10,
        schema: [ALL],
        searchSchema: [ALL],
        search: debouncedPublicSearch,
        excludes: [
          {
            field: 'id',
            operator: QueryFilterOperators.isAnyOf,
            value: _.chain(topOrgParts.data)
              .map((orgPart) => orgPart.publicPart)
              .filter((id) => !!id)
              .value(),
          },
        ],
      });
    }
  }, [
    debouncedPublicSearch,
    topOrgParts?.data,
    getLazyGetPublicParts,
    selectedPartType,
  ]);

  const orgPartsForPublicPartsIds = [
    ..._.map(foundPublicParts?.data, 'id'),
    ..._.map(topPublicParts?.data, 'id'),
  ];

  const {
    data: orgPartsForPublicParts,
    isLoading: isLoadingOrgPartsForPublicParts,
  } = useServerSideQuery<
    OrgPartSchema,
    Pick<OrgPartSchemaLite, 'id' | 'publicPart'>
  >(
    useGetOrgPartsQuery,
    orgPartsForPublicPartsIds?.length && debouncedPublicSearch
      ? {
          pageSize: DISABLE_PAGE_LIMIT,
          schema: ['id', 'publicPart'],
          filters: [
            {
              field: 'publicPart.id',
              operator: QueryFilterOperators.isAnyOf,
              value: orgPartsForPublicPartsIds,
            },
          ],
        }
      : skipToken
  );

  const orgParts: PartSearchSchemaType[] = useMemo(
    () =>
      _.map(foundOrgParts?.data ?? [], (part) => ({
        id: part.id,
        part,
        source: part?.publicPart
          ? PartTypeFilterIds.BOTH
          : PartTypeFilterIds.ORG,
        partDataType: PartTypeFilterIds.ORG,
      })),
    [foundOrgParts?.data]
  );

  const orgPartsForPublicPartsData = useMemo(
    () => _.map(orgPartsForPublicParts?.data, (part) => part?.publicPart),
    [orgPartsForPublicParts?.data]
  );

  const publicPartsData: PartSearchSchemaType[] = useMemo(
    () =>
      searchString.trim()
        ? _.map(foundPublicParts?.data ?? [], (part) => ({
            id: part.id,
            part,
            source: orgPartsForPublicPartsData.includes(part.id)
              ? PartTypeFilterIds.BOTH
              : PartTypeFilterIds.PUBLIC,
            partDataType: PartTypeFilterIds.PUBLIC,
          }))
        : [],
    [foundPublicParts?.data, orgPartsForPublicPartsData, searchString]
  );

  const bothPartsData: PartSearchSchemaType[] = useMemo(() => {
    const bothOrgParts = _.map(
      topOrgParts?.data ?? [],
      (part) =>
        ({
          id: part.id,
          part,
          source: part?.publicPart
            ? PartTypeFilterIds.BOTH
            : PartTypeFilterIds.ORG,
          partDataType: PartTypeFilterIds.ORG,
        } as PartSearchSchemaType)
    );
    const bothPublicParts = _.map(
      topPublicParts?.data ?? [],
      (part) =>
        ({
          id: part.id,
          part,
          source: orgPartsForPublicPartsData.includes(part.id)
            ? PartTypeFilterIds.BOTH
            : PartTypeFilterIds.PUBLIC,
          partDataType: PartTypeFilterIds.PUBLIC,
        } as PartSearchSchemaType)
    );
    return searchString.trim() ? [...bothOrgParts, ...bothPublicParts] : [];
  }, [
    topOrgParts?.data,
    topPublicParts?.data,
    orgPartsForPublicPartsData,
    searchString,
  ]);

  const handleSetSearchString = (searchVal: string) => {
    setSearchString(searchVal);
  };

  return {
    selectedPartType,
    setSelectedPartType,
    orgParts: {
      foundOrgParts: orgParts,
      isLoading: isLoadingOrgParts || isDebouncingOrgSearch,
      rowCount: foundOrgParts?.count,
      setters: orgPartSetters,
      queryParams: orgPartQueryParams,
    },
    publicParts: {
      foundPublicParts: publicPartsData,
      isLoading:
        isLoadingPublicParts ||
        isLoadingOrgPartsForPublicParts ||
        isDebouncingPublicSearch,
      rowCount: foundPublicParts?.count,
      setters: publicPartSetters,
      queryParams: publicPartQueryParams,
    },
    bothParts: {
      parts: bothPartsData,
      isLoading:
        isLoadingTopOrgParts ||
        isLoadingTopPublicParts ||
        isLoadingOrgPartsForPublicParts,
    },
    searchString,
    handleSetSearchString,
  };
}
