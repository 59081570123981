import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  ClientV2PATCHRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ClientV2ToggleRequest,
  ClientV2ToggleResponse,
  ListResponse,
  QueryParams,
} from 'types/api';
import { StockDocument } from 'types/inventory';
import { GenerateDocumentPdf } from 'types/procurement';
import {
  RfqSchema,
  RfqLineSchema,
  RfqSupplierJoinSchema,
  RfqSupplierJoinLiteSchema,
  RfqSupplierJoinPOSTSchema,
  RfqSupplierJoinSendRfqSchema,
  RfqLinePOSTSchema,
  RfqLinePATCHSchema,
} from 'types/sourcing';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';
import { OutboundEmail } from 'types/email';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRfqsSupplierJoins: builder.query<
      ListResponse<RfqSupplierJoinSchema>,
      QueryParams<RfqSupplierJoinSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<RfqSupplierJoinSchema>,
        QueryParams<RfqSupplierJoinSchema>
      >((params) =>
        CLIENT_V2_URLS.RFQ_SUPPLIER_JOINS(
          parseQueryParams<RfqSupplierJoinSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: RfqSupplierJoinSchema) =>
                  ({
                    type: 'ClientV2RfqsSupplierJoins',
                    id,
                  } as const)
              ),
              { type: 'ClientV2RfqsSupplierJoins', id: 'LIST' },
            ]
          : [{ type: 'ClientV2RfqsSupplierJoins', id: 'LIST' }],
    }),
    getRfqs: builder.query<ListResponse<RfqSchema>, QueryParams<RfqSchema>>({
      queryFn: createClientV2QueryFn<
        ListResponse<RfqSchema>,
        QueryParams<RfqSchema>
      >((params) => CLIENT_V2_URLS.RFQS(parseQueryParams<RfqSchema>(params))),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: RfqSchema) =>
                  ({
                    type: 'Tag',
                    id,
                  } as const)
              ),
              { type: 'ClientV2Rfqs', id: 'LIST' },
            ]
          : [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    createRfqs: builder.mutation<
      ClientV2POSTResponse<RfqSchema>,
      ClientV2POSTRequest<RfqSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<RfqSchema>,
        ClientV2POSTRequest<RfqSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.RFQS(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    updateRfqs: builder.mutation<
      ClientV2PATCHResponse<RfqSchema>,
      ClientV2PATCHRequest<RfqSchema>
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.RFQS(),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    deleteRfqs: builder.mutation<
      ClientV2DELETEResponse<RfqSchema>,
      ClientV2DELETERequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.RFQS(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    createRfqSupplierJoins: builder.mutation<
      ClientV2POSTResponse<RfqSupplierJoinLiteSchema>,
      ClientV2POSTRequest<RfqSupplierJoinPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<RfqSupplierJoinLiteSchema>,
        ClientV2POSTRequest<RfqSupplierJoinPOSTSchema>
      >((data) => ({
        url: CLIENT_V2_URLS.RFQ_SUPPLIER_JOINS(),
        method: 'POST',
        body: data,
      })),
      invalidatesTags: [
        { type: 'ClientV2Rfqs', id: 'LIST' },
        { type: 'ClientV2RfqsSupplierJoins', id: 'LIST' },
      ],
    }),
    deleteRfqSupplierJoins: builder.mutation<
      ClientV2DELETEResponse<RfqSupplierJoinSchema>,
      ClientV2DELETERequest<RfqSupplierJoinSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.RFQ_SUPPLIER_JOINS(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [
        { type: 'ClientV2Rfqs', id: 'LIST' },
        { type: 'ClientV2RfqsSupplierJoins', id: 'LIST' },
      ],
    }),

    sendRfqSupplierJoins: builder.mutation<
      ClientV2POSTResponse<OutboundEmail>,
      RfqSupplierJoinSendRfqSchema
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.RFQ_SUPPLIER_JOINS_SEND_RFQ,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error) => [
        {
          type: 'OutboundEmail',
          id: 'LIST',
        },
        { type: 'ClientV2RfqsSupplierJoins', id: 'LIST' },
      ],
    }),

    getRfqLines: builder.query<
      ListResponse<RfqLineSchema>,
      QueryParams<RfqLineSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<RfqLineSchema>,
        QueryParams<RfqLineSchema>
      >((params) =>
        CLIENT_V2_URLS.RFQ_LINES(parseQueryParams<RfqLineSchema>(params))
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: RfqLineSchema) =>
                  ({ type: 'ClientV2RfqLines', id } as const)
              ),
              { type: 'ClientV2RfqLines', id: 'LIST' },
            ]
          : [{ type: 'ClientV2RfqLines', id: 'LIST' }],
    }),
    createRfqLine: builder.mutation<
      ClientV2POSTResponse<RfqLineSchema>,
      ClientV2POSTRequest<RfqLinePOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<RfqLineSchema>,
        ClientV2POSTRequest<RfqLinePOSTSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.RFQ_LINES(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'ClientV2RfqLines', id: 'LIST' }],
    }),
    updateRfqLine: builder.mutation<
      ClientV2PATCHResponse<RfqLineSchema>,
      ClientV2PATCHRequest<RfqLinePATCHSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2PATCHResponse<RfqLineSchema>,
        ClientV2PATCHRequest<RfqLinePATCHSchema>
      >((data) => ({
        url: CLIENT_V2_URLS.RFQ_LINES(),
        method: 'PATCH',
        body: data,
      })),
      invalidatesTags: (result) =>
        result
          ? [
              ...result.updatedIds.map(
                (id: RfqLineSchema['id']) =>
                  ({ type: 'ClientV2RfqLines', id } as const)
              ),
            ]
          : [{ type: 'ClientV2RfqLines', id: 'LIST' }],
    }),
    generateRfqPdf: builder.mutation<
      StockDocument,
      {
        rfqId: RfqSchema['id'];
        payload: GenerateDocumentPdf;
      }
    >({
      query: ({ rfqId, payload }) => ({
        url: CLIENT_V2_URLS.RFQ_GENERATE_PDF(rfqId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error) => [
        {
          type: 'Document',
          id: 'LIST',
        },
        {
          type: 'StockDocumentRelatedObjects',
          id: 'LIST',
        },
      ],
    }),
    deleteRfqLines: builder.mutation<
      ClientV2DELETEResponse<RfqLineSchema>,
      ClientV2DELETERequest<RfqLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.RFQ_LINES(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2RfqLines', id: 'LIST' }],
    }),
    updateRfqLines: builder.mutation<
      ClientV2PATCHResponse<RfqLineSchema>,
      ClientV2PATCHRequest<RfqLineSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2PATCHResponse<RfqLineSchema>,
        ClientV2PATCHRequest<RfqLineSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.RFQ_LINES(),
        method: 'PATCH',
        body: payload,
      })),
      invalidatesTags: [{ type: 'ClientV2RfqLines', id: 'LIST' }],
    }),
    approveRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.APPROVE_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    unapproveRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.UNAPPROVE_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    archiveRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ARCHIVE_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    unarchiveRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.UNARCHIVE_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    voidRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.VOID_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    unvoidRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.UNVOID_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    closeRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.CLOSE_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
    reopenRfqs: builder.mutation<
      ClientV2ToggleResponse<RfqSchema>,
      ClientV2ToggleRequest<RfqSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.REOPEN_RFQS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Rfqs', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetRfqsQuery,
  useLazyGetRfqsQuery,
  useCreateRfqsMutation,
  useUpdateRfqsMutation,
  usePrefetch,
  useDeleteRfqsMutation,
  useGetRfqsSupplierJoinsQuery,
  useGenerateRfqPdfMutation,
  useCreateRfqLineMutation,
  useUpdateRfqLineMutation,
  useGetRfqLinesQuery,
  useApproveRfqsMutation,
  useUnapproveRfqsMutation,
  useArchiveRfqsMutation,
  useUnarchiveRfqsMutation,
  useCloseRfqsMutation,
  useReopenRfqsMutation,
  useVoidRfqsMutation,
  useUnvoidRfqsMutation,
  useDeleteRfqLinesMutation,
  useUpdateRfqLinesMutation,
  useCreateRfqSupplierJoinsMutation,
  useSendRfqSupplierJoinsMutation,
  useDeleteRfqSupplierJoinsMutation,
} = extendedApiSlice;
