type BugReportData = {
  sessionUrl?: string;
  url?: string;
  jamUrl?: string;
};

export type CreateBugReport = BugReportData & {
  summary: string;
  severity: number;
  stepsToReproduce?: string;
  expectedResult?: string;
  workAround?: string;
  additionalInformation?: string;
};

export type CreatePainPoint = BugReportData & {
  summary: string;
  severity: number;
  stepsToReproduce?: string;
  expectedResult?: string;
  additionalInformation?: string;
};

export type LinearTicket = {
  id: string;
  identifier: string;
  title: string;
  url: string;
};

export enum WorkstationProcess {
  RECEIVING = 'Receiving',
  INGEST = 'Ingest',
  COUNTING = 'Counting',
  KITTING = 'Kitting',
  SHIPPING = 'Shipping',
  PROCUREMENT = 'Procurement',
  OTHER = 'Other',
}

export type CreateManagedServicesIssue = BugReportData & {
  summary: string;
  workstationOrProcess: WorkstationProcess;
  stockLotIdOrCfp?: string;
  additionalInformation?: string;
};
