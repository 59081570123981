import Avatar, { AvatarSize } from 'ui-component/extended/Avatars/Avatar';
import { IconHelpTriangle } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

const NoPartAvatar = ({ size }: { size: AvatarSize }) => {
  const theme = useTheme();

  return (
    <Avatar size={size} variant="rounded">
      <IconHelpTriangle color={theme.palette.error.main} />
    </Avatar>
  );
};

export default NoPartAvatar;
