import { EstimatedHandlingRequestData } from 'hooks/useGetDecoratedKitRequestLines/utils';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import { StockLotStatus } from 'types/inventory';
import _ from 'lodash';
import { useMemo } from 'react';
import { addBusinessDays, parseISO, isAfter } from 'date-fns';
import { getNowDate, formatToUniversalDate } from 'utils/dates';

// Time in minutes for each operation
export const PROCESSING_TIMES = {
  REEL: 1, // 1 minutes per reel
  SHIPMENT_LINE: 1.5, // 1.5 minutes per line
  MERGE: 2, // 2 minutes per merge
  SPLIT: 2, // 2 minutes per split
} as const;

/**
 * Props for estimating kit ship date calculations
 * @property {DecoratedKitRequestLine[]} [decoratedKitRequestLines] - Array of kit request lines with their allocations
 * @property {EstimatedHandlingRequestData} [handlingRequestData] - Pre-calculated handling request data
 * @property {boolean} [shipPartial] - If true, returns processing date without considering expected dates
 */
export type EstimateKitShipDateProps = {
  decoratedKitRequestLines?: DecoratedKitRequestLine[];
  handlingRequestData: EstimatedHandlingRequestData;
  shipPartial?: boolean;
};

/**
 * Result type for the estimated ship date calculation
 * @property {string | null} estimatedShipDate - The calculated ship date in universal format, or null if cannot be calculated
 * @property {Object | null} latestExpectedStockLot - Information about the latest expected stock lot
 */
export type EstimatedShipDateResult = {
  estimatedShipDate: string | null;
  latestExpectedStockLot:
    | {
        stockLot: DecoratedKitRequestLine['allocations'][0]['stockLot'];
        expectedBy: string | null;
      }
    | null
    | undefined;
};

/**
 * Finds the latest expected stock lot from a list of kit request lines
 */
export const findLatestExpectedStockLot = (
  decoratedKitRequestLines: DecoratedKitRequestLine[] | undefined
): EstimatedShipDateResult['latestExpectedStockLot'] => {
  if (!decoratedKitRequestLines?.length) return null;

  const expectedStockLots = decoratedKitRequestLines.flatMap((line) =>
    line.allocations
      .filter(
        (allocation) => allocation.stockLot?.status === StockLotStatus.EXPECTED
      )
      .map(({ stockLot }) => ({
        stockLot,
        expectedBy: stockLot.expectedBy,
      }))
  );

  return expectedStockLots.length
    ? _.maxBy(expectedStockLots, 'expectedBy')
    : null;
};

/**
 * Calculates the estimated processing date based on handling request data
 */
export const calculateEstimatedProcessingDate = (
  decoratedKitRequestLines: DecoratedKitRequestLine[] | undefined,
  handlingRequestData: EstimatedHandlingRequestData
): Date | null => {
  if (!decoratedKitRequestLines?.length) return null;

  const totalMinutes =
    handlingRequestData.numberOfReels * PROCESSING_TIMES.REEL +
    handlingRequestData.numberOfShipmentLines * PROCESSING_TIMES.SHIPMENT_LINE +
    handlingRequestData.numberOfMerges * PROCESSING_TIMES.MERGE +
    handlingRequestData.numberOfSplits * PROCESSING_TIMES.SPLIT;

  // Convert minutes to days (rounding up)
  const totalDays = Math.ceil(totalMinutes / (24 * 60));

  return addBusinessDays(getNowDate(), totalDays);
};

/**
 * Determines the final ship date based on processing date and expected stock lot
 *
 * If shipPartial is true, we can ship as soon as the processing is complete,
 * without waiting for expected stock lots since we don't need all items.
 *
 * If shipPartial is false, we need to wait for all expected stock lots to arrive
 * before shipping, so we use the later date between processing completion and
 * the latest expected stock lot arrival.
 */
export const calculateFinalShipDate = (
  decoratedKitRequestLines: DecoratedKitRequestLine[] | undefined,
  estimatedProcessingDate: Date | null,
  latestExpectedStockLot: EstimatedShipDateResult['latestExpectedStockLot'],
  shipPartial: boolean
): string | null => {
  if (!decoratedKitRequestLines?.length) return null;
  if (!estimatedProcessingDate) return null;

  // For partial shipments, we can ship as soon as processing is complete
  if (shipPartial) return formatToUniversalDate(estimatedProcessingDate);

  // For complete shipments, wait for expected stock if needed
  if (latestExpectedStockLot?.expectedBy) {
    const expectedDate = parseISO(latestExpectedStockLot.expectedBy);
    return isAfter(expectedDate, estimatedProcessingDate)
      ? formatToUniversalDate(expectedDate)
      : formatToUniversalDate(estimatedProcessingDate);
  }

  return formatToUniversalDate(estimatedProcessingDate);
};

/**
 * Hook to estimate the ship date for a kit based on its request lines and processing requirements
 *
 * The estimation takes into account:
 * - Number of reels needed
 * - Number of shipment lines to process
 * - Number of merges required
 * - Number of splits required
 * - Expected dates of any stock lots
 *
 * @param props - {@link EstimateKitShipDateProps}
 * @returns {@link EstimatedShipDateResult}
 */
export const useEstimateKitShipDate = ({
  decoratedKitRequestLines,
  handlingRequestData,
  shipPartial = false,
}: EstimateKitShipDateProps): EstimatedShipDateResult =>
  useMemo(() => {
    const latestExpectedStockLot = findLatestExpectedStockLot(
      decoratedKitRequestLines
    );
    const estimatedProcessingDate = calculateEstimatedProcessingDate(
      decoratedKitRequestLines,
      handlingRequestData
    );
    const estimatedShipDate = calculateFinalShipDate(
      decoratedKitRequestLines,
      estimatedProcessingDate,
      latestExpectedStockLot,
      shipPartial
    );

    return {
      estimatedShipDate,
      latestExpectedStockLot,
    };
  }, [decoratedKitRequestLines, handlingRequestData, shipPartial]);
