import { useState } from 'react';
import { handleErr, pluralizeNoun } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import {
  ClientV2ToggleRequest,
  GenericResource,
  ToggleMutation,
} from 'types/api';
import { ToggleRecordActionStrings } from 'ui-component/clientV2/ToggleRecordDialog';

export type UseToggleRecordsProps<T extends GenericResource> = {
  useToggleTrueMutation: ToggleMutation<T>;
  useToggleFalseMutation: ToggleMutation<T>;
  recordName: string;
  actionStrings: Pick<
    ToggleRecordActionStrings,
    'successToggledTrue' | 'successToggledFalse'
  >;
  ids: string[];
  isCurrentlyTrue: boolean;
  onSuccess?: () => void;
};

/**
 * useToggleRecords
 * To be used specifically with Client V2 api & ToggleRecordDialog
 * Used for things like Approve/Unapprove, Archive/Unarchive, Lock/Unlock
 */
const useToggleRecords = <T extends GenericResource>({
  useToggleTrueMutation,
  useToggleFalseMutation,
  recordName,
  actionStrings,
  ids,
  isCurrentlyTrue,
  onSuccess,
}: UseToggleRecordsProps<T>) => {
  const [toggleTrue, { isLoading: isLoadingTrue }] = useToggleTrueMutation();
  const [toggleFalse, { isLoading: isLoadingFalse }] = useToggleFalseMutation();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();

  const handleApprove = async () => {
    try {
      const payload = { ids } as ClientV2ToggleRequest<T>;
      if (isCurrentlyTrue) {
        await toggleFalse(payload).unwrap();
        dispatchSuccessSnackbar(
          `${ids.length.toLocaleString('en-US')} ${pluralizeNoun(
            recordName,
            ids.length
          )} ${actionStrings.successToggledFalse}`
        );
      } else {
        await toggleTrue(payload).unwrap();
        dispatchSuccessSnackbar(
          `${ids.length.toLocaleString('en-US')} ${pluralizeNoun(
            recordName,
            ids.length
          )} ${actionStrings.successToggledTrue}`
        );
      }
      if (onSuccess) onSuccess();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return {
    handleApprove,
    isLoading: isLoadingTrue || isLoadingFalse,
    submitError,
    resetSubmitError: () => setSubmitError(null),
  };
};

export default useToggleRecords;
