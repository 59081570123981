import { Alert, AlertProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

// Base styled component with default styling
const BaseStyledAlert = styled(Alert)({
  '& .MuiAlert-message': {
    width: '100%',
  },
});

/**
 * ValidationAlert - A customized MUI Alert component with configurable color styling
 *
 * This component extends the standard MUI Alert component with custom styling options,
 * allowing for consistent color application across the alert's border, text, icon, and buttons.
 *
 * @component
 * @example
 * ```tsx
 * <ValidationAlert color="#ff0000" severity="error">
 *   This is an error message
 * </ValidationAlert>
 * ```
 *
 * @param {Object} props - Component props
 * @param {string} props.color - The color to apply to the alert's border, text, icon, and buttons
 * @returns {JSX.Element} A styled Alert component with consistent color theming
 */
export const ValidationAlert: FC<
  Omit<AlertProps, 'color'> & { color: string }
> = ({ color, style, children, sx, ...rest }) => {
  const colorStyles = {
    border: `1px solid ${color}`,
    color,
    ...style,
  };

  return (
    <BaseStyledAlert
      {...rest}
      style={colorStyles}
      sx={{
        '& .MuiAlert-icon': {
          color,
        },
        '& .MuiButton-contained': {
          backgroundColor: color,
        },
        ...sx,
      }}
    >
      {children}
    </BaseStyledAlert>
  );
};
