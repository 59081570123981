import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { useUpdateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import { DateString } from 'types';
import { Grid, Stack, Typography } from '@mui/material';
import { formatToShortDate, convertDate } from 'utils/dates';
import { HookFormDatePicker } from 'ui-component/HookFormComponents';
import useSnackbar from 'hooks/useSnackbar';
import * as Yup from 'yup';
import { useTypedForm } from 'hooks/useTypedForm';
import { format } from 'date-fns';

type EstimatedShipDateDialogProps = {
  open: boolean;
  onClose: () => void;
  kitRequestId: string;
  currentEstimatedShipDate: DateString | null;
  savedEstimatedShipDate: DateString | null;
};

// Define the validation schema for the form
const estimatedShipDateSchema = Yup.object({
  targetShipDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      // Handle empty string or null values
      if (!originalValue) return null;
      // If it's already a Date object, return it
      if (originalValue instanceof Date) return originalValue;
      // Otherwise try to convert from string to Date
      try {
        return convertDate(originalValue);
      } catch (e) {
        return null;
      }
    }),
});

const EstimatedShipDateDialog = ({
  open,
  onClose,
  kitRequestId,
  currentEstimatedShipDate,
  savedEstimatedShipDate,
}: EstimatedShipDateDialogProps) => {
  const [updateKitRequest, { isLoading }] = useUpdateKitRequestsMutation();
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();

  const form = useTypedForm(estimatedShipDateSchema, {
    defaultValues: {
      targetShipDate: currentEstimatedShipDate
        ? convertDate(currentEstimatedShipDate)
        : null,
    },
  });

  const onSubmit = async (
    data: Yup.InferType<typeof estimatedShipDateSchema>
  ) => {
    try {
      // Convert Date back to string format for the API
      const targetShipDate = data.targetShipDate
        ? format(data.targetShipDate, 'yyyy-MM-dd')
        : null;

      await updateKitRequest({
        id: kitRequestId,
        targetShipDate,
        estimatedShipDate: currentEstimatedShipDate,
      }).unwrap();

      dispatchSuccessSnackbar('Estimated ship date updated successfully');
      onClose();
    } catch (error) {
      dispatchErrorSnackbar(error as string);
    }
  };

  return (
    <ExtendedDialog
      title="Update Estimated Ship Date"
      open={open}
      onCloseDialog={onClose}
      maxWidth="sm"
      permissionScope={{
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      }}
      isForm
      onSubmit={form.handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      submitButtonCopy="Update Ship Date"
    >
      <Stack spacing={3}>
        <Typography variant="body1">
          The estimated ship date has changed. Would you like to update it?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Saved Estimated Date:</Typography>
            <Typography variant="body1" fontWeight="bold">
              {savedEstimatedShipDate
                ? formatToShortDate(savedEstimatedShipDate)
                : 'None'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">New Estimated Date:</Typography>
            <Typography variant="body1" fontWeight="bold">
              {currentEstimatedShipDate
                ? formatToShortDate(currentEstimatedShipDate)
                : 'None'}
            </Typography>
          </Grid>
        </Grid>

        <HookFormDatePicker
          name="targetShipDate"
          label="New Estimated Ship Date"
          control={form.control}
          minDate={
            currentEstimatedShipDate
              ? convertDate(currentEstimatedShipDate)
              : undefined
          }
          fullWidth
        />
      </Stack>
    </ExtendedDialog>
  );
};

export default EstimatedShipDateDialog;
