import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { LinkTarget } from 'types';

export enum AvatarSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  BADGE = 'badge',
  XXL = 'xxl',
}

interface AvatarProps extends MuiAvatarProps {
  alt?: string;
  src?: string;
  className?: string;
  color?: string;
  target?: LinkTarget;
  href?: string;
  sx?: MuiAvatarProps['sx'];
  children?: ReactNode;
  outline?: boolean;
  size?: AvatarSize;
}

const Avatar = ({
  className,
  color,
  outline,
  size,
  sx,
  ...others
}: AvatarProps) => {
  const theme = useTheme();

  const colorSX = color &&
    !outline && {
      color: theme.palette.background.paper,
      bgcolor: `${color}.main`,
    };
  const outlineSX = outline && {
    color: color ? `${color}.main` : `primary.main`,
    bgcolor: theme.palette.background.paper,
    border: '2px solid',
    borderColor: color ? `${color}.main` : `primary.main`,
  };
  let sizeSX;
  switch (size) {
    case 'badge':
      sizeSX = {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
      };
      break;
    case 'xs':
      sizeSX = {
        width: theme.spacing(4.25),
        height: theme.spacing(4.25),
      };
      break;
    case 'sm':
      sizeSX = {
        width: theme.spacing(5),
        height: theme.spacing(5),
      };
      break;
    case 'lg':
      sizeSX = {
        width: theme.spacing(9),
        height: theme.spacing(9),
      };
      break;
    case 'xl':
      sizeSX = {
        width: theme.spacing(10.25),
        height: theme.spacing(10.25),
      };
      break;
    case 'xxl':
      sizeSX = {
        width: theme.spacing(19),
        height: theme.spacing(19),
      };
      break;
    case 'md':
      sizeSX = {
        width: theme.spacing(7.5),
        height: theme.spacing(7.5),
      };
      break;
    default:
      sizeSX = {};
  }

  return (
    <MuiAvatar
      sx={{ ...colorSX, ...outlineSX, ...sizeSX, ...sx }}
      {...others}
    />
  );
};

export default Avatar;
