import {
  Grid,
  SxProps,
  Theme,
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Avatar, { AvatarSize } from 'ui-component/extended/Avatars/Avatar';
import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { statusColorCallback } from 'ui-component/DataGrid/GridColTypeDef';
import { statusTextMapper } from 'constants/datagrid';
import { FC, ReactElement, ReactNode } from 'react';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { Icon, IconCpu, IconDotsVertical } from '@tabler/icons-react';
import HeroSection from 'ui-component/Hero/HeroSection';
import { PermissionScope } from 'types/apps';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import _ from 'lodash';

export type TextDetails = {
  value?: string;
  clipboard?: string;
  clipboardValue?: string;
  children?: ReactNode;
  editInline?: ReactElement;
  disabled?: boolean;
  tooltip?: string;
  link?: string;
  linkTooltip?: string;
};

interface MenuOption {
  label: string;
  icon: Icon;
  onClick: () => void;
}

type HeroOptions = {
  top?: TextDetails;
  middle?: TextDetails;
  additionalMiddle?: TextDetails;
  bottom?: TextDetails;
  status?: string;
} & (
  | {
      image: string;
      imageMenuOptions?: MenuOption[];
    }
  | {
      image?: string;
      imageMenuOptions?: never;
    }
);

interface HeroProps {
  isLoading: boolean;
  options: HeroOptions;
  permissionScope?: PermissionScope;
  textMapper?: { [p: string]: string };
  statusColorMapper?: { [p: string]: ChipColor };
  avatarSx?: SxProps<Theme>;
  FallbackIcon?: Icon;
}

const Hero: FC<HeroProps> = ({
  isLoading,
  options,
  permissionScope,
  textMapper,
  statusColorMapper,
  avatarSx = {},
  FallbackIcon = IconCpu,
}) => (
  <>
    {'image' in options && (
      <Grid item xs="auto">
        <WithSkeleton isLoading={isLoading}>
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <Avatar
              src={options?.image}
              size={AvatarSize.XL}
              variant="rounded"
              alt="Product image"
              sx={avatarSx}
            >
              <FallbackIcon size={48} />
            </Avatar>
            {(options?.imageMenuOptions ?? []).length > 0 && (
              <PopupState variant="popover" popupId="hero-menu">
                {(popupState) => (
                  <>
                    <Tooltip
                      title="Options"
                      sx={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                      }}
                    >
                      <IconButton
                        {...bindTrigger(popupState)}
                        size="small"
                        color="secondary"
                      >
                        <IconDotsVertical />
                      </IconButton>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                      {_.map(options?.imageMenuOptions, (option) => {
                        const OptionIcon = option.icon;
                        return (
                          <MenuItem
                            key={option.label}
                            onClick={() => {
                              option.onClick();
                              popupState.close();
                            }}
                          >
                            <ListItemIcon>
                              <OptionIcon />
                            </ListItemIcon>
                            <ListItemText>{option.label}</ListItemText>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                )}
              </PopupState>
            )}
          </Box>
        </WithSkeleton>
      </Grid>
    )}
    <Grid item xs>
      {'status' in options && (
        <WithSkeleton isLoading={isLoading} width={100}>
          <Chip
            label={(textMapper ?? statusTextMapper)[options?.status as string]}
            chipcolor={
              statusColorMapper
                ? statusColorMapper[
                    (textMapper ?? statusTextMapper)[options?.status as string]
                  ]
                : statusColorCallback(
                    (textMapper ?? statusTextMapper)[options?.status as string]
                  )
            }
            style={{ textTransform: 'uppercase' }}
            sx={{ mb: 2 }}
          />
        </WithSkeleton>
      )}
      {'top' in options && (
        <HeroSection
          isLoading={isLoading}
          width={100}
          variant="body1"
          permissionScope={permissionScope}
          {...options.top}
        />
      )}
      {'middle' in options && (
        <HeroSection
          isLoading={isLoading}
          width={150}
          sx={!options?.middle?.clipboard ? { mb: 1 } : {}}
          variant="h3"
          permissionScope={permissionScope}
          {...options.middle}
        />
      )}
      {'additionalMiddle' in options && (
        <HeroSection
          isLoading={isLoading}
          width={150}
          sx={!options?.additionalMiddle?.clipboard ? { mb: 1 } : {}}
          variant="h3"
          permissionScope={permissionScope}
          {...options.additionalMiddle}
        />
      )}
      {'bottom' in options && (
        <HeroSection
          isLoading={isLoading}
          width={100}
          variant="body1"
          permissionScope={permissionScope}
          {...options.bottom}
        />
      )}
    </Grid>
  </>
);
export default Hero;
