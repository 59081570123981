import {
  AlertTitle,
  ChipProps,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconToiletPaper,
} from '@tabler/icons-react';
import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { SxProps } from '@mui/system';
import {
  KitRequestValidation,
  KitRequestValidationNameIds,
  KitRequestValidationType,
  KitRequestValidationNameStrings,
} from 'types/kitting';
import _ from 'lodash';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { ValidationAlert } from 'ui-component/ValidationAlert';

type KitValidationChipProps = {
  severity: KitRequestValidationType;
  quantity: number;
  alertNameId?: KitRequestValidationNameIds;
  showAlertName?: boolean;
  onClick?: () => void;
  disableTooltip?: boolean;
  variant?: ChipProps['variant'];
  overrideTooltip?: string;
};

const KitValidationChip = ({
  severity,
  quantity,
  alertNameId,
  showAlertName = true,
  onClick,
  disableTooltip = false,
  variant,
  overrideTooltip,
}: KitValidationChipProps) => {
  const AlertChipIcon = {
    [KitRequestValidationType.ERROR]: IconAlertOctagon,
    [KitRequestValidationType.WARNING]: IconAlertTriangle,
  };

  const quantString = String(quantity.toLocaleString('en-US'));
  const labelAndTooltip = `${
    alertNameId ? `${KitRequestValidationNameStrings[alertNameId]} - ` : ``
  }${quantString}`;

  return (
    <Tooltip
      title={overrideTooltip || (disableTooltip ? undefined : labelAndTooltip)}
      placement="top"
    >
      <span>
        <Chip
          variant={variant}
          sx={{
            p: 0.5,
          }}
          label={showAlertName ? labelAndTooltip : quantString}
          chipcolor={ChipColor[severity]}
          ChipIcon={AlertChipIcon[severity]}
          onClick={onClick}
          outlineBgColor="white"
        />
      </span>
    </Tooltip>
  );
};

type KitScrubbingAlertProps = {
  isLoading?: boolean;
  sx?: SxProps;
  validations: KitRequestValidation;
  onClick?: () => void;
};

export const KitScrubbingAlert = ({
  isLoading = false,
  sx,
  validations,
  onClick,
}: KitScrubbingAlertProps) => {
  const theme = useTheme();

  const validationsToRender = !isLoading
    ? _.pickBy(validations, (validation) => validation.lines.length > 0)
    : {};

  const warningsOnly = !isLoading
    ? _.chain(validationsToRender)
        .filter((validation) => validation.lines.length > 0)
        .every(
          (validation) =>
            validation.validationType === KitRequestValidationType.WARNING
        )
        .value()
    : false;

  return (
    <ValidationAlert
      variant="outlined"
      icon={
        isLoading ? (
          <CircularProgress color="inherit" />
        ) : warningsOnly ? (
          <IconAlertTriangle />
        ) : (
          <IconAlertOctagon />
        )
      }
      color={
        isLoading
          ? theme.palette.info.main
          : warningsOnly
          ? theme.palette.warning.dark
          : theme.palette.error.dark
      }
      severity={isLoading ? 'info' : warningsOnly ? 'warning' : 'error'}
      sx={sx}
    >
      {isLoading ? (
        <>
          <AlertTitle sx={{ fontWeight: 700 }}>Validating Kit Lines</AlertTitle>
          Checking your Kit for potential issues.
        </>
      ) : (
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={9}>
            <Stack direction="column">
              <AlertTitle sx={{ fontWeight: 700 }}>
                {`Action ${warningsOnly ? 'Recommended' : 'Required'}`}
              </AlertTitle>
              <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                {_.map(validationsToRender, (validation, key) => (
                  <KitValidationChip
                    key={key}
                    severity={validation.validationType}
                    quantity={validation.lines.length}
                    alertNameId={key as KitRequestValidationNameIds}
                    variant="filled"
                  />
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <SegmentButton
              variant="contained"
              startIcon={<IconToiletPaper />}
              color={warningsOnly ? 'warning' : 'error'}
              onClick={onClick}
              fullWidth
              sx={{ color: 'white' }}
            >
              Fix Errors with Kit Scrubbing Tool
            </SegmentButton>
          </Grid>
        </Grid>
      )}
    </ValidationAlert>
  );
};
