import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  useGetProductionRunsQuery,
  useLazyGetProductionRunQuery,
} from 'store/slices/apiV1/production';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import { ALL_APP_IDS, ALL_APPS } from 'constants/appConstants';
import CreateEditProductionRunDialog from 'views/production/components/CreateEditProductionRunDialog';
import _ from 'lodash';
import { ProductionRun, ProductionRunLite } from 'types/production';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { TeamLite } from 'types/properties';
import { useAppAccessContext } from 'contexts/AppAccessContext';

type HookFormProductionRunAutocompleteProps = {
  children?: ReactNode;
  defaultValue?: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  sx?: SxProps<Theme>;
  helperText?: string;
  mt?: number;
  boxSx?: SxProps<Theme>;
  fullWidth?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  isInlineEdit?: boolean;
  // TODO: its super annoying to have to type this to handle both intermixed and I can foresee this being a pain point for other cases
  // I have an idea described in this ticket: https://linear.app/cofactr/issue/UI-3584/create-tolite-util-function
  // This spot would be a good first use case
  filterOptions?: (
    options: (ProductionRunLite | ProductionRun)[]
  ) => (ProductionRunLite | ProductionRun)[];
  noOptionsText?: string;
  teamId?: TeamLite['id'];
  [props: string]: unknown;
} & HookFormComponentProps;

export const HookFormProductionRunAutocomplete = ({
  errors,
  control,
  name = '',
  label,
  defaultValue,
  disabled,
  multiple = false,
  sx,
  mt = 2,
  boxSx,
  helperText,
  isInlineEdit,
  fullWidth = true,
  setValue,
  filterOptions,
  noOptionsText,
  teamId,
  shouldUnregister = false,
  ...rest
}: HookFormProductionRunAutocompleteProps) => {
  const [newProductionRunDialogOpen, setNewProductionRunDialogOpen] =
    useState(false);
  const [selected, setSelected] = useState<
    ProductionRunLite | ProductionRun | null
  >(null);
  const [contextuallyCreatedOption, setContextuallyCreatedOption] =
    useState<ProductionRun | null>(null);
  const { data: productionRuns, isLoading: isLoadingProductionRuns } =
    useGetProductionRunsQuery({});
  const [getProductionRun, { data: productionRun }] =
    useLazyGetProductionRunQuery();

  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);

  const options: (ProductionRunLite | ProductionRun)[] = useMemo(() => {
    const combinedOptions = [
      ...(productionRun ? [productionRun] : []),
      ...(productionRuns || []),
      ...(contextuallyCreatedOption ? [contextuallyCreatedOption] : []),
    ].filter((option) => option !== null && option !== undefined);

    const allOptions = _.chain(combinedOptions)
      .uniqBy('id')
      .filter((option) => {
        const optionTeamId = option?.team
          ? typeof option.team === 'string'
            ? option.team
            : option.team.id
          : undefined;
        return (
          option !== undefined &&
          (propertiesAppEnabled && teamId && optionTeamId
            ? optionTeamId === teamId
            : true)
        );
      })
      .value();

    return filterOptions ? filterOptions(allOptions) : allOptions;
  }, [productionRuns, contextuallyCreatedOption]);

  const getBatch = async (batchId: string) => {
    const batch = await getProductionRun(batchId).unwrap();
    setSelected(batch);
  };

  useEffect(() => {
    if (defaultValue) {
      const defaultProductionRun = _.find(
        productionRuns,
        (pr) => pr.id === defaultValue
      );
      if (!defaultProductionRun && typeof defaultValue === 'string') {
        getBatch(defaultValue);
      }
      setSelected(defaultProductionRun || null);
    }
  }, [defaultValue, productionRuns]);

  const AutoCompleteInput = (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field: { onChange, onBlur } }) => (
        <Autocomplete
          loading={isLoadingProductionRuns}
          fullWidth={fullWidth}
          sx={{ mt, ...sx }}
          options={options}
          onChange={(__, data) => {
            if (data?.id !== selected?.id) {
              setSelected(data);
            }
            onChange(data?.id);
          }}
          // didn't seem worth trying to make typescript accept this
          // all the get production runs are lite and the one from contextual create is the full object
          // this would only be a problem if we decide in this component to reference nested fields
          // the TODO above would fix this
          value={selected as ProductionRunLite}
          onBlur={onBlur}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={noOptionsText ?? 'No options'}
          renderInput={(params) => (
            <Box>
              <TextField {...params} label={label} fullWidth />
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Box>
          )}
          disabled={disabled}
        />
      )}
    />
  );

  return (
    <Box sx={{ ...boxSx }}>
      {disabled ? (
        AutoCompleteInput
      ) : (
        <>
          <ContextualCreateButton
            onClick={() => setNewProductionRunDialogOpen(true)}
            permissionScope={{ app: ALL_APPS.PRODUCTION.id }}
            sx={
              isInlineEdit
                ? {
                    borderRadius: '0px',
                    borderRight: 'none',
                    height: '42px',
                    p: '5px',
                    minWidth: '0',
                    position: 'relative',
                    left: '-1px',
                    '.MuiButton-startIcon': { p: 0, m: 0 },
                  }
                : undefined
            }
          >
            {AutoCompleteInput}
          </ContextualCreateButton>
          <CreateEditProductionRunDialog
            dialogOpen={newProductionRunDialogOpen}
            onClose={() => setNewProductionRunDialogOpen(false)}
            onCreateSuccess={(value) => {
              setContextuallyCreatedOption(value);
              setSelected(value);
              setValue && setValue(name, value.id);
            }}
          />
        </>
      )}
    </Box>
  );
};
