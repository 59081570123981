import { DateString, DateTimeString } from 'types';
import { OrgLite } from 'types/org';

export enum CustomPropertyModel {
  ORG = 'org',
  PRODUCTION_RUN = 'production_run',
  ORG_PART = 'org_part',
  STOCK_LOT = 'stock_lot',
  STOCK_LOCATION = 'stock_location',
  ORG_SUPPLIER = 'org_supplier',
  PURCHASE = 'purchase',
  PURCHASE_LINE = 'purchase_line',
  PURCHASE_NO_PART_LINE = 'purchase_no_part_line',
  PURCHASE_REQUEST = 'purchase_request',
  PURCHASE_REQUEST_GROUP = 'purchase_request_group',
  SUPPLIER_BILL = 'supplier_bill',
  SUPPLIER_BILL_LINE = 'supplier_bill_line',
  BOM_LINE = 'bom_line',
  BOM = 'bom',
  USER = 'user',
  SHIPMENT = 'shipment',
  SHIPMENT_LINE = 'shipment_line',
  KIT_REQUEST = 'kit_request',
  KIT_REQUEST_LINE = 'kit_request_line',
  SUPPLIER_QUOTE = 'supplier_quote',
  SUPPLIER_QUOTE_LINE = 'supplier_quote_line',
  RFQ = 'rfq',
  RFQ_LINE = 'rfq_line',
  V2_ORG_PART = 'v2_org_part',
}

export const CustomPropertyModelDisplay: Record<CustomPropertyModel, string> = {
  [CustomPropertyModel.ORG]: 'Organization',
  [CustomPropertyModel.PRODUCTION_RUN]: 'Production Run',
  [CustomPropertyModel.ORG_PART]: 'Part',
  [CustomPropertyModel.STOCK_LOT]: 'Stock Lot',
  [CustomPropertyModel.STOCK_LOCATION]: 'Stock Location',
  [CustomPropertyModel.ORG_SUPPLIER]: 'Supplier',
  [CustomPropertyModel.PURCHASE]: 'Purchase Order',
  [CustomPropertyModel.PURCHASE_LINE]: 'Purchase Order Line',
  [CustomPropertyModel.PURCHASE_NO_PART_LINE]: 'Purchase Order No Part Line',
  [CustomPropertyModel.PURCHASE_REQUEST]: 'Requisition',
  [CustomPropertyModel.PURCHASE_REQUEST_GROUP]: 'Requisition',
  [CustomPropertyModel.SUPPLIER_BILL]: 'Supplier Bill',
  [CustomPropertyModel.SUPPLIER_BILL_LINE]: 'Supplier Bill Line',
  [CustomPropertyModel.BOM_LINE]: 'Bill of Materials Line',
  [CustomPropertyModel.BOM]: 'Bill of Materials',
  [CustomPropertyModel.USER]: 'User',
  [CustomPropertyModel.SHIPMENT]: 'Shipment',
  [CustomPropertyModel.SHIPMENT_LINE]: 'Shipment Line',
  [CustomPropertyModel.KIT_REQUEST]: 'Kit Request',
  [CustomPropertyModel.KIT_REQUEST_LINE]: 'Kit Request Line',
  [CustomPropertyModel.SUPPLIER_QUOTE]: 'Quote',
  [CustomPropertyModel.SUPPLIER_QUOTE_LINE]: 'Quote Line',
  [CustomPropertyModel.RFQ]: 'RFQ',
  [CustomPropertyModel.RFQ_LINE]: 'RFQ Line',
  [CustomPropertyModel.V2_ORG_PART]: 'Org Part',
};

export enum CustomPropertyVisibility {
  NEW_RECORD_DIALOG = 'new_record_dialog',
  EDIT_RECORD_DIALOG = 'edit_record_dialog',
  DATAGRID_EDIT = 'datagrid_edit',
  DATAGRID_READ_ONLY = 'datagrid_read_only',
  INFO_SECTION = 'info_section',
  DETAIL_SIDEBAR = 'detail_sidebar',
  WAREHOUSE = 'warehouse',
}

export const CustomPropertyVisibilityDisplay: Record<
  CustomPropertyVisibility,
  string
> = {
  [CustomPropertyVisibility.NEW_RECORD_DIALOG]: 'New Record Dialog',
  [CustomPropertyVisibility.EDIT_RECORD_DIALOG]: 'Edit Record Dialog',
  [CustomPropertyVisibility.DATAGRID_EDIT]: 'Datagrid (Editable)',
  [CustomPropertyVisibility.DATAGRID_READ_ONLY]: 'Datagrid (Read Only)',
  [CustomPropertyVisibility.INFO_SECTION]: 'Detail Info Section',
  [CustomPropertyVisibility.DETAIL_SIDEBAR]: 'Detail Sidebar',
  [CustomPropertyVisibility.WAREHOUSE]: 'Warehouse App',
};

export enum CustomPropertyDataType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  USER = 'user',
  BOOLEAN = 'boolean',
  SINGLE_CHOICE = 'single_choice',
  MULTI_CHOICE = 'multi_choice',
}

export const CustomPropertyDataTypeDisplay: Record<
  CustomPropertyDataType,
  string
> = {
  [CustomPropertyDataType.STRING]: 'Text',
  [CustomPropertyDataType.NUMBER]: 'Number',
  [CustomPropertyDataType.DATE]: 'Date',
  [CustomPropertyDataType.DATETIME]: 'Date & Time',
  [CustomPropertyDataType.USER]: 'User',
  [CustomPropertyDataType.BOOLEAN]: 'Boolean',
  [CustomPropertyDataType.SINGLE_CHOICE]: 'Single Choice',
  [CustomPropertyDataType.MULTI_CHOICE]: 'Multiple Choice',
};

export type CustomPropertyValue = {
  id: string;
  value: string;
  label: string;
  metadata?: object | null;
};

export type CustomProperty = {
  id: string;
  key: string;
  inheritanceKey: string | null;
  name: string;
  active: boolean;
  defaultValue: string | null;
  nullable: boolean;
  model: CustomPropertyModel;
  visibility: CustomPropertyVisibility[];
  dataType: CustomPropertyDataType;
  values: CustomPropertyValue[];
  org: OrgLite;
};

export type CustomPropertyField = {
  [key: string]:
    | string
    | number
    | boolean
    | DateString
    | DateTimeString
    | null
    | string[];
};
