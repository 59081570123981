import { useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import { useTypedForm, ValidFormData } from 'hooks/useTypedForm';
import { DeepPartial } from 'types';
import { GenericResource } from 'types/api';
import {
  TypedRecordDialogReturn,
  TypedUpdateRecordDialogProps,
} from 'hooks/typedRecordDialogs/types';

export function useTypedUpdateRecordDialog<
  TSchema extends Yup.ObjectSchema<ObjectShape>,
  TSubmitData extends GenericResource,
  TResponseData extends GenericResource = TSubmitData
>(
  props: TypedUpdateRecordDialogProps<TSchema, TSubmitData, TResponseData>
): TypedRecordDialogReturn<
  TSchema,
  TSubmitData,
  TResponseData,
  TypedUpdateRecordDialogProps<TSchema, TSubmitData, TResponseData>,
  'BaseUpdateRecordDialog'
> {
  const methods = useTypedForm(props.validationSchema, {
    defaultValues: props.defaultValues,
    mode: 'all',
  });

  const { reset, handleSubmit } = methods;

  const { defaultValues } = props;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues as DeepPartial<ValidFormData<TSchema>>);
    }
  }, [reset, defaultValues]);

  const BaseUpdateRecordDialog = useMemo(() => {
    const DialogComponent: TypedRecordDialogReturn<
      TSchema,
      TSubmitData,
      TResponseData,
      TypedUpdateRecordDialogProps<TSchema, TSubmitData, TResponseData>,
      'BaseUpdateRecordDialog'
    >['BaseUpdateRecordDialog'] = ({
      children,
      open,
      onClose,
      dialogProps,
      confirmClose,
      maxWidth = 'sm',
    }) => {
      const [submitError, setSubmitError] = useState<string | null>(null);
      const { dispatchSuccessSnackbar } = useSnackbar();

      const [update, { isLoading: isLoadingUpdate }] =
        dialogProps.props.useUpdateMutation();

      const handleClose = () => {
        onClose();
        reset();
        setSubmitError(null);
      };

      const onSubmit = async (data: ValidFormData<TSchema>) => {
        try {
          const records = dialogProps.props.selectedRecords || [
            dialogProps.props.selectedRecord,
          ];
          const submitData = records.map((record) => ({
            ...(dialogProps.props.otherProps || {}),
            ...dialogProps.props.preSubmit(data),
            id: record.id,
            ...(dialogProps.props.idProp
              ? {
                  [dialogProps.props.idProp]: record.id,
                }
              : {}),
          })) as Partial<TSubmitData>[];

          const response = await update(submitData).unwrap();

          if (dialogProps.props.onSave) {
            dialogProps.props.onSave(response.updatedIds);
          }

          handleClose();
          dispatchSuccessSnackbar(
            dialogProps.props.overrideSuccessSnackbar ??
              `${dialogProps.props.recordName}${
                records.length > 1 ? 's' : ''
              } Updated`
          );
        } catch (err) {
          handleErr(err, (errMessage: string) => {
            setSubmitError(errMessage);
          });
        }
      };

      return (
        <ExtendedDialog
          onSubmit={handleSubmit(onSubmit)}
          formSubmitError={submitError}
          submitButtonCopy={dialogProps.props.submitButtonCopy || 'Save'}
          isSubmitting={isLoadingUpdate}
          confirmClose={confirmClose}
          open={open}
          isForm
          onCloseDialog={handleClose}
          title={
            dialogProps.props.overrideTitle ??
            `Update ${dialogProps.props.recordName}`
          }
          trackingNames={dialogProps.props.trackingNames}
          permissionScope={dialogProps.props.permissionScope}
          maxWidth={maxWidth}
        >
          {children}
        </ExtendedDialog>
      );
    };

    return DialogComponent;
  }, [handleSubmit, reset]);

  return {
    methods,
    control: methods.control,
    errors: methods.formState.errors,
    watch: methods.watch,
    dialogProps: {
      props,
      methods,
    },
    BaseUpdateRecordDialog,
  };
}
