import { Box, Grid, Stack } from '@mui/material';
import {
  Icon,
  IconCirclePlus,
  IconFilter,
  IconReload,
} from '@tabler/icons-react';
import _ from 'lodash';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useCallback } from 'react';
import Chip, { ChipColor, chipProps } from 'ui-component/extended/Chip';
import { GenericObject } from 'types';
import { actionButtonSpacing } from 'constants/themeConstants';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';

export type FilterOptionType = {
  id: string;
  label: string;
  color?: ChipColor;
  icon?: Icon;
  [key: string]: unknown;
};

interface BaseProps {
  onFilterOptionClick: (filterOptionKey: string) => void;
  resetFilters: () => void;
  activeFilters: string[];
  plusButtonClick?: () => void;
  resetToEmptyActiveFilters?: boolean;
}

// Props when customGetFilterChip is not provided, where filterOptions are FilterOptionType
interface WithBasicFilterOptionProps extends BaseProps {
  filterOptions: FilterOptionType[];
  customGetFilterChip?: never;
  additionalChipProps?: chipProps;
}

// Props when customGetFilterChip is provided, requiring T to be explicitly provided
interface WithCustomGetFilterChipProps<T extends GenericObject>
  extends BaseProps {
  filterOptions: T[];
  customGetFilterChip: ({
    filterOption,
    onClick,
  }: {
    filterOption: T;
    onClick: (filterOptionKey: string) => void;
  }) => ReactNode;
  additionalChipProps?: never;
}

const FilterBar = <T extends GenericObject = FilterOptionType>({
  filterOptions,
  onFilterOptionClick,
  resetFilters,
  activeFilters,
  customGetFilterChip,
  plusButtonClick,
  resetToEmptyActiveFilters,
  additionalChipProps,
}: WithBasicFilterOptionProps | WithCustomGetFilterChipProps<T>) => {
  const theme = useTheme();

  const handleFilterOptionClick = useCallback((key) => {
    onFilterOptionClick(key);
  }, []);

  const getFilterChip = (filterOption: FilterOptionType) => {
    const rest = _.omit(filterOption, ['id', 'label', 'color']);
    return (
      <Chip
        key={filterOption.id}
        label={filterOption.label}
        onClick={() => handleFilterOptionClick(filterOption.id)}
        chipcolor={filterOption.color}
        variant={
          activeFilters.includes(filterOption.id) ? 'filled' : 'outlined'
        }
        sx={{ m: 0 }}
        {...rest}
        {...additionalChipProps}
      />
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 1,
        borderRadius: '20px',
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <Grid item xs="auto" sx={{ mr: actionButtonSpacing }}>
        <Box display="flex" alignItems="center" minHeight="100%">
          <IconFilter />
        </Box>
      </Grid>
      <Grid item xs>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {_.map(filterOptions, (option) =>
            customGetFilterChip
              ? customGetFilterChip({
                  filterOption: option as T,
                  onClick: handleFilterOptionClick,
                })
              : getFilterChip(option as FilterOptionType)
          )}
          {!!plusButtonClick && (
            <SegmentIconButton
              size="small"
              onClick={plusButtonClick}
              sx={{ width: '32px', height: '32px' }}
            >
              <IconCirclePlus />
            </SegmentIconButton>
          )}
        </Stack>
      </Grid>
      <Grid item xs="auto">
        <Box
          display="flex"
          justifyContent="right"
          alignItems="center"
          minHeight="100%"
        >
          <SegmentButton
            onClick={resetFilters}
            startIcon={<IconReload />}
            disabled={
              resetToEmptyActiveFilters
                ? !activeFilters.length
                : activeFilters.length === filterOptions.length
            }
          >
            Reset Filters
          </SegmentButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterBar;
