import {
  Button,
  MenuItem,
  ClickAwayListener,
  Paper,
  ButtonProps,
  Popper,
  ButtonGroup,
  Grow,
  MenuList,
  Box,
  BoxProps,
} from '@mui/material';
import { ALL_RECORD_TYPE_IDS, ALL_RECORD_TYPES } from 'constants/recordTypes';
import { useDocumentTemplates } from 'hooks/useDocumentTemplates';
import useSnackbar from 'hooks/useSnackbar';
import { useGeneratePurchaseOrderPdfMutation } from 'store/slices/apiV1/procurement';
import { DocumentTemplate, StockDocument } from 'types/inventory';
import _ from 'lodash';
import { useRef, useState, MouseEvent, useEffect } from 'react';
import { handleErr } from 'utils/functions';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { IconFileExport } from '@tabler/icons-react';

interface GenerateDocumentButtonProps {
  recordType: ALL_RECORD_TYPE_IDS;
  defaultDocumentTemplateId?: DocumentTemplate['id'] | null;
  size?: ButtonProps['size'];
  additionalGenerateProps?: object;
  setSelectedDocumentId?: (id: string) => void;
  boxSx?: BoxProps['sx'];
  isLoading?: boolean;
}

const GenerateDocumentButton = ({
  recordType,
  defaultDocumentTemplateId,
  size,
  additionalGenerateProps,
  setSelectedDocumentId,
  boxSx,
  isLoading,
}: GenerateDocumentButtonProps) => {
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const recordTypeDefinition = ALL_RECORD_TYPES[recordType];
  const generatePdfMutation =
    recordTypeDefinition?.generatePdfMutation ||
    useGeneratePurchaseOrderPdfMutation;

  const [generatePdf, { isLoading: isLoadingGeneratePdf }] =
    generatePdfMutation();

  const {
    allDocumentTemplates,
    defaultDocumentTemplates,
    groupedDocumentTemplates,
    fetching: isFetchingDocumentTemplates,
  } = useDocumentTemplates();

  const documentTemplates = _.get(
    groupedDocumentTemplates,
    recordTypeDefinition.generatePdfDocumentType || '',
    []
  );

  const defaultDocumentTemplate = defaultDocumentTemplateId
    ? _.find(allDocumentTemplates, { id: defaultDocumentTemplateId }) ||
      _.get(
        defaultDocumentTemplates,
        recordTypeDefinition.generatePdfDocumentType || '',
        null
      )
    : _.get(
        defaultDocumentTemplates,
        recordTypeDefinition.generatePdfDocumentType || '',
        null
      );

  const [selectedIndex, setSelectedIndex] = useState(
    defaultDocumentTemplate?.id
      ? documentTemplates.findIndex(
          (documentTemplate) =>
            documentTemplate.id === defaultDocumentTemplate?.id
        )
      : 0
  );

  useEffect(() => {
    if (isFetchingDocumentTemplates) return;
    setSelectedIndex(
      defaultDocumentTemplate?.id
        ? documentTemplates.findIndex(
            (documentTemplate) =>
              documentTemplate.id === defaultDocumentTemplate?.id
          )
        : 0
    );
  }, [isFetchingDocumentTemplates]);

  const selectedDocumentTemplateId = documentTemplates[selectedIndex]?.id;

  const options = documentTemplates.map(
    (option) => option.externalName || option.name
  );

  const onGeneratePdf = async () => {
    if (!selectedDocumentTemplateId) return;
    try {
      const document = (await generatePdf({
        ...(additionalGenerateProps || {}),
        payload: {
          documentTemplate: selectedDocumentTemplateId,
        },
      }).unwrap()) as StockDocument;
      setSelectedDocumentId && setSelectedDocumentId(document?.id);
      dispatchSuccessSnackbar('Document Generated');
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        dispatchErrorSnackbar(errMessage);
      });
    }
  };

  const handleMenuItemClick = (
    _event: MouseEvent<HTMLLIElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={boxSx}>
      <WithSkeleton isLoading={isLoading}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="Button group with a nested menu"
        >
          <LoadingButton
            onClick={onGeneratePdf}
            loading={isLoadingGeneratePdf}
            size={size}
            variant="contained"
            startIcon={<IconFileExport />}
          >
            {options[selectedIndex]}
          </LoadingButton>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            size={size}
            sx={{
              px: '5px',
              minWidth: '20px',
              '&.MuiButtonGroup-grouped': { px: '5px', minWidth: '20px' },
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </WithSkeleton>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default GenerateDocumentButton;
