import { useState } from 'react';
import { PermissionScope } from 'types/apps';
import SidebarActionButton from 'ui-component/DetailLayout/SidebarComponents/SidebarActionButton';
import { IconArchiveOff, IconArchive } from '@tabler/icons-react';

import { GenericResource, ToggleMutation } from 'types/api';
import ToggleRecordDialog, {
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';

interface SidebarArchiveButtonProps<T extends GenericResource> {
  disabled?: boolean;
  permissionScope?: PermissionScope;
  archived: boolean;
  idToUpdate: string;
  recordName: string;
  message?: string;
  useClientArchiveMutation: ToggleMutation<T>;
  useClientUnarchiveMutation: ToggleMutation<T>;
}

/**
 * SidebarArchiveButtonClientV2
 * To be used specifically with Client V2 api
 */
const SidebarArchiveButtonClientV2 = <T extends GenericResource>({
  disabled,
  permissionScope,
  archived,
  idToUpdate,
  recordName,
  message,
  useClientArchiveMutation,
  useClientUnarchiveMutation,
}: SidebarArchiveButtonProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SidebarActionButton
        Icon={archived ? IconArchiveOff : IconArchive}
        label={archived ? 'Unarchive' : 'Archive'}
        tooltip={archived ? 'Unarchive' : 'Archive Record'}
        color="secondary"
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
        permissionScope={permissionScope}
      />
      <ToggleRecordDialog<
        T,
        typeof useClientArchiveMutation,
        typeof useClientUnarchiveMutation
      >
        id={idToUpdate}
        isCurrentlyTrue={archived}
        dialogOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        recordName={recordName}
        useToggleTrueMutation={useClientArchiveMutation}
        useToggleFalseMutation={useClientUnarchiveMutation}
        toggleActionType={ToggleRecordActionTypes.ARCHIVE}
        message={message}
      />
    </>
  );
};

export default SidebarArchiveButtonClientV2;
