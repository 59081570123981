import {
  useState,
  useMemo,
  useEffect,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { GenericResource, ListResponse, QueryParams } from 'types/api';
import { useController } from 'react-hook-form';
import { QueryDefinition, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { debounce } from 'lodash';

/**
 * HookQuerySingleFieldSearchSelect is a simplified version of HookQuerySearchSelect
 * that allows selecting string values from a specified field of objects
 * returned by a query, rather than working with the full objects.
 *
 * @example
 * <HookQuerySingleFieldSearchSelect
 *   label="Country"
 *   name="country"
 *   useGetQuery={useGetCountriesQuery}
 *   errors={errors}
 *   control={control}
 *   searchSchema={['name']}
 *   schema={['name']}
 *   fieldName="name"
 *   freeSolo
 * />
 */

type FreeSoloStringProps = {
  /** Accept free user input as a valid value */
  freeSolo: true;
};

type NotFreeSoloStringProps = {
  freeSolo?: false;
};

export type HookQuerySingleFieldSearchSelectProps<T extends GenericResource> =
  HookFormComponentProps & {
    name: string;
    disabled?: boolean;
    mb?: number;
    schema: QueryParams<T>['schema'];
    searchSchema?: QueryParams<T>['schema'];
    filters?: QueryParams<T>['filters'];
    excludes?: QueryParams<T>['excludes'];
    useGetQuery: UseQuery<
      QueryDefinition<
        QueryParams<T>,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
        string,
        ListResponse<T>
      >
    >;
    /** Field name to extract from the object for string value */
    fieldName: keyof T;
    blended?: boolean;
    fullWidth?: boolean;
    disableClearable?: boolean;
    hideInitialIfMoreOptions?: boolean;
  } & (FreeSoloStringProps | NotFreeSoloStringProps) &
    TextFieldProps;

const filter = createFilterOptions<string>();

export const HookQuerySingleFieldSearchSelect = <T extends GenericResource>({
  errors,
  name,
  label,
  control,
  useGetQuery,
  filters = [],
  excludes = [],
  schema,
  searchSchema,
  fieldName,
  disabled,
  sx,
  freeSolo = false,
  fullWidth = false,
  mb = 2,
  disableClearable = false,
  hideInitialIfMoreOptions = false,
  blended = false,
}: HookQuerySingleFieldSearchSelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [stringOptions, setStringOptions] = useState<string[]>([]);
  const [userEnteredOption, setUserEnteredOption] = useState<string | null>(
    null
  );

  const {
    field: { ref, onChange, value, onBlur },
    fieldState: { isDirty },
  } = useController({
    name,
    control,
  });

  const debouncedSetSearchQuery = debounce((searchValue: string) => {
    setSearchQuery(searchValue);
  }, 300);

  const initialFilters: QueryParams<T>['filters'] = useMemo(
    () =>
      isDirty
        ? filters
        : value
        ? [
            {
              field: fieldName,
              operator: 'isAnyOf',
              value: [value],
            },
            ...filters,
          ]
        : filters || [],
    [value, isDirty, filters, fieldName]
  );

  const {
    data: { data: initialValueOptions = [] } = { data: [] },
    isFetching: isFetchingInitialValueOptions,
  } = useGetQuery(
    initialFilters.length > 0
      ? {
          pageSize: 100,
          filters: initialFilters,
          excludes,
          schema: [fieldName, ...(Array.isArray(schema) ? schema : [])],
          blended,
        }
      : skipToken
  );

  const {
    data: { data: options = [] } = { data: [] },
    isFetching: isFetchingOptions,
  } = useGetQuery({
    filters,
    excludes,
    schema: [fieldName, ...(Array.isArray(schema) ? schema : [])],
    searchSchema: searchSchema || schema,
    search: searchQuery,
    blended,
  });

  useEffect(() => {
    const combinedData = [
      ...options,
      ...(hideInitialIfMoreOptions && !!options.length
        ? []
        : initialValueOptions),
    ];

    const extractedValues = combinedData
      .filter((item) => item && item[fieldName] !== undefined)
      .map((item) => String(item[fieldName]));
    const uniqueValues = Array.from(new Set(extractedValues));

    const sortedValues = uniqueValues.sort((a, b) => {
      const aLower = a.toLowerCase();
      const bLower = b.toLowerCase();
      const searchLower = searchQuery?.toLowerCase() || '';

      if (aLower.startsWith(searchLower) && !bLower.startsWith(searchLower)) {
        return -1;
      }
      if (!aLower.startsWith(searchLower) && bLower.startsWith(searchLower)) {
        return 1;
      }
      return aLower.localeCompare(bLower);
    });

    if (
      sortedValues.length !== stringOptions.length ||
      sortedValues.some((val, index) => val !== stringOptions[index])
    ) {
      setStringOptions(sortedValues);
    }
  }, [
    options,
    initialValueOptions,
    fieldName,
    hideInitialIfMoreOptions,
    searchQuery,
    stringOptions,
  ]);

  const allOptions = useMemo(() => {
    if (userEnteredOption && !stringOptions.includes(userEnteredOption)) {
      return [...stringOptions, userEnteredOption];
    }
    return stringOptions;
  }, [stringOptions, userEnteredOption]);

  const handleChange = (event: SyntheticEvent, newValue: string | null) => {
    if (newValue === null) {
      onChange(null);
      setUserEnteredOption(null);
      return;
    }

    if (!stringOptions.includes(newValue) && freeSolo) {
      setUserEnteredOption(newValue);
    }

    if (newValue !== value) {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      sx={sx}
      open={open}
      value={value || null}
      multiple={false}
      freeSolo={freeSolo}
      selectOnFocus={freeSolo}
      clearOnBlur={freeSolo}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(optionList, params) => {
        const filtered = filter(optionList, params);

        if (
          freeSolo &&
          params.inputValue !== '' &&
          !optionList.includes(params.inputValue)
        ) {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      onChange={handleChange}
      options={allOptions}
      loading={isFetchingOptions || isFetchingInitialValueOptions}
      noOptionsText={`No ${label} found`}
      renderInput={(params) => (
        <Box sx={{ mb }}>
          <TextField
            inputRef={ref}
            {...params}
            onBlur={onBlur}
            onChange={(e) => {
              if (params.inputProps.onChange)
                params.inputProps.onChange(e as ChangeEvent<HTMLInputElement>);
              debouncedSetSearchQuery(e.target.value);
            }}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetchingOptions || isFetchingInitialValueOptions ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
      disabled={disabled}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
    />
  );
};
