import { Org } from 'types/org';
import { Team } from 'types/properties';
import { ChipColor } from 'ui-component/extended/Chip';
import { FlagshipModelOptions } from 'constants/recordTypes';

export const TagColorOptionsDisplay = {
  [ChipColor.primary]: 'Dark Green',
  [ChipColor.secondary]: 'Blue',
  [ChipColor.success]: 'Light Green',
  [ChipColor.error]: 'Red',
  [ChipColor.orange]: 'Orange',
  [ChipColor.grey]: 'Grey',
  [ChipColor.warning]: 'Yellow',
  [ChipColor.default]: 'Default',
};

type ModelOptions = {
  model: FlagshipModelOptions;
};

export type TagSchema = {
  id: string;
  name: string;
  color: ChipColor;
  active: boolean;
  modelOptions: ModelOptions[];
  org: Org;
  team: Team;
};

export type Tag = {
  id: string;
  name: string;
  color: ChipColor;
  active: boolean;
};
