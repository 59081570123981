import {
  useFormContext,
  UseFormReturn,
  SubmitErrorHandler,
} from 'react-hook-form';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import {
  ValidFormData,
  FormInputData,
  TypedFormReturn,
} from 'hooks/useTypedForm';

/**
 * A type-safe wrapper around react-hook-form's useFormContext hook that provides
 * proper typing for form data during input and after validation.
 *
 * This hook should be used in conjunction with useTypedForm in nested form components
 * that need access to the form context.
 *
 * Key features:
 * - Maintains type safety with Yup schema types
 * - Preserves partial form data typing during input
 * - Ensures validated data typing at submission
 * - Provides properly typed form methods
 *
 * @example
 * ```typescript
 * // In parent component
 * const schema = Yup.object({
 *   name: Yup.string().required(),
 *   age: Yup.number().required(),
 * });
 *
 * const methods = useTypedForm(schema);
 *
 * return (
 *   <FormProvider {...methods}>
 *     <ChildComponent />
 *   </FormProvider>
 * );
 *
 * // In child component
 * const { register, watch } = useTypedFormContext<typeof schema>();
 * const nameValue = watch('name'); // string | undefined
 * ```
 */
export function useTypedFormContext<
  TSchema extends Yup.ObjectSchema<ObjectShape>
>(): Omit<UseFormReturn<FormInputData<TSchema>>, 'handleSubmit'> & {
  handleSubmit: (
    onValid: (data: ValidFormData<TSchema>) => unknown | Promise<unknown>,
    onInvalid?: SubmitErrorHandler<FormInputData<TSchema>>
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
} {
  const methods = useFormContext<FormInputData<TSchema>>();

  return methods as TypedFormReturn<TSchema>;
}
