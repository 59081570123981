import { URLS } from 'store/slices/constants/apiV1';
import {
  Deadline,
  DeadlineRequest,
  StockDocument,
  ExpandedShipments,
  HandlingRequestToConsumeConsume,
  HandlingTaskRow,
  LabelPreviewRequest,
  LabelTemplate,
  PickListTreeNode,
  PreviewZpl,
  Printer,
  PrintLabelRequest,
  Shipment,
  ShipmentConflicts,
  ShipmentCreate,
  ShipmentEstimates,
  ShipmentEstimatesRequest,
  ShipmentLine,
  ShipmentLineCreate,
  ShipmentLineUpdateRequest,
  ShipmentMarkAsShipped,
  ShipmentSolver2,
  ShipmentSolver2Request,
  ShipmentToReceive,
  StockLocation,
  StockLocationCreate,
  StockLotCreate,
  StockLotReceive,
  StockLotScanMove,
  StockLotWithPrice,
  Supplier,
  SupplierLite,
  SupplierRequest,
  ExpandedHandlingRequest,
  ShipmentLite,
  SupplierCreate,
  PurchaseRule,
  AllocationCRUDSchema,
  AllocationsCreate,
  StockLocationUpdate,
  AllocationQuerySchema,
  StockLotQuerySchema,
  StockLotCRUDSchema,
  DocumentReview,
  DocumentExtractor,
  StockLocationPickListGETSchema,
  HandlingRequestsGETSchema,
  ShipmentToReceiveGETSchema,
  DocumentTemplate,
  PrintStockDocumentRequest,
} from 'types/inventory';
import { Part, PartInventoryQuerySchema } from 'types/part';
import { Org } from 'types/org';
import { apiSlice, fetchWithReauth } from 'store/slices/api';
import { RootState } from 'store';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  parseQueryParams,
  transformResponseToFallbackNullOrgPartToPart,
} from 'store/slices/utils';
import { ListResponse, QueryParams } from 'types/api';
import { PurchaseLite } from 'types/purchasing';
import { PartRuleSet } from 'types/partRules';
import { DateString } from 'types';
import { ProductionRun } from 'types/production';
import { BomLite } from 'types/bom';
import { GenerateDocumentPdf, SupplierBillSchema } from 'types/procurement';
import { KitRequestSchema } from 'types/kitting';
import { OrgSupplier } from 'types/suppliers';
import { SupplierQuoteSchema, RfqSchema } from 'types/sourcing';
import { PurchaseRequestGroupSchema } from 'types/clientV2/purchaseRequests';
import _ from 'lodash';
import { OrgPartSchema } from 'types/clientV2/parts';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStockLocations: builder.query<StockLocation[], { orgId: Org['id'] }>({
      query: ({ orgId }) => URLS.STOCK_LOCATIONS(orgId, null),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: StockLocation) =>
                  ({
                    type: 'StockLocations',
                    id,
                  } as const)
              ),
              { type: 'StockLocations', id: 'LIST' },
            ]
          : [{ type: 'StockLocations', id: 'LIST' }],
    }),
    getFacilities: builder.query<StockLocation[], {}>({
      query: () => URLS.FACILITY_STOCK_LOCATIONS,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: StockLocation) =>
                  ({
                    type: 'Facilities',
                    id,
                  } as const)
              ),
              { type: 'Facilities', id: 'LIST' },
            ]
          : [{ type: 'Facilities', id: 'LIST' }],
    }),
    getStockLocation: builder.query<StockLocation, StockLocation['id']>({
      query: (stockLocationId) => URLS.STOCK_LOCATION(stockLocationId),
      providesTags: (result) =>
        result
          ? [{ type: 'StockLocations', id: result.id }]
          : [{ type: 'StockLocations', id: 'LIST' }],
    }),
    createStockLocation: builder.mutation<
      StockLocation,
      { payload: StockLocationCreate }
    >({
      query: ({ payload }) => ({
        url: URLS.STOCK_LOCATIONS(null, null),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'StockLocations',
        'PreviewZpl',
        { type: 'StockLocations', id: 'LIST' },
        { type: 'StockLocations', id: 'PARTIAL-LIST' },
        { type: 'Facilities', id: 'LIST' },
      ],
    }),
    updateStockLocation: builder.mutation<
      StockLocation,
      { stockLocationId: StockLocation['id']; payload: StockLocationUpdate }
    >({
      query: ({ stockLocationId, payload }) => ({
        url: URLS.STOCK_LOCATIONS(null, stockLocationId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { stockLocationId }) =>
        result
          ? [
              { type: 'StockLocations', id: stockLocationId },
              { type: 'Facilities', id: stockLocationId },
              'PreviewZpl',
            ]
          : [],
    }),
    printPrintLabelStockLocation: builder.mutation<
      StockLocation,
      { stockLocationId: StockLocation['id']; payload: PrintLabelRequest }
    >({
      query: ({ stockLocationId, payload }) => ({
        url: URLS.STOCK_LOCATION_PRINT_LABEL(stockLocationId),
        method: 'POST',
        body: payload,
      }),
    }),
    getStockLocationPickList: builder.query<
      PickListTreeNode,
      StockLocationPickListGETSchema
    >({
      query: (queryParams) => URLS.STOCK_LOCATION_PICK_LIST(queryParams),
      providesTags: () => [{ type: 'PickListTree', id: 'LIST' }],
    }),
    getShipments: builder.query<
      Shipment[],
      { orgId: Org['id']; productionRunId?: string | null; expand?: boolean }
    >({
      query: ({ orgId, expand = true, productionRunId = null }) =>
        URLS.SHIPMENTS({ orgId, expand, productionRunId }),
      // TODO: I think this should be done like inlineShoppingListLines, in the view itself
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: Shipment) =>
                  ({
                    type: 'Shipments',
                    id,
                  } as const)
              ),
              { type: 'Shipments', id: 'LIST' },
            ]
          : [{ type: 'Shipments', id: 'LIST' }],
    }),
    getExpandedShipments: builder.query<
      ExpandedShipments[],
      { orgId: Org['id']; productionRunId?: string | null; expand?: boolean }
    >({
      query: ({ orgId, expand = true, productionRunId = null }) =>
        URLS.SHIPMENTS({ orgId, expand, productionRunId }),
      // TODO: I think this should be done like inlineShoppingListLines, in the view itself
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: ExpandedShipments) =>
                  ({
                    type: 'Shipments',
                    id,
                  } as const)
              ),
              { type: 'Shipments', id: 'LIST' },
            ]
          : [{ type: 'Shipments', id: 'LIST' }],
    }),
    getShipmentsToPick: builder.query<
      ShipmentLite[],
      { shipFromId: StockLocation['id']; sandbox?: boolean }
    >({
      query: ({ shipFromId, sandbox = false }) =>
        URLS.SHIPMENTS_TO_PICK({ shipFromId, sandbox, expand: false }),
    }),
    getExpandedShipmentsToPick: builder.query<
      Shipment[],
      { shipFromId: StockLocation['id']; sandbox?: boolean }
    >({
      query: ({ shipFromId, sandbox = false }) =>
        URLS.SHIPMENTS_TO_PICK({ shipFromId, sandbox, expand: true }),
    }),
    getShipmentsToReceive: builder.query<
      ShipmentToReceive[],
      ShipmentToReceiveGETSchema
    >({
      query: ({ shipToId, sandbox = false }) =>
        URLS.SHIPMENTS_TO_RECEIVE({ shipToId, sandbox }),
      providesTags: (result) => [
        { type: 'Shipments', id: 'ShipmentsToReceive' },
      ],
    }),
    createShipment: builder.mutation<
      Shipment,
      { payload: ShipmentCreate; overrideOrgId?: Org['id'] }
    >({
      query: ({ payload, overrideOrgId }) => ({
        url: URLS.SHIPMENTS({}),
        method: 'POST',
        body: payload,
        headers: {
          'x-org-id': overrideOrgId,
        },
      }),
      invalidatesTags: [
        { type: 'Shipments', id: 'LIST' },
        { type: 'Shipments', id: 'ShipmentsToReceive' },
        'PreviewZpl',
      ],
    }),
    getShipment: builder.query<
      Shipment,
      {
        id: Shipment['id'];
        expand?: boolean;
      }
    >({
      query: ({ id, expand = true }) => URLS.SHIPMENTS({ id, expand }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Shipments', id: result.id },
              { type: 'Shipments', id: 'LIST' },
            ]
          : [{ type: 'Shipments', id: 'LIST' }],
    }),
    updateShipment: builder.mutation<
      Shipment,
      { id: Shipment['id']; payload: ShipmentCreate }
    >({
      query: ({ id, payload }) => ({
        url: URLS.SHIPMENTS({ id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Shipments', id },
        'PreviewZpl',
      ],
    }),
    updateBatchShipments: builder.mutation<Shipment[], ShipmentCreate[]>({
      query: (payload) => ({
        url: URLS.SHIPMENTS({}),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result) => [
        ...(result
          ? result.map(({ id }: Shipment) => ({
              type: 'Shipments' as 'Shipments',
              id,
            }))
          : []),
        'PreviewZpl',
      ],
    }),
    approveShipment: builder.mutation<Shipment, Shipment['id']>({
      query: (shipmentId) => ({
        url: URLS.SHIPMENT_APPROVE(shipmentId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, shipmentId) => [
        { type: 'Shipments', id: shipmentId },
        { type: 'ClientV2Shipments', id: 'LIST' },
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
        { type: 'HandlingTaskRows', id: 'LIST' },
      ],
    }),
    generateShipmentPdf: builder.mutation<
      StockDocument,
      {
        shipmentId: Shipment['id'];
        payload: GenerateDocumentPdf;
      }
    >({
      query: ({ shipmentId, payload }) => ({
        url: URLS.SHIPMENT_GENERATE_PDF(shipmentId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error) => [
        {
          type: 'Document',
          id: 'LIST',
        },
        {
          type: 'StockDocumentRelatedObjects',
          id: 'LIST',
        },
      ],
    }),
    fillShipmentPrices: builder.mutation<Shipment, Shipment['id']>({
      query: (shipmentId) => ({
        url: URLS.SHIPMENT_FILL_PRICES(shipmentId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, shipmentId) => [
        { type: 'Shipments', id: shipmentId },
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
      ],
    }),
    markShipmentShipped: builder.mutation<
      Shipment,
      { shipmentId: Shipment['id']; payload: ShipmentMarkAsShipped }
    >({
      query: ({ shipmentId, payload }) => ({
        url: URLS.SHIPMENT_MARK_SHIPPED(shipmentId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { shipmentId }) => [
        { type: 'Shipments', id: shipmentId },
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
        { type: 'HandlingTaskRows', id: 'LIST' },
      ],
    }),
    completeShipmentIngest: builder.mutation<Shipment, Shipment['id']>({
      query: (shipmentId) => ({
        url: URLS.SHIPMENT_COMPLETE_INGEST(shipmentId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, shipmentId) => [
        { type: 'Shipments', id: shipmentId },
        { type: 'Shipments', id: 'ShipmentsToReceive' },
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
        { type: 'HandlingTaskRows', id: 'LIST' },
      ],
    }),
    cancelShipment: builder.mutation<Shipment, Shipment['id']>({
      query: (shipmentId) => ({
        url: URLS.SHIPMENT_CANCEL(shipmentId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, shipmentId) => [
        { type: 'Shipments', id: shipmentId },
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
      ],
    }),
    printPrintLabelShipment: builder.mutation<
      Shipment,
      { shipmentId: Shipment['id']; payload: PrintLabelRequest }
    >({
      query: ({ shipmentId, payload }) => ({
        url: URLS.SHIPMENT_PRINT_LABEL(shipmentId),
        method: 'POST',
        body: payload,
      }),
    }),
    sendToShipstationShipment: builder.mutation<Shipment, Shipment['id']>({
      query: (shipmentId) => ({
        url: URLS.SHIPMENT_SEND_TO_SHIPSTATION(shipmentId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, shipmentId) => [
        { type: 'Shipments', id: shipmentId },
      ],
    }),
    deleteShipment: builder.mutation<Shipment, Shipment['id']>({
      query: (shipmentId) => ({
        url: URLS.SHIPMENTS({ id: shipmentId }),
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        // delete don't have to fetch the original one
        { type: 'Shipments', id: 'LIST' },
        { type: 'HandlingTaskRows', id: 'LIST' },
      ],
    }),
    createShipmentLine: builder.mutation<
      ShipmentLine,
      {
        shipmentId: Shipment['id'];
        payload: ShipmentLineCreate;
        overrideOrgId?: Org['id'];
      }
    >({
      query: ({ shipmentId, payload, overrideOrgId }) => ({
        url: URLS.SHIPMENT_LINE({ shipmentId }),
        method: 'POST',
        body: payload,
        headers: {
          'x-org-id': overrideOrgId,
        },
      }),
      invalidatesTags: (result, error, { shipmentId }) => [
        { type: 'Shipments', id: shipmentId },
      ],
    }),
    updateShipmentLine: builder.mutation<
      ShipmentLine,
      {
        shipmentId: Shipment['id'];
        shipmentLineId: ShipmentLine['id'];
        payload: ShipmentLineUpdateRequest;
      }
    >({
      query: ({ shipmentId, shipmentLineId, payload }) => ({
        url: URLS.SHIPMENT_LINE({
          shipmentId,
          lineId: shipmentLineId,
        }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { shipmentId }) => [
        { type: 'Shipments', id: shipmentId },
      ],
    }),
    deleteShipmentLine: builder.mutation<
      void,
      {
        shipmentId: Shipment['id'];
        shipmentLineId: ShipmentLine['id'];
        shipment?: Shipment;
      }
    >({
      query: ({ shipmentId, shipmentLineId }) => ({
        url: URLS.SHIPMENT_LINE({ shipmentId, lineId: shipmentLineId }),
        method: 'DELETE',
      }),
      async onQueryStarted(
        { shipmentId, shipmentLineId, shipment },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        if (!shipment) {
          return;
        }
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getShipment',
            { id: shipmentId, expand: true },
            (draft) => ({
              ...shipment,
              lines: shipment.lines.filter(
                (line) => line.id !== shipmentLineId
              ),
            })
          )
        );
      },
      invalidatesTags: (result, error, { shipmentId }) => [
        { type: 'Shipments', id: shipmentId },
      ],
    }),
    printPrintLabelShipmentLine: builder.mutation<
      ShipmentLine,
      {
        shipmentId: Shipment['id'];
        shipmentLineId: ShipmentLine['id'];
        payload: PrintLabelRequest;
      }
    >({
      query: ({ shipmentId, shipmentLineId, payload }) => ({
        url: URLS.SHIPMENT_LINE_PRINT_LABEL(shipmentId, shipmentLineId),
        method: 'POST',
        body: payload,
      }),
    }),
    getHandlingTaskRows: builder.query<
      HandlingTaskRow[],
      {
        orgId: Org['id'];
        stockLotId?: StockLotCRUDSchema['id'];
        stockLotIds?: StockLotCRUDSchema['id'][];
        expand?: boolean;
        fromDate?: DateString | null;
        toDate?: DateString | null;
      }
    >({
      query: ({
        orgId,
        stockLotId = null,
        stockLotIds = null,
        expand = false,
        fromDate = null,
        toDate = null,
      }) =>
        URLS.HANDLING_TASK_ROWS({
          orgId,
          stockLotId,
          stockLotIds,
          expand,
          fromDate,
          toDate,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: HandlingTaskRow) =>
                  ({
                    type: 'HandlingTaskRows',
                    id,
                  } as const)
              ),
              { type: 'HandlingTaskRows', id: 'LIST' },
            ]
          : [{ type: 'HandlingTaskRows', id: 'LIST' }],
    }),
    getHandlingRequests: builder.query<
      ExpandedHandlingRequest[],
      HandlingRequestsGETSchema
    >({
      query: ({ stockLotId, facilityId, sandbox, requestTypes }) =>
        URLS.HANDLING_REQUESTS({
          stockLotId,
          facilityId,
          sandbox,
          requestTypes,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: ExpandedHandlingRequest) =>
                  ({
                    type: 'HandlingRequests',
                    id,
                  } as const)
              ),
              { type: 'HandlingRequests', id: 'LIST' },
            ]
          : [{ type: 'HandlingRequests', id: 'LIST' }],
    }),
    getHandlingRequestCounts: builder.query<
      {
        [key: string]: number;
      },
      {
        facilityId?: StockLocation['id'];
        sandbox?: boolean;
        includeDryingMslResets?: boolean;
      }
    >({
      query: ({ facilityId, sandbox, includeDryingMslResets }) =>
        URLS.HANDLING_REQUEST_COUNTS(
          facilityId,
          sandbox,
          includeDryingMslResets
        ),
    }),
    getShipmentsToConsume: builder.query<
      ShipmentLite[],
      { facilityId: StockLocation['id'] }
    >({
      query: ({ facilityId }) => URLS.SHIPMENTS_TO_CONSUME(facilityId),
    }),
    getHandlingRequestsToConsume: builder.query<
      HandlingRequestToConsumeConsume[],
      { facilityId: StockLocation['id']; shipmentId: Shipment['id'] }
    >({
      query: ({ facilityId, shipmentId }) =>
        URLS.HANDLING_REQUESTS_TO_CONSUME(facilityId, shipmentId),
    }),
    getHandlingRequestDeadline: builder.query<
      { deadlines: Deadline[] },
      DeadlineRequest
    >({
      query: (payload) => ({
        url: URLS.HANDLING_REQUEST_DEADLINES,
        method: 'POST',
        body: payload,
      }),
    }),
    // The types of response are different enough, and the usecases
    // different enough, to justify an entirely different hook
    getSuppliersAutocomplete: builder.query<SupplierLite[], string>({
      query: (autocomplete) => URLS.SUPPLIERS(autocomplete),
      providesTags: ['SupplierAutocomplete'],
    }),
    getSuppliers: builder.query<Supplier[], SupplierRequest>({
      query: ({ autocomplete, ids, query }) =>
        URLS.SUPPLIERS(autocomplete, ids, query),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: Supplier) =>
                  ({
                    type: 'Suppliers',
                    id,
                  } as const)
              ),
              { type: 'Suppliers', id: 'LIST' },
            ]
          : [{ type: 'Suppliers', id: 'LIST' }],
    }),
    updateSupplier: builder.mutation<
      Supplier,
      { id: Supplier['id']; payload: SupplierCreate }
    >({
      query: ({ id, payload }) => ({
        url: URLS.SUPPLIER(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'Suppliers', id: 'LIST' },
        { type: 'Suppliers', id: 'PARTIAL-LIST' },
      ],
    }),
    createSupplier: builder.mutation<Supplier, { payload: SupplierCreate }>({
      query: ({ payload }) => ({
        url: URLS.SUPPLIER(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'Suppliers', id: 'LIST' },
        { type: 'Suppliers', id: 'PARTIAL-LIST' },
      ],
    }),
    deleteSupplier: builder.mutation<Supplier, Supplier['id']>({
      query: (supplierId) => ({
        url: URLS.SUPPLIER(supplierId),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Suppliers', id: 'LIST' },
        { type: 'Suppliers', id: 'PARTIAL-LIST' },
      ],
    }),
    getSupplier: builder.query<Supplier, Supplier['id']>({
      query: (supplierId) => URLS.SUPPLIER(supplierId),
      providesTags: (result) =>
        result
          ? [{ type: 'Suppliers', id: result.id }]
          : [{ type: 'Suppliers', id: 'LIST' }],
    }),
    getSuppliersByIds: builder.query<Supplier[], Supplier['id'][]>({
      async queryFn(suppliers, _queryApi, _extraOptions, fetchWithBQ) {
        const results = await Promise.all(
          suppliers.map((supplierId) => fetchWithBQ(URLS.SUPPLIER(supplierId)))
        ).then((values) => values);
        const successfulResults = results
          .filter((result) => !result.error)
          .map((result) => result.data as Supplier);
        const error = {
          status: 400,
          data: results.map((result) => result.error),
        };
        return successfulResults.length > 0
          ? { data: successfulResults }
          : { error: error as FetchBaseQueryError }; // only throws error when all failed
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: Supplier) =>
                  ({
                    type: 'Suppliers',
                    id,
                  } as const)
              ),
              { type: 'Suppliers', id: 'LIST' },
            ]
          : [{ type: 'Suppliers', id: 'LIST' }],
    }),
    getStockLots: builder.query<
      StockLotCRUDSchema[],
      {
        orgId?: Org['id'];
        partId?: Part['id'];
        partIds?: Part['id'][];
        facilityId?: StockLocation['id'];
        includePrices?: boolean;
        v2OrgPartId?: OrgPartSchema['id'];
        v2OrgPartIds?: OrgPartSchema['id'][];
      }
    >({
      query: ({
        orgId,
        partId,
        partIds,
        facilityId,
        includePrices,
        v2OrgPartId,
        v2OrgPartIds,
      }) =>
        URLS.STOCK_LOTS({
          partId,
          partIds,
          orgId,
          facilityId,
          expand: true,
          includePrices,
          v2OrgPartId,
          v2OrgPartIds,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: StockLotCRUDSchema) =>
                  ({
                    type: 'StockLot',
                    id,
                  } as const)
              ),
              { type: 'StockLot', id: 'LIST' },
              { type: 'StockLot', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'StockLot', id: 'LIST' }],
    }),
    createStockLot: builder.mutation<StockLotCRUDSchema, StockLotCreate>({
      query: (payload) => ({
        url: URLS.STOCK_LOTS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
        'HandlingTaskRows',
        'PreviewZpl',
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
    getStockLot: builder.query<
      StockLotWithPrice,
      { stockLotId: StockLotCRUDSchema['id'] }
    >({
      query: ({ stockLotId }) => URLS.STOCK_LOT(stockLotId),
      providesTags: (result) =>
        result
          ? [
              { type: 'StockLot', id: result.id },
              { type: 'StockLot', id: 'LIST' },
              { type: 'StockLot', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'StockLot', id: 'LIST' }],
    }),
    getStockLotScan: builder.query<
      StockLotCRUDSchema,
      { barcode: string; expand: boolean }
    >({
      query: ({ barcode, expand }) => URLS.STOCK_LOT_SCAN(barcode, expand),
      providesTags: (result) =>
        result
          ? [{ type: 'StockLot', id: result.id }]
          : [{ type: 'StockLot', id: 'LIST' }],
    }),
    receiveStockLot: builder.mutation<
      StockLotCRUDSchema,
      { stockLotId: StockLotCRUDSchema['id']; payload: StockLotReceive }
    >({
      query: ({ stockLotId, payload }) => ({
        url: URLS.STOCK_LOT_RECEIVE(stockLotId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { stockLotId }) => [
        { type: 'StockLot', id: stockLotId },
        'HandlingTaskRows',
        'PreviewZpl',
        'ExpandedPurchaseLines',
      ],
    }),
    unreceiveStockLot: builder.mutation<
      StockLotCRUDSchema,
      StockLotCRUDSchema['id']
    >({
      query: (stockLotId) => ({
        url: URLS.STOCK_LOT_UNRECEIVE(stockLotId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, stockLotId) => [
        { type: 'StockLot', id: stockLotId },
        'HandlingTaskRows',
        'PreviewZpl',
        'ExpandedPurchaseLines',
      ],
    }),
    printPrintLabelStockLot: builder.mutation<
      StockLotCRUDSchema,
      { stockLotId: StockLotCRUDSchema['id']; payload: PrintLabelRequest }
    >({
      query: ({ stockLotId, payload }) => ({
        url: URLS.STOCK_LOT_PRINT_LABEL(stockLotId),
        method: 'POST',
        body: payload,
      }),
    }),
    pickedMoveStockLot: builder.mutation<
      StockLotCRUDSchema,
      { stockLotId: StockLotCRUDSchema['id']; payload: StockLotScanMove }
    >({
      query: ({ stockLotId, payload }) => ({
        url: URLS.STOCK_LOT_PICKED_MOVE(stockLotId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { stockLotId }) => [
        { type: 'StockLot', id: stockLotId },
        'HandlingTaskRows',
      ],
    }),
    createStockDocument: builder.mutation<StockDocument, { payload: FormData }>(
      {
        queryFn: async (params, api) => {
          const activeOrgId = (api.getState() as RootState).org.activeOrgId;
          const baseArgs = URLS.STOCK_DOCUMENTS({
            stockLotId: null,
            purchaseId: null,
            bomId: null,
            productionRunId: null,
            shipmentId: null,
            kitRequestId: null,
            needsReview: null,
            supplierBillId: null,
            orgSupplierId: null,
            rfqId: null,
            supplierQuoteId: null,
            purchaseRequestGroupId: null,
          });

          const url =
            typeof baseArgs === 'string'
              ? baseArgs
              : (baseArgs as { url: string }).url;
          const response = await fetchWithReauth(
            url,
            () => api.getState() as RootState,
            api.dispatch,
            {
              method: 'POST',
              body: params.payload,
              headers: activeOrgId ? { 'X-ORG-ID': activeOrgId } : undefined,
            }
          );

          if (!response) {
            throw new Error('Failed to fetch with reauth');
          }

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          return { data: data as StockDocument };
        },
        invalidatesTags: () => ['Document', 'HandlingTaskRows'],
      }
    ),
    getStockDocuments: builder.query<
      StockDocument[],
      {
        stockLotId?: StockLotCRUDSchema['id'];
        purchaseId?: PurchaseLite['id'];
        bomId?: BomLite['id'];
        productionRunId?: ProductionRun['id'];
        shipmentId?: Shipment['id'];
        kitRequestId?: KitRequestSchema['id'];
        supplierBillId?: SupplierBillSchema['id'];
        orgSupplierId?: OrgSupplier['id'];
        needsReview?: boolean;
        rfqId?: RfqSchema['id'];
        supplierQuoteId?: SupplierQuoteSchema['id'];
        purchaseRequestGroupId?: PurchaseRequestGroupSchema['id'];
      }
    >({
      query: ({
        stockLotId,
        purchaseId,
        needsReview,
        bomId,
        productionRunId,
        shipmentId,
        kitRequestId,
        supplierBillId,
        orgSupplierId,
        rfqId,
        supplierQuoteId,
        purchaseRequestGroupId,
      }) =>
        URLS.STOCK_DOCUMENTS({
          stockLotId: stockLotId || null,
          purchaseId: purchaseId || null,
          bomId: bomId || null,
          productionRunId: productionRunId || null,
          shipmentId: shipmentId || null,
          needsReview: needsReview || null,
          kitRequestId: kitRequestId || null,
          supplierBillId: supplierBillId || null,
          orgSupplierId: orgSupplierId || null,
          rfqId: rfqId || null,
          supplierQuoteId: supplierQuoteId || null,
          purchaseRequestGroupId: purchaseRequestGroupId || null,
        }),
      providesTags: () => [{ type: 'Document', id: 'LIST' }],
    }),
    getStockDocument: builder.query<StockDocument, StockDocument['id']>({
      query: (documentId) => URLS.STOCK_DOCUMENT(documentId),
      providesTags: (result, error, documentId) => [
        { type: 'Document', id: documentId },
      ],
    }),
    reviewStockDocument: builder.mutation<
      StockDocument,
      { id: StockDocument['id']; payload: DocumentReview }
    >({
      query: ({ id, payload }) => ({
        url: URLS.STOCK_DOCUMENT_REVIEW(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Document', id },
        { type: 'Document', id: 'LIST' },
      ],
    }),
    printStockDocument: builder.mutation<
      StockDocument,
      { id: StockDocument['id']; payload: PrintStockDocumentRequest }
    >({
      query: ({ id, payload }) => ({
        url: URLS.STOCK_DOCUMENT_PRINT(id),
        method: 'POST',
        body: payload,
      }),
    }),
    getDocumentDownload: builder.query<
      string | Blob | ArrayBufferView | ArrayBuffer,
      StockDocument['id']
    >({
      query: (documentId) => ({
        url: URLS.STOCK_DOCUMENT_DOWNLOAD(documentId),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    deleteDocument: builder.mutation<string, StockDocument['id']>({
      query: (documentId) => ({
        url: URLS.STOCK_DOCUMENT(documentId),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Document', id: 'LIST' }],
    }),
    transferStockLot: builder.mutation<
      StockLocation,
      {
        targetLocationId: StockLocation['id'];
        payload: { orgId: Org['id']; stockLotIds: StockLotCRUDSchema['id'][] };
      }
    >({
      query: ({ targetLocationId, payload }) => ({
        url: URLS.STOCK_LOT_TRANSFER(targetLocationId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['StockLot'],
    }),
    getAllocations: builder.query<
      AllocationCRUDSchema[],
      {
        expand?: boolean;
        shipmentId?: Shipment['id'];
        productionRunId?: ProductionRun['id'];
      }
    >({
      query: ({ expand, shipmentId, productionRunId }) =>
        URLS.ALLOCATIONS({ expand, shipmentId, productionRunId }),
      providesTags: () => [{ type: 'Allocation', id: 'LIST' }],
    }),
    // Not exactly sure were this endpoint should go, inventory or production? do we need to re organize this?
    // this endpoint creates user defined allocations from stockLots
    createStockAllocations: builder.mutation<
      AllocationCRUDSchema[],
      AllocationsCreate[]
    >({
      query: (payload) => ({
        url: URLS.ALLOCATIONS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'Allocation', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
    getPrinters: builder.query<Printer[], void>({
      query: () => URLS.PRINTERS(),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: Printer) =>
                  ({
                    type: 'Printer',
                    id,
                  } as const)
              ),
              { type: 'Printer', id: 'LIST' },
            ]
          : [{ type: 'Printer', id: 'LIST' }],
    }),
    getPrinter: builder.query<Printer, Printer['id']>({
      query: (printerId) => URLS.PRINTER(printerId),
      providesTags: (result, error, printerId) => [
        { type: 'Printer', id: printerId },
      ],
    }),
    getDocumentTemplates: builder.query<DocumentTemplate[], void>({
      query: () => URLS.DOCUMENT_TEMPLATES(),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: DocumentTemplate) =>
                  ({
                    type: 'DocumentTemplate',
                    id,
                  } as const)
              ),
              { type: 'DocumentTemplate', id: 'LIST' },
            ]
          : [{ type: 'DocumentTemplate', id: 'LIST' }],
    }),
    printPrintLabelPrinter: builder.mutation<
      Printer,
      { printerId: Printer['id']; payload: PrintLabelRequest }
    >({
      query: ({ printerId, payload }) => ({
        url: URLS.PRINTER_PRINT_LABEL(printerId),
        method: 'POST',
        body: payload,
      }),
    }),
    getLabelTemplates: builder.query<
      LabelTemplate[],
      {
        facilityId?: string;
        shipToId?: string | null;
        recordOrgId?: string | null;
      }
    >({
      query: ({ facilityId, shipToId, recordOrgId }) =>
        URLS.LABEL_TEMPLATES(facilityId, shipToId, recordOrgId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: LabelTemplate) =>
                  ({
                    type: 'LabelTemplate',
                    id,
                  } as const)
              ),
              { type: 'LabelTemplate', id: 'LIST' },
            ]
          : [{ type: 'LabelTemplate', id: 'LIST' }],
    }),
    getLabelTemplate: builder.query<LabelTemplate, LabelTemplate['id']>({
      query: (labelTemplateId) => URLS.LABEL_TEMPLATE(labelTemplateId),
      providesTags: (result, error, labelTemplateId) => [
        { type: 'LabelTemplate', id: labelTemplateId },
      ],
    }),
    getLabelPreviewZpl: builder.query<
      PreviewZpl,
      {
        labelTemplateId: LabelTemplate['id'];
        payload: LabelPreviewRequest;
      }
    >({
      query: ({ labelTemplateId, payload }) => ({
        url: URLS.LABEL_TEMPLATE_PREVIEW_ZPL(labelTemplateId),
        method: 'POST',
        body: payload,
      }),
      providesTags: (result, error, { labelTemplateId }) => [
        { type: 'PreviewZpl', id: labelTemplateId },
      ],
    }),
    createWithSolverV2: builder.mutation<
      ShipmentSolver2,
      ShipmentSolver2Request
    >({
      query: (payload) => ({
        url: URLS.SHIPMENT_SOLVER2,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ShipmentSolver', 'Shipments', 'PreviewZpl'],
    }),
    updateWithSolverV2: builder.mutation<
      ShipmentSolver2,
      ShipmentSolver2Request
    >({
      query: (payload) => ({
        url: URLS.SHIPMENT_SOLVER2,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['ShipmentSolver', 'Shipments', 'PreviewZpl'],
    }),
    getShipmentEstimates: builder.mutation<
      ShipmentEstimates,
      ShipmentEstimatesRequest
    >({
      query: (payload) => ({
        url: URLS.SHIPMENT_ESTIMATES,
        method: 'POST',
        body: payload,
      }),
    }),
    getShipmentConflicts: builder.mutation<
      ShipmentConflicts,
      { shipments: Shipment['id'][] }
    >({
      query: (payload) => ({
        url: URLS.SHIPMENT_CONFLICTS,
        method: 'POST',
        body: payload,
      }),
    }),
    getPurchaseRules: builder.query<PurchaseRule[], {}>({
      query: () => URLS.PURCHASE_RULES(),
      providesTags: [{ type: 'PurchaseRules', id: 'LIST' }],
    }),
    getPurchaseRule: builder.query<PurchaseRule, PurchaseRule['id']>({
      query: (id) => URLS.PURCHASE_RULES(id),
      providesTags: (result, error, id) => [{ type: 'PurchaseRules', id }],
    }),
    createPurchaseRule: builder.mutation<PurchaseRule, Partial<PurchaseRule>>({
      query: (payload) => ({
        url: URLS.PURCHASE_RULES(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'PurchaseRules', id: 'LIST' }],
    }),
    updatePurchaseRule: builder.mutation<
      PurchaseRule,
      { id: PurchaseRule['id']; payload: Partial<PurchaseRule> }
    >({
      query: ({ id, payload }) => ({
        url: URLS.PURCHASE_RULES(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [{ type: 'PurchaseRules', id: 'LIST' }],
    }),
    deletePurchaseRule: builder.mutation<
      { success: boolean },
      PurchaseRule['id']
    >({
      query: (id) => ({
        url: URLS.PURCHASE_RULES(id),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'PurchaseRules', id: 'LIST' }],
    }),
    getPartRuleSets: builder.query<PartRuleSet[], void>({
      query: () => URLS.PART_RULE_SETS,
      providesTags: ['PartRuleSet'],
    }),
    getDocumentExtractors: builder.query<DocumentExtractor[], void>({
      query: () => URLS.DOCUMENT_EXTRACTORS,
      providesTags: () => [{ type: 'DocumentExtractors', id: 'LIST' }],
    }),
  }),
});

export const extendedApiSliceServerSide = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStockLotsServerSide: builder.query<
      ListResponse<StockLotQuerySchema>,
      QueryParams<StockLotQuerySchema>
    >({
      query: (params: QueryParams<StockLotQuerySchema>) => ({
        url: URLS.STOCK_LOTS_SERVER(
          parseQueryParams<StockLotQuerySchema>(params)
        ),
        headers: {
          'x-org-id': params.overrideOrgId,
        },
      }),
      transformResponse: transformResponseToFallbackNullOrgPartToPart,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: StockLotQuerySchema) =>
                  ({
                    type: 'StockLot',
                    id,
                  } as const)
              ),
              { type: 'StockLot', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'StockLot', id: 'PARTIAL-LIST' }],
    }),
    getStockLocationsServerSide: builder.query<
      ListResponse<StockLocation>,
      QueryParams<StockLocation>
    >({
      query: (params: QueryParams<StockLocation>) =>
        URLS.STOCK_LOCATIONS_SERVER(parseQueryParams<StockLocation>(params)),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: StockLocation) =>
                  ({
                    type: 'StockLocations',
                    id,
                  } as const)
              ),
              { type: 'StockLocations', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'StockLocations', id: 'PARTIAL-LIST' }],
    }),
    getInventoryServerSide: builder.query<
      ListResponse<PartInventoryQuerySchema>,
      QueryParams<PartInventoryQuerySchema>
    >({
      query: (params: QueryParams<PartInventoryQuerySchema>) =>
        URLS.INVENTORY_SERVER(
          parseQueryParams<PartInventoryQuerySchema>(params)
        ),
      transformResponse: (response: ListResponse<PartInventoryQuerySchema>) => {
        const initialResponse =
          transformResponseToFallbackNullOrgPartToPart(response);
        const withIds = _.map(initialResponse.data, (item) => ({
          ...item,
          id: item.part?.id,
        })) as PartInventoryQuerySchema[];
        return {
          ...response,
          data: withIds,
        } as ListResponse<PartInventoryQuerySchema>;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ part }: PartInventoryQuerySchema) =>
                  ({
                    type: 'PartInventory',
                    id: part?.id || (part as unknown as Part['id']),
                  } as const)
              ),
              { type: 'PartInventory', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'PartInventory', id: 'PARTIAL-LIST' }],
    }),
    getSuppliersServerSide: builder.query<
      ListResponse<Supplier>,
      QueryParams<Supplier>
    >({
      query: (params: QueryParams<Supplier>) =>
        URLS.SUPPLIERS_SERVER(parseQueryParams<Supplier>(params)),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: Supplier) =>
                  ({
                    type: 'Suppliers',
                    id,
                  } as const)
              ),
              { type: 'Suppliers', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Suppliers', id: 'PARTIAL-LIST' }],
    }),
    getAllocationsServerSide: builder.query<
      ListResponse<AllocationQuerySchema>,
      QueryParams<AllocationQuerySchema>
    >({
      query: (params: QueryParams<AllocationQuerySchema>) =>
        URLS.ALLOCATIONS_SERVER(
          parseQueryParams<AllocationQuerySchema>(params)
        ),
      transformResponse: transformResponseToFallbackNullOrgPartToPart,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: AllocationQuerySchema) =>
                  ({
                    type: 'Allocation',
                    id,
                  } as const)
              ),
              { type: 'Allocation', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Allocation', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useGetStockLocationsQuery,
  useGetFacilitiesQuery,
  useGetStockLocationQuery,
  useLazyGetStockLocationQuery,
  useCreateStockLocationMutation,
  useUpdateStockLocationMutation,
  usePrintPrintLabelStockLocationMutation,
  useGetShipmentsQuery,
  useGetExpandedShipmentsQuery,
  useGetShipmentQuery,
  useGetShipmentsToPickQuery,
  useGetExpandedShipmentsToPickQuery,
  useGetShipmentsToReceiveQuery,
  useCreateShipmentMutation,
  useUpdateShipmentMutation,
  useMarkShipmentShippedMutation,
  useApproveShipmentMutation,
  useFillShipmentPricesMutation,
  useCompleteShipmentIngestMutation,
  useSendToShipstationShipmentMutation,
  useCancelShipmentMutation,
  usePrintPrintLabelShipmentMutation,
  useDeleteShipmentMutation,
  useCreateShipmentLineMutation,
  useUpdateShipmentLineMutation,
  useUpdateBatchShipmentsMutation,
  useDeleteShipmentLineMutation,
  usePrintPrintLabelShipmentLineMutation,
  useGetHandlingTaskRowsQuery,
  useGetShipmentsToConsumeQuery,
  useGetHandlingRequestsToConsumeQuery,
  useGetHandlingRequestsQuery,
  useGetHandlingRequestCountsQuery,
  useGetHandlingRequestDeadlineQuery,
  useGetSuppliersAutocompleteQuery,
  useGetSuppliersQuery,
  useLazyGetSuppliersQuery,
  useGetSuppliersByIdsQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useGetStockLotsQuery,
  useLazyGetStockLotsQuery,
  useCreateStockDocumentMutation,
  useReviewStockDocumentMutation,
  useGetStockDocumentsQuery,
  useGetStockDocumentQuery,
  useCreateStockLotMutation,
  useGetStockLotQuery,
  useGetStockLotScanQuery,
  useLazyGetStockLotScanQuery,
  useReceiveStockLotMutation,
  useUnreceiveStockLotMutation,
  useGetStockLocationPickListQuery,
  useTransferStockLotMutation,
  usePrintPrintLabelStockLotMutation,
  usePickedMoveStockLotMutation,
  useGetDocumentDownloadQuery,
  useLazyGetDocumentDownloadQuery,
  useDeleteDocumentMutation,
  useGetAllocationsQuery,
  useGetPrintersQuery,
  useGetPrinterQuery,
  useGetDocumentTemplatesQuery,
  usePrintPrintLabelPrinterMutation,
  useGetLabelTemplatesQuery,
  useGetLabelTemplateQuery,
  useGetLabelPreviewZplQuery,
  useCreateWithSolverV2Mutation,
  useUpdateWithSolverV2Mutation,
  useGetShipmentEstimatesMutation,
  useGetShipmentConflictsMutation,
  useGetPurchaseRulesQuery,
  useGetPurchaseRuleQuery,
  useCreatePurchaseRuleMutation,
  useUpdatePurchaseRuleMutation,
  useDeletePurchaseRuleMutation,
  useCreateStockAllocationsMutation,
  useGetPartRuleSetsQuery,
  useGetDocumentExtractorsQuery,
  useGenerateShipmentPdfMutation,
  usePrintStockDocumentMutation,
} = extendedApiSlice;

export const {
  useGetStockLotsServerSideQuery,
  useLazyGetStockLotsServerSideQuery,
  useGetStockLocationsServerSideQuery,
  useGetInventoryServerSideQuery,
  useLazyGetInventoryServerSideQuery,
  useGetSuppliersServerSideQuery,
  useLazyGetSuppliersServerSideQuery,
  useGetAllocationsServerSideQuery,
  useLazyGetAllocationsServerSideQuery,
  usePrefetch,
} = extendedApiSliceServerSide;
