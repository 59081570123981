import { useTypedCreateRecordDialog } from 'hooks/typedRecordDialogs/useTypedCreateRecordDialog';
import { useCreateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import {
  createUpdateKitRequestFormConstants,
  createUpdateKitRequestValidationSchema,
  CreateKitRequestFormInputData,
} from 'views/kitting/components/constants';
import CreateUpdateKitRequestForm, {
  CreateUpdateKitRequestFormRef,
} from 'views/kitting/components/CreateUpdateKitRequestForm';
import { createUpdateKitRequestPreSubmit } from 'views/kitting/components/utils';
import { FormProvider } from 'react-hook-form';
import { KitRequestSchema, KitRequestPOSTSchema } from 'types/kitting';
import { useEffect, useMemo, useRef } from 'react';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import { ALL_RECORD_TYPE_IDS } from 'constants/recordTypes';

type CreateKitRequestDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  onCreateSuccess?: (newKitRequestId: KitRequestSchema['id']) => void;
  overrideDefaults?: Partial<CreateKitRequestFormInputData>;
  shouldUnregister?: boolean;
};

const CreateKitRequestDialog = ({
  dialogOpen,
  onClose,
  onCreateSuccess,
  overrideDefaults,
  shouldUnregister = false,
}: CreateKitRequestDialogProps) => {
  const formRef = useRef<CreateUpdateKitRequestFormRef>(null);
  const tempKitApprovalDialog = useFeature(
    FeatureFlags.tempKitApprovalDialog
  ).on;
  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.KIT_REQUEST,
    visibilityContext: CustomPropertyVisibility.NEW_RECORD_DIALOG,
  });

  const formDefaults = useMemo(
    (): CreateKitRequestFormInputData => ({
      [createUpdateKitRequestFormConstants.name.id]: '',
      [createUpdateKitRequestFormConstants.sourceLocations.id]: [],
      [createUpdateKitRequestFormConstants.productionRun.id]: null,
      [createUpdateKitRequestFormConstants.team.id]: null,
      [createUpdateKitRequestFormConstants.shipTo.id]: null,
      [createUpdateKitRequestFormConstants.shipMethod.id]: null,
      [createUpdateKitRequestFormConstants.shipPartial.id]: false,
      ...(tempKitApprovalDialog
        ? {}
        : {
            [createUpdateKitRequestFormConstants.targetShipDate.id]: null,
          }),
      [customPropertiesFieldName]: getDefaultValuesForCustomProperties(),
    }),
    [
      customPropertiesFieldName,
      getDefaultValuesForCustomProperties,
      tempKitApprovalDialog,
    ]
  );

  const { methods, BaseCreateRecordDialog, dialogProps } =
    useTypedCreateRecordDialog<
      typeof createUpdateKitRequestValidationSchema,
      KitRequestPOSTSchema,
      KitRequestSchema
    >({
      validationSchema: createUpdateKitRequestValidationSchema,
      defaultValues: formDefaults,
      useCreateMutation: useCreateKitRequestsMutation,
      recordName: 'Kit',
      preSubmit: createUpdateKitRequestPreSubmit,
      onSave: (kitRequestIds: string[]) =>
        onCreateSuccess?.(kitRequestIds?.[0]),
      recordType: ALL_RECORD_TYPE_IDS.KIT_REQUEST,
      allowAttachments: true,
    });

  useEffect(() => {
    if (dialogOpen) {
      methods.reset({ ...formDefaults, ...overrideDefaults });
    }
  }, [methods.reset, overrideDefaults, dialogOpen]);

  return (
    <BaseCreateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
      submitDisabled={formRef.current?.isFetching}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestForm
          ref={formRef}
          defaultValues={formDefaults}
          disableProgramField={
            !!overrideDefaults?.[
              createUpdateKitRequestFormConstants.productionRun.id
            ]
          }
          customProperties={customProperties}
          shouldUnregister={shouldUnregister}
        />
      </FormProvider>
    </BaseCreateRecordDialog>
  );
};

export default CreateKitRequestDialog;
