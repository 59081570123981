import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCustomPropertiesQuery } from 'store/slices/apiV1/org';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags, GenericObject } from 'types';
import {
  CustomPropertyDataType,
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { useMemo, useCallback } from 'react';
import _ from 'lodash';

export default function useGetCustomPropertyDefinitions({
  model,
  visibilityContext,
}: {
  model: CustomPropertyModel;
  visibilityContext?: CustomPropertyVisibility;
}) {
  const tempCustomProperties = useFeature(FeatureFlags.tempCustomProperties).on;

  const { data: customProperties, isLoading: isLoadingCustomProperties } =
    useGetCustomPropertiesQuery(tempCustomProperties ? undefined : skipToken);

  const filteredCustomProperties = useMemo(
    () =>
      !tempCustomProperties
        ? []
        : _.chain(customProperties || [])
            .filter(
              (cp) =>
                cp.model === model &&
                (!visibilityContext ||
                  cp.visibility.includes(visibilityContext))
            )
            .map((cp) => ({
              ..._.omit(cp, ['key']),
              key: _.camelCase(cp.key),
            }))
            .value(),
    [customProperties, model, visibilityContext]
  );

  const getDefaultValuesForCustomProperties = useCallback(
    <T extends GenericObject>(record?: T | null) =>
      _.reduce(
        filteredCustomProperties,
        (acc, property) => {
          const val = _.get(
            record?.customProperties,
            property.key,
            property.dataType === CustomPropertyDataType.MULTI_CHOICE ? [] : ''
          );
          acc[property.key] =
            property.dataType === CustomPropertyDataType.NUMBER
              ? Number(val)
              : property.dataType === CustomPropertyDataType.BOOLEAN
              ? Boolean(val)
              : val;
          return acc;
        },
        {} as Record<string, string | number | boolean | string[] | null>
      ),
    [filteredCustomProperties]
  );

  return {
    isFetching: isLoadingCustomProperties,
    customProperties: filteredCustomProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName: 'customProperties',
  };
}
