import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

import { useGetUserQuery } from 'store/slices/apiV1/user';
import { setActiveOrgId } from 'store/slices/apiV1/org';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { orgFromUserOrgId } from 'utils/userPermissions';

/**
 * Guard to universally handle ?activeorg= query parameters
 * Users should be able to be linked directly to a view from
 * a given org's perspective, and the route should resolve
 * if they have permission for that org, with their app
 * state set to that org as the active org (with dropdown)
 * reflecting this, etc.
 * @param {PropTypes.node} children children element/node
 */
const OrgGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useSelector((state) => state.session);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { activeOrgId, blended, sandbox } = useSelector(({ org }) => org);
  const { data: user, isSuccess: isGetUserSuccess } = useGetUserQuery(
    isLoggedIn ? undefined : skipToken
  );

  useEffect(() => {
    if (isGetUserSuccess) {
      const activeOrgParam = searchParams.get('activeorgid');
      const blendedOrgParam = searchParams.get('blended') === 'true';
      const sandboxParam = searchParams.get('sandbox') === 'true';

      // Was the search parameter there and it's different from the current active org id?
      if (activeOrgParam && activeOrgParam !== activeOrgId) {
        // If so, is it valid and a parameter for which the user has permissions?
        if (_.find(user.orgs, ['id', activeOrgParam])) {
          // Set the active org id to be this id, and, wipe the search param
          // It should only be used for linking and on initial app load
          dispatch(
            setActiveOrgId({
              orgId: activeOrgParam,
              org: orgFromUserOrgId(user, activeOrgParam),
              skipResetState: false,
              blended: blendedOrgParam || blended,
              sandbox: sandboxParam || sandbox,
            })
          );
        }
      } else if (!activeOrgId) {
        // Normal login handling
        dispatch(
          setActiveOrgId({
            orgId: user.org,
            org: orgFromUserOrgId(user, user.org),
            skipResetState: false,
            blended: blendedOrgParam || blended,
            sandbox: sandboxParam || sandbox,
          })
        );
      }
      if (activeOrgParam) {
        searchParams.delete('activeorgid');
        setSearchParams(searchParams);
      }
    }
  }, [isGetUserSuccess, activeOrgId]);

  return children;
};

export default OrgGuard;
