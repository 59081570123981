import {
  createKitRequestLineFormConstants,
  createKitRequestLinePartValidation,
  createKitRequestLineStockLotValidation,
} from 'views/kitting/Kit/components/constants';
import {
  useCreateKitRequestLinesMutation,
  useLockKitRequestLinesMutation,
} from 'store/slices/clientV2/kitRequests';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import { useState } from 'react';
import * as Yup from 'yup';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useCreateAllocationsMutation } from 'store/slices/clientV2/allocations';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { handleErr } from 'utils/functions';
import { ALL_APP_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import useSnackbar from 'hooks/useSnackbar';
import { ClientV2POSTRequest, ClientV2POSTResponse } from 'types/api';
import { KitRequestLinePOSTSchema, KitRequestLineSchema } from 'types/kitting';
import CreateKitRequestFromPart from 'views/kitting/Kit/components/CreateUpdateKitRequestLineForms/CreateKitRequestFromPart';
import CreateUpdateKitRequestFromStockLot from 'views/kitting/Kit/components/CreateUpdateKitRequestLineForms/CreateUpdateKitRequestFromStockLot';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import _ from 'lodash';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';

enum TAB_NAMES {
  PART = 'part',
  STOCK_LOT = 'stockLot',
}

type CreateKitRequestLineDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  kitRequestId: string;
};

const CreateKitRequestLineDialog = ({
  dialogOpen,
  onClose,
  kitRequestId,
}: CreateKitRequestLineDialogProps) => {
  const tempParts2Ui = useFeature(FeatureFlags.tempParts2Ui).on;

  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState<TAB_NAMES>(TAB_NAMES.PART);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [disableCreateFromStockLot, setDisableCreateFromStockLot] =
    useState(false);

  const { kitRequest } = useKitRequestContext();

  const [createKitRequestLines, { isLoading: isLoadingCreateKitRequestLines }] =
    useCreateKitRequestLinesMutation();
  const [
    createAllocations,
    {
      isLoading: isLoadingCreateAllocations,
      isError: isCreateAllocationsError,
    },
  ] = useCreateAllocationsMutation();
  const [lockKitRequestLines, { isLoading: isLoadingLockKitRequestLines }] =
    useLockKitRequestLinesMutation();

  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.KIT_REQUEST_LINE,
    visibilityContext: CustomPropertyVisibility.NEW_RECORD_DIALOG,
  });

  const formDefaults = {
    [createKitRequestLineFormConstants.part.id]: '',
    [createKitRequestLineFormConstants.v2OrgPart.id]: '',
    [createKitRequestLineFormConstants.targetQuantityPart.id]: '',
    [createKitRequestLineFormConstants.stockLot.id]: '',
    [createKitRequestLineFormConstants.targetQuantityStockLot.id]: '',
    [customPropertiesFieldName]: getDefaultValuesForCustomProperties(),
  };

  const validationSchema =
    currentTab === TAB_NAMES.PART
      ? Yup.object().shape(createKitRequestLinePartValidation(tempParts2Ui))
      : Yup.object().shape(createKitRequestLineStockLotValidation);

  const methods = useForm<FieldValues>({
    defaultValues: formDefaults,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = methods;

  const handleClose = () => {
    setCurrentTab(TAB_NAMES.PART);
    onClose();
    setSubmitError(null);
    setDisableCreateFromStockLot(false);
    reset();
  };

  const getKitRequestLinePayload = (data: FieldValues) => {
    if (currentTab === TAB_NAMES.PART) {
      return {
        kitRequest: kitRequestId,
        part: tempParts2Ui
          ? undefined
          : data[createKitRequestLineFormConstants.part.id],
        v2OrgPart: tempParts2Ui
          ? data[createKitRequestLineFormConstants.v2OrgPart.id]
          : undefined,
        targetQuantity:
          data[createKitRequestLineFormConstants.targetQuantityPart.id],
        minimumQuantity:
          data[createKitRequestLineFormConstants.targetQuantityPart.id],
      } as ClientV2POSTRequest<KitRequestLinePOSTSchema>;
    }
    return {
      kitRequest: kitRequestId,
      part: tempParts2Ui
        ? undefined
        : data[createKitRequestLineFormConstants.stockLot.id]?.part,
      v2OrgPart: tempParts2Ui
        ? data[createKitRequestLineFormConstants.stockLot.id]?.v2OrgPart
        : undefined,
      targetQuantity:
        data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
      minimumQuantity:
        data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
    } as ClientV2POSTRequest<KitRequestLinePOSTSchema>;
  };

  const handleCreateKitRequestLineFromPart = async (data: FieldValues) => {
    try {
      const payload = getKitRequestLinePayload(data);
      if (Object.keys(dirtyFields).includes(customPropertiesFieldName)) {
        _.set(payload, 'customProperties', data[customPropertiesFieldName]);
      }
      const response = await createKitRequestLines(payload).unwrap();
      dispatchSuccessSnackbar('Kit Line created successfully');
      handleClose();
      return response;
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
      return null;
    }
  };

  const handleCreateKitRequestLineFromStockLot = async (
    data: FieldValues,
    kitRequestLineResponse: ClientV2POSTResponse<KitRequestLineSchema> | null
  ) => {
    const newKitRequestLineId = kitRequestLineResponse?.createdIds[0];
    if (newKitRequestLineId) {
      try {
        await createAllocations({
          kitRequestLine: newKitRequestLineId,
          stockLot: data[createKitRequestLineFormConstants.stockLot.id].id,
          quant:
            data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
        }).unwrap();
        await lockKitRequestLines({ ids: [newKitRequestLineId] }).unwrap();
        dispatchSuccessSnackbar('Kit Line created successfully');
      } catch (err) {
        dispatchErrorSnackbar(
          isCreateAllocationsError
            ? 'Kit Line created, but allocation creation failed. Please try again using the edit line button.'
            : 'Kit Line created, but locking failed. Lock this line to avoid allocations being overwritten.'
        );
      }
      handleClose();
    }
  };

  const onSubmit = async (data: FieldValues) => {
    const createdIds = await handleCreateKitRequestLineFromPart(data);
    if (currentTab === TAB_NAMES.STOCK_LOT) {
      await handleCreateKitRequestLineFromStockLot(data, createdIds);
    } else {
      dispatchSuccessSnackbar('Kit Line created successfully');
      handleClose();
    }
  };

  return (
    <ExtendedDialog
      onSubmit={handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy="Save"
      isSubmitting={
        isLoadingCreateKitRequestLines ||
        isLoadingCreateAllocations ||
        isLoadingLockKitRequestLines
      }
      open={dialogOpen}
      isForm
      onCloseDialog={handleClose}
      title="Create Kit Line"
      permissionScope={{
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      }}
      submitDisabled={Boolean(
        currentTab === TAB_NAMES.STOCK_LOT && disableCreateFromStockLot
      )}
    >
      <ExtendedTabs
        onChange={(tabName: string) => {
          setCurrentTab(tabName as TAB_NAMES);
        }}
        defaultTab={currentTab}
        tabOptions={[
          {
            name: TAB_NAMES.PART,
            label: 'Part',
            contents: (
              <FormProvider {...methods}>
                <CreateKitRequestFromPart customProperties={customProperties} />
              </FormProvider>
            ),
          },
          {
            disabled: (kitRequest?.sourceLocations ?? []).length === 0,
            name: TAB_NAMES.STOCK_LOT,
            label: 'Stock Lot',
            contents: (
              <FormProvider {...methods}>
                <CreateUpdateKitRequestFromStockLot
                  isCreate
                  customProperties={customProperties}
                />
              </FormProvider>
            ),
          },
        ]}
      />
    </ExtendedDialog>
  );
};

export default CreateKitRequestLineDialog;
