import { ALL_APP_IDS } from 'constants/appConstants';
import { RecordType } from 'types/apps';
import { CustomPropertyModel } from 'types/customProperty';
import { OrgSupplier } from 'types/suppliers';
import { OrgPartSchema, PublicPartSchema } from 'types/clientV2/parts';
import { ProductionRun } from 'types/production';
import {
  PurchaseRequestGroupSchema,
  PurchaseRequestSchema,
} from 'types/clientV2/purchaseRequests';
import { StockDocType, StockLotSchemaClientV2 } from 'types/inventory';
import { BomQuerySchema } from 'types/bom';
import { ShipmentSchema } from 'types/clientV2/shipments';
import { KitRequestSchema } from 'types/kitting';
import {
  RfqLineSchema,
  RfqSchema,
  RfqSupplierJoinSchema,
  SupplierQuoteLineSchema,
  SupplierQuoteSchema,
} from 'types/sourcing';
import {
  NoPartPurchaseLineSchema,
  PurchaseEvent,
  PurchaseOrder,
  PurchaseOrderLineSchema,
  SupplierBillLineSchema,
  SupplierBillSchema,
} from 'types/procurement';
import { useGenerateShipmentPdfMutation } from 'store/slices/apiV1/inventory';
import { useGeneratePurchaseOrderPdfMutation } from 'store/slices/apiV1/procurement';
import {
  useApproveRfqsMutation,
  useArchiveRfqsMutation,
  useDeleteRfqLinesMutation,
  useDeleteRfqsMutation,
  useGenerateRfqPdfMutation,
  useUnapproveRfqsMutation,
  useUnarchiveRfqsMutation,
  useUnvoidRfqsMutation,
  useUpdateRfqLinesMutation,
  useUpdateRfqsMutation,
  useVoidRfqsMutation,
} from 'store/slices/clientV2/sourcing';
import {
  useDeleteSupplierQuoteMutation,
  useUpdateSupplierQuoteMutation,
  useDeleteSupplierQuoteLineMutation,
  useUpdateSupplierQuoteLineMutation,
  useUnvoidSupplierQuoteLineActionMutation,
  useUnapproveSupplierQuoteLineActionMutation,
  useArchiveSupplierQuoteActionMutation,
  useApproveSupplierQuoteLineActionMutation,
  useVoidSupplierQuoteLineActionMutation,
  useUnarchiveSupplierQuoteActionMutation,
} from 'store/slices/clientV2/supplierQuotes';
import {
  useDeleteSupplierBillMutation,
  useApproveSupplierBillActionMutation,
  useArchiveSupplierBillActionMutation,
  useUnarchiveSupplierBillActionMutation,
  useVoidSupplierBillActionMutation,
  useUnapproveSupplierBillActionMutation,
  useUpdateSupplierBillMutation,
  useUnvoidSupplierBillActionMutation,
  useDeleteSupplierBillLineMutation,
  useUpdateSupplierBillLineMutation,
} from 'store/slices/clientV2/supplierBills';
import { useUpdateOrgPartMutation } from 'store/slices/clientV2/parts';

export enum FlagshipModelOptions {
  ORG_SUPPLIER = 'orgsupplier',
  PURCHASE_REQUEST = 'purchaserequest',
  PURCHASE_REQUEST_GROUP = 'purchaserequestgroup',
  RFQ = 'rfq',
  RFQ_LINE = 'rfqline',
  RFQ_SUPPLIER_JOIN = 'rfqsupplierjoin',
  SUPPLIER_QUOTE = 'supplierquote',
  SUPPLIER_QUOTE_LINE = 'supplierquoteline',
  SUPPLIER_BILL = 'supplierbill',
  SUPPLIER_BILL_LINE = 'supplierbillline',
  STOCK_LOT = 'stocklot',
  SHIPMENT = 'shipment',
  KIT_REQUEST = 'kitrequest',
  V2_ORG_PART = 'v2orgpart',
  PURCHASE = 'purchase',
  PURCHASE_LINE = 'purchaseline',
  NO_PART_PURCHASE_LINE = 'nopartpurchaseline',
  PURCHASE_EVENT = 'purchaseevent',
  PRODUCTION_RUN = 'productionrun',
  BOM = 'bom',
}

export enum ALL_RECORD_TYPE_IDS {
  RFQ = 'RFQ',
  RFQ_LINE = 'RFQ_LINE',
  RFQ_SUPPLIER_JOIN = 'RFQ_SUPPLIER_JOIN',
  QUOTE = 'QUOTE',
  QUOTE_LINE = 'QUOTE_LINE',
  SUPPLIER_BILL = 'SUPPLIER_BILL',
  SUPPLIER_BILL_LINE = 'SUPPLIER_BILL_LINE',
  STOCK_LOT = 'STOCK_LOT',
  SHIPMENT = 'SHIPMENT',
  KIT_REQUEST = 'KIT_REQUEST',
  ORG_SUPPLIER = 'ORG_SUPPLIER',
  V2_ORG_PART = 'V2_ORG_PART',
  PURCHASE = 'PURCHASE',
  PURCHASE_LINE = 'PURCHASE_LINE',
  NO_PART_PURCHASE_LINE = 'NO_PART_PURCHASE_LINE',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  PURCHASE_REQUEST_GROUP = 'PURCHASE_REQUEST_GROUP',
  PURCHASE_EVENT = 'PURCHASE_EVENT',
  PRODUCTION_RUN = 'PRODUCTION_RUN',
  BOM = 'BOM',
  PART = 'PART',
}

export type RecordTypeMap = {
  [ALL_RECORD_TYPE_IDS.RFQ]: RecordType<RfqSchema>;
  [ALL_RECORD_TYPE_IDS.RFQ_LINE]: RecordType<RfqLineSchema>;
  [ALL_RECORD_TYPE_IDS.RFQ_SUPPLIER_JOIN]: RecordType<RfqSupplierJoinSchema>;
  [ALL_RECORD_TYPE_IDS.QUOTE]: RecordType<SupplierQuoteSchema>;
  [ALL_RECORD_TYPE_IDS.QUOTE_LINE]: RecordType<SupplierQuoteLineSchema>;
  [ALL_RECORD_TYPE_IDS.SUPPLIER_BILL]: RecordType<SupplierBillSchema>;
  [ALL_RECORD_TYPE_IDS.SUPPLIER_BILL_LINE]: RecordType<SupplierBillLineSchema>;
  [ALL_RECORD_TYPE_IDS.STOCK_LOT]: RecordType<StockLotSchemaClientV2>;
  [ALL_RECORD_TYPE_IDS.SHIPMENT]: RecordType<ShipmentSchema>;
  [ALL_RECORD_TYPE_IDS.KIT_REQUEST]: RecordType<KitRequestSchema>;
  [ALL_RECORD_TYPE_IDS.ORG_SUPPLIER]: RecordType<OrgSupplier>;
  [ALL_RECORD_TYPE_IDS.V2_ORG_PART]: RecordType<OrgPartSchema>;
  [ALL_RECORD_TYPE_IDS.PURCHASE]: RecordType<PurchaseOrder>;
  [ALL_RECORD_TYPE_IDS.PURCHASE_LINE]: RecordType<PurchaseOrderLineSchema>;
  [ALL_RECORD_TYPE_IDS.NO_PART_PURCHASE_LINE]: RecordType<NoPartPurchaseLineSchema>;
  [ALL_RECORD_TYPE_IDS.PURCHASE_REQUEST]: RecordType<PurchaseRequestSchema>;
  [ALL_RECORD_TYPE_IDS.PURCHASE_REQUEST_GROUP]: RecordType<PurchaseRequestGroupSchema>;
  [ALL_RECORD_TYPE_IDS.PURCHASE_EVENT]: RecordType<PurchaseEvent>;
  [ALL_RECORD_TYPE_IDS.PRODUCTION_RUN]: RecordType<ProductionRun>;
  [ALL_RECORD_TYPE_IDS.BOM]: RecordType<BomQuerySchema>;
  [ALL_RECORD_TYPE_IDS.PART]:
    | RecordType<OrgPartSchema>
    | RecordType<PublicPartSchema>;
};

export const ALL_RECORD_TYPES: RecordTypeMap = {
  [ALL_RECORD_TYPE_IDS.RFQ]: {
    id: ALL_RECORD_TYPE_IDS.RFQ,
    singularName: 'RFQ',
    pluralName: 'RFQs',
    app: ALL_APP_IDS.SOURCING,
    tagRecord: true,
    customPropertyModel: CustomPropertyModel.RFQ,
    approveMutation: useApproveRfqsMutation,
    unapproveMutation: useUnapproveRfqsMutation,
    archiveMutation: useArchiveRfqsMutation,
    unarchiveMutation: useUnarchiveRfqsMutation,
    voidMutation: useVoidRfqsMutation,
    unvoidMutation: useUnvoidRfqsMutation,
    deleteMutation: useDeleteRfqsMutation,
    updateMutation: useUpdateRfqsMutation,
    externallySyncableRecord: true,
    flagshipModel: FlagshipModelOptions.RFQ,
    generatePdfMutation: useGenerateRfqPdfMutation,
    generatePdfDocumentType: StockDocType.RFQ,
  },
  [ALL_RECORD_TYPE_IDS.RFQ_LINE]: {
    id: ALL_RECORD_TYPE_IDS.RFQ_LINE,
    singularName: 'RFQ Line',
    pluralName: 'RFQ Lines',
    app: ALL_APP_IDS.SOURCING,
    tagRecord: true,
    customPropertyModel: CustomPropertyModel.RFQ_LINE,
    deleteMutation: useDeleteRfqLinesMutation,
    updateMutation: useUpdateRfqLinesMutation,
    teamRecord: true,
    flagshipModel: FlagshipModelOptions.RFQ_LINE,
  },
  [ALL_RECORD_TYPE_IDS.RFQ_SUPPLIER_JOIN]: {
    id: ALL_RECORD_TYPE_IDS.RFQ_SUPPLIER_JOIN,
    singularName: 'Recipient',
    pluralName: 'Recipients',
    app: ALL_APP_IDS.SOURCING,
    flagshipModel: FlagshipModelOptions.RFQ_SUPPLIER_JOIN,
  },
  [ALL_RECORD_TYPE_IDS.QUOTE]: {
    id: ALL_RECORD_TYPE_IDS.QUOTE,
    singularName: 'Quote',
    pluralName: 'Quotes',
    app: ALL_APP_IDS.SOURCING,
    tagRecord: true,
    customPropertyModel: CustomPropertyModel.SUPPLIER_QUOTE,
    deleteMutation: useDeleteSupplierQuoteMutation,
    updateMutation: useUpdateSupplierQuoteMutation,
    archiveMutation: useArchiveSupplierQuoteActionMutation,
    unarchiveMutation: useUnarchiveSupplierQuoteActionMutation,
    externallySyncableRecord: true,
    flagshipModel: FlagshipModelOptions.SUPPLIER_QUOTE,
  },
  [ALL_RECORD_TYPE_IDS.QUOTE_LINE]: {
    id: ALL_RECORD_TYPE_IDS.QUOTE_LINE,
    singularName: 'Quote Line',
    pluralName: 'Quote Lines',
    app: ALL_APP_IDS.SOURCING,
    tagRecord: true,
    customPropertyModel: CustomPropertyModel.SUPPLIER_QUOTE_LINE,
    deleteMutation: useDeleteSupplierQuoteLineMutation,
    updateMutation: useUpdateSupplierQuoteLineMutation,
    approveMutation: useApproveSupplierQuoteLineActionMutation,
    unapproveMutation: useUnapproveSupplierQuoteLineActionMutation,
    voidMutation: useVoidSupplierQuoteLineActionMutation,
    unvoidMutation: useUnvoidSupplierQuoteLineActionMutation,
    teamRecord: true,
    flagshipModel: FlagshipModelOptions.SUPPLIER_QUOTE_LINE,
  },
  [ALL_RECORD_TYPE_IDS.SUPPLIER_BILL]: {
    id: ALL_RECORD_TYPE_IDS.SUPPLIER_BILL,
    singularName: 'Bill',
    pluralName: 'Bills',
    app: ALL_APP_IDS.PROCUREMENT,
    customPropertyModel: CustomPropertyModel.SUPPLIER_BILL,
    approveMutation: useApproveSupplierBillActionMutation,
    unapproveMutation: useUnapproveSupplierBillActionMutation,
    archiveMutation: useArchiveSupplierBillActionMutation,
    unarchiveMutation: useUnarchiveSupplierBillActionMutation,
    voidMutation: useVoidSupplierBillActionMutation,
    unvoidMutation: useUnvoidSupplierBillActionMutation,
    deleteMutation: useDeleteSupplierBillMutation,
    updateMutation: useUpdateSupplierBillMutation,
    externallySyncableRecord: true,
    flagshipModel: FlagshipModelOptions.SUPPLIER_BILL,
  },
  [ALL_RECORD_TYPE_IDS.SUPPLIER_BILL_LINE]: {
    id: ALL_RECORD_TYPE_IDS.SUPPLIER_BILL_LINE,
    singularName: 'Bill Line',
    pluralName: 'Bill Lines',
    app: ALL_APP_IDS.PROCUREMENT,
    customPropertyModel: CustomPropertyModel.SUPPLIER_BILL_LINE,
    deleteMutation: useDeleteSupplierBillLineMutation,
    updateMutation: useUpdateSupplierBillLineMutation,
    flagshipModel: FlagshipModelOptions.SUPPLIER_BILL_LINE,
  },
  [ALL_RECORD_TYPE_IDS.STOCK_LOT]: {
    id: ALL_RECORD_TYPE_IDS.STOCK_LOT,
    singularName: 'Stock Lot',
    pluralName: 'Stock Lots',
    app: ALL_APP_IDS.STOCK,
    flagshipModel: FlagshipModelOptions.STOCK_LOT,
  },
  [ALL_RECORD_TYPE_IDS.SHIPMENT]: {
    id: ALL_RECORD_TYPE_IDS.SHIPMENT,
    singularName: 'Shipment',
    pluralName: 'Shipments',
    app: ALL_APP_IDS.KITTING,
    flagshipModel: FlagshipModelOptions.SHIPMENT,
    generatePdfMutation: useGenerateShipmentPdfMutation,
    generatePdfDocumentType: StockDocType.PACKING_SLIP,
  },
  [ALL_RECORD_TYPE_IDS.KIT_REQUEST]: {
    id: ALL_RECORD_TYPE_IDS.KIT_REQUEST,
    singularName: 'Kit',
    pluralName: 'Kits',
    app: ALL_APP_IDS.KITTING,
    flagshipModel: FlagshipModelOptions.KIT_REQUEST,
  },
  [ALL_RECORD_TYPE_IDS.ORG_SUPPLIER]: {
    id: ALL_RECORD_TYPE_IDS.ORG_SUPPLIER,
    singularName: 'Supplier',
    pluralName: 'Suppliers',
    app: ALL_APP_IDS.SUPPLIERS,
    flagshipModel: FlagshipModelOptions.ORG_SUPPLIER,
    tagRecord: true,
  },
  [ALL_RECORD_TYPE_IDS.V2_ORG_PART]: {
    id: ALL_RECORD_TYPE_IDS.V2_ORG_PART,
    singularName: 'Part',
    pluralName: 'Parts',
    app: ALL_APP_IDS.PARTS,
    flagshipModel: FlagshipModelOptions.V2_ORG_PART,
    customPropertyModel: CustomPropertyModel.V2_ORG_PART,
    updateMutation: useUpdateOrgPartMutation,
  },
  [ALL_RECORD_TYPE_IDS.PURCHASE]: {
    id: ALL_RECORD_TYPE_IDS.PURCHASE,
    singularName: 'Purchase Order',
    pluralName: 'Purchase Orders',
    app: ALL_APP_IDS.PROCUREMENT,
    flagshipModel: FlagshipModelOptions.PURCHASE,
    generatePdfMutation: useGeneratePurchaseOrderPdfMutation,
    generatePdfDocumentType: StockDocType.PURCHASE_ORDER,
  },
  [ALL_RECORD_TYPE_IDS.PURCHASE_LINE]: {
    id: ALL_RECORD_TYPE_IDS.PURCHASE_LINE,
    singularName: 'Purchase Order Line',
    pluralName: 'Purchase Order Lines',
    app: ALL_APP_IDS.PROCUREMENT,
    flagshipModel: FlagshipModelOptions.PURCHASE_LINE,
  },
  [ALL_RECORD_TYPE_IDS.NO_PART_PURCHASE_LINE]: {
    id: ALL_RECORD_TYPE_IDS.NO_PART_PURCHASE_LINE,
    singularName: 'Other Purchase Order Line',
    pluralName: 'Other Purchase Order Lines',
    app: ALL_APP_IDS.PROCUREMENT,
    flagshipModel: FlagshipModelOptions.NO_PART_PURCHASE_LINE,
  },
  [ALL_RECORD_TYPE_IDS.PURCHASE_REQUEST]: {
    id: ALL_RECORD_TYPE_IDS.PURCHASE_REQUEST,
    singularName: 'Requisition Line',
    pluralName: 'Requisition Lines',
    app: ALL_APP_IDS.REQUISITIONS,
    flagshipModel: FlagshipModelOptions.PURCHASE_REQUEST,
    tagRecord: true,
  },
  [ALL_RECORD_TYPE_IDS.PURCHASE_REQUEST_GROUP]: {
    id: ALL_RECORD_TYPE_IDS.PURCHASE_REQUEST_GROUP,
    singularName: 'Requisition',
    pluralName: 'Requisitions',
    app: ALL_APP_IDS.REQUISITIONS,
    flagshipModel: FlagshipModelOptions.PURCHASE_REQUEST_GROUP,
    tagRecord: true,
  },
  [ALL_RECORD_TYPE_IDS.PURCHASE_EVENT]: {
    id: ALL_RECORD_TYPE_IDS.PURCHASE_EVENT,
    singularName: 'Purchase Update',
    pluralName: 'Purchase Updates',
    app: ALL_APP_IDS.PROCUREMENT,
    flagshipModel: FlagshipModelOptions.PURCHASE_EVENT,
  },
  [ALL_RECORD_TYPE_IDS.PRODUCTION_RUN]: {
    id: ALL_RECORD_TYPE_IDS.PRODUCTION_RUN,
    singularName: 'Program',
    pluralName: 'Programs',
    app: ALL_APP_IDS.PRODUCTION,
    flagshipModel: FlagshipModelOptions.PRODUCTION_RUN,
  },
  [ALL_RECORD_TYPE_IDS.BOM]: {
    id: ALL_RECORD_TYPE_IDS.BOM,
    singularName: 'BOM',
    pluralName: 'BOMs',
    app: ALL_APP_IDS.BOMS,
    flagshipModel: FlagshipModelOptions.BOM,
  },
  [ALL_RECORD_TYPE_IDS.PART]: {
    id: ALL_RECORD_TYPE_IDS.PART,
    singularName: 'Part',
    pluralName: 'Parts',
    app: ALL_APP_IDS.PARTS,
    flagshipModel: FlagshipModelOptions.V2_ORG_PART,
  },
};
