import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ClientV2ToggleRequest,
  ClientV2ToggleResponse,
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  AsyncJobResponse,
  ListResponse,
  QueryParams,
  ClientV2BulkActionRequest,
  ClientV2PATCHRequest,
} from 'types/api';
import {
  PurchaseOrderCore,
  PurchaseOrderLineSchema,
  NoPartPurchaseLineSchema,
  SupplierBillSchema,
  SupplierBillPOSTSchema,
  SupplierBillPATCHSchema,
  SupplierBillLineSchema,
  SupplierBillLinePOSTSchema,
  SupplierBillLinePATCHSchema,
  SupplierBillLineMatchCore,
  CheckIfCanAutoApproveSupplierBillResponse,
} from 'types/procurement';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSupplierBills: builder.query<
      ListResponse<SupplierBillSchema>,
      QueryParams<SupplierBillSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<SupplierBillSchema>,
        QueryParams<SupplierBillSchema>
      >((params) =>
        CLIENT_V2_URLS.SUPPLIER_BILLS(
          parseQueryParams<SupplierBillSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: SupplierBillSchema) =>
                  ({ type: 'SupplierBills', id } as const)
              ),
              { type: 'SupplierBills', id: 'LIST' },
            ]
          : [{ type: 'SupplierBills', id: 'LIST' }],
    }),
    getSupplierBill: builder.query<
      SupplierBillSchema,
      {
        supplierBillId: SupplierBillSchema['id'];
        params?: QueryParams<SupplierBillSchema>;
      }
    >({
      query: ({ supplierBillId, params = {} }) =>
        CLIENT_V2_URLS.SUPPLIER_BILLS(
          `${supplierBillId}${parseQueryParams<SupplierBillSchema>(params)}`
        ),
      providesTags: (result) =>
        result
          ? [{ type: 'SupplierBills', id: result.id }]
          : [{ type: 'SupplierBills', id: 'LIST' }],
    }),
    createSupplierBill: builder.mutation<
      ClientV2POSTResponse<SupplierBillSchema>,
      ClientV2POSTRequest<SupplierBillPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<SupplierBillSchema>,
        ClientV2POSTRequest<SupplierBillPOSTSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'SupplierBills', id: 'LIST' }],
    }),
    updateSupplierBill: builder.mutation<
      ClientV2PATCHResponse<SupplierBillSchema>,
      ClientV2PATCHRequest<SupplierBillPATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error) => [
        ...(result
          ? result.updatedIds.map(
              (id) => ({ type: 'SupplierBills', id } as const)
            )
          : []),
        { type: 'SupplierBillLines', id: 'LIST' },
      ],
    }),
    updateSupplierBillPurchaseOrderAction: builder.mutation<
      ClientV2PATCHResponse<SupplierBillSchema>,
      {
        id: SupplierBillSchema['id'];
        payload: { purchase: PurchaseOrderCore['id'] };
      }
    >({
      query: ({ id, payload }) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_UPDATE_PURCHASE_ORDER(),
        method: 'POST',
        body: {
          ...payload,
          id,
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'SupplierBills', id },
        { type: 'SupplierBillLines', id: 'LIST' },
      ],
    }),
    approveSupplierBillAction: builder.mutation<
      ClientV2ToggleResponse<SupplierBillSchema>,
      ClientV2ToggleRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_APPROVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierBills', id })),
    }),
    unapproveSupplierBillAction: builder.mutation<
      ClientV2ToggleResponse<SupplierBillSchema>,
      ClientV2ToggleRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_UNAPPROVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierBills', id })),
    }),
    archiveSupplierBillAction: builder.mutation<
      ClientV2ToggleResponse<SupplierBillSchema>,
      ClientV2ToggleRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_ARCHIVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierBills', id })),
    }),
    unarchiveSupplierBillAction: builder.mutation<
      ClientV2ToggleResponse<SupplierBillSchema>,
      ClientV2ToggleRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_UNARCHIVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) =>
        data.ids.map((id) => ({ type: 'SupplierBills', id })),
    }),
    voidSupplierBillAction: builder.mutation<
      ClientV2ToggleResponse<SupplierBillSchema>,
      ClientV2ToggleRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_VOID(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        ...data.ids.map((id) => ({ type: 'SupplierBills' as const, id })),
        { type: 'SupplierBillLines' as const, id: 'LIST' },
      ],
    }),
    unvoidSupplierBillAction: builder.mutation<
      ClientV2ToggleResponse<SupplierBillSchema>,
      ClientV2ToggleRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_UNVOID(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        ...data.ids.map((id) => ({ type: 'SupplierBills' as const, id })),
        { type: 'SupplierBillLines' as const, id: 'LIST' },
      ],
    }),
    deleteSupplierBill: builder.mutation<
      ClientV2DELETEResponse<SupplierBillSchema>,
      ClientV2DELETERequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS(),
        method: 'DELETE',
        body: { ids: data.ids },
      }),
      invalidatesTags: [{ type: 'SupplierBills', id: 'LIST' }],
    }),
    checkSupplierBillCanAutoApproveAction: builder.mutation<
      CheckIfCanAutoApproveSupplierBillResponse[],
      ClientV2BulkActionRequest<SupplierBillSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS_CHECK_AUTO_APPROVE,
        method: 'POST',
        body: data,
      }),
    }),
    autoMatchPurchaseLinesAction: builder.mutation<
      AsyncJobResponse<null>,
      ClientV2POSTRequest<SupplierBillLineSchema['id'][]>
    >({
      query: (ids) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINE_AUTO_MATCH(),
        method: 'POST',
        body: {
          supplierBillLineIds: ids,
        },
      }),
      invalidatesTags: [{ type: 'SupplierBillLines', id: 'LIST' }],
    }),
    createSupplierBillLine: builder.mutation<
      ClientV2POSTResponse<SupplierBillLineSchema>,
      ClientV2POSTRequest<SupplierBillLinePOSTSchema>
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINES(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'SupplierBillLines', id: 'LIST' }],
    }),
    updateSupplierBillLine: builder.mutation<
      ClientV2PATCHResponse<SupplierBillLineSchema>,
      ClientV2PATCHRequest<SupplierBillLinePATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINES(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error) => [
        { type: 'SupplierBills', id: 'LIST' },
        ...(result
          ? result.updatedIds.map(
              (id) => ({ type: 'SupplierBillLines', id } as const)
            )
          : []),
      ],
    }),
    getSupplierBillLines: builder.query<
      ListResponse<SupplierBillLineSchema>,
      QueryParams<SupplierBillLineSchema>
    >({
      query: (params: QueryParams<SupplierBillLineSchema>) =>
        CLIENT_V2_URLS.SUPPLIER_BILL_LINES(
          parseQueryParams<SupplierBillLineSchema>(params)
        ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: SupplierBillLineSchema) =>
                  ({ type: 'SupplierBillLines', id } as const)
              ),
              { type: 'SupplierBillLines', id: 'LIST' },
            ]
          : [{ type: 'SupplierBillLines', id: 'LIST' }],
    }),
    deleteSupplierBillLine: builder.mutation<
      ClientV2DELETEResponse<SupplierBillLineSchema>,
      ClientV2DELETERequest<SupplierBillLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINES(),
        method: 'DELETE',
        body: { ids: data.ids },
      }),
      invalidatesTags: (result) => [
        { type: 'SupplierBills', id: 'LIST' },
        ...(result?.deletedIds ?? []).map(
          (id) => ({ type: 'SupplierBillLines', id } as const)
        ),
      ],
    }),
    addSupplierBillLinePurchaseOrderLineMatch: builder.mutation<
      ClientV2POSTResponse<SupplierBillLineMatchCore>,
      {
        id: SupplierBillLineSchema['id'];
        payload: SupplierBillLineMatchCore[];
      }
    >({
      query: ({ id, payload }) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINE_ADD_MATCHES(id),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'SupplierBillLines', id },
      ],
    }),
    updateSupplierBillLinePurchaseOrderLineMatch: builder.mutation<
      ClientV2POSTResponse<SupplierBillLineMatchCore>,
      {
        id: SupplierBillLineSchema['id'];
        payload: SupplierBillLineMatchCore[];
      }
    >({
      query: ({ id, payload }) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINE_UPDATE_MATCHES(id),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'SupplierBillLines', id },
      ],
    }),
    deleteSupplierBillLinePurchaseOrderLineMatch: builder.mutation<
      ClientV2DELETEResponse<SupplierBillLineMatchCore>,
      {
        id: SupplierBillLineSchema['id'];
        payload:
          | {
              purchaseLine?: PurchaseOrderLineSchema['id'];
            }
          | {
              noPartPurchaseLine?: NoPartPurchaseLineSchema['id'];
            };
      }
    >({
      query: ({ id, payload }) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINE_DELETE_MATCHES(id),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'SupplierBillLines', id },
      ],
    }),
  }),
});

export const {
  useGetSupplierBillsQuery,
  useGetSupplierBillQuery,
  useCreateSupplierBillMutation,
  useUpdateSupplierBillMutation,
  useUpdateSupplierBillPurchaseOrderActionMutation,
  useApproveSupplierBillActionMutation,
  useUnapproveSupplierBillActionMutation,
  useArchiveSupplierBillActionMutation,
  useUnarchiveSupplierBillActionMutation,
  useVoidSupplierBillActionMutation,
  useUnvoidSupplierBillActionMutation,
  useCheckSupplierBillCanAutoApproveActionMutation,
  useAutoMatchPurchaseLinesActionMutation,
  useGetSupplierBillLinesQuery,
  useDeleteSupplierBillMutation,
  useCreateSupplierBillLineMutation,
  useUpdateSupplierBillLineMutation,
  useDeleteSupplierBillLineMutation,
  useAddSupplierBillLinePurchaseOrderLineMatchMutation,
  useUpdateSupplierBillLinePurchaseOrderLineMatchMutation,
  useDeleteSupplierBillLinePurchaseOrderLineMatchMutation,
  usePrefetch,
} = extendedApiSlice;
