import { StockLotCRUDSchema } from 'types/inventory';
import {
  OrgPartSchema,
  OrgPartSchemaLite,
  PublicPartSchema,
  PublicPartSchemaLite,
} from 'types/clientV2/parts';
import PartV2Cell from 'ui-component/DataGrid/Cell/PartV2Cell';

export type RenderPartV2CellProps = {
  noPart?: string;
  altCount?: number;
  stockLot?: StockLotCRUDSchema | StockLotCRUDSchema['id'];
  searchParams?: string;
  unapprovedAltCount?: number;
} & (
  | { orgPart: OrgPartSchema | OrgPartSchemaLite; publicPart?: undefined }
  | { orgPart?: undefined; publicPart: PublicPartSchema | PublicPartSchemaLite }
  | { orgPart?: undefined; publicPart?: undefined }
);

export function renderPartV2Cell(props: RenderPartV2CellProps) {
  return <PartV2Cell {...props} />;
}
