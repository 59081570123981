import { Avatar, Box, Divider, Drawer, Grid, Stack } from '@mui/material';
import { ReactNode, createContext, useState, Fragment, useMemo } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ExtendedTabs, {
  ExtendedTabOption,
} from 'ui-component/extended/ExtendedTabs';
import { useTheme } from '@mui/material/styles';
import { actionButtonSpacing } from 'constants/themeConstants';
import SidebarInfoSection, {
  SidebarInfoSectionProps,
} from 'ui-component/DetailLayout/SidebarComponents/SidebarInfoSection';
import _ from 'lodash';
import {
  IconFileExport,
  IconLayoutBottombarCollapse,
  IconLayoutBottombarExpand,
  IconLayoutNavbarCollapse,
  IconLayoutNavbarExpand,
  IconLayoutSidebarRightCollapse,
  IconLayoutSidebarRightExpand,
  IconPaperclip,
  IconPlug,
  IconShare,
  IconTag,
} from '@tabler/icons-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  ALL,
  DeleteMutation,
  GenericResource,
  PatchMutation,
  ToggleMutation,
} from 'types/api';
import { ALL_RECORD_TYPE_IDS, ALL_RECORD_TYPES } from 'constants/recordTypes';
import { appPermissionAccessLevels, RecordType } from 'types/apps';
import SidebarApproveButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarApproveButtonClientV2';
import SidebarRejectButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarRejectButtonClientV2';
import SidebarDeleteButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarDeleteButtonClientV2';
import SidebarArchiveButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarArchiveButtonClientV2';
import SidebarCustomProperties from 'ui-component/DetailLayout/SidebarComponents/SidebarCustomProperties';
import { InfoSectionItemFormatTypes } from 'ui-component/InfoSectionItem';
import EditInline from 'ui-component/EditInline';
import HookFormTagsAutocomplete from 'ui-component/HookFormComponents/HookFormTagsAutocomplete';
import ExternallySyncedInfoSection from 'ui-component/ExternallySyncedInfoSection';
import { formatToDateTime } from 'utils/dates';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { DateTimeString, FeatureFlags } from 'types';
import { Tag } from 'types/clientV2/tag';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { useGetStockDocumentRelatedObjectsQuery } from 'store/slices/clientV2/stockDocuments';
import { StockDocumentRelatedObjectSchema } from 'types/clientV2/stockDocuments';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DatagridFilterOperatorsValues } from 'types/datagrid';
import { useFeature } from '@growthbook/growthbook-react';
import { DocumentTemplate } from 'types/inventory';
import GenerateDocumentButton from 'ui-component/StockDocuments/GenerateDocumentButton';
import InlineUploadDocumentsDropZone from 'ui-component/StockDocuments/UploadDocuments/InlineUploadDocumentsDropZone';
import { useDialogManager } from 'hooks/useDialogManager';
import StockDocumentsDialog from 'ui-component/StockDocuments/StockDocumentsDialog';
import { useIsCofactr } from 'hooks/useIsCofactr';
import { useSelector } from 'store';
import SidebarActionButton from 'ui-component/DetailLayout/SidebarComponents/SidebarActionButton';
import { copyClipboard } from 'utils/functions';

export enum DETAIL_VIEW_DIALOGS {
  GENERATED_DOCUMENTS = 'GENERATED_DOCUMENTS',
  ATTACHMENTS = 'ATTACHMENTS',
}

export const DrawerContext = createContext(false);

export interface DetailLayoutProps<T extends GenericResource> {
  headerSection?: ReactNode;
  headerBottomContent?: ReactNode;
  footerSection?: ReactNode;
  tabOptions?: ExtendedTabOption[];
  sidebarActionButtons?: ReactNode[];
  sidebarInfoSections?: SidebarInfoSectionProps[];
  additionalSidebarSections?: ReactNode[];
  tabTrackingName?: string;
  record?: T | null;
  recordType?: ALL_RECORD_TYPE_IDS | null;
  isLoadingRecord?: boolean;
  documentGenerationProps?: {
    defaultDocumentTemplateId?: DocumentTemplate['id'] | null;
    additionalGenerateProps?: object;
  };
}

const DetailLayout = <T extends GenericResource>({
  headerSection,
  headerBottomContent,
  footerSection,
  tabOptions,
  sidebarActionButtons = [],
  sidebarInfoSections = [],
  additionalSidebarSections = [],
  tabTrackingName,
  record,
  recordType,
  isLoadingRecord = false,
  documentGenerationProps,
}: DetailLayoutProps<T>) => {
  const tempStockDocsRefactor = useFeature(
    FeatureFlags.tempStockDocsRefactor
  ).on;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [headerOpen, setHeaderOpen] = useState(true);
  const [footerOpen, setFooterOpen] = useState(true);

  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );

  const { isCofactr } = useIsCofactr();
  const { activeOrgId } = useSelector((state) => state.org);
  const { toggleDialog, isDialogOpen } = useDialogManager(
    Object.values(DETAIL_VIEW_DIALOGS)
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const recordTypeDefinition = recordType
    ? (ALL_RECORD_TYPES[recordType] as unknown as RecordType<T>)
    : null;

  const recordExternallySynced = _.get(record, 'externallySynced', false);
  const recordTags = record?.tags ? (_.get(record, 'tags', []) as Tag[]) : null;

  const isExternallySynced: boolean = Boolean(
    recordTypeDefinition?.externallySyncableRecord && recordExternallySynced
  );

  const { data: stockDocuments, isLoading: isLoadingStockDocuments } =
    useServerSideQuery<
      StockDocumentRelatedObjectSchema,
      StockDocumentRelatedObjectSchema
    >(
      useGetStockDocumentRelatedObjectsQuery,
      record?.id && recordType && tempStockDocsRefactor
        ? {
            filters: [
              {
                field: 'relatedObjectId',
                operator: DatagridFilterOperatorsValues.is,
                value: record.id,
              },
            ],
            schema: [
              ALL,
              {
                stockDocument: [],
              },
            ],
          }
        : skipToken
    );

  const relatedStockDocuments = _.chain(stockDocuments?.data || [])
    .filter((d) => d.stockDocument.createdFromObjectId !== record?.id)
    .uniqBy((d) => d.stockDocument.id)
    .value();
  const generatedStockDocuments = _.chain(stockDocuments?.data || [])
    .filter((d) => d.stockDocument.createdFromObjectId === record?.id)
    .uniqBy((d) => d.stockDocument.id)
    .value();

  const allSidebarInfoSections = [
    ...(isExternallySynced
      ? [
          {
            caption: 'Integration Source',
            isLoading: isLoadingRecord,
            placeholder: ' ',
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.children,
            textValue: record?.dataSource,
            Icon: IconPlug,
            alwaysVisible: true,
            children: <ExternallySyncedInfoSection record={record as T} />,
            collapsedTooltip: `Synced from integration at ${
              record?.dataSourceSyncedAt
                ? formatToDateTime(record?.dataSourceSyncedAt as DateTimeString)
                : 'Unknown'
            }`,
          } as SidebarInfoSectionProps,
        ]
      : []),
    ...(recordTypeDefinition?.tagRecord &&
    recordTags &&
    recordTypeDefinition.updateMutation
      ? [
          {
            caption: 'Tags',
            isLoading: isLoadingRecord,
            formatType: InfoSectionItemFormatTypes.tags,
            tagsValue: recordTags,
            count: recordTags.length,
            Icon: IconTag,
            disableEdit: isExternallySynced,
            collapsedTooltip: _.chain(recordTags)
              .map((tag) => tag.name)
              .join(', ')
              .value(),
            editInline: (
              <EditInline
                useMutation={
                  recordTypeDefinition.updateMutation as UseMutation<any>
                }
                name="tags"
                label="Tags"
                value={recordTags.map((tag) => tag.id)}
                id={record?.id || ''}
                permissionScope={{
                  app: recordTypeDefinition.app,
                  accessLevel: appPermissionAccessLevels.edit,
                }}
                submitAsClientV2Api
              >
                <HookFormTagsAutocomplete
                  tagModel={recordTypeDefinition?.flagshipModel}
                  disableRequiredProps
                  isInlineEdit
                />
              </EditInline>
            ),
          } as SidebarInfoSectionProps,
        ]
      : []),
    ...(tempStockDocsRefactor &&
    record &&
    recordTypeDefinition?.flagshipModel &&
    recordTypeDefinition.generatePdfMutation
      ? ([
          {
            caption: 'Generate Documents',
            isLoading: isLoadingRecord || isLoadingStockDocuments,
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.documents,
            documentsValue: generatedStockDocuments,
            count: generatedStockDocuments?.length || 0,
            Icon: IconFileExport,
            onIconClick: () => {
              setSelectedDocumentId(null);
              toggleDialog(DETAIL_VIEW_DIALOGS.GENERATED_DOCUMENTS);
            },
            children: recordType ? (
              <GenerateDocumentButton
                size="small"
                recordType={recordType}
                defaultDocumentTemplateId={
                  documentGenerationProps?.defaultDocumentTemplateId ||
                  undefined
                }
                isLoading={isLoadingRecord}
                boxSx={
                  generatedStockDocuments?.length || isLoadingRecord
                    ? { mt: actionButtonSpacing }
                    : undefined
                }
                additionalGenerateProps={
                  documentGenerationProps?.additionalGenerateProps
                }
              />
            ) : undefined,
            alwaysVisible: true,
            collapsedTooltip: _.chain(generatedStockDocuments)
              .map((doc) => doc.stockDocument.name)
              .join(', ')
              .value(),
            documentsOnClick: (id) => {
              setSelectedDocumentId(id);
              toggleDialog(DETAIL_VIEW_DIALOGS.GENERATED_DOCUMENTS);
            },
          },
        ] as SidebarInfoSectionProps[])
      : []),
    ...(tempStockDocsRefactor && record && recordTypeDefinition?.flagshipModel
      ? ([
          {
            caption: 'Attachments',
            isLoading: isLoadingRecord || isLoadingStockDocuments,
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.documents,
            documentsValue: relatedStockDocuments,
            count: relatedStockDocuments.length,
            Icon: IconPaperclip,
            onIconClick: () => {
              setSelectedDocumentId(null);
              toggleDialog(DETAIL_VIEW_DIALOGS.ATTACHMENTS);
            },
            children: recordType ? (
              <InlineUploadDocumentsDropZone<T>
                record={record}
                recordType={recordType}
                isLoading={isLoadingRecord}
                boxSx={
                  relatedStockDocuments.length || isLoadingRecord
                    ? { mt: actionButtonSpacing }
                    : undefined
                }
              />
            ) : undefined,
            alwaysVisible: true,
            collapsedTooltip: _.chain(relatedStockDocuments)
              .map((doc) => doc.stockDocument.name)
              .join(', ')
              .value(),
            documentsOnClick: (id) => {
              setSelectedDocumentId(id);
              toggleDialog(DETAIL_VIEW_DIALOGS.ATTACHMENTS);
            },
          },
        ] as SidebarInfoSectionProps[])
      : []),
    ...sidebarInfoSections,
    ...(record?.createdAt
      ? [
          {
            caption: 'Created',
            isLoading: isLoadingRecord,
            placeholder: ' ',
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.user,
            userValue: record?.createdBy,
            dateValue: record?.createdAt,
            useInfoSectionAutomationOption: true,
            visible: Boolean(record?.createdAt),
          } as SidebarInfoSectionProps,
        ]
      : []),
    ...(record?.approvedAt
      ? [
          {
            caption: 'Approved',
            isLoading: isLoadingRecord,
            placeholder: ' ',
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.user,
            userValue: record?.approvedBy,
            dateValue: record?.approvedAt,
            useInfoSectionAutomationOption: true,
            visible: Boolean(record?.approvedAt),
          } as SidebarInfoSectionProps,
        ]
      : []),
    ...(record?.voidedAt
      ? [
          {
            caption: 'Rejected',
            isLoading: isLoadingRecord,
            placeholder: ' ',
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.user,
            userValue: record?.voidedBy,
            dateValue: record?.voidedAt,
            useInfoSectionAutomationOption: true,
            visible: Boolean(record?.voidedAt),
          } as SidebarInfoSectionProps,
        ]
      : []),
    ...(record?.archivedAt
      ? [
          {
            caption: 'Archived',
            isLoading: isLoadingRecord,
            placeholder: ' ',
            clipboard: false,
            formatType: InfoSectionItemFormatTypes.user,
            userValue: record?.archivedBy,
            dateValue: record?.archivedAt,
            useInfoSectionAutomationOption: true,
            visible: Boolean(record?.archivedAt),
          } as SidebarInfoSectionProps,
        ]
      : []),
  ];

  const sidebarItemsWhenCollapsed = _.filter(
    allSidebarInfoSections,
    (section) => !!section.Icon && (!!section.count || !!section.alwaysVisible)
  );

  const showInfoSectionDivider = useMemo(
    () =>
      open
        ? allSidebarInfoSections.length > 0 ||
          additionalSidebarSections.length > 0 ||
          recordTypeDefinition?.customPropertyModel ||
          recordTypeDefinition?.tagRecord
        : sidebarItemsWhenCollapsed.length > 0 ||
          (recordTypeDefinition?.tagRecord &&
            recordTags &&
            recordTags.length > 0),
    [
      open,
      sidebarItemsWhenCollapsed,
      allSidebarInfoSections,
      additionalSidebarSections,
      recordTypeDefinition?.customPropertyModel,
      recordTypeDefinition?.tagRecord,
    ]
  );

  const visibleSidebarInfoSections = allSidebarInfoSections.filter(
    (section) => section.visible !== false
  );

  const collapsedDrawerWidth = 69;
  const drawerWidth = 260;

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          width: '100%',
          height: 'calc(100% + 40px)',
          mt: '-20px',
          mb: '-20px',
          py: 0,
          pr: `${open ? drawerWidth : collapsedDrawerWidth}px`,
        }}
      >
        {headerSection && (
          <Grid item xs="auto">
            <Stack
              direction="column"
              spacing={0}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {headerOpen && (
                <MainCard
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    width: '100%',
                  }}
                  bottomContent={headerBottomContent}
                >
                  {headerSection}
                </MainCard>
              )}
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  overflow: 'hidden',
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.brand.moss,
                  color: theme.palette.brand.steel,
                  borderTopLeftRadius: headerOpen ? undefined : 0,
                  borderTopRightRadius: headerOpen ? undefined : 0,
                  marginTop: headerOpen ? '-17px' : 0,
                  '&:hover': {
                    background: theme.palette.primary.dark,
                    color: theme.palette.common.white,
                  },
                }}
                onClick={() =>
                  setHeaderOpen((prevHeaderOpen) => !prevHeaderOpen)
                }
                color="inherit"
              >
                {headerOpen ? (
                  <IconLayoutNavbarCollapse stroke={1.5} size="1.3rem" />
                ) : (
                  <IconLayoutNavbarExpand stroke={1.5} size="1.3rem" />
                )}
              </Avatar>
            </Stack>
          </Grid>
        )}
        {tabOptions && (
          <Grid
            item
            xs
            sx={{
              mt: headerSection ? '8px' : '0px',
              mb: footerSection ? '8px' : '0px',
            }}
          >
            <MainCard contentSX={{ p: 0, '&:last-child': { p: 0 } }}>
              <ExtendedTabs
                tabOptions={tabOptions}
                queryParam="tab"
                trackingName={tabTrackingName}
              />
            </MainCard>
          </Grid>
        )}

        {footerSection && (
          <Grid item xs="auto">
            <Stack
              direction="column"
              spacing={0}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  overflow: 'hidden',
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.brand.moss,
                  color: theme.palette.brand.steel,
                  borderBottomLeftRadius: footerOpen ? undefined : 0,
                  borderBottomRightRadius: footerOpen ? undefined : 0,
                  marginBottom: footerOpen ? '-17px' : 0,
                  '&:hover': {
                    background: theme.palette.primary.dark,
                    color: theme.palette.common.white,
                  },
                }}
                onClick={() =>
                  setFooterOpen((prevFooterOpen) => !prevFooterOpen)
                }
                color="inherit"
              >
                {footerOpen ? (
                  <IconLayoutBottombarCollapse stroke={1.5} size="1.3rem" />
                ) : (
                  <IconLayoutBottombarExpand stroke={1.5} size="1.3rem" />
                )}
              </Avatar>
              {footerOpen && (
                <MainCard
                  sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    width: '100%',
                  }}
                >
                  {footerSection}
                </MainCard>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
      <Drawer
        variant="permanent"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : collapsedDrawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            height: '100%',
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box
          sx={{
            position: 'fixed',
            top: '16px',
            right: `${(open ? drawerWidth : collapsedDrawerWidth) - 34 / 2}px`,
            zIndex: 100000,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: 'hidden',
              transition: 'all .2s ease-in-out',
              background: theme.palette.brand.moss,
              color: theme.palette.brand.steel,
              '&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.common.white,
              },
            }}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            color="inherit"
          >
            {open ? (
              <IconLayoutSidebarRightCollapse stroke={1.5} size="1.3rem" />
            ) : (
              <IconLayoutSidebarRightExpand stroke={1.5} size="1.3rem" />
            )}
          </Avatar>
        </Box>
        <DrawerContext.Provider value={open}>
          <Box sx={{ height: '100%' }}>
            <PerfectScrollbar component="div">
              <Grid
                container
                direction="column"
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'stretch',
                  mb: actionButtonSpacing,
                }}
              >
                <Grid item xs="auto">
                  <Box sx={{ width: '100%', height: '36px' }} />
                </Grid>
                <Grid item xs="auto" sx={{ px: '16px' }}>
                  <Stack
                    direction="column"
                    spacing={actionButtonSpacing}
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    {recordTypeDefinition?.approveMutation &&
                      recordTypeDefinition?.unapproveMutation &&
                      record?.id && (
                        <SidebarApproveButtonClientV2<
                          T,
                          typeof recordTypeDefinition.approveMutation,
                          typeof recordTypeDefinition.unapproveMutation
                        >
                          recordName={recordTypeDefinition.singularName}
                          approved={record?.approvedAt !== null}
                          idToUpdate={record?.id}
                          useClientApproveMutation={
                            recordTypeDefinition.approveMutation
                          }
                          useClientUnapproveMutation={
                            recordTypeDefinition.unapproveMutation
                          }
                          permissionScope={{
                            app: recordTypeDefinition.app,
                            accessLevel: appPermissionAccessLevels.edit,
                          }}
                          disabled={isExternallySynced}
                        />
                      )}
                    {recordTypeDefinition?.voidMutation &&
                      recordTypeDefinition?.unvoidMutation &&
                      record?.id && (
                        <SidebarRejectButtonClientV2<T>
                          recordName={recordTypeDefinition.singularName}
                          voided={record?.voidedAt !== null}
                          idToUpdate={record?.id}
                          useClientVoidMutation={
                            recordTypeDefinition.voidMutation as ToggleMutation<T>
                          }
                          useClientUnvoidMutation={
                            recordTypeDefinition.unvoidMutation as ToggleMutation<T>
                          }
                          permissionScope={{
                            app: recordTypeDefinition.app,
                            accessLevel: appPermissionAccessLevels.edit,
                          }}
                          disabled={isExternallySynced}
                        />
                      )}
                    {sidebarActionButtons.map((button, index) => (
                      <Box key={index}>{button}</Box>
                    ))}
                    {isCofactr && record && (
                      <SidebarActionButton
                        Icon={IconShare}
                        label="Share Link"
                        onClick={() => {
                          const url = new URL(window.location.href);
                          url.searchParams.set(
                            'activeorgid',
                            activeOrgId || ''
                          );
                          copyClipboard(url.toString());
                        }}
                      />
                    )}
                    {recordTypeDefinition?.archiveMutation &&
                      recordTypeDefinition?.unarchiveMutation &&
                      record?.id && (
                        <SidebarArchiveButtonClientV2<T>
                          recordName={recordTypeDefinition.singularName}
                          archived={record?.archivedAt !== null}
                          idToUpdate={record?.id}
                          useClientArchiveMutation={
                            recordTypeDefinition.archiveMutation as ToggleMutation<T>
                          }
                          useClientUnarchiveMutation={
                            recordTypeDefinition.unarchiveMutation as ToggleMutation<T>
                          }
                          permissionScope={{
                            app: recordTypeDefinition.app,
                            accessLevel: appPermissionAccessLevels.edit,
                          }}
                          disabled={isExternallySynced}
                        />
                      )}
                    {recordTypeDefinition?.deleteMutation && record?.id && (
                      <SidebarDeleteButtonClientV2<T>
                        recordName={recordTypeDefinition.singularName}
                        idToUpdate={record?.id}
                        useClientDeleteMutation={
                          recordTypeDefinition.deleteMutation as DeleteMutation<T>
                        }
                        permissionScope={{
                          app: recordTypeDefinition.app,
                          accessLevel: appPermissionAccessLevels.edit,
                        }}
                      />
                    )}
                  </Stack>
                </Grid>
                {showInfoSectionDivider && (
                  <Divider sx={{ mt: 1, mb: 1 }} flexItem />
                )}
                <Grid item xs>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={actionButtonSpacing}
                    sx={{ mx: '16px', mt: actionButtonSpacing }}
                  >
                    {open &&
                      _.map(visibleSidebarInfoSections, (section, index) => (
                        <Fragment key={`${section.caption}-${index}`}>
                          <SidebarInfoSection {...section} />
                          {(section.visible ||
                            section.visible === undefined) && (
                            <Divider flexItem />
                          )}
                        </Fragment>
                      ))}
                    {recordTypeDefinition?.customPropertyModel &&
                      record?.customProperties &&
                      recordTypeDefinition.updateMutation && (
                        <SidebarCustomProperties<T>
                          useMutation={
                            recordTypeDefinition.updateMutation as PatchMutation<T>
                          }
                          record={record as T}
                          model={recordTypeDefinition.customPropertyModel}
                          isLoadingRecord={isLoadingRecord}
                          idProp="id"
                          submitAsClientV2Api
                          disableEdit={isExternallySynced}
                        />
                      )}
                    {open &&
                      _.map(additionalSidebarSections, (section, index) => (
                        <Fragment key={index}>{section}</Fragment>
                      ))}
                    {!open &&
                      sidebarItemsWhenCollapsed.length > 0 &&
                      _.map(sidebarItemsWhenCollapsed, (section, index) => (
                        <SidebarInfoSection
                          {...section}
                          key={`${section.caption}-${index}`}
                        />
                      ))}
                  </Stack>
                </Grid>
              </Grid>
            </PerfectScrollbar>
          </Box>
        </DrawerContext.Provider>
      </Drawer>
      {record && recordType && (
        <StockDocumentsDialog
          open={isDialogOpen(DETAIL_VIEW_DIALOGS.GENERATED_DOCUMENTS)}
          onClose={() => toggleDialog(DETAIL_VIEW_DIALOGS.GENERATED_DOCUMENTS)}
          selectedDocumentId={selectedDocumentId}
          setSelectedDocumentId={setSelectedDocumentId}
          title="Generate Documents"
          documents={generatedStockDocuments}
          disableDelete
        >
          <GenerateDocumentButton
            size="small"
            recordType={recordType}
            defaultDocumentTemplateId={
              documentGenerationProps?.defaultDocumentTemplateId || undefined
            }
            isLoading={isLoadingRecord}
            boxSx={
              generatedStockDocuments.length || isLoadingRecord
                ? { mt: actionButtonSpacing }
                : undefined
            }
            additionalGenerateProps={
              documentGenerationProps?.additionalGenerateProps
            }
            setSelectedDocumentId={setSelectedDocumentId}
          />
        </StockDocumentsDialog>
      )}
      {record && recordType && (
        <StockDocumentsDialog
          open={isDialogOpen(DETAIL_VIEW_DIALOGS.ATTACHMENTS)}
          onClose={() => toggleDialog(DETAIL_VIEW_DIALOGS.ATTACHMENTS)}
          selectedDocumentId={selectedDocumentId}
          setSelectedDocumentId={setSelectedDocumentId}
          documents={relatedStockDocuments}
        >
          <InlineUploadDocumentsDropZone
            record={record}
            recordType={recordType}
            size="md"
            setSelectedDocumentId={setSelectedDocumentId}
          />
        </StockDocumentsDialog>
      )}
    </>
  );
};

export default DetailLayout;
