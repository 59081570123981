import {
  BoxProps,
  MenuItem,
  IconButton,
  Typography,
  Grid,
  Divider,
  Stack,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ALL_RECORD_TYPE_IDS, ALL_RECORD_TYPES } from 'constants/recordTypes';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { GenericResource } from 'types/api';
import { StockDocType, StockDocTypeLabels } from 'types/inventory';
import { FormValues } from 'ui-component/UploadStockDocsWidget';
import _ from 'lodash';
import FileUploadInput from 'ui-component/FileUploadInput';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { useState } from 'react';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { IconX } from '@tabler/icons-react';
import { actionButtonSpacing } from 'constants/themeConstants';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';
import useSnackbar from 'hooks/useSnackbar';
import { useCreateStockDocsV2 } from 'hooks/useCreateStockDocsV2';
import { useCreateStockDocumentRelatedObjectMutation } from 'store/slices/clientV2/stockDocuments';
import {
  StockDocumentRelatedObjectPOSTSchema,
  StockDocumentSchema,
} from 'types/clientV2/stockDocuments';
import { OrgLite } from 'types/org';

export interface UnsavedStockDocument {
  file: File;
  documentType: StockDocType;
  notes: string;
}

interface InlineUploadDocumentsDropZoneProps<T extends GenericResource> {
  record?: T;
  recordType: ALL_RECORD_TYPE_IDS;
  setSelectedDocumentId?: (id: string) => void;
  onSave?: (ids: StockDocumentSchema['id'][]) => void;
  boxSx?: BoxProps['sx'];
  isLoading?: boolean;
  recordOrgId?: string | null;
  size?: 'sm' | 'md';
  onDialogCloseWithoutSave?: (docs: UnsavedStockDocument[]) => void;
}

const InlineUploadDocumentsDropZone = <T extends GenericResource>({
  record,
  recordType,
  recordOrgId,
  setSelectedDocumentId,
  onSave,
  boxSx,
  isLoading,
  size = 'sm',
  onDialogCloseWithoutSave,
}: InlineUploadDocumentsDropZoneProps<T>) => {
  const theme = useTheme();
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const recordTypeDefinition = ALL_RECORD_TYPES[recordType];
  const { isLoading: isLoadingCreateStockDoc, createStockDocuments } =
    useCreateStockDocsV2();

  const [
    createStockDocumentRelatedObject,
    { isLoading: isLoadingCreateStockDocumentRelatedObject },
  ] = useCreateStockDocumentRelatedObjectMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm<FormValues>({ defaultValues: { stockDocuments: [] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stockDocuments',
  });

  const stockDocuments = watch('stockDocuments');

  const onBatchChange = (newFiles?: File[]) => {
    if (newFiles) {
      append(
        _.map(newFiles, (f) => ({
          file: f,
          documentType: StockDocType.FILE,
          notes: '',
        }))
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      if (onDialogCloseWithoutSave) {
        onDialogCloseWithoutSave(data.stockDocuments);
        handleClose();
        return;
      }
      // first we create the actual stock documents
      const results = await createStockDocuments(
        _.map(data.stockDocuments, (i) => ({
          file: i.file,
          documentType: i.documentType,
          nonBillable: true,
          notes: i.notes,
          filename: i.file.name,
          processDocument: false,
        }))
      );
      // then we create the joins
      const orgId =
        recordOrgId ?? _.get(record, 'org.id') ?? _.get(record, 'org');

      const stockDocIds: string[] = _.map(results, 'value.id');
      const joins: StockDocumentRelatedObjectPOSTSchema[] = _.map(
        stockDocIds,
        (id) => ({
          stockDocument: id,
          relatedObjectId: record?.id ?? '',
          relatedObjectType: recordTypeDefinition.flagshipModel,
          org: orgId as OrgLite['id'],
        })
      );

      await createStockDocumentRelatedObject(joins).unwrap();

      dispatchSuccessSnackbar('Documents created');

      if (setSelectedDocumentId) {
        setSelectedDocumentId(stockDocIds[0]);
      }

      if (onSave) {
        onSave(stockDocIds);
      }

      handleClose();
    } catch (err) {
      dispatchErrorSnackbar('Request failed');
      throw err;
    }
  };

  return (
    <>
      <Box sx={boxSx}>
        <WithSkeleton isLoading={isLoading}>
          <FileUploadInput
            batchFileMode
            onBatchChange={onBatchChange}
            allowAllExtensions
            defaultText="Upload Files"
            inlineVariant
            inlineVariantHeight={size}
          />
        </WithSkeleton>
      </Box>
      <ExtendedDialog
        open={open}
        title="Upload Documents"
        onCloseDialog={handleClose}
        submitButtonCopy="Save"
        isSubmitting={
          isLoadingCreateStockDoc || isLoadingCreateStockDocumentRelatedObject
        }
        submitDisabled={(stockDocuments || []).length < 1}
        confirmClose
        isForm
        onSubmit={handleSubmit(onSubmit)}
        maxWidth="md"
        additionalContentAboveForm={
          <FileUploadInput
            batchFileMode
            onBatchChange={onBatchChange}
            allowAllExtensions
            defaultText="Upload Files"
            inlineVariant
            inlineVariantHeight="md"
          />
        }
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={actionButtonSpacing}
          divider={<Divider flexItem />}
        >
          {fields.map((field, index) => (
            <Stack
              key={field.id}
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="h5">{field.file.name}</Typography>
                </Grid>
                <Grid item xs="auto">
                  <IconButton aria-label="delete" onClick={() => remove(index)}>
                    <IconX color={theme.palette.error.main} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={actionButtonSpacing}>
                <Grid item xs={6}>
                  <HookFormSelect
                    name={`stockDocuments.${index}.documentType`}
                    label="Document Type"
                    control={control}
                    errors={errors}
                    mt={0}
                  >
                    {_.toPairs(StockDocTypeLabels).map((option) => (
                      <MenuItem key={option[0]} value={option[0]}>
                        {option[1]}
                      </MenuItem>
                    ))}
                  </HookFormSelect>
                </Grid>
                <Grid item xs={6}>
                  <HookFormInput
                    name={`stockDocuments.${index}.notes`}
                    label="Notes"
                    control={control}
                    errors={errors}
                    boxSx={{ mb: 0 }}
                  />
                </Grid>
              </Grid>
            </Stack>
          ))}
        </Stack>
      </ExtendedDialog>
    </>
  );
};

export default InlineUploadDocumentsDropZone;
