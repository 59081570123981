import { useState, MutableRefObject } from 'react';
import {
  MenuItem,
  ButtonProps,
  Divider,
  Typography,
  Skeleton,
} from '@mui/material';
import {
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridPrintExportMenuItem,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useFeature } from '@growthbook/growthbook-react';
import _ from 'lodash';

import { FeatureFlags } from 'types';
import { ALL, DISABLE_PAGE_LIMIT } from 'types/api';
import { DatagridNames, ExtendedGridColDef } from 'types/datagrid';
import {
  ReportTemplateSchema,
  ReportTemplateSchemaLite,
} from 'types/clientV2/reporting';
import { GenerateReportDataType, ReportDataTypes } from 'types/generateReports';
import useGenerateReports, { ReportType } from 'hooks/useGenerateReport';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { useGetReportTemplatesQuery } from 'store/slices/clientV2/reporting';
import { nowShortDate } from 'utils/dates';
import { downloadFile } from 'utils/functions';
import { processCsvData, processExcelData } from 'ui-component/DataGrid/utils';

type CustomExportButtonProps = {
  apiRef: MutableRefObject<GridApiPremium>;
  columns: ExtendedGridColDef[];
  exportFields: () => string[];
  exportFileNamePrefix?: string | null;
  generateReportData?: GenerateReportDataType;
  gridName: DatagridNames;
} & ButtonProps;

export default function CustomExportButton({
  apiRef,
  columns,
  exportFields,
  exportFileNamePrefix,
  generateReportData,
  gridName,
  ...rest
}: CustomExportButtonProps) {
  const [generateReportOpen, setGenerateReportOpen] = useState<boolean>(false);
  const generateReportsFeature = useFeature(FeatureFlags.generateReports).on;
  const { GenerateReportDialog, generateReport } = useGenerateReports(
    !!generateReportData
  );

  const {
    data: { data: templates = [] } = {
      data: [],
    },
    isFetching: isFetchingTemplates,
  } = useServerSideQuery<ReportTemplateSchema, ReportTemplateSchemaLite>(
    useGetReportTemplatesQuery,
    {
      pageSize: DISABLE_PAGE_LIMIT,
      schema: [
        ALL,
        {
          codeSnippetQuery: [],
          codeSnippetPreProcessor: [],
          codeSnippetPostProcessor: [],
        },
      ],
      filters: [
        {
          field: 'associatedDatagrid',
          operator: 'is',
          value: gridName,
        },
      ],
    }
  );

  return (
    <GridToolbarExportContainer {...rest}>
      {[
        <MenuItem
          key="csv-export"
          onClick={() => {
            const fieldsToExport = exportFields();
            const columnsToExport = columns.filter((column) =>
              fieldsToExport.includes(column.field)
            );
            const csvData = apiRef?.current?.getDataAsCsv({
              fields: fieldsToExport,
            });
            if (csvData) {
              const updatedCsvData = processCsvData(csvData, columnsToExport);
              const blob = new Blob([updatedCsvData], {
                type: 'text/csv;charset=utf-8;',
              });
              downloadFile(
                blob,
                `${exportFileNamePrefix || 'CofactrExport'}_${nowShortDate()}`,
                'csv'
              );
            }
          }}
        >
          Download as CSV
        </MenuItem>,
        <GridExcelExportMenuItem
          key="excel-export"
          options={{
            exceljsPostProcess: async (processInput) => {
              processExcelData(
                processInput,
                columns.filter((column) =>
                  exportFields().includes(column.field)
                )
              );
              return Promise.resolve();
            },
            fields: exportFields(),
            fileName: `${
              exportFileNamePrefix || 'CofactrExport'
            }_${nowShortDate()}`,
          }}
        />,
        <GridPrintExportMenuItem key="print-export" />,
        _.includes(ReportDataTypes, gridName) && generateReportsFeature && (
          <MenuItem
            key="custom-export"
            onClick={() => {
              setGenerateReportOpen(true);
            }}
          >
            Custom Report
          </MenuItem>
        ),
        ...(isFetchingTemplates
          ? [
              <Divider key="loading-templates-divider" />,
              <Skeleton key="loading-skeleton" sx={{ m: 2 }} />,
            ]
          : []),
        ...(templates.length
          ? [
              <Divider key="templates-divider" />,
              <MenuItem
                key="templates-header"
                sx={{ '&:hover': { background: 'none', cursor: 'default' } }}
              >
                <Typography variant="subtitle2">Templates</Typography>
              </MenuItem>,
              ...templates.map((template) => (
                <MenuItem
                  key={template.id}
                  onClick={() =>
                    generateReport({
                      type: ReportType.REPORT_TEMPLATE,
                      data: generateReportData,
                      template,
                      gridName,
                    })
                  }
                >
                  {template.name}
                </MenuItem>
              )),
            ]
          : []),
      ]}
      <GenerateReportDialog
        key="report-dialog"
        data={generateReportData}
        open={generateReportOpen}
        onClose={() => {
          setGenerateReportOpen(false);
        }}
        gridName={gridName}
      />
    </GridToolbarExportContainer>
  );
}
