import { FC, ReactNode, useContext } from 'react';
import InfoSectionItem, {
  InfoSectionItemProps,
} from 'ui-component/InfoSectionItem';
import { DrawerContext } from '..';
import { Icon as IconType } from '@tabler/icons-react';
import { Badge, Box, ButtonBase, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { ColorOptions } from 'types';

export interface SidebarInfoSectionProps extends InfoSectionItemProps {
  count?: number;
  Icon?: IconType;
  color?: ColorOptions;
  visible?: boolean;
  alwaysVisible?: boolean;
  collapsedTooltip?: string;
  children?: ReactNode;
  onIconClick?: () => void;
}

const SidebarInfoSection: FC<SidebarInfoSectionProps> = ({
  Icon,
  count,
  color = 'primary',
  visible = true,
  alwaysVisible = false,
  collapsedTooltip,
  onIconClick,
  ...rest
}) => {
  const theme = useTheme();
  const open = useContext(DrawerContext);

  if (!visible) {
    return <></>;
  }

  if (!open && !!Icon && !!count && !!onIconClick) {
    return (
      <Box sx={{ pt: 1 }}>
        <Tooltip title={collapsedTooltip}>
          <ButtonBase onClick={onIconClick} sx={{ borderRadius: '8px' }}>
            <Badge badgeContent={count} color="primary" max={99}>
              <Icon
                size={24}
                stroke={1.5}
                color={_.get(theme.palette, [color, 'main'])}
              />
            </Badge>
          </ButtonBase>
        </Tooltip>
      </Box>
    );
  }
  if (!open && !!Icon && alwaysVisible && !!onIconClick) {
    return (
      <Box sx={{ pt: 1 }}>
        <Tooltip title={collapsedTooltip}>
          <ButtonBase onClick={onIconClick} sx={{ borderRadius: '8px' }}>
            <Icon
              size={24}
              stroke={1.5}
              color={_.get(theme.palette, [color, 'main'])}
            />
          </ButtonBase>
        </Tooltip>
      </Box>
    );
  }
  if (!open && !!Icon && !!count && !onIconClick) {
    return (
      <Box sx={{ pt: 1 }}>
        <Tooltip title={collapsedTooltip}>
          <Badge badgeContent={count} color="primary" max={99}>
            <Icon
              size={24}
              stroke={1.5}
              color={_.get(theme.palette, [color, 'main'])}
            />
          </Badge>
        </Tooltip>
      </Box>
    );
  }
  if (!open && !!Icon && alwaysVisible && !onIconClick) {
    return (
      <Box sx={{ pt: 1 }}>
        <Tooltip title={collapsedTooltip}>
          <Icon
            size={24}
            stroke={1.5}
            color={_.get(theme.palette, [color, 'main'])}
          />
        </Tooltip>
      </Box>
    );
  }
  if (!open) {
    return <></>;
  }
  return <InfoSectionItem {...rest} />;
};

export default SidebarInfoSection;
