import { Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconAlertTriangle } from '@tabler/icons-react';
import {
  PartSupplyRetryStatus,
  StatusColumnFailureText,
  SupplyColumnFailureText,
} from 'types/partStatus';

export enum KbFailureAlertVariant {
  SUPPLY_COLUMN = 'supply_column',
  STATUS_COLUMN = 'status_column',
}

const copyVariant = {
  [KbFailureAlertVariant.SUPPLY_COLUMN]: SupplyColumnFailureText,
  [KbFailureAlertVariant.STATUS_COLUMN]: StatusColumnFailureText,
};

const KbFailureAlert = ({
  variant,
  retryStatus,
}: {
  variant: KbFailureAlertVariant;
  retryStatus: PartSupplyRetryStatus;
}) => {
  const theme = useTheme();
  const copy = copyVariant[variant][retryStatus];
  return (
    <Tooltip placement="top" title={copy}>
      <Stack direction="row">
        <IconAlertTriangle color={theme.palette.error.main} />
      </Stack>
    </Tooltip>
  );
};

export default KbFailureAlert;
