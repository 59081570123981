import _ from 'lodash';
import {
  GridRenderEditCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FieldValues } from 'react-hook-form';
import { ExtendedGridColDef } from 'types/datagrid';
import {
  CustomProperty,
  CustomPropertyDataType,
  CustomPropertyModel,
  CustomPropertyValue,
  CustomPropertyVisibility,
} from 'types/customProperty';
import {
  dateTimeValueFormatter,
  dateValueFormatter,
} from 'ui-component/DataGrid/ValueFormatters';
import {
  convertDate,
  formatToUniversalDate,
  formatToUniversalDateTime,
} from 'utils/dates';
import {
  BooleanIconRenderCell,
  RenderUserLookupCell,
} from 'ui-component/DataGrid/Render';
import {
  EditInlineAutocomplete,
  EditInlineAutocompleteMultiple,
  EditInlineCheckbox,
  EditInlineDatePicker,
  EditInlineUserAutocomplete,
} from 'ui-component/HookFormComponents/InlineEditInputComponents';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  booleanFilterOperators,
  booleanValueOptions,
} from 'ui-component/DataGrid/FilterOperators';

const customPropertyDataTypeToMuiColumnType: Record<
  CustomPropertyDataType,
  'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect'
> = {
  [CustomPropertyDataType.STRING]: 'string',
  [CustomPropertyDataType.NUMBER]: 'number',
  [CustomPropertyDataType.DATE]: 'date',
  [CustomPropertyDataType.DATETIME]: 'dateTime',
  [CustomPropertyDataType.USER]: 'string',
  [CustomPropertyDataType.BOOLEAN]: 'boolean',
  [CustomPropertyDataType.SINGLE_CHOICE]: 'singleSelect',
  [CustomPropertyDataType.MULTI_CHOICE]: 'string',
};

const getStringTypeProps = (f: CustomProperty) =>
  ({
    valueGetter: (params: GridValueGetterParams) =>
      _.get(params.row?.customProperties, f.key, f.defaultValue),
  } as Partial<ExtendedGridColDef>);

const getNumberTypeProps = (f: CustomProperty) =>
  ({
    valueGetter: (params: GridValueGetterParams) =>
      _.get(
        params.row?.customProperties,
        f.key,
        _.toNumber(f.defaultValue) || 0
      ),
    mutationPreSubmit: (data: FieldValues) =>
      ({
        customProperties: {
          [_.camelCase(f.key)]: _.get(data, ['customProperties', f.key])
            ? _.toNumber(_.get(data, ['customProperties', f.key]))
            : null,
        },
      } as FieldValues),
  } as Partial<ExtendedGridColDef>);

const getDateTypeProps = (f: CustomProperty) =>
  ({
    valueFormatter: dateValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      _.get(params.row?.customProperties, f.key, f.defaultValue)
        ? convertDate(
            _.get(params.row?.customProperties, f.key, f.defaultValue)
          )
        : null,
    mutationInputElement: EditInlineDatePicker,
    mutationPreSubmit: (data: FieldValues) =>
      ({
        customProperties: {
          [_.camelCase(f.key)]: _.get(data, ['customProperties', f.key])
            ? formatToUniversalDate(_.get(data, ['customProperties', f.key]))
            : null,
        },
      } as FieldValues),
  } as Partial<ExtendedGridColDef>);

const getDateTimeTypeProps = (f: CustomProperty) =>
  ({
    valueFormatter: dateTimeValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      _.get(params.row?.customProperties, f.key, f.defaultValue)
        ? convertDate(
            _.get(params.row?.customProperties, f.key, f.defaultValue)
          )
        : null,
    mutationInputElement: EditInlineDatePicker,
    mutationPreSubmit: (data: FieldValues) =>
      ({
        customProperties: {
          [_.camelCase(f.key)]: _.get(data, ['customProperties', f.key])
            ? formatToUniversalDateTime(
                _.get(data, ['customProperties', f.key])
              )
            : null,
        },
      } as FieldValues),
  } as Partial<ExtendedGridColDef>);

const getUserTypeProps = (f: CustomProperty) =>
  ({
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderUserLookupCell(
        _.get(params.row?.customProperties, f.key, f.defaultValue)
      ),
    mutationInputElement: EditInlineUserAutocomplete,
  } as Partial<ExtendedGridColDef>);

const getBooleanTypeProps = (f: CustomProperty) =>
  ({
    valueGetter: (params: GridValueGetterParams) =>
      _.get(params.row?.customProperties, f.key, f.defaultValue),
    renderCell: BooleanIconRenderCell,
    mutationInputElement: EditInlineCheckbox,
    filterOperators: booleanFilterOperators,
    valueOptions: booleanValueOptions,
  } as Partial<ExtendedGridColDef>);

const getSingleChoiceTypeProps = (f: CustomProperty) =>
  ({
    valueGetter: (params: GridValueGetterParams) =>
      _.get(params.row?.customProperties, f.key, f.defaultValue),
    valueFormatter: ({ value }) => _.find(f.values, { value })?.label,
    mutationInputElement: EditInlineAutocomplete,
    additionalInputElementProps: {
      options: f.values,
      getOptionLabel: (option: CustomPropertyValue) => option.label,
      initialValueMatcher: (value: string) =>
        _.find(f.values, (option) => option.value === value) ?? null,
      valueFromOption: (option: CustomPropertyValue) => option.value,
    },
    valueOptions: f.values,
  } as Partial<ExtendedGridColDef>);

const getMultiChoiceTypeProps = (f: CustomProperty) =>
  ({
    valueGetter: (params: GridValueGetterParams) =>
      _.get(params.row?.customProperties, f.key, f.defaultValue),
    valueFormatter: ({ value }) =>
      _.chain(value)
        .split(',')
        .map((v) => _.find(f.values, { value: v })?.label)
        .join(', ')
        .value(),
    mutationInputElement: EditInlineAutocompleteMultiple,
    additionalInputElementProps: {
      options: f.values,
    },
    valueOptions: f.values,
  } as Partial<ExtendedGridColDef>);

const additionalColumnProps: Record<
  CustomPropertyDataType,
  (f: CustomProperty) => Partial<ExtendedGridColDef>
> = {
  [CustomPropertyDataType.STRING]: getStringTypeProps,
  [CustomPropertyDataType.NUMBER]: getNumberTypeProps,
  [CustomPropertyDataType.DATE]: getDateTypeProps,
  [CustomPropertyDataType.DATETIME]: getDateTimeTypeProps,
  [CustomPropertyDataType.USER]: getUserTypeProps,
  [CustomPropertyDataType.BOOLEAN]: getBooleanTypeProps,
  [CustomPropertyDataType.SINGLE_CHOICE]: getSingleChoiceTypeProps,
  [CustomPropertyDataType.MULTI_CHOICE]: getMultiChoiceTypeProps,
};

export default function useGetCustomPropertyColumns({
  model,
  visibilityContext,
  useMutation,
  getMutationArgs,
  mutationAsClientV2 = false,
}: {
  model: CustomPropertyModel;
  visibilityContext?:
    | CustomPropertyVisibility.DATAGRID_READ_ONLY
    | CustomPropertyVisibility.DATAGRID_EDIT;
  useMutation?: UseMutation<any>;
  getMutationArgs?: (data: FieldValues, params: GridRenderEditCellParams) => {};
  mutationAsClientV2?: boolean;
}) {
  const { isFetching: isLoadingCustomProperties, customProperties } =
    useGetCustomPropertyDefinitions({ model, visibilityContext });

  const getBaseProps = (f: CustomProperty) => ({
    field: `customProperties.${f.key}`,
    headerName: f.name,
    description: `Custom Property: ${f.name}`,
    type: _.get(customPropertyDataTypeToMuiColumnType, f.dataType, 'string'),
    minWidth: 100,
    hideable: true,
    flex: 0.5,
    aggregable: false,
    ...additionalColumnProps[f.dataType](f),
  });

  const customPropertyColumns: ExtendedGridColDef[] = useMutation
    ? customProperties.map(
        (f) =>
          ({
            ...getBaseProps(f),
            ...(_.includes(f.visibility, CustomPropertyVisibility.DATAGRID_EDIT)
              ? {
                  inlineEditRenderCell: true,
                  editable: true,
                  useMutation,
                  getMutationArgs,
                  mutationAsClientV2,
                  mutationPreSubmit: (data: FieldValues) => ({
                    customProperties: data.customProperties,
                  }),
                }
              : {}),
          } as ExtendedGridColDef)
      )
    : customProperties.map((f) => getBaseProps(f) as ExtendedGridColDef);

  return { isFetching: isLoadingCustomProperties, customPropertyColumns };
}
