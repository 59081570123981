import {
  UseFormProps,
  UseFormReturn,
  useForm,
  SubmitErrorHandler,
} from 'react-hook-form';
import * as Yup from 'yup';
import { DeepPartial } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { ObjectShape } from 'yup/lib/object';

// The shape of the form data after validation, with all required fields present
export type ValidFormData<TSchema extends Yup.ObjectSchema<ObjectShape>> =
  Yup.InferType<TSchema>;

export type FormInputData<TSchema extends Yup.ObjectSchema<ObjectShape>> =
  DeepPartial<ValidFormData<TSchema>>;

export type TypedFormReturn<TSchema extends Yup.ObjectSchema<ObjectShape>> =
  Omit<UseFormReturn<FormInputData<TSchema>>, 'handleSubmit'> & {
    handleSubmit: (
      onValid: (data: ValidFormData<TSchema>) => unknown | Promise<unknown>,
      onInvalid?: SubmitErrorHandler<FormInputData<TSchema>>
    ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
  };

/**
 * A type-safe wrapper around react-hook-form's useForm hook that handles the
 * transition between partial form data during input and validated data at submission.
 *
 * Key features:
 * - Automatically infers types from Yup schema
 * - Makes all form fields optional during input phase via FormInputData
 * - Ensures type safety at submission with ValidFormData
 * - Handles Yup validation internally with yupResolver
 * - Provides type-safe handleSubmit with validated data
 *
 * @example
 * ```typescript
 * const schema = Yup.object({
 *   stockLot: Yup.object({
 *     id: Yup.string().required(),
 *     quant: Yup.number().required(),
 *     part: Yup.string().required(),
 *   }).required(),
 *   targetQuantity: Yup.number().required(),
 *   targetDate: Yup.date().required(),
 *   createdAt: Yup.date().required(),
 * });
 *
 * const form = useTypedForm(schema, {
 *   defaultValues: {
 *     stockLot: { id: '', quant: 0, part: '' },
 *     targetQuantity: 0,
 *     targetDate: new Date(),
 *     createdAt: new Date()
 *   },
 *   mode: 'all'
 * });
 *
 * // handleSubmit provides fully typed data after validation
 * form.handleSubmit(async (data) => {
 *   // data.targetDate will be Date
 *   // data.createdAt will be Date
 * });
 * ```
 */
export function useTypedForm<TSchema extends Yup.ObjectSchema<ObjectShape>>(
  schema: TSchema,
  props: Omit<UseFormProps<FormInputData<TSchema>>, 'resolver'> = {}
): TypedFormReturn<TSchema> {
  const methods = useForm({
    ...props,
    resolver: yupResolver(schema),
  });

  // Use useCallback instead of useMemo for function memoization
  const memoizedHandleSubmit = useCallback(
    (
      onValid: (data: ValidFormData<TSchema>) => unknown | Promise<unknown>,
      onInvalid?: SubmitErrorHandler<FormInputData<TSchema>>
    ) =>
      methods.handleSubmit(
        (data) => onValid(data as ValidFormData<TSchema>),
        onInvalid
      ),
    [methods.handleSubmit]
  );

  return {
    ...methods,
    handleSubmit: memoizedHandleSubmit,
  };
}
