import { ICommandFromClientType } from 'commandbar/build/internal/src/middleware/types';
import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';

// Routes must start with leading slash or commandbar will
// append the route to the current route
export const CommandbarRecordActions: {
  [key: string]: ICommandFromClientType;
} = {
  Bom: {
    text: 'Go to BOM',
    name: 'open_bom',
    heading: 'BOM Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.BOMS.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  StockLot: {
    text: 'Go to StockLot',
    name: 'open_stocklot',
    heading: 'Stock Lot Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  StockLocation: {
    text: 'Go to Stock Locations',
    name: 'open_stocklocations',
    heading: 'Stock Location Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.MANAGED_INVENTORY.views?.STOCK_LOCATIONS?.path}`,
      operation: 'router',
    },
  },
  Shipment: {
    text: 'Go to Shipment',
    name: 'open_shipment',
    heading: 'Shipment Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.KITTING.views?.SHIPMENTS?.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  OrgPart: {
    text: 'Go to Part',
    name: 'open_part',
    heading: 'Part Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.PART_SEARCH.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  OrgSupplier: {
    text: 'Go to Supplier',
    name: 'open_suppliers',
    heading: 'Supplier Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  PurchaseRule: {
    text: 'Go to Purchase Rule',
    name: 'open_purchase_rule',
    heading: 'Purchase Rule Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.SUPPLIERS.views?.PURCHASE_RULES?.path}`,
      operation: 'router',
    },
  },
  ProductionRun: {
    text: 'Go to Program',
    name: 'open_production_run',
    heading: 'Program Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.PRODUCTION.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  PurchaseOrder: {
    text: 'Go to Purchase Order',
    name: 'open_purchase_order',
    heading: 'Purchase Order Actions',
    template: {
      type: 'link',
      value: `${ALL_VIEWS.PURCHASE_ORDERS.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  KitRequest: {
    text: 'Go to Kit',
    name: 'open_kit_request',
    heading: 'Kit Actions',
    template: {
      type: 'link',
      value: `${ALL_VIEWS.KITS.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  PurchaseRequestGroup: {
    text: 'Go to Requisition',
    name: 'open_purchase_group',
    heading: 'Requisition Actions',
    template: {
      type: 'link',
      value: `${ALL_VIEWS.REQUISITIONS_TABLE.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  Rfq: {
    text: 'Go to RFQ',
    name: 'open_rfq',
    heading: 'RFQ Actions',
    template: {
      type: 'link',
      value: `${ALL_VIEWS.RFQ.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  SupplierQuote: {
    text: 'Go to Quote',
    name: 'open_supplier_quote',
    heading: 'Quote Actions',
    template: {
      type: 'link',
      value: `${ALL_VIEWS.QUOTE.path}/{{record.id}}`,
      operation: 'router',
    },
  },
};
