import { API_URL } from 'constants/envConstants';

interface ClientV2Urls {
  SUPPLIER_BILLS: (query?: string) => string;
  SUPPLIER_BILLS_UPDATE_PURCHASE_ORDER: (query?: string) => string;
  SUPPLIER_BILLS_APPROVE: (query?: string) => string;
  SUPPLIER_BILLS_UNAPPROVE: (query?: string) => string;
  SUPPLIER_BILLS_ARCHIVE: (query?: string) => string;
  SUPPLIER_BILLS_UNARCHIVE: (query?: string) => string;
  SUPPLIER_BILLS_VOID: (query?: string) => string;
  SUPPLIER_BILLS_UNVOID: (query?: string) => string;
  SUPPLIER_BILLS_CHECK_AUTO_APPROVE: string;
  SUPPLIER_BILL_LINES: (query?: string) => string;
  SUPPLIER_BILL_LINE_AUTO_MATCH: (query?: string) => string;
  SUPPLIER_BILL_LINE_ADD_MATCHES: (supplierBillLineId: string) => string;
  SUPPLIER_BILL_LINE_UPDATE_MATCHES: (supplierBillLineId: string) => string;
  SUPPLIER_BILL_LINE_DELETE_MATCHES: (supplierBillLineId: string) => string;
  PURCHASE_ORDERS: (query?: string) => string;
  PURCHASE_ORDER_LINES: (query?: string) => string;
  PURCHASE_ORDER_NO_PART_LINES: (query?: string) => string;
  KIT_REQUESTS: (query?: string) => string;
  KIT_REQUESTS_APPROVE: () => string;
  KIT_REQUESTS_UNAPPROVE: () => string;
  KIT_REQUESTS_ARCHIVE: () => string;
  KIT_REQUESTS_UNARCHIVE: () => string;
  KIT_REQUEST_PRINT_LABEL: (kitRequestId: string) => string;
  KIT_REQUEST_LINES: (query?: string) => string;
  KIT_REQUEST_LINES_LOCK: () => string;
  KIT_REQUEST_LINES_UNLOCK: () => string;
  HANDLING_REQUESTS: (query?: string) => string;
  PICKED_HANDLING_REQUESTS: string;
  STOCK_EVENTS: (query?: string) => string;
  CANCEL_HANDLING_REQUEST: (handling_request_id: string) => string;
  CANCEL_HANDLING_REQUESTS: string;
  CREATE_SPLICE_COMBINE_HANDLING_REQUESTS: string;
  CREATE_STOCK_EVENTS: string;
  CREATE_SPLIT_STOCK_EVENT: string;
  CREATE_SPLICE_COMBINE_STOCK_EVENT: string;
  UNDO_STOCK_EVENT: (stockEventId: string) => string;
  STOCK_LOT: (id: string) => string;
  RECEIVE_STOCK_LOT_BY_PURCHASE_LINE: (id: string) => string;
  RECEIVE_STOCK_LOT_BY_SHIPMENT_LINE: (id: string) => string;
  CREATE_RECEIVE_SHIPMENT_LINE: string;
  ALLOCATIONS: (query?: string) => string;
  SHIPMENTS: (query?: string) => string;
  SHIPMENT_LINES: (query?: string) => string;
  ADD_AND_PACK_SHIPMENT_LINE: (shipmentId: string) => string;
  STOCK_LOCATIONS: (query?: string) => string;
  RECALCULATE_KIT_REQUEST_LINE_ALLOCATIONS: string;
  TAGS: (query?: string) => string;
  PURCHASE_REQUEST_GROUPS: (query?: string) => string;
  PURCHASE_REQUEST_GROUPS_APPROVE: () => string;
  PURCHASE_REQUEST_GROUPS_UNAPPROVE: () => string;
  PURCHASE_REQUEST_GROUPS_ARCHIVE: () => string;
  PURCHASE_REQUEST_GROUPS_UNARCHIVE: () => string;
  PURCHASE_REQUEST_GROUPS_VOID: () => string;
  PURCHASE_REQUEST_GROUPS_UNVOID: () => string;
  PURCHASE_REQUESTS: (query?: string) => string;
  RFQS: (query?: string) => string;
  RFQ_GENERATE_PDF: (rfqId: string) => string;
  RFQ_LINES: (query?: string) => string;
  SUPPLIER_QUOTES: (query?: string) => string;
  SUPPLIER_QUOTES_ARCHIVE: (query?: string) => string;
  SUPPLIER_QUOTES_UNARCHIVE: (query?: string) => string;
  SUPPLIER_QUOTE_LINES: (query?: string) => string;
  SUPPLIER_QUOTE_LINES_APPROVE: (query?: string) => string;
  SUPPLIER_QUOTE_LINES_UNAPPROVE: (query?: string) => string;
  SUPPLIER_QUOTE_LINES_VOID: (query?: string) => string;
  SUPPLIER_QUOTE_LINES_UNVOID: (query?: string) => string;
  RFQ_SUPPLIER_JOINS: (query?: string) => string;
  SUPPLIER_QUOTE_LINES_AUTO_MATCH: (query?: string) => string;
  RFQ_SUPPLIER_JOINS_SEND_RFQ: string;
  APPROVE_RFQS: string;
  UNAPPROVE_RFQS: string;
  VOID_RFQS: string;
  UNVOID_RFQS: string;
  ARCHIVE_RFQS: string;
  UNARCHIVE_RFQS: string;
  CLOSE_RFQS: string;
  REOPEN_RFQS: string;
  ORG_PARTS: (query?: string) => string;
  PUBLIC_PARTS: (query?: string) => string;
  ORG_PARTS_ADD_SPECS: string;
  ORG_PARTS_REMOVE_SPECS: string;
  ORG_PARTS_SET_HERO_IMAGE: string;
  ORG_PARTS_RESYNC: string;
  PUNCHOUT_SESSIONS: (query?: string) => string;
  REPORT_TEMPLATES: (query?: string) => string;
  REPORT_CODE_SNIPPETS: (query?: string) => string;
  STOCK_DOCUMENT_RELATED_OBJECTS: (query?: string) => string;
  PRINT_STOCK_DOCUMENT_RELATED_OBJECTS: string;
  DOWNLOAD_STOCK_DOCUMENT_RELATED_OBJECT: (id: string) => string;
  PUBLIC_MANUFACTURERS: (query?: string) => string;
  PUBLIC_CLASSIFICATIONS: (query?: string) => string;
  PROGRAMS: (query?: string) => string;
  PROGRAM_LINES: (query?: string) => string;
  BOMS: (query?: string) => string;
  BOM_LINES: (query?: string) => string;
}

const API_URL_CLIENT_V2 = `${API_URL}/client`;

export const CLIENT_V2_URLS: ClientV2Urls = {
  SUPPLIER_BILLS: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/${query}`,
  SUPPLIER_BILLS_UPDATE_PURCHASE_ORDER: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/update_purchase/${query}`,
  SUPPLIER_BILLS_APPROVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/approve/${query}`,
  SUPPLIER_BILLS_UNAPPROVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/unapprove/${query}`,
  SUPPLIER_BILLS_ARCHIVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/archive/${query}`,
  SUPPLIER_BILLS_UNARCHIVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/unarchive/${query}`,
  SUPPLIER_BILLS_VOID: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/void/${query}`,
  SUPPLIER_BILLS_UNVOID: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/unvoid/${query}`,
  SUPPLIER_BILLS_CHECK_AUTO_APPROVE: `${API_URL_CLIENT_V2}/supplier_bills/check_if_can_auto_approve/`,
  SUPPLIER_BILL_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bill_lines/${query}`,
  SUPPLIER_BILL_LINE_AUTO_MATCH: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/auto_match_purchase_lines/${query}`,
  SUPPLIER_BILL_LINE_ADD_MATCHES: (supplierBillLineId) =>
    `${API_URL_CLIENT_V2}/supplier_bill_lines/${supplierBillLineId}/add_match_purchase_lines/`,
  SUPPLIER_BILL_LINE_UPDATE_MATCHES: (supplierBillLineId) =>
    `${API_URL_CLIENT_V2}/supplier_bill_lines/${supplierBillLineId}/update_match_purchase_lines/`,
  SUPPLIER_BILL_LINE_DELETE_MATCHES: (supplierBillLineId) =>
    `${API_URL_CLIENT_V2}/supplier_bill_lines/${supplierBillLineId}/remove_match_purchase_lines/`,
  PURCHASE_ORDERS: (query = '') =>
    `${API_URL_CLIENT_V2}/purchase_orders/${query}`,
  PURCHASE_ORDER_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/purchase_order_lines/${query}`,
  PURCHASE_ORDER_NO_PART_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/no_part_purchase_order_lines/${query}`,
  KIT_REQUESTS: (query = '') => `${API_URL_CLIENT_V2}/kit_requests/${query}`,
  KIT_REQUESTS_APPROVE: () => `${API_URL_CLIENT_V2}/kit_requests/approve/`,
  KIT_REQUESTS_UNAPPROVE: () => `${API_URL_CLIENT_V2}/kit_requests/unapprove/`,
  KIT_REQUESTS_ARCHIVE: () => `${API_URL_CLIENT_V2}/kit_requests/archive/`,
  KIT_REQUESTS_UNARCHIVE: () => `${API_URL_CLIENT_V2}/kit_requests/unarchive/`,
  KIT_REQUEST_PRINT_LABEL: (kitRequestId) =>
    `${API_URL_CLIENT_V2}/kit_requests/${kitRequestId}/print_label/`,
  KIT_REQUEST_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/kit_request_lines/${query}`,
  KIT_REQUEST_LINES_LOCK: () => `${API_URL_CLIENT_V2}/kit_request_lines/lock/`,
  KIT_REQUEST_LINES_UNLOCK: () =>
    `${API_URL_CLIENT_V2}/kit_request_lines/unlock/`,
  HANDLING_REQUESTS: (query = '') =>
    `${API_URL_CLIENT_V2}/handling_requests/${query}`,
  PICKED_HANDLING_REQUESTS: `${API_URL_CLIENT_V2}/handling_requests/picked/`,
  STOCK_EVENTS: (query = '') => `${API_URL_CLIENT_V2}/stock_events/${query}`,
  CANCEL_HANDLING_REQUEST: (handling_request_id) =>
    `${API_URL_CLIENT_V2}/handling_requests/${handling_request_id}/cancel/`,
  CANCEL_HANDLING_REQUESTS: `${API_URL_CLIENT_V2}/handling_requests/cancel/`,
  CREATE_SPLICE_COMBINE_HANDLING_REQUESTS: `${API_URL_CLIENT_V2}/handling_requests/create_splice_combine/`,
  CREATE_STOCK_EVENTS: `${API_URL_CLIENT_V2}/stock_lots/create_stock_events/`,
  CREATE_SPLIT_STOCK_EVENT: `${API_URL_CLIENT_V2}/stock_lots/create_split/`,
  CREATE_SPLICE_COMBINE_STOCK_EVENT: `${API_URL_CLIENT_V2}/stock_lots/create_splice_combine/`,
  UNDO_STOCK_EVENT: (stockEventId) =>
    `${API_URL_CLIENT_V2}/stock_events/${stockEventId}/undo/`,
  STOCK_LOT: (id) => `${API_URL_CLIENT_V2}/stock_lots/${id}/`,
  RECEIVE_STOCK_LOT_BY_PURCHASE_LINE: (id) =>
    `${API_URL_CLIENT_V2}/stock_lots/${id}/receive_by_purchase_line/`,
  RECEIVE_STOCK_LOT_BY_SHIPMENT_LINE: (id) =>
    `${API_URL_CLIENT_V2}/stock_lots/${id}/receive_by_shipment_line/`,
  CREATE_RECEIVE_SHIPMENT_LINE: `${API_URL_CLIENT_V2}/stock/create_and_receive_shipment_line/`,
  ALLOCATIONS: (query = '') => `${API_URL_CLIENT_V2}/allocations/${query}`,
  SHIPMENTS: (query = '') => `${API_URL_CLIENT_V2}/shipments/${query}`,
  SHIPMENT_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/shipment_lines/${query}`,
  ADD_AND_PACK_SHIPMENT_LINE: (shipmentId) =>
    `${API_URL_CLIENT_V2}/shipments/${shipmentId}/add_and_pack_line/`,
  STOCK_LOCATIONS: (query = '') =>
    `${API_URL_CLIENT_V2}/stock_locations/${query}`,
  RECALCULATE_KIT_REQUEST_LINE_ALLOCATIONS: `${API_URL_CLIENT_V2}/kit_request_lines/recalculate_allocations/`,
  TAGS: (query = '') => `${API_URL_CLIENT_V2}/tags/${query}`,
  PURCHASE_REQUESTS: (query = '') =>
    `${API_URL_CLIENT_V2}/purchase_requests/${query}`,
  PURCHASE_REQUEST_GROUPS: (query = '') =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/${query}`,
  PURCHASE_REQUEST_GROUPS_APPROVE: () =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/approve/`,
  PURCHASE_REQUEST_GROUPS_UNAPPROVE: () =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/unapprove/`,
  PURCHASE_REQUEST_GROUPS_ARCHIVE: () =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/archive/`,
  PURCHASE_REQUEST_GROUPS_UNARCHIVE: () =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/unarchive/`,
  PURCHASE_REQUEST_GROUPS_VOID: () =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/void/`,
  PURCHASE_REQUEST_GROUPS_UNVOID: () =>
    `${API_URL_CLIENT_V2}/purchase_request_groups/unvoid/`,
  RFQS: (query = '') => `${API_URL_CLIENT_V2}/rfqs/${query}`,
  RFQ_LINES: (query = '') => `${API_URL_CLIENT_V2}/rfq_lines/${query}`,
  RFQ_GENERATE_PDF: (rfqId: string) =>
    `${API_URL_CLIENT_V2}/rfqs/${rfqId}/generate_pdf/`,
  SUPPLIER_QUOTES: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quotes/${query}`,
  SUPPLIER_QUOTES_ARCHIVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quotes/archive/${query}`,
  SUPPLIER_QUOTES_UNARCHIVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quotes/unarchive/${query}`,
  SUPPLIER_QUOTE_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quote_lines/${query}`,
  SUPPLIER_QUOTE_LINES_APPROVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quote_lines/approve/${query}`,
  SUPPLIER_QUOTE_LINES_UNAPPROVE: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quote_lines/unapprove/${query}`,
  SUPPLIER_QUOTE_LINES_VOID: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quote_lines/void/${query}`,
  SUPPLIER_QUOTE_LINES_UNVOID: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quote_lines/unvoid/${query}`,
  SUPPLIER_QUOTE_LINES_AUTO_MATCH: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_quote_lines/auto_match_rfq_lines/${query}`,
  RFQ_SUPPLIER_JOINS: (query = '') =>
    `${API_URL_CLIENT_V2}/rfq_supplier_joins/${query}`,
  RFQ_SUPPLIER_JOINS_SEND_RFQ: `${API_URL_CLIENT_V2}/rfq_supplier_joins/send/`,
  APPROVE_RFQS: `${API_URL_CLIENT_V2}/rfqs/approve/`,
  UNAPPROVE_RFQS: `${API_URL_CLIENT_V2}/rfqs/unapprove/`,
  VOID_RFQS: `${API_URL_CLIENT_V2}/rfqs/void/`,
  UNVOID_RFQS: `${API_URL_CLIENT_V2}/rfqs/unvoid/`,
  ARCHIVE_RFQS: `${API_URL_CLIENT_V2}/rfqs/archive/`,
  UNARCHIVE_RFQS: `${API_URL_CLIENT_V2}/rfqs/unarchive/`,
  CLOSE_RFQS: `${API_URL_CLIENT_V2}/rfqs/close/`,
  REOPEN_RFQS: `${API_URL_CLIENT_V2}/rfqs/reopen/`,
  ORG_PARTS: (query = '') => `${API_URL_CLIENT_V2}/org_parts/${query}`,
  PUBLIC_PARTS: (query = '') => `${API_URL_CLIENT_V2}/public_parts/${query}`,
  ORG_PARTS_ADD_SPECS: `${API_URL_CLIENT_V2}/org_parts/add_specs/`,
  ORG_PARTS_REMOVE_SPECS: `${API_URL_CLIENT_V2}/org_parts/remove_specs/`,
  ORG_PARTS_SET_HERO_IMAGE: `${API_URL_CLIENT_V2}/org_parts/set_hero_image/`,
  ORG_PARTS_RESYNC: `${API_URL_CLIENT_V2}/org_parts/resync/`,
  PUNCHOUT_SESSIONS: (query = '') =>
    `${API_URL_CLIENT_V2}/punchout_sessions/${query}`,
  REPORT_TEMPLATES: (query = '') =>
    `${API_URL_CLIENT_V2}/reporting_templates/${query}`,
  REPORT_CODE_SNIPPETS: (query = '') =>
    `${API_URL_CLIENT_V2}/code_snippets/${query}`,
  STOCK_DOCUMENT_RELATED_OBJECTS: (query = '') =>
    `${API_URL_CLIENT_V2}/stock_document_related_objects/${query}`,
  PRINT_STOCK_DOCUMENT_RELATED_OBJECTS: `${API_URL_CLIENT_V2}/stock_document_related_objects/print/`,
  DOWNLOAD_STOCK_DOCUMENT_RELATED_OBJECT: (id: string) =>
    `${API_URL_CLIENT_V2}/stock_document_related_objects/${id}/download/`,
  PUBLIC_MANUFACTURERS: (query = '') =>
    `${API_URL_CLIENT_V2}/public_manufacturers/${query}`,
  PUBLIC_CLASSIFICATIONS: (query = '') =>
    `${API_URL_CLIENT_V2}/public_classes/${query}`,
  PROGRAMS: (query = '') => `${API_URL_CLIENT_V2}/programs/${query}`,
  PROGRAM_LINES: (query = '') => `${API_URL_CLIENT_V2}/program_lines/${query}`,
  BOMS: (query = '') => `${API_URL_CLIENT_V2}/boms/${query}`,
  BOM_LINES: (query = '') => `${API_URL_CLIENT_V2}/bom_lines/${query}`,
};
