import {
  FormControlLabel,
  FormHelperText,
  Switch,
  FormControlLabelProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { FC, ReactNode } from 'react';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type HookFormToggleProps = {
  label?: string | ReactNode;
  disabled?: boolean;
  defaultValue?: boolean;
  sx?: SxProps<Theme>;
  helperText?: string | ReactNode;
  additionalOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
} & HookFormComponentProps;

export const HookFormToggle: FC<HookFormToggleProps> = ({
  name = '',
  control,
  label,
  disabled,
  defaultValue,
  sx,
  helperText,
  additionalOnChange,
  required = false,
  labelPlacement = 'end',
  shouldUnregister = false,
}) => (
  <Controller
    name={name}
    control={control}
    shouldUnregister={shouldUnregister}
    defaultValue={defaultValue}
    render={({ field: { onChange, value } }) => (
      <>
        <FormControlLabel
          value={value}
          key={name}
          sx={sx}
          labelPlacement={labelPlacement}
          control={
            <Switch
              checked={!!value}
              onChange={(e) => {
                onChange(e);
                additionalOnChange && additionalOnChange(e);
              }}
              name={name}
              disabled={disabled}
            />
          }
          label={label}
        />
        {helperText && (
          <FormHelperText id={`${name}HelperText`}>{helperText}</FormHelperText>
        )}
      </>
    )}
  />
);
