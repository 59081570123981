import { useTypedUpdateRecordDialog } from 'hooks/typedRecordDialogs/useTypedUpdateRecordDialog';
import {
  createUpdateKitRequestFormConstants,
  createUpdateKitRequestValidationSchema,
  CreateKitRequestFormInputData,
} from 'views/kitting/components/constants';
import { useUpdateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import CreateUpdateKitRequestForm from 'views/kitting/components/CreateUpdateKitRequestForm';
import { createUpdateKitRequestPreSubmit } from 'views/kitting/components/utils';
import { getDefaultShippingMethod } from 'utils/functions';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { FormProvider } from 'react-hook-form';
import { KitRequestSelectedSubtype } from 'views/kitting/Kits';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { KitRequestSchema, KitRequestPATCHSchema } from 'types/kitting';
import { useMemo } from 'react';
import { convertDate } from 'utils/dates';

type UpdateKitRequestDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  kitRequestToUpdate: KitRequestSelectedSubtype;
  shouldUnregister?: boolean;
};

const UpdateKitRequestDialog = ({
  dialogOpen,
  onClose,
  kitRequestToUpdate,
  shouldUnregister = false,
}: UpdateKitRequestDialogProps) => {
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);

  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.KIT_REQUEST,
    visibilityContext: CustomPropertyVisibility.EDIT_RECORD_DIALOG,
  });

  const formDefaults = useMemo(
    (): CreateKitRequestFormInputData => ({
      [createUpdateKitRequestFormConstants.name.id]:
        kitRequestToUpdate?.[createUpdateKitRequestFormConstants.name.id] ?? '',
      [createUpdateKitRequestFormConstants.sourceLocations.id]: (
        kitRequestToUpdate?.sourceLocations ?? []
      ).map((loc: { id: string }) => loc.id),
      [createUpdateKitRequestFormConstants.productionRun.id]:
        kitRequestToUpdate?.productionRun?.id ?? null,
      [createUpdateKitRequestFormConstants.team.id]:
        kitRequestToUpdate?.[createUpdateKitRequestFormConstants.team.id] ??
        null,
      [createUpdateKitRequestFormConstants.shipTo.id]:
        kitRequestToUpdate?.shipTo?.id ?? null,
      [createUpdateKitRequestFormConstants.shipMethod.id]:
        kitRequestToUpdate?.carrier && kitRequestToUpdate?.service
          ? `${kitRequestToUpdate.carrier}_${kitRequestToUpdate.service}`
          : getDefaultShippingMethod({ org }),
      [createUpdateKitRequestFormConstants.shipPartial.id]:
        kitRequestToUpdate?.[
          createUpdateKitRequestFormConstants.shipPartial.id
        ] ?? false,
      [createUpdateKitRequestFormConstants.targetShipDate.id]:
        kitRequestToUpdate?.targetShipDate
          ? convertDate(kitRequestToUpdate.targetShipDate)
          : null,
      [customPropertiesFieldName]:
        getDefaultValuesForCustomProperties<KitRequestSelectedSubtype>(
          kitRequestToUpdate
        ),
    }),
    [
      kitRequestToUpdate,
      org,
      customPropertiesFieldName,
      getDefaultShippingMethod,
      getDefaultValuesForCustomProperties,
    ]
  );

  const { methods, BaseUpdateRecordDialog, dialogProps } =
    useTypedUpdateRecordDialog<
      typeof createUpdateKitRequestValidationSchema,
      KitRequestPATCHSchema,
      KitRequestSchema
    >({
      validationSchema: createUpdateKitRequestValidationSchema,
      defaultValues: formDefaults,
      useUpdateMutation: useUpdateKitRequestsMutation,
      recordName: 'Kit',
      selectedRecord: kitRequestToUpdate,
      preSubmit: createUpdateKitRequestPreSubmit,
    });

  return (
    <BaseUpdateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestForm
          defaultValues={formDefaults}
          customProperties={customProperties}
          shouldUnregister={shouldUnregister}
        />
      </FormProvider>
    </BaseUpdateRecordDialog>
  );
};

export default UpdateKitRequestDialog;
