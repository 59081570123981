import { useState } from 'react';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import {
  ClientV2ToggleRequest,
  GenericResource,
  ToggleMutation,
} from 'types/api';
import { ToggleRecordActionStrings } from 'ui-component/clientV2/ToggleRecordDialog';
import { MutationDefinition } from '@reduxjs/toolkit/query';

// Utility type to extract the payload type from an RTK mutation
export type ExtractMutationArg<T> = T extends MutationDefinition<
  infer Arg,
  any,
  any,
  any
>
  ? Arg
  : never;

export type ExtendedToggleRequest<T extends GenericResource> =
  ClientV2ToggleRequest<T> & Partial<Omit<T, keyof GenericResource>>;

export type TogglePayload<M> = M extends (...args: any[]) => any
  ? Omit<Parameters<ReturnType<M>[0]>[0], 'ids'>
  : never;

export type TogglePayloadFn<M extends (...args: any) => any> = () =>
  | TogglePayload<M>
  | undefined
  | Promise<TogglePayload<M> | undefined>;

export type UseToggleRecordProps<
  T extends GenericResource,
  TrueM extends ToggleMutation<T>,
  FalseM extends ToggleMutation<T>
> = {
  useToggleTrueMutation: TrueM;
  useToggleFalseMutation: FalseM;
  recordName: string;
  actionStrings: Pick<
    ToggleRecordActionStrings,
    'successToggledTrue' | 'successToggledFalse'
  >;
  id: string;
  isCurrentlyTrue: boolean;
  onSuccess?: () => void;
  getToggleOnPayload?: TogglePayloadFn<TrueM>;
  getToggleOffPayload?: TogglePayloadFn<FalseM>;
};

/**
 * useToggleRecord
 * To be used specifically with Client V2 api & ToggleRecordDialog
 * Used for things like Approve/Unapprove, Archive/Unarchive, Lock/Unlock
 * @template T - The resource type extending GenericResource
 * @template TrueM - The toggle true mutation type
 * @template FalseM - The toggle false mutation type
 */
const useToggleRecord = <
  T extends GenericResource,
  TrueM extends ToggleMutation<T>,
  FalseM extends ToggleMutation<T>
>({
  useToggleTrueMutation,
  useToggleFalseMutation,
  recordName,
  actionStrings,
  id,
  isCurrentlyTrue,
  onSuccess,
  getToggleOnPayload,
  getToggleOffPayload,
}: UseToggleRecordProps<T, TrueM, FalseM>) => {
  const [toggleTrue, { isLoading: isLoadingTrue }] = useToggleTrueMutation();
  const [toggleFalse, { isLoading: isLoadingFalse }] = useToggleFalseMutation();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();

  const handleApprove = async () => {
    try {
      const additionalPayload = isCurrentlyTrue
        ? getToggleOffPayload
          ? await getToggleOffPayload()
          : undefined
        : getToggleOnPayload
        ? await getToggleOnPayload()
        : undefined;

      const payload = {
        ids: [id],
        ...additionalPayload,
      } as ExtendedToggleRequest<T>;

      if (isCurrentlyTrue) {
        await toggleFalse(payload).unwrap();
        dispatchSuccessSnackbar(
          `${recordName} ${actionStrings.successToggledFalse}`
        );
      } else {
        await toggleTrue(payload).unwrap();
        dispatchSuccessSnackbar(
          `${recordName} ${actionStrings.successToggledTrue}`
        );
      }
      if (onSuccess) onSuccess();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return {
    handleApprove,
    isLoading: isLoadingTrue || isLoadingFalse,
    submitError,
    resetSubmitError: () => setSubmitError(null),
  };
};

export default useToggleRecord;
