import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Box, Container, CssBaseline, useMediaQuery } from '@mui/material';
import Sidebar from 'layout/MainLayout/Sidebar';
import config from 'config';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import ScreenSizeAlert from 'ui-component/ScreenSizeAlert';
import { drawerWidth, collapsedDrawerWidth } from 'constants/themeConstants';
import { NewVersionBanner } from 'ui-component/NewVersionBanner';
import NoPlanBanner from 'ui-component/NoPlanBanner';
import { useNewVersion } from 'contexts/NewVersionContext';

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: MainStyleProps) => ({
  ...theme.typography.mainContent,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shorter,
  }),
  marginLeft: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: `calc(100% - ${(open ? drawerWidth : collapsedDrawerWidth) + 20}px)`,
}));

const { container } = config;

const MainLayout = () => {
  const { showNewVersion } = useNewVersion();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { showNoPlanBanner } = useSelector((state) => state.utility);

  useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
  }, [matchDownMd]);

  return (
    <>
      {showNewVersion && !showNoPlanBanner && <NewVersionBanner />}
      {showNoPlanBanner && <NoPlanBanner />}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* Sidebar Drawer */}
        <Sidebar />
        {/* main content */}
        <Main theme={theme} open={drawerOpen}>
          {container && (
            <Container maxWidth="lg">
              <Outlet />
            </Container>
          )}
          {!container && <Outlet />}
        </Main>
        <ScreenSizeAlert />
      </Box>
    </>
  );
};

export default MainLayout;
