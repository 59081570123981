import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import {
  ClientV2PATCHRequest,
  ClientV2BulkActionRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ListResponse,
  QueryParams,
} from 'types/api';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';
import { parseQueryParams } from 'store/slices/utils';
import {
  OrgPartActionSchema,
  OrgPartPATCHSchema,
  OrgPartPOSTSchema,
  OrgPartRemoveSpecsSchema,
  OrgPartSchema,
  OrgPartSpecsPOSTSchema,
  PublicClassificationSchema,
  PublicPartSchema,
} from 'types/clientV2/parts';
import _ from 'lodash';
import { RootState } from 'store/index';
import { PublicManufacturerSchema } from 'types/part';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicParts: builder.query<
      ListResponse<PublicPartSchema>,
      QueryParams<PublicPartSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.PUBLIC_PARTS(
          parseQueryParams<PublicPartSchema>(params)
        ),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: PublicPartSchema) =>
                  ({
                    type: 'PublicPart',
                    id,
                  } as const)
              ),
              { type: 'PublicPart', id: 'LIST' },
            ]
          : [{ type: 'PublicPart', id: 'LIST' }],
    }),
    getOrgParts: builder.query<
      ListResponse<OrgPartSchema>,
      QueryParams<OrgPartSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<OrgPartSchema>,
        QueryParams<OrgPartSchema>
      >((params) =>
        CLIENT_V2_URLS.ORG_PARTS(parseQueryParams<OrgPartSchema>(params))
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: OrgPartSchema) =>
                  ({
                    type: 'OrgPartV2',
                    id,
                  } as const)
              ),
              { type: 'OrgPartV2', id: 'LIST' },
            ]
          : [{ type: 'OrgPartV2', id: 'LIST' }],
    }),
    createOrgPart: builder.mutation<
      ClientV2POSTResponse<OrgPartSchema>,
      ClientV2POSTRequest<OrgPartPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<OrgPartSchema>,
        ClientV2POSTRequest<OrgPartPOSTSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.ORG_PARTS(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'OrgPartV2', id: 'LIST' }],
    }),
    updateOrgPart: builder.mutation<
      ClientV2PATCHResponse<OrgPartSchema>,
      ClientV2PATCHRequest<OrgPartPATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ORG_PARTS(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result) =>
        result
          ? _.map(result.updatedIds, (id) => ({
              type: 'OrgPartV2',
              id,
            }))
          : [],
    }),
    addUpdateCustomOrgPartSpecs: builder.mutation<
      ClientV2PATCHResponse<OrgPartSchema>,
      OrgPartActionSchema<OrgPartSpecsPOSTSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ORG_PARTS_ADD_SPECS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) =>
        result
          ? _.map(result.updatedIds, (id) => ({
              type: 'OrgPartV2',
              id,
            }))
          : [],
    }),
    getPublicManufacturers: builder.query<
      ListResponse<PublicManufacturerSchema>,
      QueryParams<PublicManufacturerSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.PUBLIC_MANUFACTURERS(
          parseQueryParams<PublicManufacturerSchema>(params)
        ),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: PublicManufacturerSchema) =>
                  ({
                    type: 'PublicManufacturer',
                    id,
                  } as const)
              ),
              { type: 'PublicManufacturer', id: 'LIST' },
            ]
          : [{ type: 'PublicManufacturer', id: 'LIST' }],
    }),
    getPublicClassifications: builder.query<
      ListResponse<PublicClassificationSchema>,
      QueryParams<PublicClassificationSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.PUBLIC_CLASSIFICATIONS(
          parseQueryParams<PublicClassificationSchema>(params)
        ),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: PublicClassificationSchema) =>
                  ({
                    type: 'PublicClassification',
                    id,
                  } as const)
              ),
              { type: 'PublicClassification', id: 'LIST' },
            ]
          : [{ type: 'PublicClassification', id: 'LIST' }],
    }),
    removeCustomOrgPartSpecs: builder.mutation<
      ClientV2PATCHResponse<OrgPartSchema>,
      OrgPartActionSchema<OrgPartRemoveSpecsSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ORG_PARTS_REMOVE_SPECS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) =>
        result
          ? _.map(result.updatedIds, (id) => ({
              type: 'OrgPartV2',
              id,
            }))
          : [],
    }),
    uploadOrgPartHeroImage: builder.mutation<
      ClientV2PATCHResponse<OrgPartSchema>,
      { payload: FormData }
    >({
      queryFn: async ({ payload }, { getState }) => {
        const result = await fetch(CLIENT_V2_URLS.ORG_PARTS_SET_HERO_IMAGE, {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Bearer ${
              (getState() as RootState).session.authToken
            }`,
          },
        });
        const data = await result.json();
        return { data };
      },
      invalidatesTags: (result) =>
        result
          ? _.map(result.updatedIds, (id) => ({
              type: 'OrgPartV2',
              id,
            }))
          : [],
    }),
    resyncOrgPart: builder.mutation<
      ClientV2PATCHResponse<OrgPartSchema>,
      ClientV2BulkActionRequest<OrgPartSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2PATCHResponse<OrgPartSchema>,
        ClientV2BulkActionRequest<OrgPartSchema>
      >((data) => ({
        url: CLIENT_V2_URLS.ORG_PARTS_RESYNC,
        method: 'POST',
        body: data,
      })),
      invalidatesTags: (result) =>
        result
          ? _.map(result.updatedIds, (id) => ({
              type: 'OrgPartV2',
              id,
            }))
          : [],
    }),
  }),
});

export const {
  usePrefetch,
  useGetPublicPartsQuery,
  useLazyGetPublicPartsQuery,
  useGetOrgPartsQuery,
  useLazyGetOrgPartsQuery,
  useCreateOrgPartMutation,
  useUpdateOrgPartMutation,
  useAddUpdateCustomOrgPartSpecsMutation,
  useRemoveCustomOrgPartSpecsMutation,
  useUploadOrgPartHeroImageMutation,
  useResyncOrgPartMutation,
  useGetPublicManufacturersQuery,
  useGetPublicClassificationsQuery,
} = extendedApiSlice;
