import { FC } from 'react';
import {
  Tooltip,
  Badge,
  MenuItem,
  Menu,
  IconButton,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  IconHelpTriangle,
  IconCpu,
  IconExternalLink,
  IconDotsVertical,
  IconCircleCheck,
  IconHelpCircle,
  IconCopy,
  IconPackage,
} from '@tabler/icons-react';
import Avatar, { AvatarSize } from 'ui-component/extended/Avatars/Avatar';
import { useNavigate } from 'react-router-dom';
import { copyClipboard } from 'utils/functions';
import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { OrgPartIcons, OrgPartType } from 'types/clientV2/parts';
import _ from 'lodash';

interface GenericPartAvatarProps {
  partId?: string;
  partHeroImage?: string;
  partCPID?: string;
  altCount?: number;
  unapprovedAltCount?: number;
  stockLotId?: string;
  searchParams?: string;
  size?: AvatarSize;
  partType?: OrgPartType;
}

const GenericPartAvatar: FC<GenericPartAvatarProps> = ({
  partId,
  partHeroImage,
  partCPID,
  altCount,
  unapprovedAltCount,
  stockLotId,
  searchParams,
  size = AvatarSize.MD,
  partType = OrgPartType.OTS,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const partLink = partId
    ? `${ALL_VIEWS.PARTS_DETAIL.path}/${partId}${
        searchParams ? `?${searchParams}` : ''
      }`
    : '';

  const stockLotLink = stockLotId
    ? searchParams
      ? `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/${stockLotId}?${searchParams}`
      : `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/${stockLotId}`
    : '';

  const PartTypeIcon = _.get(OrgPartIcons, partType, IconCpu);

  const menuOptionsPart = {
    goToPart: {
      label: 'Go To Part',
      icon: IconCpu,
      hidden: !partLink,
      onClick: () => navigate(partLink),
    },
    openPartInNewTab: {
      label: 'Open Part In New Tab',
      icon: IconExternalLink,
      hidden: !partLink,
      onClick: () =>
        window.open(`${window.location.origin}${partLink}`, '_blank'),
    },
    copyLinkToPart: {
      label: 'Copy Link to Part',
      icon: IconCopy,
      hidden: !partLink,
      onClick: () => copyClipboard(`${window.location.origin}${partLink}`),
    },
    copyCofactrId: {
      label: 'Copy Cofactr ID',
      icon: IconCopy,
      hidden: !partCPID,
      onClick: () => partCPID && copyClipboard(partCPID),
    },
  };

  const menuOptionsStockLot = {
    goToStockLot: {
      label: 'Go To Stock Lot',
      icon: IconPackage,
      onClick: () => navigate(stockLotLink),
    },
    openStockLotInNewTab: {
      label: 'Open Stock Lot In New Tab',
      icon: IconExternalLink,
      onClick: () =>
        window.open(`${window.location.origin}${stockLotLink}`, '_blank'),
    },
    copyLinkToStockLot: {
      label: 'Copy Link to Stock Lot',
      icon: IconCopy,
      onClick: () => copyClipboard(`${window.location.origin}${stockLotLink}`),
    },
  };

  if (!partId) {
    return (
      <Avatar size={size} variant="rounded">
        <IconHelpTriangle color={theme.palette.error.main} />
      </Avatar>
    );
  }

  return (
    <>
      <Badge
        badgeContent={
          altCount ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <IconCircleCheck width="14px" height="14px" />
              <Typography variant="inherit">
                {altCount.toLocaleString()}
              </Typography>
            </Stack>
          ) : (
            0
          )
        }
        color="success"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '.MuiBadge-anchorOriginBottomLeft': {
            transform: 'none',
            bottom: '3px',
            left: '3px',
            visibility: altCount ? 'visible' : 'hidden',
          },
        }}
      >
        <Badge
          badgeContent={
            unapprovedAltCount ? (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0.5}
              >
                <IconHelpCircle width="14px" height="14px" />
                <Typography variant="inherit">
                  {unapprovedAltCount.toLocaleString()}
                </Typography>
              </Stack>
            ) : (
              0
            )
          }
          color="secondary"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '.MuiBadge-anchorOriginBottomLeft': {
              transform: 'none',
              bottom: altCount ? '26px' : '3px',
              left: '3px',
              visibility: unapprovedAltCount ? 'visible' : 'hidden',
            },
          }}
        >
          <Avatar src={partHeroImage} size={size} variant="rounded">
            <PartTypeIcon />
          </Avatar>
          <PopupState variant="popover" popupId="moreactions-popup-menu">
            {(popupState) => (
              <>
                <Tooltip
                  title="More options"
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                  }}
                >
                  <IconButton
                    {...bindTrigger(popupState)}
                    size="small"
                    color="secondary"
                    disabled={!partId}
                    onClick={(event) => {
                      event.stopPropagation();
                      bindTrigger(popupState).onClick(event);
                    }}
                  >
                    <IconDotsVertical />
                  </IconButton>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  {Object.values(menuOptionsPart)
                    .filter((option) => !option.hidden)
                    .map((option, index) => {
                      const Icon = option.icon;
                      const key = Object.keys(menuOptionsPart)[index];
                      return (
                        <MenuItem key={key} onClick={option.onClick}>
                          <ListItemIcon>
                            <Icon />
                          </ListItemIcon>
                          <ListItemText>{option.label}</ListItemText>
                        </MenuItem>
                      );
                    })}
                  {stockLotId && <Divider />}
                  {stockLotId &&
                    Object.values(menuOptionsStockLot).map((option, index) => {
                      const Icon = option.icon;
                      const key = Object.keys(menuOptionsStockLot)[index];
                      return (
                        <MenuItem key={key} onClick={option.onClick}>
                          <ListItemIcon>
                            <Icon />
                          </ListItemIcon>
                          <ListItemText>{option.label}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </Menu>
              </>
            )}
          </PopupState>
        </Badge>
      </Badge>
    </>
  );
};

export default GenericPartAvatar;
