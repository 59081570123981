import { GridActionsColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DatagridNames, ExtendedGridColDef } from 'types/datagrid';
import _ from 'lodash';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import {
  partNoStockLotColumnDef,
  supplyColumns,
} from 'ui-component/DataGrid/PartColumnDef';
import ExtendedDatagrid from 'ui-component/DataGrid/ExtendedDatagrid';
import { sourceTypeOptions } from 'types/part';
import { RenderPartAlt } from 'ui-component/DataGrid/Render/RenderPartAlt';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import { ALL_APPS } from 'constants/appConstants';
import { AltPartsAlert } from 'ui-component/AltPartsAlert';
import { renderPartCell } from 'ui-component/DataGrid/Render';

interface KitRequestLineAltsTableProps {
  kitRequestLine: DecoratedKitRequestLine;
}

const KitRequestLineAltsTable = ({
  kitRequestLine,
}: KitRequestLineAltsTableProps) => {
  const primaryPart = kitRequestLine.orgPart;
  const altParts =
    kitRequestLine.orgParts.filter((part) =>
      kitRequestLine.altParts.includes(part.id)
    ) || [];
  const unapprovedAltParts =
    kitRequestLine.orgParts.filter((part) =>
      kitRequestLine.unapprovedAltParts.includes(part.id)
    ) || [];

  const columns: (ExtendedGridColDef | GridActionsColDef)[] = [
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status of this alternative part',
      type: 'singleSelect',
      valueOptions: sourceTypeOptions,
      hideable: false,
      minWidth: 80,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.id === primaryPart?.id
          ? 'Primary Part'
          : _.chain(unapprovedAltParts)
              .map('id')
              .includes(params.row.id)
              .value()
          ? 'Not Approved'
          : _.chain(altParts).map('id').includes(params.row.id).value()
          ? 'Approved'
          : params.row.sourceType,
      renderCell: ({ value }: GridRenderCellParams) =>
        RenderPartAlt(value as string),
      permissionScope: { app: ALL_APPS.KITTING.id },
    },
    {
      ...partNoStockLotColumnDef,
      renderCell: (params: GridRenderCellParams<string>) =>
        renderPartCell({ part: params.row, noPart: null, altCount: null }),
      valueGetter: (params: GridValueGetterParams) => params.row.mpn,
    },
    ...supplyColumns,
  ];

  const approvedPartsCount =
    (altParts?.length || 0) - (unapprovedAltParts?.length || 0);

  return (
    <>
      <AltPartsAlert approvedPartsCount={approvedPartsCount} />
      <ExtendedDatagrid
        exportFileNamePrefix="KitRequestLine-Alts"
        noRows="No matching parts found"
        gridName={DatagridNames.kitRequestLineAlts}
        shouldSaveDatagridState={false}
        rows={_.uniqBy([primaryPart, ...altParts, ...unapprovedAltParts], 'id')}
        columns={columns}
        initialVisibleColumns={[
          'status',
          'part',
          'buyable',
          'quotable',
          'maybe',
        ]}
        pagination={false}
      />
    </>
  );
};

export default KitRequestLineAltsTable;
