import { KitRequestLineSchema } from 'types/kitting';
import {
  useDeleteKitRequestLinesMutation,
  useLockKitRequestLinesMutation,
  useUnlockKitRequestLinesMutation,
  useRecalculateKitRequestLineAllocationsMutation,
  useUpdateKitRequestLinesMutation,
} from 'store/slices/clientV2/kitRequests';
import { DatagridNames, RowAction } from 'types/datagrid';
import { useMemo, useState } from 'react';
import { ALL_APP_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { useDialogManager } from 'hooks/useDialogManager';
import {
  IconEdit,
  IconShoppingCart,
  IconTrash,
  IconLock,
  IconLockOpen,
  IconRefresh,
} from '@tabler/icons-react';
import DeleteRecordDialog from 'ui-component/clientV2/DeleteRecordDialog';
import ToggleRecordDialog, {
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';
import { makeColumns } from 'views/kitting/Kit/KitRequestLineTable/columns';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import ExtendedDatagrid from 'ui-component/DataGrid/ExtendedDatagrid';
import { useIsCofactr } from 'hooks/useIsCofactr';
import KitRequestLineDetailPanel from 'views/kitting/Kit/components/KitRequestLineDetailPanel';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import CreateKitRequestLineDialog from 'views/kitting/Kit/components/CreateKitRequestLineDialog';
import UpdateKitRequestLineDialog from 'views/kitting/Kit/components/UpdateKitRequestLineDialog';
import useSnackbar from 'hooks/useSnackbar';
import useGetCustomPropertyColumns from 'hooks/useGetCustomPropertyColumns';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { KitScrubbingAlert } from 'views/kitting/Kit/components/KitScrubbingAlert';
import _ from 'lodash';
import KitScrubbingDialog from 'views/kitting/Kit/components/KitScrubbingDialog';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';

enum KIT_REQUEST_LINES_DIALOGS {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  PURCHASE = 'PURCHASE',
  LOCK = 'LOCK',
  SCRUBBING = 'SCRUBBING',
}

const KitRequestLineTable = () => {
  const tempKitApprovalDialog = useFeature(
    FeatureFlags.tempKitApprovalDialog
  ).on;
  const tempParts2Ui = useFeature(FeatureFlags.tempParts2Ui).on;

  const { dispatchErrorSnackbar, dispatchSuccessSnackbar } = useSnackbar();
  const { closeDialog, toggleDialog, isDialogOpen } = useDialogManager(
    Object.values(KIT_REQUEST_LINES_DIALOGS)
  );
  const [selectedKitRequestLine, setSelectedKitRequestLine] =
    useState<DecoratedKitRequestLine | null>(null);
  const [selectedRows, setSelectedRows] = useState<DecoratedKitRequestLine[]>(
    []
  );
  const { isCofactr } = useIsCofactr();

  const { customPropertyColumns } = useGetCustomPropertyColumns({
    model: CustomPropertyModel.KIT_REQUEST_LINE,
    visibilityContext: CustomPropertyVisibility.DATAGRID_EDIT,
    useMutation: useUpdateKitRequestLinesMutation,
    mutationAsClientV2: true,
  });

  const {
    kitRequest,
    decoratedKitRequestLines,
    isFetchingDecoratedKitRequestLines,
    validations,
  } = useKitRequestContext();

  const [
    recalculateKitRequestLineAllocations,
    { isLoading: isRecalculatingKitRequestLineAllocations },
  ] = useRecalculateKitRequestLineAllocationsMutation();

  const handleRecalculateKitRequestLineAllocations = async (
    rows: DecoratedKitRequestLine[]
  ) => {
    try {
      await recalculateKitRequestLineAllocations({
        ids: rows.map((row) => row.id),
      }).unwrap();
      dispatchSuccessSnackbar('Kit line allocations recalculated');
    } catch (error) {
      dispatchErrorSnackbar(error as string);
    }
  };

  const rowActions: RowAction<DecoratedKitRequestLine>[] = [
    {
      getLabel: () => 'Edit Line',
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequestLine(row);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.EDIT);
        }
      },
      Icon: IconEdit,
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
      isRowDisabled: (row) =>
        Boolean(
          (row?.locked && !isCofactr) ||
            (row?.kitRequest?.approvedBy && !isCofactr)
        ),
    },
    {
      getLabel: () => 'Purchase Parts',
      onRowClick: (row) => {
        if (row) {
          setSelectedRows([row]);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.PURCHASE);
        }
      },
      onBatchClick: (rows) => {
        if (rows) {
          setSelectedRows(rows);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.PURCHASE);
        }
      },
      Icon: IconShoppingCart,
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
      isRowDisabled: (row) => Boolean(row?.kitRequest?.approved),
      // TODO: Hide until purchasing flow is implemented in UI-3860
      hideRowAction: () => true,
      hideBatchAction: () => true,
    },
    {
      getLabel: (row) => (row?.locked ? 'Unlock Line' : 'Lock Line'),
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequestLine(row);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.LOCK);
        }
      },
      getRowIcon: (row) => (row?.locked ? IconLockOpen : IconLock),
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
    {
      getLabel: () => 'Delete Line',
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequestLine(row);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.DELETE);
        }
      },
      Icon: IconTrash,
      color: 'error',
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
      isRowDisabled: (row) =>
        Boolean(row?.kitRequest?.approvedBy && !isCofactr),
    },
    {
      getLabel: () => 'Recalculate Kit Line Allocations',
      onRowClick: (row) => {
        if (row) {
          handleRecalculateKitRequestLineAllocations([row]);
        }
      },
      onBatchClick: (rows) => {
        if (rows) {
          handleRecalculateKitRequestLineAllocations(rows);
        }
      },
      disabled:
        (Boolean(!kitRequest?.approved) && !tempKitApprovalDialog) ||
        isRecalculatingKitRequestLineAllocations,
      Icon: IconRefresh,
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
  ];

  const memoizedColumns = useMemo(() => {
    const columns = makeColumns({ tempParts2Ui });
    const filteredColumns =
      kitRequest?.approved || tempKitApprovalDialog
        ? columns
        : columns.filter((column) => column.field !== 'status');
    return [...filteredColumns, ...customPropertyColumns];
  }, [
    kitRequest?.approved,
    tempKitApprovalDialog,
    makeColumns,
    customPropertyColumns,
    tempParts2Ui,
  ]);

  return (
    <>
      {tempKitApprovalDialog &&
        kitRequest &&
        (validations.isLoading ||
          _.some(
            Object.values(validations.validations),
            (validation) => validation && validation.lines.length > 0
          )) && (
          <KitScrubbingAlert
            isLoading={validations.isLoading}
            validations={validations.validations}
            sx={{ my: 3 }}
            onClick={() => toggleDialog(KIT_REQUEST_LINES_DIALOGS.SCRUBBING)}
          />
        )}
      <ExtendedDatagrid<DecoratedKitRequestLine>
        gridName={DatagridNames.kitRequestLines}
        loading={isFetchingDecoratedKitRequestLines}
        rows={(decoratedKitRequestLines ?? []) as DecoratedKitRequestLine[]}
        columns={memoizedColumns}
        rowActions={rowActions}
        noRows="Click 'Add Line' to add a line to this kit"
        detailPanel
        getDetailPanelContent={({ row }) => (
          <KitRequestLineDetailPanel kitRequestLine={row} />
        )}
        editable
        editLocked={!!kitRequest?.approved}
        newRecordButton={{
          label: 'Add Line',
          onClick: () => {
            toggleDialog(KIT_REQUEST_LINES_DIALOGS.CREATE);
          },
          disabled: !!kitRequest?.approved && !isCofactr,
          permissionScope: {
            app: ALL_APP_IDS.KITTING,
            accessLevel: appPermissionAccessLevels.edit,
          },
        }}
      />
      <DeleteRecordDialog
        id={selectedKitRequestLine?.id ?? ''}
        dialogOpen={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.DELETE)}
        onClose={closeDialog}
        recordName="Kit Line"
        useClientDeleteMutation={useDeleteKitRequestLinesMutation}
      />
      <ToggleRecordDialog<
        KitRequestLineSchema,
        typeof useLockKitRequestLinesMutation,
        typeof useUnlockKitRequestLinesMutation
      >
        dialogOpen={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.LOCK)}
        onClose={closeDialog}
        recordName="Kit Line"
        isCurrentlyTrue={Boolean(selectedKitRequestLine?.locked)}
        id={selectedKitRequestLine?.id ?? ''}
        useToggleTrueMutation={useLockKitRequestLinesMutation}
        useToggleFalseMutation={useUnlockKitRequestLinesMutation}
        toggleActionType={ToggleRecordActionTypes.LOCK}
      />
      {kitRequest && (
        <CreateKitRequestLineDialog
          dialogOpen={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.CREATE)}
          onClose={closeDialog}
          kitRequestId={kitRequest?.id}
        />
      )}
      {selectedKitRequestLine && (
        <UpdateKitRequestLineDialog
          dialogOpen={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.EDIT)}
          onClose={closeDialog}
          kitRequestLine={selectedKitRequestLine}
        />
      )}
      {tempKitApprovalDialog && (
        <KitScrubbingDialog
          open={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.SCRUBBING)}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

export default KitRequestLineTable;
