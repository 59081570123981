import { useMemo } from 'react';
import { StockDocumentSchema } from 'types/clientV2/stockDocuments';
import { StockDocument } from 'types/inventory';

export const useGetDocumentTempURL = ({
  documentBlob,
  selectedDocument,
}: {
  documentBlob: string | Blob | ArrayBuffer | ArrayBufferView | undefined;
  selectedDocument: StockDocument | StockDocumentSchema | undefined;
}) => {
  const documentTempURL = useMemo(
    () =>
      documentBlob
        ? window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(
              new Blob([documentBlob as Blob], {
                type: selectedDocument?.mimeType || 'application/pdf',
              })
            )
          : window.webkitURL.createObjectURL(
              new Blob([documentBlob as Blob], {
                type: selectedDocument?.mimeType || 'application/pdf',
              })
            )
        : null,
    [documentBlob, selectedDocument]
  );

  return documentTempURL;
};
