import { handleErr } from 'utils/functions';
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import { IconArrowRight, IconPlus } from '@tabler/icons-react';
import { FC, KeyboardEvent, useEffect, useState, SyntheticEvent } from 'react';
import { useLazyGetPartsQuery } from 'store/slices/apiV1/part';
import { Part } from 'types/part';
import PartRow from 'ui-component/PartRow';
import { FormikErrors } from 'formik';
import CustomPartDialog from 'ui-component/CustomPartDialog';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { FieldError } from 'react-hook-form';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';

interface PartContextualSearchInputProps {
  helperText?: string | string[] | FieldError;
  error: boolean | undefined;
  onChange: (
    event: SyntheticEvent<Element, Event> | null,
    value: Part[]
  ) => void;
  id: string;
  label: string;
  part?: Part;
  alts?: Part[];
  setErrors?: (err: FormikErrors<unknown>) => void;
  enablePartCreate?: boolean;
  sx?: SxProps<Theme>;
  textFieldSx?: SxProps<Theme>;
  disabled?: boolean;
}

const PartContextualSearchInputSingular: FC<PartContextualSearchInputProps> = ({
  helperText,
  error,
  onChange,
  id,
  label,
  part = null,
  setErrors,
  enablePartCreate = true,
  sx,
  textFieldSx,
  disabled = false,
}) => {
  const tempHideComponentCloudSearchResults = useFeature(
    FeatureFlags.tempHideComponentCloudSearchResults
  ).on;
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [parts, setParts] = useState<Part[]>([]);
  const [createPartDialogOpen, setCreatePartDialogOpen] = useState(false);
  const [allowCreatePart, setAllowCreatePart] = useState(false);
  const [selectedPartState, setSelectedPartState] = useState<Part>(
    part ??
      ({
        id: '',
      } as Part)
  );

  const [getParts, { isFetching, isSuccess }] = useLazyGetPartsQuery();
  const handleSearch = async (query?: string) => {
    try {
      // IMPORTANT:
      // The second argument means that if this searchQuery has been passed before, the
      // cached result will be fetched, rather than performing a new network request
      // This is a really nice fast UX, but if we're worried about stale data,
      // we may want to remove this.
      const fetchedParts = await getParts(
        {
          query: query || searchQuery,
          mpnMatch: tempHideComponentCloudSearchResults,
        },
        true
      ).unwrap();
      if (tempHideComponentCloudSearchResults) {
        setParts(fetchedParts.filter((p) => !!p.customId));
      } else {
        setParts(fetchedParts);
      }
    } catch (err) {
      handleErr(err, setErrors || (() => {}));
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // prevent submitting the form
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      handleSearch();
    }
  };

  useEffect(() => {
    if (part) {
      setSearchQuery(part.mpn);
      handleSearch(part.mpn);
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (
      isSuccess &&
      parts.length === 0 &&
      searchQuery !== '' &&
      enablePartCreate
    ) {
      setAllowCreatePart(true);
    }
  }, [parts, isSuccess, searchQuery, enablePartCreate]);

  return (
    <>
      <CustomPartDialog
        dialogOpen={createPartDialogOpen}
        onClose={() => setCreatePartDialogOpen(false)}
        onCreateSuccess={(newParts) => {
          if (newParts && typeof newParts === 'object') {
            setSelectedPartState(newParts as Part);
            onChange(null, [newParts] as Part[]);
          }
          setAllowCreatePart(false);
        }}
        initialMpn={searchQuery}
      />
      <Autocomplete
        sx={sx}
        value={selectedPartState}
        disabled={disabled}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disablePortal
        disabledItemsFocusable
        noOptionsText={
          allowCreatePart
            ? 'No parts were found matching your search. Try another search, or click the plus icon to create a custom part.'
            : 'Enter a part number and type Return or click the arrow to search'
        }
        id={id}
        loading={isFetching}
        options={parts || []}
        open={open}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option?.mpn ?? option?.id ?? ''}
        defaultValue={part}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?.id}>
            <PartRow
              part={option}
              selected={selected}
              searchString={searchQuery}
            />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            sx={{ mt: 2, ...textFieldSx }}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <SegmentIconButton
                    onClick={() => setCreatePartDialogOpen(true)}
                    permissionScope={{
                      app: ALL_APPS.PART_LIBRARY.id,
                      accessLevel: appPermissionAccessLevels.edit,
                      feature: ALL_FEATURE_IDS.CUSTOM_PARTS,
                    }}
                  >
                    <IconPlus />
                  </SegmentIconButton>
                  {isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <InputAdornment
                      position="start"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleSearch();
                        setAllowCreatePart(false);
                      }}
                    >
                      <IconArrowRight stroke={1.5} fontSize="small" />
                    </InputAdornment>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            helperText={helperText}
            error={error}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        )}
        fullWidth
        onChange={(e, selectedParts) => {
          setAllowCreatePart(false);
          onChange(e, [selectedParts] as Part[]);
          setSelectedPartState(selectedParts as Part);
        }}
      />
    </>
  );
};

export default PartContextualSearchInputSingular;
