import { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import Routes from 'routes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/shared/Snackbar';
import ThemeCustomization from 'themes';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import FeatureFlagProvider from 'contexts/FeatureFlagProvider';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import * as snippet from '@segment/snippet';
import { AppAccessContextProvider } from 'contexts/AppAccessContext';
import PartDataRequesterProvider from 'contexts/PartDataRequesterContext';
import PusherProvider from 'contexts/PusherContext';
import { ConfirmProvider } from 'material-ui-confirm';
import { NewVersionProvider } from 'contexts/NewVersionContext';
import { AsyncRequestProvider } from 'contexts/AsyncRequestContext';

const App = () => {
  // Segment Initialization
  const loadSegment = () => {
    if (process.env.SEGMENT_WRITE_KEY) {
      return snippet.min({ apiKey: process.env.SEGMENT_WRITE_KEY });
    }
    return '';
  };

  useEffect(() => {
    // Finalize Segment Initialization
    if (!window.analytics) {
      const script = document.createElement('script');
      script.async = true;
      script.innerHTML = loadSegment();
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }

    return undefined;
  }, []);

  return (
    <FeatureFlagProvider>
      <AppAccessContextProvider>
        <PusherProvider>
          <AsyncRequestProvider>
            <PartDataRequesterProvider>
              <ThemeCustomization>
                <ConfirmProvider>
                  <NewVersionProvider>
                    <CssBaseline />
                    <Locales>
                      <NavigationScroll>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <>
                            <Routes />
                            <Snackbar />
                          </>
                        </LocalizationProvider>
                      </NavigationScroll>
                    </Locales>
                  </NewVersionProvider>
                </ConfirmProvider>
              </ThemeCustomization>
            </PartDataRequesterProvider>
          </AsyncRequestProvider>
        </PusherProvider>
      </AppAccessContextProvider>
    </FeatureFlagProvider>
  );
};

export default App;
