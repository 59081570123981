import {
  Avatar,
  AvatarGroup,
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { StockDocumentRelatedObjectSchema } from 'types/clientV2/stockDocuments';
import { StockDocTypeIcons, StockDocTypeLabels } from 'types/inventory';
import _ from 'lodash';
import { convertDate, formatToDateTime } from 'utils/dates';

interface FilepileInfoSectionProps {
  documents: StockDocumentRelatedObjectSchema[] | null | undefined;
  isLoading?: boolean;
  onClick?: (id: string | null) => void;
}

const FilepileInfoSection: FC<FilepileInfoSectionProps> = ({
  documents,
  isLoading,
  onClick,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack direction="row" spacing={0.5}>
        <Skeleton variant="rounded" width={26} height={36} />
        <Skeleton variant="rounded" width={26} height={36} />
        <Skeleton variant="rounded" width={26} height={36} />
        <Skeleton variant="rounded" width={26} height={36} />
        <Skeleton variant="rounded" width={26} height={36} />
        <Skeleton variant="rounded" width={26} height={36} />
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <AvatarGroup
        max={9}
        spacing={8}
        onClick={() => onClick?.(null)}
        sx={{ cursor: 'pointer' }}
      >
        {_.orderBy(
          documents || [],
          (d) => (d.createdAt ? convertDate(d.createdAt) : null),
          ['desc']
        ).map((doc) => {
          const Icon = StockDocTypeIcons[doc.stockDocument.documentType];
          return (
            <Tooltip
              title={
                <Stack
                  direction="column"
                  sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  {!!doc.stockDocument.createdAt && (
                    <Typography
                      variant="caption"
                      sx={{ textAlign: 'right', width: '100%' }}
                    >
                      {formatToDateTime(doc.stockDocument.createdAt)}
                    </Typography>
                  )}
                  <Typography variant="caption">
                    {StockDocTypeLabels[doc.stockDocument.documentType]}
                  </Typography>
                  <Typography>{doc.stockDocument.name}</Typography>
                  {doc.stockDocument.createdFromObjectId &&
                    doc.documentTemplateName && (
                      <Typography variant="caption">
                        {doc.documentTemplateName}
                      </Typography>
                    )}
                </Stack>
              }
              key={doc.id}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'white',
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    borderRadius: '8px',
                    color: theme.palette.text.primary,
                  },
                },
              }}
            >
              <Box>
                <Avatar
                  variant="square"
                  sx={{
                    width: `26px`,
                    height: '36px',
                    p: 0,
                    border: `2px solid white`,
                    '&.MuiAvatar-root': {
                      border: `2px solid white`,
                    },
                    backgroundColor: 'white',
                    borderRadius: '0 18px 10px 0',
                    overflow: 'hidden',
                    '& svg': {
                      position: 'relative',
                      transform: 'scale(1.5)',
                    },
                    '& .tabler-icon': {
                      strokeWidth: '1.2px',
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick?.(doc.stockDocument.id);
                  }}
                >
                  <Icon size="36px" color={theme.palette.primary.main} />
                </Avatar>
              </Box>
            </Tooltip>
          );
        })}
      </AvatarGroup>
    </Stack>
  );
};

export default FilepileInfoSection;
