import {
  GridFilterOperator,
  GridRenderCellParams,
  GridValueGetterParams,
  GridFilterInputSingleSelect,
  GridFilterItem,
  GridCellParams,
} from '@mui/x-data-grid-premium';
import {
  BooleanIconRenderCell,
  RelatedRecordRenderCell,
  renderPartCell,
} from 'ui-component/DataGrid/Render';
import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { ALL_APPS } from 'constants/appConstants';
import { ExtendedGridColDef } from 'types/datagrid';
import { orgPartNoStockLotColumnDef } from 'ui-component/DataGrid/PartColumnDef';
import { useUpdateKitRequestLinesMutation } from 'store/slices/clientV2/kitRequests';
import { appPermissionAccessLevels } from 'types/apps';
import { FieldValues } from 'react-hook-form';
import { Stack, Tooltip, Typography } from '@mui/material';
import { statusTextMapper } from 'constants/datagrid';
import _ from 'lodash';
import { KitRequestLineStatusChip } from 'hooks/useGetDecoratedKitRequestLines/KitRequestLineStatusChips';
import { RelatedRecordType } from 'types/inventory';
import { relatedRecordsComparator } from 'ui-component/DataGrid/SortComparators';
import {
  getApplyFilterFnOrgPart,
  getApplyFilterFnRelatedRecords,
  getApplyFilterFnV2OrgParts,
  relatedRecordsFilterOperators,
} from 'ui-component/DataGrid/FilterOperators';
import {
  KitRequestAllocationStatus,
  kitRequestAllocationStatusTextMapper,
  StatusCount,
} from 'hooks/useGetDecoratedKitRequestLines/utils';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import { OrgPart } from 'types/part';
import { renderPartV2Cell } from 'ui-component/DataGrid/Render/RenderPartV2Cell';
import { V2OrgPartsFieldName } from 'ui-component/DataGrid/PartV2ColumnDef';

const statusFilterOptions = Object.values(kitRequestAllocationStatusTextMapper);

export const kitRequestStatusFilterOperators: GridFilterOperator[] = [
  {
    label: 'contains',
    value: 'contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) {
        return null;
      }

      return (
        params: GridCellParams<string, DecoratedKitRequestLine>
      ): boolean => {
        const statusCounts = params.row.stats?.statusCounts || [];
        const searchValue = filterItem.value.toString().toLowerCase();

        const totalAllocated = _.sumBy(params.row.allocations, 'quant');
        const totalShipped = _.chain(params.row.shipmentLines)
          .filter((sl) => !!sl.shipment.shippedAt)
          .sumBy('shippedQuant')
          .value();
        const minimumAdditionalToAllocate = Math.max(
          (params.row.minimumQuantity || 0) - (totalAllocated + totalShipped),
          0
        );
        const hasUnavailableStatus = minimumAdditionalToAllocate > 0;

        if (
          searchValue === KitRequestAllocationStatus.Unavailable &&
          hasUnavailableStatus
        ) {
          return true;
        }

        return statusCounts.some((count: StatusCount) =>
          kitRequestAllocationStatusTextMapper[count.status]
            .toLowerCase()
            .includes(searchValue)
        );
      };
    },
    InputComponent: GridFilterInputSingleSelect,
    InputComponentProps: { options: statusFilterOptions },
  },
];

export const kitRequestLineStatusColumnDef: ExtendedGridColDef = {
  field: 'status',
  headerName: 'Status',
  description: 'Indicates the allocation status of this Kit Line',
  type: 'singleSelect',
  valueOptions: statusFilterOptions,
  minWidth: 150,
  hideable: false,
  filterOperators: kitRequestStatusFilterOperators,
  valueGetter: (params: GridValueGetterParams) =>
    [
      ..._.chain(params.row.stats?.statusCounts)
        .map('status')
        .map((s) => statusTextMapper[s as keyof typeof statusTextMapper])
        .uniq()
        .value(),
      ...(Math.max(
        (params.row.minimumQuantity || 0) -
          _.sumBy(params.row.allocations, 'quantity'),
        0
      ) > 0
        ? ['Unavailable']
        : []),
    ].join('/') ?? 'Unknown',
  renderCell: (params: GridRenderCellParams) => (
    <KitRequestLineStatusChip kitRequestLine={params.row} />
  ),
  permissionScope: { app: ALL_APPS.PART_SEARCH.id },
};

const findV2OrgPart = (row: DecoratedKitRequestLine) => {
  if (!row?.v2OrgPart) {
    return null;
  }

  const foundPart = _.find(row.v2OrgParts, (part) => part.id === row.v2OrgPart);

  return foundPart ?? null;
};

export const makeColumns = ({ tempParts2Ui }: { tempParts2Ui: boolean }) => {
  const columns: ExtendedGridColDef[] = [
    kitRequestLineStatusColumnDef,
    {
      ...orgPartNoStockLotColumnDef,
      field: tempParts2Ui
        ? V2OrgPartsFieldName
        : orgPartNoStockLotColumnDef.field,
      valueGetter: (params: GridValueGetterParams) => {
        const foundPart = findV2OrgPart(params.row as DecoratedKitRequestLine);
        return tempParts2Ui ? foundPart?.mpn : params.row.orgPart?.mpn;
      },
      getApplyQuickFilterFn: (value) =>
        tempParts2Ui
          ? getApplyFilterFnV2OrgParts(value)
          : getApplyFilterFnOrgPart(value),
      renderCell: (params: GridRenderCellParams) => {
        const foundV2OrgPart = tempParts2Ui ? findV2OrgPart(params.row) : null;
        return foundV2OrgPart
          ? renderPartV2Cell({
              orgPart: foundV2OrgPart,
              altCount: (params.row?.altV2OrgParts ?? []).length,
              unapprovedAltCount: (params.row?.unapprovedAltV2OrgParts ?? [])
                .length,
            })
          : renderPartCell({
              part: params.row.orgPart,
              noPart: null,
              altCount: (
                params.row.orgParts.filter((part: OrgPart) =>
                  params.row.altParts.includes(part.id)
                ) || []
              ).length,
              stockLot: null,
              searchParams: undefined,
              unapprovedAltCount: (
                params.row.orgParts.filter((part: OrgPart) =>
                  params.row.unapprovedAltParts.includes(part.id)
                ) || []
              ).length,
            });
      },
    },
    {
      field: 'targetQuantity',
      headerName: 'Quantity',
      description: 'Quantity of this part in this Kit Line',
      type: 'number',
      flex: 0.25,
      permissionScope: {
        app: ALL_APPS.KITTING.id,
      },
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          title={`Minimum Quantity: ${params.row.minimumQuantity.toLocaleString()}`}
          placement="top"
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0.5}
          >
            <Typography>{params.value.toLocaleString()}</Typography>
          </Stack>
        </Tooltip>
      ),
    },
    {
      field: 'locked',
      headerName: 'Locked',
      description:
        'Indicates whether the system can automatically calculate allocations for this Kit Line',
      type: 'boolean',
      flex: 0.3,
      hideable: true,
      renderCell: (params: GridRenderCellParams) =>
        BooleanIconRenderCell(
          params,
          IconLock,
          IconLockOpen,
          'error',
          'primary'
        ),
      permissionScope: { app: ALL_APPS.KITTING.id },
    },
    {
      field: 'printedNotes',
      headerName: 'Printed Notes',
      description:
        'Notes to be printed on stock lot shipment label and packing slip',
      type: 'string',
      flex: 0.5,
      permissionScope: { app: ALL_APPS.KITTING.id },
      editable: true,
      inlineEditRenderCell: true,
      useMutation: useUpdateKitRequestLinesMutation,
      editPermissionScope: {
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      },
      mutationPreSubmit: (data: FieldValues) => ({
        printedNotes: data.printedNotes,
      }),
      mutationAsClientV2: true,
    },
    {
      field: 'quantityShipped',
      headerName: 'Quantity Shipped',
      description: 'Quantity of this part that has been shipped',
      type: 'number',
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.shipmentLines?.length) {
          return '-';
        }
        return _.sumBy(params.row.shipmentLines, 'shippedQuant');
      },
      flex: 0.25,
    },
    {
      field: 'relatedRecords',
      headerName: 'Related Records',
      description: 'Other records related to transaction',
      type: 'string',
      minWidth: 200,
      flex: 0.5,
      hideable: true,
      valueGetter: (params: GridValueGetterParams) =>
        _.chain(params.row.shipmentLines)
          .filter((sl) => !!sl.shipment.shippedAt)
          .map((sl) => ({
            name: sl.shipment.shipmentCode,
            recordType: RelatedRecordType.SHIPMENT,
            recordId: sl.shipment.id,
            orgId: sl.shipment.org,
          }))
          .value(),
      renderCell: (params: GridRenderCellParams) => (
        <RelatedRecordRenderCell {...params} />
      ),
      filterOperators: relatedRecordsFilterOperators,
      sortComparator: relatedRecordsComparator,
      getApplyQuickFilterFn: getApplyFilterFnRelatedRecords,
    },
  ];

  return columns;
};
