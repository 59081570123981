import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const Parts = Loadable(lazy(() => import('views/parts/PartsV2/Parts')));
const Part = Loadable(lazy(() => import('views/parts/PartsV2/PartWrapper')));

export const PartsRoutes = [
  {
    path: `${ALL_VIEWS.PARTS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.PARTS.id,
          view: ALL_VIEWS.PARTS.id,
        }}
      >
        <FullHeightMainCardWrapper>
          <Parts />
        </FullHeightMainCardWrapper>
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PARTS.deprecatedPath}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PARTS.id,
            view: ALL_VIEWS.PARTS.id,
          }}
        >
          <Parts />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PARTS_DETAIL.path}/:partId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.PARTS.id,
          view: ALL_VIEWS.PARTS_DETAIL.id,
        }}
      >
        <Part />
      </PermissionsRouteWrapper>
    ),
  },
];
