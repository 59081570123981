import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  CustomProperty,
  CustomPropertyDataType,
  CustomPropertyValue,
} from 'types/customProperty';
import { FC } from 'react';
import { HookFormCheckbox } from 'ui-component/HookFormComponents/HookFormCheckbox';
import { ISOStringHookFormDatePicker } from 'ui-component/HookFormComponents/HookFormDatePicker/ISOStringHookFormDatePicker';
import { HookFormUserAutocomplete } from 'ui-component/HookFormComponents/HookFormUserAutocomplete';
import { HookFormInput } from 'ui-component/HookFormComponents/HookFormInput';
import { HookFormNumberInput } from 'ui-component/HookFormComponents/HookFormNumberInput';
import { HookFormAutocomplete } from 'ui-component/HookFormComponents/HookFormAutoComplete';
import _ from 'lodash';
import { HookFormAutocompleteMultiple } from 'ui-component/HookFormComponents/HookFormAutoCompleteMultiple';

export type HookFormCustomPropertyInputProps = {
  control: Control<any, object>;
  errors?: FieldErrors;
  customProperty: CustomProperty;
  setValue: UseFormSetValue<FieldValues>;
  shouldUnregister?: boolean;
};

export const CUSTOM_PROPS_NAME_PREPEND = 'customProperties.';

export const HookFormCustomPropertyInput: FC<
  HookFormCustomPropertyInputProps
> = ({
  control,
  errors,
  customProperty,
  setValue,
  shouldUnregister = false,
}) => {
  const label = customProperty.name;
  const name = `${CUSTOM_PROPS_NAME_PREPEND}${customProperty.key}`;

  if (customProperty.dataType === CustomPropertyDataType.BOOLEAN) {
    return (
      <HookFormCheckbox
        control={control}
        name={name}
        label={label}
        errors={errors}
        shouldUnregister={shouldUnregister}
      />
    );
  }

  if (
    customProperty.dataType === CustomPropertyDataType.DATE ||
    customProperty.dataType === CustomPropertyDataType.DATETIME
  ) {
    return (
      <ISOStringHookFormDatePicker
        control={control}
        name={name}
        label={label}
        errors={errors}
        shouldUnregister={shouldUnregister}
      />
    );
  }

  if (customProperty.dataType === CustomPropertyDataType.USER) {
    return (
      <HookFormUserAutocomplete
        control={control}
        name={name}
        label={label}
        errors={errors}
        setValue={setValue}
        shouldUnregister={shouldUnregister}
      />
    );
  }

  if (customProperty.dataType === CustomPropertyDataType.SINGLE_CHOICE) {
    return (
      <HookFormAutocomplete
        shouldUnregister={shouldUnregister}
        control={control}
        name={name}
        label={label}
        errors={errors}
        options={customProperty.values}
        mb={0}
        getOptionLabel={(option: CustomPropertyValue) => option.label}
        initialValueMatcher={(value: string) =>
          _.find(customProperty.values, (option) => option.value === value) ??
          null
        }
        valueFromOption={(option: CustomPropertyValue) => option.value}
      />
    );
  }

  if (customProperty.dataType === CustomPropertyDataType.MULTI_CHOICE) {
    return (
      <HookFormAutocompleteMultiple
        shouldUnregister={shouldUnregister}
        control={control}
        name={name}
        label={label}
        errors={errors}
        options={customProperty.values}
      />
    );
  }

  if (customProperty.dataType === CustomPropertyDataType.NUMBER) {
    return (
      <HookFormNumberInput
        shouldUnregister={shouldUnregister}
        control={control}
        name={name}
        label={label}
        errors={errors}
      />
    );
  }

  return (
    <HookFormInput
      shouldUnregister={shouldUnregister}
      control={control}
      name={name}
      label={label}
      errors={errors}
    />
  );
};
