import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { packageTypes } from 'types/part';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';

export const BasePackageAutocompleteInlineEdit = ({
  name = '',
  label,
  errors,
  control,
}: HookFormComponentProps) => {
  const options = [...packageTypes];
  const [debouncedInput, setDebouncedInput] = useState('');

  const optionsWithUserInput = [...options];
  !_.find(options, (o) => o === debouncedInput) &&
    optionsWithUserInput.push(debouncedInput);

  const handleDebounce = useCallback(
    _.debounce((nextValue) => setDebouncedInput(nextValue), 1000),
    []
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <Autocomplete
          options={optionsWithUserInput}
          onInputChange={(__, option) => {
            handleDebounce(option);
          }}
          onChange={(__, data) => {
            onChange(data);
          }}
          sx={{
            '.MuiOutlinedInput-root': { height: '42px' },
            '.MuiAutocomplete-input': {
              minWidth: '50px !important',
            },
          }}
          onBlur={onBlur}
          autoComplete
          autoSelect
          filterSelectedOptions
          value={value}
          getOptionLabel={(option) => option}
          size="small"
          renderOption={(props, option, { selected }) => (
            <li {...props} key={`${option}-${Math.random()}`}>
              <MenuItem
                selected={selected}
                sx={{
                  fontStyle: option === debouncedInput ? 'italic' : 'normal',
                }}
              >
                {option}
              </MenuItem>
            </li>
          )}
          renderInput={(params) => (
            <Box sx={{ width: '100%' }}>
              <TextField
                {...params}
                label={label}
                fullWidth
                inputRef={ref}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderBottomRightRadius: '0px',
                    borderTopRightRadius: '0px',
                  },
                }}
              />
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
            </Box>
          )}
        />
      )}
    />
  );
};
