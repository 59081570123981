import {
  HookFormInput,
  HookQuerySearchSelect,
} from 'ui-component/HookFormComponents';
import { packageValueOptions, StockLotQuerySchema } from 'types/inventory';
import { createKitRequestLineFormConstants } from 'views/kitting/Kit/components/constants';
import { useGetStockLotsServerSideQuery } from 'store/slices/apiV1/inventory';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import { useLazyGetAllocationsClientV2Query } from 'store/slices/clientV2/allocations';
import { ALL, QueryParams } from 'types/api';
import { DatagridFilterOperatorsValues } from 'types/datagrid';
import { useEffect } from 'react';
import { calculateAvailableQuantForStockLot } from 'views/kitting/Kit/utils';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import { CustomProperty } from 'types/customProperty';
import { Stack } from '@mui/system';
import { HookFormCustomPropertyInput } from 'ui-component/HookFormComponents/HookFormCustomPropertyInput';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';

interface BaseCreateUpdateKitRequestFromStockLotProps {
  showStockLotSelect?: boolean;
  calculateAvailableQuantWithKitRequestLine?: boolean;
  customProperties?: CustomProperty[];
}

type CreateUpdateKitRequestFromStockLotProps =
  BaseCreateUpdateKitRequestFromStockLotProps &
    (
      | {
          isCreate: true;
          kitRequestLine?: never;
        }
      | {
          isCreate: false;
          kitRequestLine?: DecoratedKitRequestLine;
          showStockLotSelect?: boolean;
          calculateAvailableQuantWithKitRequestLine?: boolean;
        }
    );

const CreateUpdateKitRequestFromStockLot = ({
  isCreate = false,
  kitRequestLine,
  showStockLotSelect = false,
  calculateAvailableQuantWithKitRequestLine = true,
  customProperties,
}: CreateUpdateKitRequestFromStockLotProps) => {
  const tempParts2Ui = useFeature(FeatureFlags.tempParts2Ui).on;

  const { kitRequest } = useKitRequestContext();

  const [getAllocations] = useLazyGetAllocationsClientV2Query();

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useFormContext();

  const watchStockLot = useWatch({
    control,
    name: createKitRequestLineFormConstants.stockLot.id,
  });

  const handleGetAllocationsForStockLot = async (stockLotId: string) => {
    const { data: allocations } = await getAllocations({
      schema: [
        ALL,
        {
          stockLot: [],
        },
      ],
      filters: [
        {
          field: 'stockLot.id',
          operator: DatagridFilterOperatorsValues.isAnyOf,
          value: stockLotId,
        },
      ],
    }).unwrap();
    const available = calculateAvailableQuantForStockLot({
      allocations,
      stockLotQuant: watchStockLot?.quant,
      kitRequest,
      kitRequestLineId: calculateAvailableQuantWithKitRequestLine
        ? kitRequestLine?.id!
        : undefined,
    });
    if (available < watchStockLot.quant) {
      setError(createKitRequestLineFormConstants.targetQuantityStockLot.id, {
        message: `${
          available === 0
            ? 'All quantity of this stock lot has already been allocated. Please select another stock lot.'
            : `This stock lot only has an available quantity of ${available}. Please select another stock lot${
                isCreate
                  ? ' or create kit line for the part'
                  : calculateAvailableQuantWithKitRequestLine
                  ? ''
                  : ' or allocate only the available quantity'
              }.`
        }`,
      });
    } else {
      trigger(createKitRequestLineFormConstants.targetQuantityStockLot.id);
    }
  };

  useEffect(() => {
    if (watchStockLot?.id && watchStockLot?.quant) {
      clearErrors(createKitRequestLineFormConstants.targetQuantityStockLot.id);
      handleGetAllocationsForStockLot(watchStockLot.id);
    }
  }, [watchStockLot?.id]);

  const overrideOnChangeStockLot = (
    value: StockLotQuerySchema | StockLotQuerySchema[]
  ) => {
    const stockLot = value as StockLotQuerySchema;
    if (isCreate) {
      setValue(
        createKitRequestLineFormConstants.targetQuantityStockLot.id,
        stockLot?.quant
      );
    }
    return {
      id: stockLot?.id,
      quant: stockLot?.quant,
      part: tempParts2Ui ? undefined : stockLot?.part?.id,
      v2OrgPart: tempParts2Ui ? stockLot?.v2OrgPart : undefined,
    };
  };

  const queryFilters = [
    {
      field: 'quant',
      operator: '>',
      value: 0,
    },
    'and',
    {
      field: 'facility.id',
      operator: 'isAnyOf',
      value: _.map(kitRequest?.sourceLocations, 'id'),
    },
    'or',
    {
      field: 'stockLocation.id',
      operator: 'isAnyOf',
      value: _.map(kitRequest?.sourceLocations, 'id'),
    },
  ];

  const partQueryFilter = tempParts2Ui
    ? {
        field: 'v2OrgPart',
        operator: DatagridFilterOperatorsValues.isAnyOf,
        value: [
          kitRequestLine?.v2OrgPart,
          ...(kitRequestLine?.altV2OrgParts ?? []),
        ],
      }
    : {
        field: 'orgPart.part',
        operator: DatagridFilterOperatorsValues.isAnyOf,
        value: [
          kitRequestLine?.part,
          ...(kitRequestLine?.altParts ?? []),
        ].filter((part) => !!part),
      };

  return (
    <>
      {Boolean(isCreate || kitRequestLine?.locked || showStockLotSelect) && (
        <HookQuerySearchSelect<StockLotQuerySchema>
          errors={errors}
          control={control}
          name={createKitRequestLineFormConstants.stockLot.id}
          label={createKitRequestLineFormConstants.stockLot.label}
          overrideOnChange={overrideOnChangeStockLot}
          getDefaultValue={(
            option:
              | Pick<StockLotQuerySchema, 'quant' | 'id' | 'part'>
              | Pick<StockLotQuerySchema, 'id'>
          ) => option?.id}
          useGetQuery={useGetStockLotsServerSideQuery}
          filters={
            (!isCreate && kitRequestLine?.part
              ? [partQueryFilter, ...queryFilters]
              : queryFilters) as QueryParams<StockLotQuerySchema>['filters']
          }
          schema={[
            'quant',
            'lotId',
            'package',
            'v2OrgPart',
            { part: ['mpn', 'id'] },
          ]}
          getOptionLabel={(option) => {
            if (_.every(Object.values(option), _.isNil)) {
              return '';
            }
            return `${option.part?.mpn}: ${option.quant}pc ${
              option.package
                ? ` -  ${
                    _.find(packageValueOptions, ['value', option.package])
                      ?.label
                  }`
                : ''
            } (${option.part?.id}${option.lotId})`;
          }}
        />
      )}
      <HookFormInput
        control={control}
        name={createKitRequestLineFormConstants.targetQuantityStockLot.id}
        label={createKitRequestLineFormConstants.targetQuantityStockLot.label}
        type="number"
        errors={errors}
      />
      {customProperties && (
        <Stack sx={{ mt: 2 }}>
          {_.map(customProperties, (property) => (
            <HookFormCustomPropertyInput
              control={control}
              errors={errors}
              key={property.key}
              customProperty={property}
              setValue={setValue}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export default CreateUpdateKitRequestFromStockLot;
