import { apiSlice, createClientV2QueryFn } from 'store/slices/api';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';
import { parseQueryParams } from 'store/slices/utils';
import { ListResponse, QueryParams } from 'types/api';
import {
  ReportCodeSnippetSchema,
  ReportTemplateSchema,
} from 'types/clientV2/reporting';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReportTemplates: builder.query<
      ListResponse<ReportTemplateSchema>,
      QueryParams<ReportTemplateSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<ReportTemplateSchema>,
        QueryParams<ReportTemplateSchema>
      >((params) =>
        CLIENT_V2_URLS.REPORT_TEMPLATES(
          parseQueryParams<ReportTemplateSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: ReportTemplateSchema) =>
                  ({ type: 'ClientV2ReportTemplates', id } as const)
              ),
              { type: 'ClientV2ReportTemplates', id: 'LIST' },
            ]
          : [{ type: 'ClientV2ReportTemplates', id: 'LIST' }],
    }),
    getReportCodeSnippets: builder.query<
      ListResponse<ReportCodeSnippetSchema>,
      QueryParams<ReportCodeSnippetSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<ReportCodeSnippetSchema>,
        QueryParams<ReportCodeSnippetSchema>
      >((params) =>
        CLIENT_V2_URLS.REPORT_CODE_SNIPPETS(
          parseQueryParams<ReportCodeSnippetSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: ReportCodeSnippetSchema) =>
                  ({ type: 'ClientV2ReportCodeSnippets', id } as const)
              ),
              { type: 'ClientV2ReportCodeSnippets', id: 'LIST' },
            ]
          : [{ type: 'ClientV2ReportCodeSnippets', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetReportTemplatesQuery,
  useGetReportCodeSnippetsQuery,
  usePrefetch,
} = extendedApiSlice;
