import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
  SkipToken,
} from '@reduxjs/toolkit/dist/query/react';
import { GenericResource, ListResponse, QueryParams } from 'types/api';

export type ServerSideQueryParams<
  BaseResource extends GenericResource,
  ModifiedResource extends GenericResource & RecursiveLite<BaseResource>
> = Omit<QueryParams<BaseResource>, 'schema'> & {
  schema?: QueryParams<ModifiedResource>['schema'];
};

export type RecursiveLite<T> = {
  [K in keyof T]?: T[K] extends object
    ? RecursiveLite<T[K]> | string | null
    : T[K] extends Array<infer U>
    ? U extends object
      ? Array<RecursiveLite<U> | string> | null
      : T[K] | null
    : T[K] | string | null;
};
/**
 * `useServerSideQuery` is a custom hook for type-safe data fetching for our Server Side Query endpoints.
 *
 * @param useServerSideQueryHook - RTK Query hook for data fetching.
 * @param params - Query parameters or a `skipToken` to skip the query.
 * @returns An object containing the fetched data and a loading state.
 *
 * This hook leverages TypeScript's static typing to ensure the fetched data aligns with the expected type.
 * It takes a base resource type (`BaseResource`) representing the full set of possible fields from the server,
 * and a modified resource type (`ModifiedResource`) representing a subset of these fields.
 * This allows you to specify exactly which fields you want to fetch, ensuring the returned data is correctly typed.
 */
const useServerSideQuery = <
  BaseResource extends GenericResource,
  ModifiedResource extends GenericResource & RecursiveLite<BaseResource>
>(
  useServerSideQueryHook: UseQuery<
    QueryDefinition<
      QueryParams<BaseResource>,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      any,
      ListResponse<BaseResource>
    >
  >,
  params:
    | (Omit<QueryParams<BaseResource>, 'schema'> & {
        schema?: QueryParams<ModifiedResource>['schema'];
      })
    | SkipToken
) => {
  const { data, ...rest } = useServerSideQueryHook(
    params as QueryParams<BaseResource>
  );

  return {
    data: data as ListResponse<ModifiedResource> | undefined,
    ...rest,
  };
};

export default useServerSideQuery;
