export const KitScrubbingDatagridClasses = {
  VALIDATION_ERROR: 'validation-error',
  VALIDATION_WARNING: 'validation-warning',
};

export const KIT_SCRUBBING_COLUMNS = {
  STATUS: 'status',
  PART: 'part',
  TARGET_QUANTITY: 'targetQuantity',
  ALLOCATED_QUANTITY: 'allocatedQuantity',
};
