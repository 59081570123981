import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  packageTypes,
  lifecycleStatus,
  lifeCycleStatusFormats,
  Part,
  PartCore,
  terminationTypeOptionsExpanded,
  UpdatePartRequest,
} from 'types/part';
import { handleErr } from 'utils/functions';
import _ from 'lodash';
import { useSelector } from 'store';
import PackageAutocomplete from 'ui-component/CustomPartDialog/components/PackageAutocomplete';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { useUpdatePartMutation } from 'store/slices/apiV1/part';
import {
  updatePartFormConstants,
  validationSchema,
} from 'ui-component/EditPartDialog/constants';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import useSnackbar from 'hooks/useSnackbar';

type CustomPartDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  initialPart: Part | PartCore;
};

const EditPartDialog = ({
  dialogOpen,
  onClose,
  initialPart,
}: CustomPartDialogProps) => {
  const [submitError, setSubmitError] = useState('');
  const { activeOrg } = useSelector((state) => state.org);
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [updatePart, { isLoading: isLoadingUpdatePart }] =
    useUpdatePartMutation();

  const formDefaultValues = {
    [updatePartFormConstants.customId.name]: initialPart.customId ?? '',
    [updatePartFormConstants.description.name]: initialPart?.description ?? '',
    [updatePartFormConstants.package.name]: initialPart?.package ?? '',
    [updatePartFormConstants.terminationType.name]:
      initialPart?.terminationType ?? '',
    [updatePartFormConstants.lifecycleStatus.name]:
      initialPart?.lifecycleStatus ?? '',
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm<FieldValues>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onSubmit = async (data: FieldValues) => {
    setSubmitError('');
    try {
      const payload = _.omitBy(
        data,
        (val, key) =>
          _.isNil(val) ||
          val === '' ||
          !Object.keys(dirtyFields).includes(String(key))
      ) as UpdatePartRequest;
      await updatePart({
        id: initialPart.id,
        payload,
      }).unwrap();
      handleClose();
      reset({}, { keepValues: true });
      dispatchSuccessSnackbar('Part Updated');
    } catch (err) {
      handleErr(err, setSubmitError);
    }
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <ExtendedDialog
      open={dialogOpen}
      onCloseDialog={handleClose}
      title="Update Part"
      isSubmitting={isLoadingUpdatePart}
      permissionScope={{
        app: ALL_APPS.PART_LIBRARY.id,
        accessLevel: appPermissionAccessLevels.edit,
      }}
      isForm
      submitButtonCopy="Update Part"
      onSubmit={handleSubmit((data) => onSubmit(data))}
      formSubmitError={submitError}
    >
      <HookFormInput
        control={control}
        name={updatePartFormConstants.customId.name}
        label={`${activeOrg?.name || 'Custom'} Part Number`}
        errors={errors}
        sx={{ mt: 2 }}
        permissionScope={{
          app: ALL_APPS.PART_LIBRARY.id,
          feature: ALL_FEATURE_IDS.CUSTOM_PNS,
        }}
      />
      <HookFormInput
        control={control}
        name={updatePartFormConstants.description.name}
        label={updatePartFormConstants.description.label}
        errors={errors}
      />
      <PackageAutocomplete
        handleSetValue={(option: string | null) =>
          setValue(updatePartFormConstants.package.name, option, {
            shouldDirty: true,
          })
        }
        label={updatePartFormConstants.package.label}
        name={updatePartFormConstants.package.name}
        options={packageTypes}
        errors={errors}
        initialValue={initialPart?.package ?? ''}
      />
      <HookFormSelect
        control={control}
        name={updatePartFormConstants.terminationType.name}
        label={updatePartFormConstants.terminationType.label}
        errors={errors}
        sx={{ mb: 2, mt: 0 }}
      >
        {terminationTypeOptionsExpanded.map((packageType) => (
          <MenuItem value={packageType.value} key={packageType.value}>
            {packageType.label}
          </MenuItem>
        ))}
      </HookFormSelect>
      <HookFormSelect
        control={control}
        name={updatePartFormConstants.lifecycleStatus.name}
        label={updatePartFormConstants.lifecycleStatus.label}
        errors={errors}
        sx={{ mb: 2, mt: 0 }}
      >
        {Object.values(lifecycleStatus).map((status) => (
          <MenuItem value={status} key={status}>
            {lifeCycleStatusFormats[status].label}
          </MenuItem>
        ))}
      </HookFormSelect>
    </ExtendedDialog>
  );
};

export default EditPartDialog;
