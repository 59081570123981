import { Checkbox, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import { StockDocumentRelatedObjectSchema } from 'types/clientV2/stockDocuments';
import ClickableMainCard from 'ui-component/cards/ClickableMainCard';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import _ from 'lodash';
import {
  ExtractorContextTypes,
  StockDocType,
  StockDocTypeIcons,
  StockDocTypeLabels,
  StockDocumentProcessingStatus,
} from 'types/inventory';
import { formatToDateTime } from 'utils/dates';
import MainCard from 'ui-component/cards/MainCard';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import {
  IconCameraSpark,
  IconCodeDots,
  IconFileDownload,
  IconFileTextSpark,
  IconMailOpened,
  IconPaperclip,
  IconPrinter,
  IconRotateClockwise2,
  IconTrash,
  IconWorld,
} from '@tabler/icons-react';
import { ReactNode, useCallback, useState } from 'react';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import ReactHtmlParser from 'react-html-parser';
import { useGetDocumentTempURL } from 'hooks/useGetDocumentTempURL';
import {
  useDeleteStockDocumentRelatedObjectMutation,
  useDownloadStockDocumentRelatedObjectQuery,
  useLazyDownloadStockDocumentRelatedObjectQuery,
} from 'store/slices/clientV2/stockDocuments';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import fileDownload from 'js-file-download';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import usePrinting from 'hooks/usePrinting';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { ALL_APP_IDS } from 'constants/appConstants';
import DeleteRecordDialog from 'ui-component/clientV2/DeleteRecordDialog';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';

interface StockDocumentsDialogProps {
  selectedDocumentId: string | null;
  open: boolean;
  onClose: () => void;
  title?: string;
  children?: ReactNode;
  setSelectedDocumentId: (id: string | null) => void;
  documents: StockDocumentRelatedObjectSchema[];
  disableDelete?: boolean;
  selectedDocuments?: string[];
  setSelectedDocuments?: (ids: string[]) => void;
}

const StockDocumentsDialog = ({
  selectedDocumentId,
  setSelectedDocumentId,
  open,
  onClose,
  title = 'Attachments',
  documents,
  children,
  disableDelete,
  selectedDocuments,
  setSelectedDocuments,
}: StockDocumentsDialogProps) => {
  const theme = useTheme();
  const { dispatchErrorSnackbar } = useSnackbar();
  const [openDocumentDeleteDialog, setOpenDocumentDeleteDialog] =
    useState(false);
  const { hasAppPermission } = useAppAccessContext();

  const allowPrinting = hasAppPermission(ALL_APP_IDS.WAREHOUSE);

  const selectedDocument =
    documents.find((doc) => doc.stockDocument.id === selectedDocumentId) ||
    _.chain(documents).orderBy('createdAt', 'desc').first().value() ||
    null;

  const { data: documentBlob } = useDownloadStockDocumentRelatedObjectQuery(
    selectedDocument?.id || skipToken
  );

  const documentTempURL = useGetDocumentTempURL({
    documentBlob,
    selectedDocument: selectedDocument?.stockDocument,
  });

  const {
    handlePrintStockDocumentRelatedObjects: printStockDocumentRelatedObjects,
  } = usePrinting({});

  const [fetchDocument] = useLazyDownloadStockDocumentRelatedObjectQuery();

  const handleFetchDocument = async (id: string, fileName: string) => {
    try {
      const document = await fetchDocument(id).unwrap();
      fileDownload(
        document,
        fileName.substring(fileName.lastIndexOf('/') + 1).split('?')[0]
      );
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        dispatchErrorSnackbar(errMessage);
      });
    }
  };

  const handleSuccess = useCallback(() => {
    setSelectedDocumentId(null);
    setOpenDocumentDeleteDialog(false);
  }, [setSelectedDocumentId, setOpenDocumentDeleteDialog]);

  const isEmail =
    selectedDocument?.stockDocument.metadata?.context?.type ===
    ExtractorContextTypes.EMAIL;
  const hasJson = selectedDocument?.stockDocument.parsedData;

  const isWebsite =
    selectedDocument?.stockDocument.metadata?.context?.type ===
      ExtractorContextTypes.WEBSITE &&
    selectedDocument?.stockDocument.metadata?.context?.pageContent;

  return (
    <>
      <ExtendedDialog
        maxWidth="xl"
        open={open}
        onCloseDialog={onClose}
        title={title}
        noActions={!setSelectedDocuments}
        onClickPrimaryButton={onClose}
        submitButtonCopy="Save"
      >
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            {children}
          </Grid>
          {/* Doc Picker Sidebar */}
          <Grid item xs={3}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={actionButtonSpacing}
            >
              {_.orderBy(documents, 'createdAt', 'desc').map((doc) => {
                const Icon = StockDocTypeIcons[doc.stockDocument.documentType];

                return (
                  <ClickableMainCard
                    key={doc.id}
                    boxShadow
                    highlight={selectedDocument?.id === doc.id}
                    contentSX={{ p: actionButtonSpacing }}
                    onClick={() => setSelectedDocumentId(doc.stockDocument.id)}
                  >
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        spacing={1}
                      >
                        <Grid item xs="auto">
                          {doc.stockDocument.processingStartedAt ? (
                            <Tooltip title="Queued for DocAI Processing">
                              <IconRotateClockwise2
                                size={20}
                                color={theme.palette.secondary.main}
                              />
                            </Tooltip>
                          ) : doc.stockDocument.processingStatus ===
                              StockDocumentProcessingStatus.SUBMITTED &&
                            doc.stockDocument.documentType !==
                              StockDocType.PACKAGE_PHOTO ? (
                            <Tooltip title="Processed with DocAI">
                              <IconFileTextSpark
                                size={20}
                                color={theme.palette.success.main}
                              />
                            </Tooltip>
                          ) : doc.stockDocument.processingStatus ===
                              StockDocumentProcessingStatus.SUBMITTED &&
                            doc.stockDocument.documentType ===
                              StockDocType.PACKAGE_PHOTO ? (
                            <Tooltip title="Processed with ReceiveAI">
                              <IconCameraSpark
                                size={20}
                                color={theme.palette.success.main}
                              />
                            </Tooltip>
                          ) : doc.stockDocument.needsReview ? (
                            <Tooltip title="Needs Review in DocAI Inbox">
                              <IconFileTextSpark
                                size={20}
                                color={theme.palette.warning.main}
                              />
                            </Tooltip>
                          ) : (
                            <Icon
                              size={20}
                              color={theme.palette.primary.main}
                            />
                          )}
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5" noWrap>
                            {_.get(
                              StockDocTypeLabels,
                              doc.stockDocument.documentType,
                              'Document'
                            )}
                          </Typography>
                        </Grid>
                        {!!setSelectedDocuments && (
                          <Grid item xs="auto">
                            <Checkbox
                              checked={selectedDocuments?.includes(
                                doc.stockDocument.id
                              )}
                              onChange={() =>
                                setSelectedDocuments(
                                  selectedDocuments?.includes(
                                    doc.stockDocument.id
                                  )
                                    ? _.filter(
                                        selectedDocuments || [],
                                        (d) => d !== doc.stockDocument.id
                                      )
                                    : [
                                        ...(selectedDocuments || []),
                                        doc.stockDocument.id,
                                      ]
                                )
                              }
                              onClick={(e) => e.stopPropagation()}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {!!doc.createdAt && (
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            sx={{ textAlign: 'right', width: '100%' }}
                          >
                            {doc.stockDocument.createdAt
                              ? formatToDateTime(doc.stockDocument.createdAt)
                              : ''}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {doc.stockDocument.metadata?.context?.senderEmail ||
                            doc.stockDocument.name}
                        </Typography>
                      </Grid>
                      {doc.stockDocument.metadata?.context?.type ===
                        ExtractorContextTypes.EMAIL && (
                        <Grid item xs={12}>
                          <Typography variant="body1" noWrap>
                            {doc.stockDocument.metadata?.context?.subject}
                          </Typography>
                        </Grid>
                      )}
                      {!!doc.documentExtractorName && (
                        <Grid item xs={12}>
                          <Typography variant="caption">
                            {doc.documentExtractorName}
                          </Typography>
                        </Grid>
                      )}
                      {!!doc.stockDocument.notes && (
                        <Grid item xs={12}>
                          <Typography variant="caption">
                            {doc.stockDocument.notes}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </ClickableMainCard>
                );
              })}
            </Stack>
          </Grid>
          {/* No Selected Doc */}
          {documents.length > 0 && !selectedDocument && (
            <Grid item xs={9}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%', width: '100%' }}
              >
                <Typography variant="h4">Select a Document</Typography>
                <Typography variant="body1">
                  Select a document from the side bar to view or download.
                </Typography>
              </Stack>
            </Grid>
          )}
          {/* Selected Doc */}
          {!!selectedDocument && (
            <Grid item xs={9}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={actionButtonSpacing}
              >
                {!!selectedDocument.createdAt && (
                  <Typography variant="body1">
                    {selectedDocument.stockDocument.metadata?.context?.type ===
                    ExtractorContextTypes.EMAIL
                      ? 'Received'
                      : selectedDocument.stockDocument.metadata?.context
                          ?.type === ExtractorContextTypes.SCAN
                      ? 'Scanned'
                      : 'Uploaded'}{' '}
                    at{' '}
                    {selectedDocument.stockDocument.createdAt
                      ? formatToDateTime(
                          selectedDocument.stockDocument.createdAt
                        )
                      : ''}
                  </Typography>
                )}
                {selectedDocument.stockDocument.metadata?.context?.type ===
                  ExtractorContextTypes.EMAIL && (
                  <Typography variant="h3">
                    {selectedDocument.stockDocument.metadata?.context?.subject}
                  </Typography>
                )}
                <MainCard>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography variant="h4">
                      {selectedDocument.stockDocument.name}
                    </Typography>
                    <SegmentIconButton
                      onClick={() =>
                        handleFetchDocument(
                          selectedDocument.id,
                          selectedDocument.stockDocument.document || ''
                        )
                      }
                    >
                      <IconFileDownload color={theme.palette.primary.main} />
                    </SegmentIconButton>
                    {!disableDelete && (
                      <SegmentIconButton
                        onClick={() => setOpenDocumentDeleteDialog(true)}
                      >
                        <IconTrash color={theme.palette.error.main} />
                      </SegmentIconButton>
                    )}
                    {allowPrinting && (
                      <SegmentIconButton
                        onClick={() =>
                          printStockDocumentRelatedObjects({
                            stockDocumentRelatedObjectIds: [
                              selectedDocument.id,
                            ],
                          })
                        }
                      >
                        <IconPrinter color={theme.palette.primary.main} />
                      </SegmentIconButton>
                    )}
                  </Stack>
                  {!isEmail &&
                    !hasJson &&
                    !!documentTempURL &&
                    !!selectedDocument?.stockDocument?.mimeType && (
                      <iframe
                        title="Document"
                        src={documentTempURL}
                        width="100%"
                        height="800px"
                      />
                    )}
                  {(isEmail || hasJson || isWebsite) && (
                    <ExtendedTabs
                      tabOptions={[
                        ...(selectedDocument.stockDocument.document
                          ? [
                              {
                                name: 'document',
                                label: 'Document',
                                icon: <IconPaperclip />,
                                contents: !!documentTempURL && (
                                  <iframe
                                    title="Document"
                                    src={documentTempURL}
                                    width="100%"
                                    height="800px"
                                  />
                                ),
                              },
                            ]
                          : []),
                        ...(isEmail
                          ? [
                              {
                                name: 'email',
                                label: 'Email',
                                icon: <IconMailOpened />,
                                contents:
                                  selectedDocument.stockDocument.metadata
                                    ?.context?.bodyHtml &&
                                  selectedDocument.stockDocument.metadata
                                    ?.context?.bodyHtml !== 'false' ? (
                                    <div>
                                      {ReactHtmlParser(
                                        decodeURIComponent(
                                          selectedDocument.stockDocument
                                            .metadata?.context?.bodyHtml
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                      {decodeURIComponent(
                                        selectedDocument.stockDocument.metadata
                                          ?.context?.bodyText || ''
                                      )}
                                    </Typography>
                                  ),
                                disabled:
                                  !selectedDocument.stockDocument.metadata
                                    ?.context?.bodyText &&
                                  !selectedDocument.stockDocument.metadata
                                    ?.context?.bodyHtml,
                              },
                            ]
                          : []),
                        ...(isWebsite
                          ? [
                              {
                                name: 'website',
                                label: 'Website',
                                icon: <IconWorld />,
                                contents: (
                                  <iframe
                                    title="Webpage"
                                    srcDoc={decodeURIComponent(
                                      selectedDocument.stockDocument.metadata
                                        ?.context?.pageContent || ''
                                    )}
                                    width="100%"
                                    height="800px"
                                    sandbox="allow-same-origin"
                                  />
                                ),
                              },
                            ]
                          : []),
                        ...(hasJson
                          ? [
                              {
                                name: 'json',
                                label: 'JSON Data',
                                icon: <IconCodeDots />,
                                contents: (
                                  <CodeMirror
                                    value={
                                      selectedDocument.stockDocument.parsedData
                                        ? JSON.stringify(
                                            selectedDocument.stockDocument
                                              .parsedData,
                                            null,
                                            2
                                          )
                                        : ''
                                    }
                                    extensions={[json()]}
                                    placeholder="No extracted data"
                                    editable={false}
                                  />
                                ),
                              },
                            ]
                          : []),
                      ]}
                    />
                  )}
                </MainCard>
              </Stack>
            </Grid>
          )}
        </Grid>
      </ExtendedDialog>
      {!!selectedDocument?.id && (
        <DeleteRecordDialog<StockDocumentRelatedObjectSchema>
          id={selectedDocument.id}
          dialogOpen={openDocumentDeleteDialog}
          onClose={() => {
            setOpenDocumentDeleteDialog(false);
          }}
          recordName="Document"
          useClientDeleteMutation={useDeleteStockDocumentRelatedObjectMutation}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default StockDocumentsDialog;
