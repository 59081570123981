import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import { DialogContentText, Alert, AlertTitle, Box } from '@mui/material';
import {
  useApproveKitRequestMutation,
  useUnapproveKitRequestMutation,
} from 'store/slices/clientV2/kitRequests';
import SidebarApproveButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarApproveButtonClientV2';
import { formatPrice } from 'utils/functions';
import { useIsCofactr } from 'hooks/useIsCofactr';
import { useGetPriceModelQuery } from 'store/slices/apiV1/purchasing';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { useSelector } from 'store';
import { useMemo, useEffect } from 'react';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import * as Yup from 'yup';
import {
  formatToShortDate,
  convertDate,
  formatToUniversalDate,
} from 'utils/dates';
import { KitRequestSchema, KitRequestValidationType } from 'types/kitting';
import { useTypedForm } from 'hooks/useTypedForm';
import { HookFormDatePicker } from 'ui-component/HookFormComponents';

const formConstants = {
  targetShipDate: {
    id: 'targetShipDate',
    label: 'Estimated Ship Date',
  },
};

const approveUnapproveKitRequestValidationSchema = Yup.object({
  [formConstants.targetShipDate.id]: Yup.date().required(
    `${formConstants.targetShipDate.label} is required`
  ),
});

export const ApproveUnapproveKitRequestDialog = () => {
  const tempKitApprovalDialog = useFeature(
    FeatureFlags.tempKitApprovalDialog
  ).on;
  const {
    estimatedHandlingRequestData,
    kitRequest,
    estimatedShipDateData,
    decoratedKitRequestLines,
    isLoadingKitRequest,
    isLoadingKitRequestLines,
    validations,
  } = useKitRequestContext();
  const { isCofactr } = useIsCofactr();
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const { data: priceModel } = useGetPriceModelQuery(
    org?.priceModel.id || skipToken
  );
  const estimatedCost = useMemo(
    () =>
      (estimatedHandlingRequestData?.numberOfReels ?? 0) *
      Number(priceModel?.handlingHandlingReel8 ?? 0),
    [estimatedHandlingRequestData, priceModel]
  );

  const hasValidationError = useMemo(
    () =>
      Object.values(validations.validations).some(
        (validation) =>
          validation.lines.length > 0 &&
          validation.validationType === KitRequestValidationType.ERROR
      ),
    [validations.validations]
  );

  const methods = useTypedForm(approveUnapproveKitRequestValidationSchema, {
    defaultValues: {
      targetShipDate: estimatedShipDateData.estimatedShipDate
        ? convertDate(estimatedShipDateData.estimatedShipDate)
        : undefined,
    },
  });

  useEffect(() => {
    methods.reset({
      targetShipDate: estimatedShipDateData.estimatedShipDate
        ? convertDate(estimatedShipDateData.estimatedShipDate)
        : undefined,
    });
  }, [estimatedShipDateData.estimatedShipDate]);

  const renderShipDateMessage = useMemo(() => {
    if (!estimatedShipDateData.estimatedShipDate) return null;

    const formattedEstimatedDate = formatToShortDate(
      estimatedShipDateData.estimatedShipDate
    );
    const formattedExpectedByDate = estimatedShipDateData.latestExpectedStockLot
      ?.expectedBy
      ? formatToShortDate(
          estimatedShipDateData.latestExpectedStockLot.expectedBy
        )
      : null;

    if (kitRequest?.shipPartial) {
      if (formattedExpectedByDate) {
        return (
          <>
            Based on the required handling and processing time, initial
            shipments will begin by {formattedEstimatedDate}.
            <Box component="div" sx={{ mt: 0.5 }}>
              <em>
                Note: Final shipment will be completed after receiving remaining
                stock expected by {formattedExpectedByDate}
              </em>
            </Box>
          </>
        );
      }
      return (
        <>
          Based on the required handling and processing time, this kit will ship
          by {formattedEstimatedDate}.
        </>
      );
    }

    if (formattedExpectedByDate) {
      return (
        <>
          This kit is configured to ship complete (no partial shipments).{' '}
          <Box component="div" sx={{ mt: 0.5, fontWeight: 'bold' }}>
            Shipment is blocked until all stock is received, with final stock
            lot expected by {formattedExpectedByDate}.
          </Box>
        </>
      );
    }

    return (
      <>
        Based on the required handling and processing time, we estimate this
        complete kit will ship by {formattedEstimatedDate}.
      </>
    );
  }, [estimatedShipDateData, kitRequest?.shipPartial]);

  const getApprovePayload = () => {
    const { estimatedShipDate } = estimatedShipDateData;
    const targetShipDate = methods.getValues(formConstants.targetShipDate.id);

    if (!targetShipDate || !estimatedShipDate) return undefined;

    const formattedTargetDate = formatToUniversalDate(targetShipDate);
    const basePayload = { estimatedShipDate };

    return formattedTargetDate > estimatedShipDate
      ? { ...basePayload, targetShipDate: formattedTargetDate }
      : basePayload;
  };

  return kitRequest ? (
    <SidebarApproveButtonClientV2<
      KitRequestSchema,
      typeof useApproveKitRequestMutation,
      typeof useUnapproveKitRequestMutation
    >
      idToUpdate={kitRequest.id}
      recordName="Kit"
      approved={!!kitRequest.approved}
      useClientApproveMutation={useApproveKitRequestMutation}
      useClientUnapproveMutation={useUnapproveKitRequestMutation}
      disabled={
        isLoadingKitRequest ||
        isLoadingKitRequestLines ||
        (!!kitRequest?.approved && !isCofactr) ||
        (!kitRequest?.approved &&
          (decoratedKitRequestLines ?? []).length === 0) ||
        (tempKitApprovalDialog && !kitRequest?.approved && hasValidationError)
      }
      additionalContent={
        !kitRequest.approved && (
          <Box>
            <form onSubmit={methods.handleSubmit(getApprovePayload)}>
              <DialogContentText>
                The cost to complete this kit is estimated to be{' '}
                {formatPrice(estimatedCost)}.{' '}
              </DialogContentText>
              <DialogContentText>
                <em>(This price is an estimate of the cost)</em>
              </DialogContentText>
              {tempKitApprovalDialog &&
                estimatedShipDateData.estimatedShipDate && (
                  <>
                    <Alert severity="info" sx={{ mt: 1 }}>
                      <AlertTitle>Estimated Ship Date</AlertTitle>
                      {renderShipDateMessage}
                    </Alert>
                    <HookFormDatePicker
                      name={formConstants.targetShipDate.id}
                      label={formConstants.targetShipDate.label}
                      control={methods.control}
                      errors={methods.formState.errors}
                      minDate={
                        estimatedShipDateData.estimatedShipDate
                          ? convertDate(estimatedShipDateData.estimatedShipDate)
                          : undefined
                      }
                    />
                  </>
                )}
            </form>
          </Box>
        )
      }
      tooltip={
        !!kitRequest.approved && !isCofactr
          ? 'If you need to unapprove this kit request, please contact Cofactr Support using the chat icon in the bottom right corner.'
          : tempKitApprovalDialog && !kitRequest?.approved && hasValidationError
          ? 'This kit cannot be approved until all validation errors have been resolved.'
          : undefined
      }
      getApprovePayload={getApprovePayload}
    />
  ) : null;
};
