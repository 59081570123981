import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, DragEvent, ChangeEvent } from 'react';
import _ from 'lodash';
import { IconFolderOpen } from '@tabler/icons-react';

// Used Mui's Button here instead of SegmentButton because of limitations with the component prop
// https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop

type BatchFileUploadInputProps = {
  batchFileMode: true;
  onBatchChange: (files: File[]) => void;
  onChange?: never;
};

type SingleFileUploadInputProps = {
  batchFileMode?: false;
  onChange: (file: File) => void;
  onBatchChange?: never;
};

type FileUploadInputProps = (
  | BatchFileUploadInputProps
  | SingleFileUploadInputProps
) & {
  defaultText?: string;
  acceptedExtensions?: string[];
  allowAllExtensions?: boolean;
  inlineVariant?: boolean;
  inlineVariantHeight?: 'sm' | 'md';
};

const FileUploadInput = ({
  onChange,
  onBatchChange,
  defaultText = 'Upload CSV, XLS, XLSX File',
  acceptedExtensions = ['csv', 'xlsx', 'xls'],
  allowAllExtensions,
  batchFileMode = false,
  inlineVariant = false,
  inlineVariantHeight = 'sm',
}: FileUploadInputProps) => {
  const theme = useTheme();
  const [fileName, setFileName] = useState<string | null>(null);
  const [filetypeError, setFiletypeError] = useState<string | undefined>(
    undefined
  );

  const handleFileChange = (newFile?: File) => {
    if (newFile && onChange) {
      setFileName(newFile.name);
      onChange(newFile);
    }
  };

  const handleBatchFileChange = (newFiles?: File[]) => {
    if (newFiles && onBatchChange) {
      onBatchChange(newFiles);
    }
  };

  const handleSetFiletypeError = () => {
    setFiletypeError(
      `Invalid file type. Please select a file with one of these extensions: ${_.chain(
        acceptedExtensions
      )
        .map((ext) => `.${ext}`)
        .join(', ')
        .value()}`
    );
  };

  const handleValidateFileType = (file: File) =>
    new RegExp(
      `([a-zA-Z0-9\\s_.\\-():])+(${_.chain(acceptedExtensions)
        .map((ext) => `.${ext}`)
        .join('|')
        .value()})$`,
      'i'
    ).test(file?.name ?? '');

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    setFiletypeError(undefined);
    e.preventDefault();
    if (batchFileMode) {
      const files = Array.from(e.dataTransfer.files);
      if (
        allowAllExtensions ||
        _.every(files, (file) => handleValidateFileType(file))
      ) {
        handleBatchFileChange(files);
      } else {
        handleSetFiletypeError();
      }
    } else {
      const firstFile = _.first(e.dataTransfer.files);
      if (
        allowAllExtensions ||
        (firstFile && handleValidateFileType(firstFile))
      ) {
        handleFileChange(_.first(e.dataTransfer.files));
      } else {
        handleSetFiletypeError();
      }
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFiletypeError(undefined);
    if (batchFileMode) {
      if (e.currentTarget.files) {
        handleBatchFileChange(Array.from(e.currentTarget.files));
      }
    } else {
      handleFileChange(_.first(e.currentTarget.files));
    }
  };

  return (
    <Stack
      direction={inlineVariant ? 'row' : 'column'}
      alignItems="center"
      justifyContent="center"
      spacing={2}
      textAlign="center"
      sx={{
        border: inlineVariant ? '1px dashed' : '2px dashed',
        borderRadius: '5px',
        height: inlineVariant
          ? { sm: '32px', md: '50px' }[inlineVariantHeight]
          : '100%',
        width: '100%',
        padding: 1,
      }}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(event) => event.preventDefault()}
    >
      <Typography
        sx={{ color: filetypeError ? 'red' : 'inherit' }}
        variant={
          inlineVariant && inlineVariantHeight === 'sm' ? 'caption' : undefined
        }
      >
        {filetypeError ?? fileName ?? defaultText}
      </Typography>
      {inlineVariant ? (
        <IconButton size="small" component="label">
          <IconFolderOpen color={theme.palette.primary.main} />
          <input
            type="file"
            onChange={onInputChange}
            style={{ display: 'none' }}
            accept={
              allowAllExtensions
                ? undefined
                : _.chain(acceptedExtensions)
                    .map((ext) => `.${ext}`)
                    .join(',')
                    .value()
            }
            multiple={batchFileMode}
          />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          component="label"
          startIcon={<IconFolderOpen />}
        >
          {batchFileMode ? 'Select Files' : 'Select File'}
          <input
            type="file"
            onChange={onInputChange}
            style={{ display: 'none' }}
            accept={
              allowAllExtensions
                ? undefined
                : _.chain(acceptedExtensions)
                    .map((ext) => `.${ext}`)
                    .join(',')
                    .value()
            }
            multiple={batchFileMode}
          />
        </Button>
      )}
    </Stack>
  );
};

export default FileUploadInput;
