import { useAppAccessContext } from 'contexts/AppAccessContext';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { RenderPartV2CellProps } from 'ui-component/DataGrid/Render/RenderPartV2Cell';
import { useSelector } from 'store';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import GenericPartAvatar from 'ui-component/extended/Avatars/GenericPartAvatar';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import { useTheme } from '@mui/material/styles';
import { removeNonAlphanumericOrPeriod } from 'utils/functions';
import { useMemo } from 'react';

const PartV2Cell = ({
  orgPart,
  publicPart,
  noPart,
  altCount,
  stockLot,
  searchParams,
  unapprovedAltCount,
}: RenderPartV2CellProps) => {
  const { isPermissionDisabled } = useAppAccessContext();
  const customPnsEnabled = !isPermissionDisabled({
    app: ALL_APPS.PART_LIBRARY.id,
    feature: ALL_FEATURE_IDS.CUSTOM_PNS,
  });
  const { activeOrg } = useSelector((state) => state.org);
  const theme = useTheme();

  const partCPID = orgPart?.cofactrId ?? publicPart?.cofactrId;
  const mpn = orgPart?.mpn ?? publicPart?.mpn;
  const description = orgPart?.description ?? publicPart?.description ?? '-';

  const splitSearchString = useMemo(
    () => _.chain(searchParams).split(' ').compact().value(),
    [searchParams]
  );

  const searchStringAltMatches = _.filter(orgPart?.aliasMpns || [], (a) =>
    _.some(splitSearchString, (s) =>
      _.includes(
        removeNonAlphanumericOrPeriod(a)?.toLowerCase(),
        removeNonAlphanumericOrPeriod(s)?.toLowerCase()
      )
    )
  );

  const searchHighlightString = (input: string) => {
    if (!searchParams) return input;
    const escapedSplitSearchString = _.map(splitSearchString, (s) =>
      _.replace(s, /[.*+?^${}()|[\]\\]/g, '\\$&')
    );
    const highlightRegex = _.join(escapedSplitSearchString, '|');
    return reactStringReplace(
      input,
      new RegExp(`(${highlightRegex})`, 'i'),
      (match, i) => (
        <span
          key={i}
          style={{ color: theme.palette.success.main, fontWeight: 'bold' }}
        >
          {match}
        </span>
      )
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        my: '4px',
      }}
    >
      <Grid container columnSpacing={1.5} alignItems="center">
        <Grid item xs="auto">
          <GenericPartAvatar
            partId={orgPart?.id ?? publicPart?.id}
            partHeroImage={
              orgPart?.heroImageUrl ??
              orgPart?.heroImageFile ??
              publicPart?.heroImage
            }
            partCPID={partCPID}
            altCount={altCount}
            stockLotId={typeof stockLot === 'string' ? stockLot : stockLot?.id}
            searchParams={searchParams}
            unapprovedAltCount={unapprovedAltCount}
            partType={orgPart?.partType}
          />
        </Grid>
        <Grid item xs sx={{ overflow: 'hidden' }}>
          <Typography variant="body1">
            {noPart
              ? 'Unable to find match for:'
              : searchHighlightString(
                  orgPart?.manufacturer ?? publicPart?.manufacturer ?? '-'
                )}
          </Typography>
          <Tooltip
            title={
              (orgPart?.aliasMpns || []).length > 0
                ? `Other MPN Variants for this Part: ${orgPart?.aliasMpns.join(
                    ', '
                  )}`
                : undefined
            }
          >
            <Typography variant="subtitle1">
              {searchHighlightString(mpn ?? noPart ?? '-')}
              {mpn && <CopyClipboard label="Part MPN" input={noPart || mpn} />}
            </Typography>
          </Tooltip>
          {customPnsEnabled && orgPart?.customId && (
            <Typography variant="subtitle1">
              {searchHighlightString(orgPart.customId)}
              <CopyClipboard
                label={`${activeOrg?.name || 'Custom'} Part Number`}
                input={orgPart.customId}
              />
            </Typography>
          )}
          <Tooltip title={description ?? ''}>
            <Typography noWrap variant="body1">
              {searchHighlightString(description)}
            </Typography>
          </Tooltip>
          {searchStringAltMatches.length > 0 &&
            !_.includes(
              orgPart?.mpn?.toLowerCase(),
              searchParams?.toLowerCase()
            ) && (
              <Typography sx={{ fontStyle: 'italic' }} variant="caption">
                This part is also known as:{' '}
                {searchHighlightString(searchStringAltMatches.join(', '))}
              </Typography>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartV2Cell;
