import {
  createUpdateKitRequestFormConstants,
  CreateKitRequestValidFormData,
} from 'views/kitting/components/constants';
import { getShipmentCarrierAndService } from 'utils/functions';
import { formatToUniversalDate } from 'utils/dates';
import { KitRequestPOSTSchema } from 'types/kitting';

export const createUpdateKitRequestPreSubmit = (
  data: CreateKitRequestValidFormData
): KitRequestPOSTSchema => {
  const { defaultShippingCarrier, defaultShippingService } =
    getShipmentCarrierAndService(
      data[createUpdateKitRequestFormConstants.shipMethod.id] || ''
    );

  const targetShipDate =
    data[createUpdateKitRequestFormConstants.targetShipDate.id];

  return {
    name: data[createUpdateKitRequestFormConstants.name.id] || '',
    sourceLocations:
      data[createUpdateKitRequestFormConstants.sourceLocations.id] || [],
    productionRun:
      data[createUpdateKitRequestFormConstants.productionRun.id] || null,
    shipTo: data[createUpdateKitRequestFormConstants.shipTo.id] || null,
    shipPartial:
      data[createUpdateKitRequestFormConstants.shipPartial.id] || false,
    targetShipDate: targetShipDate
      ? formatToUniversalDate(targetShipDate)
      : null,
    carrier: defaultShippingCarrier || null,
    service: defaultShippingService || null,
    team: data[createUpdateKitRequestFormConstants.team.id] || null,
  };
};
