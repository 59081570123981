import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import { useMemo, useState, ChangeEvent } from 'react';
import {
  GridToolbarContainer,
  GridSelectionModel,
  useGridApiRef,
  GridCellParams,
  gridClasses,
} from '@mui/x-data-grid-premium';
import {
  useUpdateKitRequestLinesMutation,
  useDeleteKitRequestLinesMutation,
} from 'store/slices/clientV2/kitRequests';
import { Stack } from '@mui/material';
import ExtendedQuickFilter, {
  ExtendedQuickFilterProps,
} from 'ui-component/DataGrid/ExtendedQuickFilter';
import _ from 'lodash';
import SimpleDatagrid from 'ui-component/DataGrid/SimpleDatagrid';
import KitScrubbingActionButtons from 'views/kitting/Kit/components/KitScrubbingDialog/components/KitScrubbingActionButtons';
import useSnackbar from 'hooks/useSnackbar';
import { makeColumns } from 'views/kitting/Kit/components/KitScrubbingDialog/columns';
import { useTheme } from '@mui/material/styles';
import {
  KitScrubbingDatagridClasses,
  KIT_SCRUBBING_COLUMNS,
} from 'views/kitting/Kit/components/KitScrubbingDialog/constants';
import FilterBar, { FilterOptionType } from 'ui-component/FilterBar';
import {
  KitRequestValidationNameIds,
  KitRequestValidationNameStrings,
} from 'types/kitting';
import { ChipColor } from 'ui-component/extended/Chip';

type KitScrubbingDialogProps = {
  open: boolean;
  onClose: () => void;
};

export type StagedChanges = {
  [id: string]: {
    targetQuantity?: number;
    minimumQuantity?: number;
    deleted?: boolean;
  };
};

const filterOptions: FilterOptionType[] = [
  {
    id: KitRequestValidationNameIds.UNAVAILABLE_PARTS,
    label:
      KitRequestValidationNameStrings[
        KitRequestValidationNameIds.UNAVAILABLE_PARTS
      ],
    color: ChipColor.error,
  },
  {
    id: KitRequestValidationNameIds.PARTIALLY_UNAVAILABLE_PARTS,
    label:
      KitRequestValidationNameStrings[
        KitRequestValidationNameIds.PARTIALLY_UNAVAILABLE_PARTS
      ],
    color: ChipColor.error,
  },
];

const KitScrubbingDialog = ({ open, onClose }: KitScrubbingDialogProps) => {
  const { decoratedKitRequestLines, validations, isLoadingKitRequestLines } =
    useKitRequestContext();
  const [searchText, setSearchText] = useState('');
  const [quickSearchFocused, setQuickSearchFocused] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const apiRef = useGridApiRef();

  const [stagedChanges, setStagedChanges] = useState<StagedChanges>({});

  const [updateKitRequestLines, { isLoading: isUpdating }] =
    useUpdateKitRequestLinesMutation();
  const [deleteKitRequestLines, { isLoading: isDeleting }] =
    useDeleteKitRequestLinesMutation();
  const { dispatchErrorSnackbar, dispatchSuccessSnackbar } = useSnackbar();

  const theme = useTheme();

  const resetState = () => {
    setSearchText('');
    setQuickSearchFocused(false);
    setSelectedRows([]);
    setStagedChanges({});
    setActiveFilters([]);
  };

  const handleDialogClose = () => {
    resetState();
    onClose();
  };

  const handleQuickFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterOptionClick = (filterOptionKey: string) => {
    setActiveFilters((prev) => {
      if (prev.includes(filterOptionKey)) {
        return prev.filter((key) => key !== filterOptionKey);
      }
      return [...prev, filterOptionKey];
    });
  };

  const resetFilters = () => {
    setActiveFilters([]);
  };

  // Filter to only show lines that have validation issues and merge with modifications
  const filteredLines = useMemo(() => {
    if (!decoratedKitRequestLines || validations.isLoading) return [];

    let baseLines = [];

    // If no filters are active, show all lines with any validation issues
    if (activeFilters.length === 0) {
      baseLines = decoratedKitRequestLines.filter(
        (line) =>
          validations.validations.unavailableParts.lines.includes(line.id) ||
          validations.validations.partiallyUnavailableParts?.lines.includes(
            line.id
          )
      );
    } else {
      // Otherwise, filter based on active filters
      baseLines = decoratedKitRequestLines.filter((line) =>
        activeFilters.some((filter) => {
          if (filter === KitRequestValidationNameIds.UNAVAILABLE_PARTS) {
            return validations.validations.unavailableParts.lines.includes(
              line.id
            );
          }
          if (
            filter === KitRequestValidationNameIds.PARTIALLY_UNAVAILABLE_PARTS
          ) {
            return validations.validations.partiallyUnavailableParts?.lines.includes(
              line.id
            );
          }
          return false;
        })
      );
    }

    // Merge with any modifications
    return baseLines.map((line) => ({
      ...line,
      ...stagedChanges[line.id],
    }));
  }, [decoratedKitRequestLines, validations, stagedChanges, activeFilters]);

  const handleAdjustTargetQuantity = (lineIds: string[]) => {
    setStagedChanges((prev) => {
      const updates = { ...prev };
      lineIds.forEach((id) => {
        const line = filteredLines.find((l) => l.id === id);
        if (line) {
          const totalAllocated = _.sumBy(line.allocations, 'quant');
          updates[id] = {
            ...updates[id],
            targetQuantity: totalAllocated,
            minimumQuantity: totalAllocated,
          };
        }
      });
      return updates;
    });
    setSelectedRows([]);
  };

  const handleDeleteLines = (lineIds: string[]) => {
    setStagedChanges((prev) => {
      const updates = { ...prev };
      lineIds.forEach((id) => {
        updates[id] = {
          ...updates[id],
          deleted: true,
        };
      });
      return updates;
    });
    setSelectedRows([]);
  };

  const handleSaveChanges = async () => {
    try {
      const linesToUpdate = Object.entries(stagedChanges)
        .filter(
          ([id, changes]) =>
            !changes.deleted && changes.targetQuantity !== undefined
        )
        .map(([id, changes]) => ({
          id,
          targetQuantity: changes.targetQuantity!,
          minimumQuantity: changes.minimumQuantity!,
        }));

      const linesToDelete = Object.entries(stagedChanges)
        .filter(([id, changes]) => changes.deleted)
        .map(([id]) => id);

      if (linesToUpdate.length > 0) {
        await updateKitRequestLines(linesToUpdate).unwrap();
      }

      if (linesToDelete.length > 0) {
        await deleteKitRequestLines({ ids: linesToDelete }).unwrap();
      }

      dispatchSuccessSnackbar('Successfully saved all changes');
      onClose();
      resetState();
    } catch (error) {
      dispatchErrorSnackbar(error as string);
    }
  };

  // Check if we have any staged changes
  const hasStagedChanges = Object.keys(stagedChanges).length > 0;

  const columns = useMemo(
    () =>
      makeColumns({
        stagedChanges,
      }),
    [stagedChanges]
  );

  const quickFilterProps: ExtendedQuickFilterProps = {
    searchText,
    setSearchText,
    handleChange: handleQuickFilterChange,
    quickSearchFocused,
    setQuickSearchFocused,
  };

  const CustomToolbar = (
    <GridToolbarContainer>
      <Stack
        direction="column"
        spacing={3}
        alignItems="stretch"
        sx={{ width: '100%', mb: 2 }}
      >
        <FilterBar
          filterOptions={filterOptions}
          onFilterOptionClick={handleFilterOptionClick}
          resetFilters={resetFilters}
          activeFilters={activeFilters}
        />
        <ExtendedQuickFilter {...quickFilterProps} />
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <KitScrubbingActionButtons
            selectedRows={selectedRows}
            filteredLines={filteredLines}
            onAdjustTargetQuantity={handleAdjustTargetQuantity}
            onDeleteLines={handleDeleteLines}
          />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );

  const getCellClassName = (params: GridCellParams) => {
    if (params.field === KIT_SCRUBBING_COLUMNS.ALLOCATED_QUANTITY) {
      const allocatedQuantity = _.sumBy(params.row.allocations, 'quant');
      if (allocatedQuantity === 0) {
        return KitScrubbingDatagridClasses.VALIDATION_ERROR;
      }
      return KitScrubbingDatagridClasses.VALIDATION_WARNING;
    }

    return '';
  };

  return (
    <ExtendedDialog
      title="Scrub Kit"
      open={open}
      onCloseDialog={handleDialogClose}
      maxWidth="xl"
      confirmClose={hasStagedChanges}
      confirmCloseBodyText="You have unsaved changes. Are you sure you want to close?"
      permissionScope={{
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      }}
      isForm
      onSubmit={handleSaveChanges}
      isSubmitting={isUpdating || isDeleting}
      submitDisabled={!hasStagedChanges}
      submitButtonCopy="Save Changes"
      sx={{
        [`.${gridClasses.cell}.${KitScrubbingDatagridClasses.VALIDATION_ERROR}`]:
          {
            border: '2px solid',
            borderColor: theme.palette.error.main,
            borderRadius: '4px',
          },
        [`.${gridClasses.cell}.${KitScrubbingDatagridClasses.VALIDATION_WARNING}`]:
          {
            border: '2px solid',
            borderColor: theme.palette.warning.main,
            borderRadius: '4px',
          },
      }}
    >
      <SimpleDatagrid
        loading={isLoadingKitRequestLines || validations.isLoading}
        columns={columns}
        rows={filteredLines}
        noRows="No validation issues found"
        showQuickSearch
        extendedQuickFilterProps={quickFilterProps}
        CustomToolbar={CustomToolbar}
        hideToolbar={false}
        disableColumnFilter
        disableColumnMenu
        apiRef={apiRef}
        onSelectionModelChange={setSelectedRows}
        checkboxSelection
        getCellClassName={getCellClassName}
      />
    </ExtendedDialog>
  );
};

export default KitScrubbingDialog;
