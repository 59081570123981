import _ from 'lodash';
import ExtendedDatagrid, {
  ExtendedDatagridProps,
} from 'ui-component/DataGrid/ExtendedDatagrid';
import { GenericResource, QueryParams } from 'types/api';
import {
  GridFilterModel,
  GridSortModel,
  GridLinkOperator,
  useGridApiRef,
  GridFilterItem,
  GridSortItem,
} from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import { nowShortDate } from 'utils/dates';
import {
  transformVisibleColumns,
  transformFilters,
} from 'ui-component/DataGrid/utils';
import useSavedDatagridState from 'hooks/useSavedDatagridState';
import { ExtendedGridFilterOperator } from 'ui-component/DataGrid/FilterOperators';
import { defaultServerPageSizeOptions } from 'constants/datagrid';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FetchArgs, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import useSnackbar from 'hooks/useSnackbar';
import { downloadFile } from 'utils/functions';

interface ServerSideDataGridProps<
  BaseResource extends GenericResource,
  ModifiedResource extends GenericResource = BaseResource
> extends ExtendedDatagridProps<ModifiedResource> {
  setSortModel: (sortModel: QueryParams<BaseResource>['sort']) => void;
  setFilterModel: (filterModel: QueryParams<BaseResource>['filters']) => void;
  setExcludeModel: (
    excludeModel: QueryParams<BaseResource>['excludes']
  ) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setQuickFilterValues: (quickFilterValues: string[]) => void;
  setSearchSchema?: (searchSchema: QueryParams<BaseResource>['schema']) => void;
  filterOverrides?: {
    columnField: string;
    value?: string;
    overrideTo: GridFilterItem | GridFilterItem[];
  }[];
  sortOverrides?: {
    columnField: string;
    overrideTo: string;
  }[];
  queryParams: QueryParams<BaseResource>;
  useExportHook: UseLazyQuery<
    QueryDefinition<
      QueryParams<BaseResource>,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      Blob
    >
  >;
  children?: ReactNode;
}

const ServerSideDataGrid = <
  BaseResource extends GenericResource,
  ModifiedResource extends GenericResource = BaseResource
>({
  columns,
  setSortModel,
  setFilterModel,
  setExcludeModel,
  setPage,
  setPageSize,
  setQuickFilterValues,
  setSearchSchema,
  queryParams,
  useExportHook,
  filterOverrides,
  sortOverrides,
  ...rest
}: ServerSideDataGridProps<BaseResource, ModifiedResource>) => {
  const apiRef = useGridApiRef();
  const { savedDatagridState } = useSavedDatagridState({
    gridName: rest.gridName,
    apiRef,
    shouldSaveDatagridState: true,
  });
  const { filterModel: savedFilterModel } = savedDatagridState?.filter || {};
  const { columnVisibilityModel: savedColumns } =
    savedDatagridState?.columns || {};
  const { dispatchErrorSnackbar } = useSnackbar();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [getExport] = useExportHook();
  const [exportSchema, setExportSchema] = useState<
    QueryParams<BaseResource>['schema']
  >(queryParams.schema);

  useEffect(() => {
    const applyFilterModel = () => {
      if (savedFilterModel) {
        handleFilterModelItemsChange(savedFilterModel);
      } else if (rest.initialFilter?.filterModel) {
        handleFilterModelItemsChange(rest.initialFilter.filterModel);
      }
    };

    const applyVisibleColumns = () => {
      if (savedColumns) {
        handleVisibleColumnsChange();
      } else if (rest.initialVisibleColumns) {
        const visibleColumnsArray = rest.initialVisibleColumns
          .filter((column) => columns.find((col) => col.field === column))
          .flatMap((key) => key.split('|'));

        const transformedVisibleColumns =
          transformVisibleColumns(visibleColumnsArray);
        setSearchSchema && setSearchSchema(transformedVisibleColumns);
        setExportSchema([
          ...transformedVisibleColumns,
          ...(queryParams?.filters?.map((filter) =>
            typeof filter !== 'string' && filter?.field ? filter?.field : ''
          ) || []),
          ...(queryParams?.excludes?.map((filter) =>
            typeof filter !== 'string' && filter?.field ? filter?.field : ''
          ) || []),
        ]);
      }
    };

    applyFilterModel();
    applyVisibleColumns();
  }, []);

  // This only exists to catch actual changes to the visibility model and also because I cant figure out why the model change is not
  // triggering the handleVisibleColumnsChange function
  const comparedColumnVisibilityModel = useMemo(
    () => JSON.stringify(savedColumns),
    [savedColumns]
  );

  useEffect(() => {
    comparedColumnVisibilityModel &&
      savedColumns &&
      handleVisibleColumnsChange();
  }, [comparedColumnVisibilityModel]);

  const handleVisibleColumnsChange = () => {
    // MUI has forced my hand here. We need to use the apiRef to get the visible columns, because model is empty when Show All is selected
    const visibleModel = apiRef?.current
      ?.getVisibleColumns()
      // we need to filter out the columns that are not in the columns, because we have other garbage, like the checkbox, and actions.
      .filter((column) => columns.find((col) => col.field === column.field))
      .reduce((acc: { [key: string]: boolean }, curr) => {
        acc[curr.field] = true;
        return acc;
      }, {});

    const visibleColumnsArray = _(visibleModel)
      .pickBy((value) => value)
      .keys()
      .flatMap((key) => key.split('|'))
      .value()
      .filter((key) => key !== 'actions');

    const transformedVisibleColumns =
      transformVisibleColumns(visibleColumnsArray);

    setSearchSchema && setSearchSchema(transformedVisibleColumns);
    setExportSchema([
      ...transformedVisibleColumns,
      ...(queryParams?.filters?.map((filter) =>
        typeof filter !== 'string' && filter?.field ? filter?.field : ''
      ) || []),
      ...(queryParams?.excludes?.map((filter) =>
        typeof filter !== 'string' && filter?.field ? filter?.field : ''
      ) || []),
    ]);
  };

  const handleFilterModelChange = ({
    items,
    linkOperator = GridLinkOperator.And,
    quickFilterValues,
  }: GridFilterModel) => {
    handleFilterModelItemsChange({ items, linkOperator });
    setQuickFilterValues(quickFilterValues || []);
  };

  const handleFilterModelItemsChange = ({
    items,
    linkOperator = GridLinkOperator.And,
  }: GridFilterModel) => {
    const appliedItems = items.flatMap((item) => {
      const override = filterOverrides?.find(
        (overrideItem) =>
          overrideItem.columnField === item.columnField &&
          (overrideItem.value === item.value ||
            overrideItem.value === undefined)
      );

      if (override) {
        const overrideInput = override.overrideTo;
        const overrideItems = Array.isArray(overrideInput)
          ? overrideInput
          : [overrideInput];

        return overrideItems.map((overrideItem) => ({
          ...item,
          columnField: overrideItem.columnField || item.columnField,
          operatorValue: overrideItem.operatorValue || item.operatorValue,
          value: Object.prototype.hasOwnProperty.call(overrideItem, 'value')
            ? overrideItem.value
            : item.value,
        }));
      }

      const appliedAsArray = columns.flatMap((column) => {
        if (column.field === item.columnField) {
          const operatorWithAppliedAs: ExtendedGridFilterOperator | undefined =
            column.filterOperators?.find(
              (operator) =>
                operator.value === item.operatorValue &&
                Object.prototype.hasOwnProperty.call(operator, 'applyAs')
            );

          // Check if applyAs is an array, if not, convert it to an array
          const applyAsArray = Array.isArray(operatorWithAppliedAs?.applyAs)
            ? operatorWithAppliedAs?.applyAs
            : [operatorWithAppliedAs?.applyAs];

          return applyAsArray || [];
        }
        return [];
      });

      if (appliedAsArray.length > 0) {
        return appliedAsArray.map((appliedAs) => ({
          ...item,
          ...appliedAs,
        }));
      }
      return item;
    });

    const isNotOrNotEqual = (item: GridFilterItem) =>
      item.operatorValue !== 'not' && item.operatorValue !== '!=';

    const filterModel = transformFilters(
      appliedItems.filter(isNotOrNotEqual),
      linkOperator
    ) as QueryParams<BaseResource>['filters'];

    const excludeModel = transformFilters(
      appliedItems.filter((item) => !isNotOrNotEqual(item)),
      linkOperator
    ) as QueryParams<BaseResource>['excludes'];

    setExcludeModel(excludeModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(
      newSortModel.flatMap((item: GridSortItem) => {
        const splitFields = item.field.split('|');
        return splitFields.map((field: GridSortItem['field']) => {
          const override = sortOverrides?.find(
            (overrideItem) => overrideItem.columnField === field.trim()
          );

          return {
            orderBy: override?.overrideTo || field.trim(),
            orderDirection: item.sort,
          };
        });
      }) as QueryParams<BaseResource>['sort']
    );
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const exportCsv = useCallback(async () => {
    try {
      setIsExportLoading(true);
      const result = await getExport({
        ...queryParams,
        schema: exportSchema,
      }).unwrap();
      if (result instanceof Blob) {
        downloadFile(
          result,
          `${rest.exportFileNamePrefix || 'CofactrExport'}_${nowShortDate()}`,
          'csv'
        );
      }
      setIsExportLoading(false);
    } catch (err) {
      dispatchErrorSnackbar('There was an issue exporting the data.');
      setIsExportLoading(false);
    }
  }, [getExport, queryParams, exportSchema]);

  return (
    <ExtendedDatagrid<ModifiedResource>
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      onPageChange={handlePageChange}
      onSortModelChange={handleSortModelChange}
      onFilterModelChange={handleFilterModelChange}
      onColumnVisibilityModelChange={handleVisibleColumnsChange}
      onPageSizeChange={handlePageSizeChange}
      columns={columns}
      exportCsv={exportCsv}
      isExportLoading={isExportLoading}
      apiRefServerSide={apiRef}
      pageSizeOptions={defaultServerPageSizeOptions}
      {...rest}
    />
  );
};

export default ServerSideDataGrid;
