import PartContextualSearchInputSingular from 'ui-component/PartContextualSearchInputSingular';
import { createKitRequestLineFormConstants } from 'views/kitting/Kit/components/constants';
import { HookFormInput } from 'ui-component/HookFormComponents';
import { useFormContext } from 'react-hook-form';
import { CustomProperty } from 'types/customProperty';
import _ from 'lodash';
import { HookFormCustomPropertyInput } from 'ui-component/HookFormComponents/HookFormCustomPropertyInput';
import { Stack } from '@mui/system';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import PartV2Picker from 'ui-component/PartV2Picker';
import { Box } from '@mui/material';

const CreateKitRequestFromPart = ({
  customProperties,
}: {
  customProperties?: CustomProperty[];
}) => {
  const tempParts2Ui = useFeature(FeatureFlags.tempParts2Ui).on;

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      {tempParts2Ui && (
        <Box sx={{ mb: 2 }}>
          <PartV2Picker
            onChange={(parts) => {
              setValue(
                createKitRequestLineFormConstants.v2OrgPart.id,
                parts?.[0]?.id ?? '',
                {
                  shouldValidate: true,
                }
              );
            }}
            errors={errors}
            name={createKitRequestLineFormConstants.v2OrgPart.id}
          />
        </Box>
      )}
      {!tempParts2Ui && (
        <PartContextualSearchInputSingular
          id={createKitRequestLineFormConstants.part.id}
          label={createKitRequestLineFormConstants.part.label}
          helperText={
            errors[createKitRequestLineFormConstants.part.id]
              ? `${errors[createKitRequestLineFormConstants.part.id]?.message}`
              : undefined
          }
          error={Boolean(errors[createKitRequestLineFormConstants.part.id])}
          onChange={(__, value: { id: string }[]) => {
            if (value && value.length) {
              setValue(createKitRequestLineFormConstants.part.id, value[0]?.id);
            } else {
              setValue(createKitRequestLineFormConstants.part.id, '');
            }
          }}
          sx={{ mb: 2 }}
        />
      )}
      <HookFormInput
        control={control}
        name={createKitRequestLineFormConstants.targetQuantityPart.id}
        label={createKitRequestLineFormConstants.targetQuantityPart.label}
        type="number"
        errors={errors}
      />
      {customProperties && (
        <Stack sx={{ mt: 2 }}>
          {_.map(customProperties, (property) => (
            <HookFormCustomPropertyInput
              control={control}
              errors={errors}
              key={property.key}
              customProperty={property}
              setValue={setValue}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export default CreateKitRequestFromPart;
