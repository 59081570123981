import { useState } from 'react';
import { PermissionScope } from 'types/apps';
import SidebarActionButton from 'ui-component/DetailLayout/SidebarComponents/SidebarActionButton';
import { IconThumbDown, IconThumbDownOff } from '@tabler/icons-react';

import { GenericResource, ToggleMutation } from 'types/api';
import ToggleRecordDialog, {
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';

interface SidebarRejectButtonProps<T extends GenericResource> {
  disabled?: boolean;
  permissionScope?: PermissionScope;
  voided: boolean;
  idToUpdate: string;
  recordName: string;
  message?: string;
  useClientVoidMutation: ToggleMutation<T>;
  useClientUnvoidMutation: ToggleMutation<T>;
}

/**
 * SidebarRejectButtonClientV2
 * To be used specifically with Client V2 api
 *
 * Rejected and voided are typically synonymous in the platform,
 * where rejected is the client-facing language.
 */
const SidebarRejectButtonClientV2 = <T extends GenericResource>({
  disabled,
  permissionScope,
  voided,
  idToUpdate,
  recordName,
  message,
  useClientVoidMutation,
  useClientUnvoidMutation,
}: SidebarRejectButtonProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SidebarActionButton
        Icon={voided ? IconThumbDownOff : IconThumbDown}
        label={voided ? 'Un-reject' : 'Reject'}
        tooltip={voided ? `Un-reject ${recordName}` : `Reject ${recordName}`}
        color={voided ? 'success' : 'error'}
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
        permissionScope={permissionScope}
      />
      <ToggleRecordDialog<
        T,
        typeof useClientVoidMutation,
        typeof useClientUnvoidMutation
      >
        id={idToUpdate}
        isCurrentlyTrue={voided}
        dialogOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        recordName={recordName}
        useToggleTrueMutation={useClientVoidMutation}
        useToggleFalseMutation={useClientUnvoidMutation}
        toggleActionType={ToggleRecordActionTypes.VOID}
        message={message}
      />
    </>
  );
};

export default SidebarRejectButtonClientV2;
