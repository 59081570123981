import { useEffect } from 'react';
import { DialogContentText } from '@mui/material';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { DeleteMutation, GenericResource } from 'types/api';
import { COLOR_OPTIONS } from 'types';
import { pluralizeNoun } from 'utils/functions';
import useDeleteRecords from 'hooks/clientV2/useDeleteRecords';

interface DeleteRecordsDialogProps<T extends GenericResource> {
  ids: string[];
  dialogOpen: boolean;
  onClose: () => void;
  recordName: string;
  message?: string;
  useClientDeleteMutation: DeleteMutation<T>;
  onSuccess?: () => void;
}

/**
 * DeleteDialog
 * To be used specifically with Client V2 api
 */
const DeleteRecordsDialog = <T extends GenericResource>({
  ids,
  dialogOpen,
  onClose,
  recordName,
  message,
  useClientDeleteMutation,
  onSuccess,
}: DeleteRecordsDialogProps<T>) => {
  const handleSuccess = () => {
    onSuccess && onSuccess();
    onClose();
  };

  const { handleDelete, isLoading, submitError, resetSubmitError } =
    useDeleteRecords<T>({
      useClientDeleteMutation,
      recordName,
      ids,
      onSuccess: handleSuccess,
    });

  useEffect(() => {
    if (!dialogOpen) {
      resetSubmitError();
    }
  }, [dialogOpen]);

  return (
    <ExtendedDialog
      title={`Delete ${ids.length.toLocaleString('en-US')} ${pluralizeNoun(
        recordName,
        ids.length
      )}`}
      open={dialogOpen}
      onCloseDialog={onClose}
      submitButtonCopy="Delete"
      submitButtonColor={COLOR_OPTIONS.error}
      onClickPrimaryButton={handleDelete}
      formSubmitError={submitError}
      isSubmitting={isLoading}
    >
      <DialogContentText>
        {message ||
          `Are you sure you want to delete ${ids.length.toLocaleString(
            'en-US'
          )} ${pluralizeNoun(recordName, ids.length)}?`}
      </DialogContentText>
    </ExtendedDialog>
  );
};

export default DeleteRecordsDialog;
