import { ExtendedGridColDef } from 'types/datagrid';
import { TagsRenderCell } from 'ui-component/DataGrid/Render';
import {
  tagsFilterOperators,
  getApplyFilterFnTags,
} from 'ui-component/DataGrid/FilterOperators';
import { tagsComparator } from 'ui-component/DataGrid/SortComparators';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { EditInlineTagsAutocomplete } from 'ui-component/HookFormComponents/InlineEditInputComponents';
import _ from 'lodash';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FlagshipModelOptions } from 'constants/recordTypes';

export const tagsColumnDef = (recordName: string): ExtendedGridColDef => ({
  field: 'tags',
  headerName: 'Tags',
  description: `Tags associated with this ${recordName}`,
  type: 'string',
  minWidth: 200,
  flex: 0.5,
  hideable: true,
  filterOperators: tagsFilterOperators,
  renderCell: TagsRenderCell,
  sortComparator: tagsComparator,
  getApplyQuickFilterFn: getApplyFilterFnTags,
});

export const editableTagsColumnDef = (
  recordName: string,
  updateMutation: UseMutation<any>,
  tagModel: FlagshipModelOptions
): ExtendedGridColDef => ({
  field: 'tags',
  headerName: 'Tags',
  description: `Tags associated with this ${recordName}`,
  type: 'string',
  minWidth: 200,
  flex: 0.5,
  hideable: true,
  filterOperators: tagsFilterOperators,
  renderCell: TagsRenderCell,
  sortComparator: tagsComparator,
  getApplyQuickFilterFn: getApplyFilterFnTags,
  editable: true,
  inlineEditRenderCell: true,
  useMutation: updateMutation,
  getMutationDefaultValue: (params: GridRenderEditCellParams) =>
    _.map(params.row.tags || [], 'id') as string[],
  mutationInputElement: EditInlineTagsAutocomplete,
  additionalInputElementProps: {
    tagModel,
  },
  mutationAsClientV2: true,
});
