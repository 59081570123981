import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ListResponse,
  QueryParams,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  ClientV2PATCHResponse,
} from 'types/api';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';
import {
  PrintStockDocumentRelatedObjectRequest,
  StockDocumentRelatedObjectPOSTSchema,
  StockDocumentRelatedObjectSchema,
} from 'types/clientV2/stockDocuments';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStockDocumentRelatedObjects: builder.query<
      ListResponse<StockDocumentRelatedObjectSchema>,
      QueryParams<StockDocumentRelatedObjectSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<StockDocumentRelatedObjectSchema>,
        QueryParams<StockDocumentRelatedObjectSchema>
      >((params) =>
        CLIENT_V2_URLS.STOCK_DOCUMENT_RELATED_OBJECTS(
          parseQueryParams<StockDocumentRelatedObjectSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: StockDocumentRelatedObjectSchema) =>
                  ({ type: 'StockDocumentRelatedObjects', id } as const)
              ),
              { type: 'StockDocumentRelatedObjects', id: 'LIST' },
            ]
          : [{ type: 'StockDocumentRelatedObjects', id: 'LIST' }],
    }),
    createStockDocumentRelatedObject: builder.mutation<
      ClientV2POSTResponse<StockDocumentRelatedObjectSchema>,
      ClientV2POSTRequest<StockDocumentRelatedObjectPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<StockDocumentRelatedObjectSchema>,
        ClientV2POSTRequest<StockDocumentRelatedObjectPOSTSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.STOCK_DOCUMENT_RELATED_OBJECTS(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'StockDocumentRelatedObjects', id: 'LIST' }],
    }),
    deleteStockDocumentRelatedObject: builder.mutation<
      ClientV2DELETEResponse<StockDocumentRelatedObjectSchema>,
      ClientV2DELETERequest<StockDocumentRelatedObjectSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.STOCK_DOCUMENT_RELATED_OBJECTS(),
        method: 'DELETE',
        body: { ids: data.ids },
      }),
      invalidatesTags: [{ type: 'StockDocumentRelatedObjects', id: 'LIST' }],
    }),
    printStockDocumentRelatedObject: builder.mutation<
      ClientV2PATCHResponse<StockDocumentRelatedObjectSchema>,
      PrintStockDocumentRelatedObjectRequest
    >({
      query: (payload) => ({
        url: CLIENT_V2_URLS.PRINT_STOCK_DOCUMENT_RELATED_OBJECTS,
        method: 'POST',
        body: payload,
      }),
    }),
    downloadStockDocumentRelatedObject: builder.query<
      string | Blob | ArrayBufferView | ArrayBuffer,
      StockDocumentRelatedObjectSchema['id']
    >({
      query: (documentId) => ({
        url: CLIENT_V2_URLS.DOWNLOAD_STOCK_DOCUMENT_RELATED_OBJECT(documentId),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetStockDocumentRelatedObjectsQuery,
  useCreateStockDocumentRelatedObjectMutation,
  useDeleteStockDocumentRelatedObjectMutation,
  usePrintStockDocumentRelatedObjectMutation,
  useDownloadStockDocumentRelatedObjectQuery,
  useLazyDownloadStockDocumentRelatedObjectQuery,
} = extendedApiSlice;
