import { FormInputData, ValidFormData } from 'hooks/useTypedForm';
import * as Yup from 'yup';

export const createUpdateKitRequestFormConstants = {
  name: { label: 'Name', id: 'name' as const },
  sourceLocations: { label: 'Kit From', id: 'sourceLocations' as const },
  productionRun: { label: 'Related Program', id: 'productionRun' as const },
  team: { label: 'Team', id: 'team' as const },
  shipTo: { label: 'Destination Facility', id: 'shipTo' as const },
  shipMethod: { label: 'Ship Method', id: 'shipMethod' as const },
  shipPartial: {
    label:
      'Send a partial shipment on the target ship date if only some parts are available to ship',
    id: 'shipPartial' as const,
  },
  targetShipDate: { label: 'Target Ship Date', id: 'targetShipDate' as const },
  internalNotes: { label: 'Internal Notes', id: 'internalNotes' as const },
  externalNotes: { label: 'Notes', id: 'externalNotes' as const },
};

export const createUpdateKitRequestValidationSchema = Yup.object({
  [createUpdateKitRequestFormConstants.name.id]: Yup.string().required(
    `${createUpdateKitRequestFormConstants.name.label} is required`
  ),
  [createUpdateKitRequestFormConstants.sourceLocations.id]: Yup.array(
    Yup.string().defined().required()
  )
    .min(
      1,
      `At least one ${createUpdateKitRequestFormConstants.sourceLocations.label} is required`
    )
    .required(
      `${createUpdateKitRequestFormConstants.sourceLocations.label} is required`
    ),
  [createUpdateKitRequestFormConstants.productionRun.id]:
    Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.team.id]: Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.shipTo.id]: Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.shipMethod.id]: Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.shipPartial.id]:
    Yup.boolean().nullable(),
  [createUpdateKitRequestFormConstants.targetShipDate.id]: Yup.date()
    .transform((currentValue, originalValue) =>
      originalValue === '' ? null : currentValue
    )
    .nullable(),
});

export type CreateKitRequestValidFormData = ValidFormData<
  typeof createUpdateKitRequestValidationSchema
>;

export type CreateKitRequestFormInputData = FormInputData<
  typeof createUpdateKitRequestValidationSchema
>;
