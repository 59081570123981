import { Workbook } from 'exceljs';
import {
  BomLinesLinesTableV2Data,
  BomPurchasingSelectsData,
  ProductionPurchasingSelectsData,
  ProgramPurchaseOrdersGenerateReportData,
  SnapshotApprovalGenerateReportData,
} from 'types/generateReports';
import _ from 'lodash';
import { convertDate, formatDaysToDaysOrWeeks } from 'utils/dates';
import { Part } from 'types/part';
import { pluralizeNoun, pluralizeWordOption } from 'utils/functions';
import { format } from 'date-fns';
import {
  calcBestUnitPrice,
  calcFactoryLead,
  calcMinLead,
  calcReferenceBreakPrice,
} from 'utils/offerCalculations';

export type ReportPreProcessor = (
  inputData: any,
  metadata: any
) => any | undefined | null;

export type ReportPostProcessor =
  | ((inputWorkbook: Workbook, mergeData: any, metadata: any) => Workbook)
  | undefined
  | null;

export const validatePreProcessor = (preProcessor: any): boolean => {
  if (typeof preProcessor !== 'function') return false;
  // Check if argument count is within bounds
  if (!(preProcessor.length > 0 && preProcessor.length <= 2)) return false;
  return true;
};

export const validatePostProcessor = (postProcessor: any): boolean => {
  if (typeof postProcessor !== 'function') return false;
  // Check if argument count is within bounds
  if (!(postProcessor.length > 0 && postProcessor.length <= 3)) return false;
  return true;
};

// utility scripts
const calcPurchasingLineNotes = ({
  isBackordered,
  isUncertainLead,
  availableFromBrokers,
  availableFromBrokersSooner,
  suggestedAlts,
  availableNowQuant,
}: {
  isBackordered?: boolean;
  isUncertainLead?: boolean;
  availableFromBrokers?: boolean;
  availableFromBrokersSooner?: boolean;
  suggestedAlts?: number;
  availableNowQuant?: number;
}) => {
  const notes = [];
  if (availableNowQuant) {
    notes.push(
      `${availableNowQuant.toLocaleString('en-US')}${pluralizeNoun(
        'pc',
        availableNowQuant
      )} are available now.`
    );
  }
  if (isBackordered) {
    notes.push(
      `Lead times for${
        availableNowQuant ? ' the remaining' : ''
      } backordered parts are subject to change.`
    );
  }
  if (isUncertainLead) {
    notes.push(
      'Ship and transit times can vary with this distributor due to international warehouse locations.'
    );
  }
  if (availableFromBrokers && !availableFromBrokersSooner) {
    notes.push('Part is available via broker market.');
  }
  if (availableFromBrokersSooner) {
    notes.push('Part is available via broker market sooner.');
  }
  if (suggestedAlts) {
    notes.push(
      `Suggested ${pluralizeNoun('alt', suggestedAlts)} ${pluralizeWordOption(
        'is',
        'are',
        suggestedAlts
      )} available now.`
    );
  }
  return notes.join(' ');
};

// preProcessor Scripts

const bomPurchasingDefault = (
  inputData: BomPurchasingSelectsData,
  metadata: { markup: number }
) => {
  // merge bomline data into purchase data
  let mergedData = _.map(inputData?.selectedOption?.parts || [], (p) => ({
    ...p,
    displayStatus:
      p.status === 'buyable'
        ? 'Available'
        : p.minimumLead
        ? 'Extended Lead'
        : 'Need to Quote',
    bomline: _.find(inputData.rowData, (r) => r.id === p.requestLineId),
    formattedMinimumLead: `${formatDaysToDaysOrWeeks(
      p.minimumLead,
      'business day'
    )}`,
    formattedAlts: _.chain(inputData.rowData)
      .find((r) => r.id === p.requestLineId)
      .get('alts')
      .map((a: Part) => `${a.mfg} ${a.mpn}`)
      .join(', ')
      .value(),
    formattedUnapprovedAlts: _.chain(inputData.rowData)
      .find((r) => r.id === p.requestLineId)
      .get('unapprovedAlts')
      .map((a: Part) => `${a.mfg} ${a.mpn}`)
      .join(', ')
      .value(),
    suppliers: _.chain(p.breakdown).map('sourceName').join(', ').value(),
    notes: calcPurchasingLineNotes({
      isBackordered: _.some(p.breakdown, { isBackordered: true }),
      isUncertainLead: _.some(p.breakdown, { isUncertainLead: true }),
    }),
  }));

  // update pricing for parts that are only available at slower lead times
  mergedData.forEach((p) => {
    if (p.minimumLead && !p.landedPrice) {
      const partOption = _.chain(inputData?.availabilities?.options || [])
        .find({ lead: p.minimumLead })
        .get('parts')
        .find({ requestLineId: p.requestLineId })
        .value();
      p.landedPrice = partOption.landedPrice;
      p.suppliers = _.chain(partOption)
        .get('breakdown')
        .map('sourceName')
        .join(', ')
        .value();
      p.notes = calcPurchasingLineNotes({
        isBackordered: _.some(partOption.breakdown, { isBackordered: true }),
        isUncertainLead: _.some(partOption.breakdown, {
          isUncertainLead: true,
        }),
        availableNowQuant: p.bomline?.buyable,
        availableFromBrokers:
          (p.bomline?.quotable || 0) + (p.bomline?.maybe || 0) >= p.totalQuant,
        availableFromBrokersSooner:
          (p.bomline?.quotable || 0) + (p.bomline?.maybe || 0) >=
            p.totalQuant && !!p.formattedMinimumLead,
        suggestedAlts: p.bomline?.unapprovedAlts.length,
      });
    }
  });

  // apply markup to landed price
  mergedData.forEach((p) => {
    if (p.landedPrice) {
      p.landedPrice *= 1 + metadata.markup;
    }
  });

  // sort mergedData by status
  mergedData = _.sortBy(mergedData, [
    (r) =>
      ({ Available: 3, 'Extended Lead': 2, 'Need to Quote': 1 }[
        r.displayStatus
      ]),
    'bomline.shortRefdes',
  ]);

  const selectedLeadWithKitting =
    (inputData?.selectedOption?.kittingEstimate?.shippingLead || 0) +
    1 +
    (inputData?.selectedOption?.lead || 0);
  const totalKittingCost =
    (inputData?.selectedOption?.kittingEstimate?.kittingCost || 0) -
    (inputData?.selectedOption?.kittingEstimate?.shippingCost || 0);

  return {
    ...inputData,
    mergedData,
    selectedLeadWithKitting,
    totalKittingCost,
  };
};

const productionRunPurchasingDefault = (
  inputData: ProductionPurchasingSelectsData,
  metadata: { markup: number }
) => {
  // merge part row data into purchase data
  let mergedData = _.map(inputData?.selectedOption?.parts || [], (p) => ({
    ...p,
    displayStatus:
      p.status === 'buyable'
        ? 'Available'
        : p.minimumLead
        ? 'Extended Lead'
        : 'Need to Quote',
    rowData: _.find(inputData.rowData, (r) => r.id === p.part.id),
    formattedMinimumLead: `${formatDaysToDaysOrWeeks(
      p.minimumLead,
      'business day'
    )}`,
    formattedAlts: _.chain(inputData.rowData)
      .find((r) => r.id === p.part.id)
      .get('alts')
      .map((a: Part) => `${a.mfg} ${a.mpn}`)
      .join(', ')
      .value(),
    formattedUnapprovedAlts: _.chain(inputData.rowData)
      .find((r) => r.id === p.part.id)
      .get('unapprovedAlts')
      .map((a: Part) => `${a.mfg} ${a.mpn}`)
      .join(', ')
      .value(),
    suppliers: _.chain(p.breakdown).map('sourceName').join(', ').value(),
    notes: calcPurchasingLineNotes({
      isBackordered: _.some(p.breakdown, { isBackordered: true }),
      isUncertainLead: _.some(p.breakdown, { isUncertainLead: true }),
    }),
    raw: {},
    usedInRefDes: '',
  }));

  // calculate related BOM fields
  mergedData.forEach((p) => {
    p.raw = _.first(p.rowData?.relatedBomLines)?.raw || {};
    p.usedInRefDes = _.chain(p.rowData?.relatedBomLines)
      .map(
        (bl) =>
          `${_.find(p.rowData?.relatedBoms, (b) => b.id === bl.bom)?.name}: ${
            bl.shortRefdes
          }`
      )
      .join(', ')
      .value();
  });
  const relatedBomsWithQuants = _.chain(inputData.productionRun?.lines || [])
    .map((l) => `${(l.quant || 0).toLocaleString('en-US')}x ${l.bom.name}`)
    .join(', ')
    .value();

  // update pricing for parts that are only available at slower lead times
  mergedData.forEach((p) => {
    if (p.minimumLead && !p.landedPrice) {
      const partOption = _.chain(inputData?.availabilities?.options || [])
        .find({ lead: p.minimumLead })
        .get('parts')
        .find({ requestLineId: p.requestLineId })
        .value();
      p.landedPrice = partOption.landedPrice;
      p.suppliers = _.chain(partOption)
        .get('breakdown')
        .map('sourceName')
        .join(', ')
        .value();
      p.notes = calcPurchasingLineNotes({
        isBackordered: _.some(partOption.breakdown, { isBackordered: true }),
        isUncertainLead: _.some(partOption.breakdown, {
          isUncertainLead: true,
        }),
        availableNowQuant: p.rowData?.buyable,
        availableFromBrokers:
          (p.rowData?.quotable || 0) + (p.rowData?.maybe || 0) >= p.totalQuant,
        availableFromBrokersSooner:
          (p.rowData?.quotable || 0) + (p.rowData?.maybe || 0) >=
            p.totalQuant && !!p.formattedMinimumLead,
        suggestedAlts: p.rowData?.unapprovedAlts.length,
      });
    }
  });

  // apply markup to landed price
  mergedData.forEach((p) => {
    if (p.landedPrice) {
      p.landedPrice *= 1 + metadata.markup;
    }
  });

  // sort mergedData by status
  mergedData = _.sortBy(mergedData, [
    (r) =>
      ({ Available: 3, 'Extended Lead': 2, 'Need to Quote': 1 }[
        r.displayStatus
      ]),
    'part.mpn',
  ]);

  return {
    ...inputData,
    mergedData,
    relatedBomsWithQuants,
  };
};

const snapshotApprovalDefault = (
  inputData: SnapshotApprovalGenerateReportData
) => {
  const snapshot = inputData?.snapshot;
  const suppliers = inputData?.suppliers;
  const mergedData = _.map(snapshot?.details || [], (detail) => {
    const supplier = _.find(suppliers, { id: detail.sourceId });
    return {
      supplier: supplier?.name || '',
      description: detail.description,
      unitPrice: detail.unitPrice?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      }),
      quant: detail.quant?.toLocaleString('en-US'),
      totalPrice: detail.price.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      }),
    };
  });

  const kittingTotal =
    (Number(snapshot?.kittingEstimateRaw?.packingCost) || 0) +
    (Number(snapshot?.kittingEstimateRaw?.handlingRequestCost) || 0);

  return {
    ...inputData,
    mergedData,
    kittingTotal,
    snapshot,
  };
};

const purchaseOrderProgramHistorySap = (
  inputData: ProgramPurchaseOrdersGenerateReportData
) => {
  // add indices to purchaseOrders
  const purchaseOrders = _.map(inputData.purchaseOrders, (po, i) => {
    const documentDueDate = _.chain(inputData.purchaseOrderLines)
      .filter({ purchase: po.id })
      .map((pl) => (pl.needDeliveryBy ? convertDate(pl.needDeliveryBy) : null))
      .compact()
      .max()
      .value();
    return {
      ...po,
      index: i + 1,
      documentDate: po.createdAt
        ? format(convertDate(po.createdAt), 'yyyyMMdd')
        : '',
      documentDueDate: documentDueDate
        ? format(documentDueDate, 'yyyyMMdd')
        : '',
      productionRun: inputData.productionRun,
    };
  });

  // add indices to purchaseOrderLines
  const purchaseOrderLines = _.chain(inputData.purchaseOrderLines)
    .orderBy('part.mpn')
    .map((pl, i) => {
      const rowData = _.find(
        inputData.rowData,
        (r) =>
          r.part.id === pl.part.id ||
          _.includes(_.map(r.alts, 'id'), pl.part.id)
      );
      return {
        ..._.omit(pl, ['purchaseOrder']),
        purchaseOrder: _.find(
          purchaseOrders,
          (po) => po.id === pl.purchaseOrder.id
        ),
        index: i + 1,
        rowData,
        relatedBomLine: _.first(rowData?.relatedBomLines),
        unitPrice: _.toNumber(pl.lineTotalPaid) / _.toNumber(pl.quant),
        firstRefDes: _.first(_.first(rowData?.relatedBomLines)?.refdes) || '',
      };
    })
    .value();

  return {
    productionRun: inputData.productionRun,
    rowData: inputData.rowData,
    purchaseOrderLines,
    purchaseOrders,
  };
};

const bomCommonPriceBreaks = (inputData: BomLinesLinesTableV2Data) => {
  const rowData = _.map(
    _.filter(inputData.rowData, (r) => !!r.part),
    (r) => {
      const allOffers = _.chain([
        r.supply?.offers || [],
        ..._.chain(r.altsSupply)
          .filter((s) => _.chain(r.alts).map('id').includes(s.id).value())
          .map('offers')
          .value(),
      ])
        .flatten()
        .value();

      const allReferencePrices = _.chain([
        r.supply?.offers || [],
        ..._.chain(r.altsSupply)
          .filter((s) => _.chain(r.alts).map('id').includes(s.id).value())
          .map('offers')
          .value(),
      ])
        .compact()
        .flatten()
        .map('prices')
        .flatten()
        .map((p) => ({
          quantity: p.priceBreak,
          price: p.price,
          currency: 'USD',
          convertedPrice: p.price,
          convertedCurrency: 'USD',
          conversionRate: 1,
        }))
        .value();

      const factoryLead = calcFactoryLead({ offers: allOffers });
      return {
        ...r,
        firstAlt: _.first(r.alts),
        moq: _.chain(allOffers).map('moq').min().value() || 1,
        multiple: _.chain(allOffers).map('multiple').max().value() || 1,
        priceAt100:
          calcBestUnitPrice({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100 * r.quant,
            ignoreReportedStock: true,
          }) || calcReferenceBreakPrice(allReferencePrices, 100 * r.quant),
        leadAt100:
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100 * r.quant,
          }) || factoryLead,
        formattedLeadAt100: formatDaysToDaysOrWeeks(
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100 * r.quant,
          }) || factoryLead,
          'business day',
          'week',
          false,
          true
        ),
        priceAt1000:
          calcBestUnitPrice({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 1000 * r.quant,
            ignoreReportedStock: true,
          }) || calcReferenceBreakPrice(allReferencePrices, 1000 * r.quant),
        leadAt1000:
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 1000 * r.quant,
          }) || factoryLead,
        formattedLeadAt1000: formatDaysToDaysOrWeeks(
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 1000 * r.quant,
          }) || factoryLead,
          'business day',
          'week',
          false,
          true
        ),
        priceAt5000:
          calcBestUnitPrice({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 5000 * r.quant,
            ignoreReportedStock: true,
          }) || calcReferenceBreakPrice(allReferencePrices, 5000 * r.quant),
        leadAt5000:
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 5000 * r.quant,
          }) || factoryLead,
        formattedLeadAt5000: formatDaysToDaysOrWeeks(
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 5000 * r.quant,
          }) || factoryLead,
          'business day',
          'week',
          false,
          true
        ),
        priceAt25000:
          calcBestUnitPrice({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 25000 * r.quant,
            ignoreReportedStock: true,
          }) || calcReferenceBreakPrice(allReferencePrices, 25000 * r.quant),
        leadAt25000:
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 25000 * r.quant,
          }) || factoryLead,
        formattedLeadAt25000: formatDaysToDaysOrWeeks(
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 25000 * r.quant,
          }) || factoryLead,
          'business day',
          'week',
          false,
          true
        ),
        priceAt100000:
          calcBestUnitPrice({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100000 * r.quant,
            ignoreReportedStock: true,
          }) || calcReferenceBreakPrice(allReferencePrices, 100000 * r.quant),
        leadAt100000:
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100000 * r.quant,
          }) || factoryLead,
        formattedLeadAt100000: formatDaysToDaysOrWeeks(
          calcMinLead({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100000 * r.quant,
          }) || factoryLead,
          'business day',
          'week',
          false,
          true
        ),
        supplierQuality:
          calcBestUnitPrice({
            part: r.part as Part,
            supply: r.supply,
            altsSupply: _.filter(r.altsSupply, (s) =>
              _.chain(r.alts).map('id').includes(s.id).value()
            ),
            quant: 100000 * r.quant,
            ignoreReportedStock: true,
          }) > 0
            ? 'Standard'
            : 'Unverified',
      };
    }
  );
  return {
    ...inputData,
    rowData,
    priceAt100: _.chain(rowData).map('priceAt100').sum().value(),
    priceAt1000: _.chain(rowData).map('priceAt1000').sum().value(),
    priceAt5000: _.chain(rowData).map('priceAt5000').sum().value(),
    priceAt25000: _.chain(rowData).map('priceAt25000').sum().value(),
    priceAt100000: _.chain(rowData).map('priceAt100000').sum().value(),
    leadAt100: _.chain(rowData).map('leadAt100').max().value(),
    leadAt1000: _.chain(rowData).map('leadAt1000').max().value(),
    leadAt5000: _.chain(rowData).map('leadAt5000').max().value(),
    leadAt25000: _.chain(rowData).map('leadAt25000').max().value(),
    leadAt100000: _.chain(rowData).map('leadAt100000').max().value(),
    formattedLeadAt100: formatDaysToDaysOrWeeks(
      _.chain(rowData).map('leadAt100').max().value(),
      'business day',
      'week',
      false,
      true
    ),
    formattedLeadAt1000: formatDaysToDaysOrWeeks(
      _.chain(rowData).map('leadAt1000').max().value(),
      'business day',
      'week',
      false,
      true
    ),
    formattedLeadAt5000: formatDaysToDaysOrWeeks(
      _.chain(rowData).map('leadAt5000').max().value(),
      'business day',
      'week',
      false,
      true
    ),
    formattedLeadAt25000: formatDaysToDaysOrWeeks(
      _.chain(rowData).map('leadAt25000').max().value(),
      'business day',
      'week',
      false,
      true
    ),
    formattedLeadAt100000: formatDaysToDaysOrWeeks(
      _.chain(rowData).map('leadAt100000').max().value(),
      'business day',
      'week',
      false,
      true
    ),
  };
};

// postProcessor Scripts

// script constants

export const preProcessorScripts: {
  [key: string]: ReportPreProcessor;
} = {
  bomPurchasingDefault,
  productionRunPurchasingDefault,
  snapshotApprovalDefault,
  purchaseOrderProgramHistorySap,
  bomCommonPriceBreaks,
};

export const postProcessorScripts: {
  [key: string]: ReportPostProcessor;
} = {};
