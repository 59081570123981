export const getTrackingStrings = () => ({
  createOrder: 'Create Order',
  closeOrderConfirmation: 'Close Order Confirmation Dialog',
  submitOrderConfirmation: 'Submit Order Confirmation',
  shipmentSolver: 'Shipment Solver Dialog',
  closeSpotBuy: 'Close Spot Buy Dialog',
  userAccountNotifications: 'User Account Notifications',
  userAccount: 'User Account',
  bomlineCreate: 'Bomline Create Dialog',
  boms: 'Boms',
  confirmInspection: 'Confirm Inspection Dialog',
  openCreateNewStockLot: 'Open Create New Stock Lot Dialog',
  closeCreateNewStockLot: 'Close Create New Stock Lot Dialog',
  newStockLot: 'New Stock Lot Dialog',
  closeNewStockLot: 'Close New Stock Lot Dialog',
  createNewStockLot: 'Create New Stock Lot',
  inventoryPart: 'Inventory Part',
  stockLots: 'Stock Lots',
  orgPreferences: 'Org Preferences',
  closeAddPartToBom: 'Close Add Part to BOM Dialog',
  addPartToBom: 'Add Part to BOM',
  closeAddWatchlist: 'Close Add Watchlist Subscription Dialog',
  cancelAddWatchlist: 'Cancel Add Watchlist Subscription',
  closeCancelSourcingRequest: 'Close Cancel Sourcing Request',
  cancelSourcingRequest: 'Cancel Sourcing Request',
  closePartWatchlist: 'Close Part Watchlist Management Dialog',
  deletePartWatchlist: 'Delete Part WatchList',
  pausePartWatchlist: 'Pause Part Watchlist',
  closeNewSourcingRequest: 'Close New Sourcing Request Dialog',
  submitNewSourcingRequest: 'Submit New Sourcing Request',
  spotBuyPart: 'Spot Buy Part',
  refreshPart: 'Refresh Part',
  revertPartEdits: 'Revert Part Edits',
  openNewRequest: 'Open New Request Dialog',
  purchasingSubTabNav: 'Parts Purchasing Sub Tab Nav',
  quotesSubTabNav: 'Parts Quotes Sub Tab Nav',
  partsTabNav: 'Parts Tab Nav',
  addPartToWatchlist: 'Add Part to Watchlist',
  productionRun: 'Program',
  orders: 'Orders',
  cancelCreateEditProductionRun: 'Cancel Create/Edit Program',
  saveCreateEditProductionRun: 'Save Create/Edit Program',
  newProductionRun: 'New Program',
  cancelAddEditProductionLine: 'Cancel Add/Edit Production Line',
  submitAddEditProductionLine: 'Submit Add/Edit Production Line',
  approveUnapproveBom: 'Approve/Un-Aapprove Bom', // typo but we're keeping it since it's been tracking that way since instantiation
  cancelApproveUnapproveProductionRun: 'Cancel Approve/Un-Approve Program',
  approveUnapproveProductionRun: 'Approve/Un-Approve Program',
  cancelCreateUpdateShipment: 'Cancel Create/Update Shipment',
  createUpdateShipment: 'Create/Update Shipment',
  cancelCreateSourcingRequest: 'Cancel Create Sourcing Request',
  createSourcingRequest: 'Create Sourcing Request',
  closeDeleteProductionLine: 'Close Delete Production Line Dialog',
  cancelDeleteProductionLine: 'Cancel Delete Production Line',
  deleteProductionLine: 'Delete Production Line',
  closeCreateUpdateAllocations: 'Close Create/Update Allocations Dialog',
  cancelCreateUpdateAllocations: 'Cancel Create/Update Allocations',
  createUpdateAllocations: 'Create/Update Allocations',
  closeReleaseAllocations: 'Close Release Allocations Dialog',
  cancelReleaseAllocations: 'Cancel Release Allocations',
  releaseAllocations: 'Release Allocations',
  addBom: 'Add BOM',
  closeProductionConsumeStock: 'Close Production Consume Stock Dialog',
  manufacturingRequirementsSaveCancelEdit:
    'Manufacturing Requirements Save/Cancel/Edit',
  editStockLocationSettings: 'Edit Stock Location Settings',
  productionRunActionsSaveCancelEdit: 'Program Actions Save/Cancel/Edit',
  productionRunShippingSaveCancelEdit: 'Program Shipping Save/Cancel/Edit',
  stockLotLabelingSaveCancelEdit: 'Stock Lot Labeling & Notes Save/Cancel/Edit',
  stockLotSelectionSaveCancelEdit: 'Stock Lot Selection Save Cancel Edit',
  approveRunApproveUnapprove: 'Approve/Un-Approve Program',
  consumeStockMarkComplete: 'Mark Program Complete',
  createAllocations: 'Create Allocations',
  updateAllocations: 'Update Allocations',
  createShipments: 'Create Shipments',
  viewShipments: 'View Shipments',
  viewSourcingRequests: 'View Sourcing Requests',
  newSourcingRequests: 'New Sourcing Requests',
  openNewLocationDialog: 'Open New Location Dialog',
  viewPart: 'View Part',
  viewSourcingOptions: 'View Sourcing Options',
  closeEditShipmentDialog: 'Close Edit Shipment Dialog',
  closeCreateShipmentDialog: 'Close Create Shipment Dialog',
  createNewShipment: 'Create New Shipment',
  editShipment: 'Edit Shipment',
  closeApproveShipmentDialog: 'Close Approve Shipment Dialog',
  markAsShipped: 'Mark Shipment as Shipped',
  sendShipment: 'Send Shipment',
  closeCancelShipmentDialog: 'Close Cancel Shipment Dialog',
  cancelShipment: 'Cancel Shipment',
  closeDeleteShipmentDialog: 'Close Delete Shipment Dialog',
  deleteShipment: 'Delete Shipment',
  openEditShipmentDialog: 'Open Edit Shipment Dialog',
  openCancelShipmentDialog: 'Open Cancel Shipment Dialog',
  openDeleteShipmentDialog: 'Open Delete Shipment Dialog',
  openAddStockLotDialog: 'Open Add Stock Lot Dialog',
  closeAddStockLotDialog: 'Close Add Stock Lot Dialog',
  cancelAddStockLot: 'Cancel Add Stock Lot',
  saveAddStockLot: 'Save Add Stock Lot',
  closeRemoveStockLotDialog: 'Close Remove Stock Lot Dialog',
  removeStockLot: 'Remove Stock Lot',
  openNewShipmentDialog: 'Open Create Shipment Dialog',
  viewPartStock: 'View Part Stock',
  printLabel: 'Print Label',
  addStockLotToShipment: 'Add Stock Lot to Shipment',
  closeCancelAddStockLotToShipment: 'Close Cancel Add Stock Lot to Shipment',
  closeAddStockLotToShipment: 'Close Add Stock Lot to Shipment Dialog',
  openAddStockLotToShipment: 'Open Add Stock Lot to Shipment Dialog',
  openConfirmDisposeDialog: 'Open Confirm Dispose Dialog',
  closeConfirmDisposeDialog: 'Close Confirm Dispose Dialog',
  cancelConfirmDispose: 'Cancel Confirm Dispose',
  trashConfirmDispose: 'Trash Confirm Dispose',
  openTrashStockLotDialog: 'Open Trash Stock Lot Dialog',
  closeTrashStockLotDialog: 'Close Trash Stock Lot Dialog',
  cancelTrashStockLotDialog: 'Cancel Trash Stock Lot Dialog',
  trashTrashStockLotDialog: 'Trash Trash Stock Lot Dialog',
  openResellStockLotDialog: 'Open Resell Stock Lot Dialog',
  closeResellStockLotDialog: 'Close Resell Stock Lot Dialog',
  cancelResellStockLotDialog: 'Cancel Resell Stock Lot Dialog',
  trashResellStockLotDialog: 'Trash Resell Stock Lot Dialog',
  openTransferStockLocationDialog: 'Open Transfer Stock Location Dialog',
  closeTransferStockLocationDialog: 'Close Transfer Stock Location Dialog',
  cancelTransferStockLocationDialog: 'Cancel Transfer Stock Location Dialog',
  proceedTransferStockLocationDialog: 'Proceed Transfer Stock Location Dialog',
  openSplittingTaskDialog: 'Open Splitting Task Dialog',
  closeSplittingTaskDialog: 'Close Splitting Task Dialog',
  cancelSplittingTaskDialog: 'Cancel Splitting Task Dialog',
  proceedSplittingTaskDialog: 'Proceed Splitting Task Dialog',
  closeCancelTaskDialog: 'Close Cancel Task Dialog',
  yesCancelTask: 'Yes Cancel Task',
  openStockLotEditQuantityDialog: 'Open Stock Lot Edit Quantity Dialog',
  closeStockLotEditQuantityDialog: 'Close Stock Lot Edit Quantity Dialog',
  cancelStockLotEditQuantity: 'Cancel Stock Lot Edit Quantity',
  saveStockLotEditQuantity: 'Save Stock Lot Edit Quantity',
  openStockLotEditNotesDialog: 'Open Stock Lot Edit Notes Dialog',
  closeStockLotEditNotesDialog: 'Close Stock Lot Edit Notes Dialog',
  cancelStockLotEditNotes: 'Cancel Stock Lot Edit Notes',
  saveStockLotEditNotes: 'Save Stock Lot Edit Notes',
  openStockLocationShipmentDialog: 'Open Stock Location Shipment Dialog',
  closeStockLocationShipmentDialog: 'Close Stock Location Shipment Dialog',
  cancelStockLocationShipment: 'Cancel Stock Location Shipment',
  addStockLocationShipment: 'Add Stock Location to Shipment',
  openStockLocationDeleteDialog: 'Open Stock Location Delete Dialog',
  closeStockLocationDeleteDialog: 'Close Stock Location Delete Dialog',
  cancelStockLocationDelete: 'Cancel Stock Location Delete',
  deleteStockLocation: 'Delete Stock Location',
  closeCreateUpdateLocationDialog: 'Close Create Update Location Dialog',
  cancelCreateUpdateLocation: 'Cancel Close Update Location',
  createLocation: 'Create Location',
  updateLocation: 'Update Location',
  closeBulkCreateExpectedDialog: 'Close Bulk Create Expected Dialog',
  submitBulkCreateExpected: 'Submit Bulk Create Expected',
  bulkCreateStockLotsAndAddToShipment:
    'Bulk Create Stock Lots and Add To Shipment',
  orderSnapshotsTabNav: 'Order Snapshots Tab Nav',
  rejectSnapshot: 'Reject Snapshot',
  approveRequestedSnapshot: 'Approve Requested Snapshot',
  approveOrder: 'Approve Snapshot',
  requestSnapshotApprove: 'Request Approve Snapshot',
  createSupplierBillLine: 'Create Supplier Bill Line',
  closeMarkSupplierBillAsPaidDialog: 'Close Mark Supplier Bill As Paid Dialog',
  cancelMarkSupplierBillAsPaidDialog:
    'Cancel Mark Supplier Bill As Paid Dialog',
  markSupplierBillAsPaid: 'Mark Supplier Bill As Paid',
  closeSupplierBillRelatedPurchaseOrderDialog:
    'Close Supplier Bill Related Purchase Order Dialog',
  cancelSupplierBillRelatedPurchaseOrderDialog:
    'Cancel Supplier Bill Related Purchase Order Dialog',
  changeSupplierBillRelatedPurchaseOrder:
    'Change Supplier Bill Related Purchase Order',
  closeCreateSupplierBillLineDialog: 'Close Create Supplier Bill Line Dialog',
  cancelCreateSupplierBillLineDialog: 'Cancel Create Supplier Bill Line Dialog',
  updateSupplierBillLine: 'Update Supplier Bill Line',
  closeUpdateSupplierBillLineDialog: 'Close Update Supplier Bill Line Dialog',
  cancelUpdateSupplierBillLineDialog: 'Cancel Update Supplier Bill Line Dialog',
  closeAddPurchaseOrderLineMatchDialog:
    'Close Add Purchase Order Line Match To Supplier Bill Line Dialog',
  cancelAddPurchaseOrderLineMatchDialog:
    'Cancel Add Purchase Order Line Match To Supplier Bill Line Dialog',
  addPurchaseOrderLineMatch:
    'Add Purchase Order Line Match To Supplier Bill Line Dialog',
  closeUpdateSupplierQuoteRfqDialog: 'Close Update Supplier Quote RFQ Dialog',
  cancelUpdateSupplierQuoteRfqDialog: 'Cancel Update Supplier Quote RFQ Dialog',
  updateSupplierQuoteRfq: 'Update Supplier Quote RFQ',
  updateSupplierQuoteLine: 'Update Supplier Quote Line',
  closeUpdateSupplierQuoteLineDialog: 'Close Update Supplier Quote Line Dialog',
  cancelUpdateSupplierQuoteLineDialog:
    'Cancel Update Supplier Quote Line Dialog',
  openCreateNewOrgPartDialog: 'Open Create New Org Part Dialog',
  closeCreateNewOrgPartDialog: 'Close Create New Org Part Dialog',
  cancelCreateNewOrgPartDialog: 'Cancel Create New Org Part Dialog',
  saveCreateNewOrgPartDialog: 'Save Create New Org Part Dialog',
});
