import { Part, PartCore } from 'types/part';
import { StockLotCRUDSchema } from 'types/inventory';
import PartCell from 'ui-component/shared/PartCell';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { UUID } from 'types/api';

type RenderPartCellProps = {
  part: Part | PartCore;
  noPart?: string | null;
  altCount?: number | null;
  stockLot?: StockLotCRUDSchema | null | StockLotCRUDSchema['id'];
  searchParams?: string;
  unapprovedAltCount?: number | null;
  params?: GridRenderCellParams;
};

export function renderPartCell({
  part,
  noPart,
  altCount,
  stockLot,
  searchParams,
  unapprovedAltCount,
  params,
}: RenderPartCellProps) {
  const v2OrgPart: UUID | undefined = params?.row?.v2OrgPart
    ? typeof params.row.v2OrgPart === 'string'
      ? params.row.v2OrgPart
      : params.row.v2OrgPart.id
    : undefined;
  return (
    <PartCell
      part={part}
      noPart={noPart}
      altCount={altCount}
      stockLot={stockLot}
      searchParams={searchParams}
      unapprovedAltCount={unapprovedAltCount}
      v2OrgPart={v2OrgPart}
    />
  );
}
