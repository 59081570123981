import { useCreateStockDocumentMutation } from 'store/slices/apiV1/inventory';
import { StockDocType } from 'types/inventory';

interface StockDocData {
  document?: string;
  file?: File;
  documentType: StockDocType;
  filename: string;
  processDocument?: boolean;
  notes?: string;
  receiveAi?: boolean;
}

export function useCreateStockDocsV2() {
  const [createStockDocument, { isLoading: isLoadingCreateStockDoc }] =
    useCreateStockDocumentMutation();

  const createStockDoc = async (d: StockDocData) => {
    const stockDocData = new FormData();
    stockDocData.append('document_type', d.documentType);
    stockDocData.append('shared_document', 'false');
    stockDocData.append('v2_mode', 'true');
    stockDocData.append('name', d.filename);

    if (d.notes) stockDocData.append('notes', d.notes);
    if (d.processDocument) stockDocData.append('process_document', 'true');
    else stockDocData.append('process_document', 'false');

    if (d.receiveAi) stockDocData.append('receive_ai', 'true');

    if (d.document) {
      await fetch(d.document)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], d.filename);
          stockDocData.append('document', file);
        });
    }

    if (d.file) stockDocData.append('document', d.file);
    const result = await createStockDocument({
      payload: stockDocData,
    }).unwrap();

    return result;
  };

  const createStockDocuments = async (data: StockDocData[]) => {
    const results = await Promise.allSettled(
      data.map((d) => createStockDoc(d))
    );
    return results;
  };
  return {
    isLoading: isLoadingCreateStockDoc,
    createStockDocuments,
  };
}
