import { CustomPropertiesType, DateString, DateTimeString } from 'types';
import { Org } from 'types/org';
import { ProductionRun } from 'types/production';
import { User } from 'types/user';
import { Snapshot } from 'types/purchasing';
import {
  ClientV2ToggleRequest,
  Lite,
  PATCHSchema,
  POSTSchema,
} from 'types/api';
import { Team } from 'types/properties';
import { OrgPart } from 'types/part';
import { StockLocationSchemaLite } from 'types/clientV2/stockLocations';
import { AllocationSchemaLite } from 'types/clientV2/allocations';
import { OrgPartSchema, PART_VERSION } from 'types/clientV2/parts';

export type KitRequestSchema = {
  id: string;
  kitCode: string;
  name: string | null;
  internalNotes: string | null;
  externalNotes: string | null;
  printedNotes: string | null;
  approved: boolean | null;
  fulfilledAt: DateTimeString | null;
  voidedAt: DateTimeString | null;
  approvedAt: DateTimeString | null;
  archived: DateTimeString | null;
  carrier: string | null;
  service: string | null;
  shipPartial: boolean;
  targetShipDate: DateString | null;
  org: Org;
  processingOrg: Org;
  productionRun: ProductionRun | null;
  createdBy: User | null;
  approvedBy: User | null;
  voidedBy: User | null;
  sourceLocations: StockLocationSchemaLite[];
  shipTo: StockLocationSchemaLite | null;
  kittedFor: Snapshot;
  team: Team | null;
  estimatedShipDate: DateString | null;
  locked: boolean | null;
  customProperties: CustomPropertiesType;
};

export type KitRequestSchemaLite = Lite<KitRequestSchema>;

export type KitRequestPOSTSchema = POSTSchema<
  KitRequestSchema,
  'name',
  | 'internalNotes'
  | 'externalNotes'
  | 'printedNotes'
  | 'shipPartial'
  | 'targetShipDate'
  | 'carrier'
  | 'service'
  | 'productionRun'
  | 'shipTo'
  | 'team'
  | 'sourceLocations'
  | 'kittedFor'
  | 'estimatedShipDate'
>;

export type KitRequestPATCHSchema = PATCHSchema<KitRequestPOSTSchema>;

export type KitRequestLineSchema = {
  id: string;
  /** --------------------------------- */
  /** --- Deprecated Part V1 Fields --- */
  /** --------------------------------- */
  part: string;
  altParts: string[];
  unapprovedAltParts: string[];
  orgPart: OrgPart;
  orgParts: OrgPart[];
  orgPartJoins: string[];
  /** -------------------------- */
  /** --- New Part V2 Fields --- */
  /** -------------------------- */
  partVersion: PART_VERSION;
  v2OrgPart: OrgPartSchema['id'];
  v2OrgParts: OrgPartSchema[];
  altV2OrgParts: OrgPartSchema['id'][];
  unapprovedAltV2OrgParts: OrgPartSchema['id'][];
  /** ------------------------ */
  /** --- Remaining Fields --- */
  /** ------------------------ */
  minimumQuantity: number | null;
  targetQuantity: number | null;
  internalNotes: string | null;
  externalNotes: string | null;
  allocations: AllocationSchemaLite[];
  printedNotes: string | null;
  targetPackage: string;
  fulfilledAt: DateTimeString | null;
  kitRequest: KitRequestSchemaLite;
  fulfilledBy: User | null;
  locked: boolean;
  customProperties: CustomPropertiesType;
};

export type KitRequestLineSchemaLite = Lite<KitRequestLineSchema>;

export type KitRequestLinePOSTSchema = POSTSchema<
  KitRequestLineSchema,
  'kitRequest',
  | 'part'
  | 'altParts'
  | 'unapprovedAltParts'
  | 'minimumQuantity'
  | 'targetQuantity'
  | 'internalNotes'
  | 'externalNotes'
  | 'printedNotes'
  | 'targetPackage'
  | 'customProperties'
  | 'v2OrgPart'
  | 'altV2OrgParts'
  | 'unapprovedAltV2OrgParts'
>;
export type KitRequestLinePATCHSchema = KitRequestLinePOSTSchema & {
  id: string;
};

export type KitRequestApproveSchema =
  ClientV2ToggleRequest<KitRequestSchema> & {
    estimatedShipDate?: DateString;
    targetShipDate?: DateString;
  };

export enum KitRequestStatus {
  DRAFT = 'draft',
  APPROVED = 'approved',
  FULFILLED = 'fulfilled',
  VOIDED = 'cancelled',
}

export enum KitRequestValidationType {
  WARNING = 'warning',
  ERROR = 'error',
}

export type KitRequestValidationDetail = {
  validationType: KitRequestValidationType;
  lines: KitRequestLineSchemaLite['id'][];
};

export enum KitRequestValidationNameIds {
  UNAVAILABLE_PARTS = 'unavailableParts',
  PARTIALLY_UNAVAILABLE_PARTS = 'partiallyUnavailableParts',
  ESTIMATED_SHIP_DATE_CHANGED = 'estimatedShipDateChanged',
}

export const KitRequestValidationNameStrings = {
  [KitRequestValidationNameIds.UNAVAILABLE_PARTS]: 'Unavailable Parts',
  [KitRequestValidationNameIds.PARTIALLY_UNAVAILABLE_PARTS]:
    'Partially Unavailable Parts',
  [KitRequestValidationNameIds.ESTIMATED_SHIP_DATE_CHANGED]:
    'Ship Date Changed',
};

export type KitRequestValidation = {
  unavailableParts: KitRequestValidationDetail;
  partiallyUnavailableParts?: KitRequestValidationDetail;
  estimatedShipDateChanged?: KitRequestValidationDetail;
};
