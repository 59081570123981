import { ExtendedGridColDef } from 'types/datagrid';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { nestedValueGetter } from 'ui-component/DataGrid/ValueGetters';
import {
  getApplyFilterFnPart,
  getApplyFilterFnV2OrgParts,
} from 'ui-component/DataGrid/FilterOperators';
import { ALL_APPS } from 'constants/appConstants';
import { renderPartV2Cell } from 'ui-component/DataGrid/Render/RenderPartV2Cell';
import { GridValueGetterParams } from '@mui/x-data-grid';

export const V2OrgPartFieldName =
  'v2OrgPart.mpn|v2OrgPart.manufacturer|v2OrgPart.description|v2OrgPart.cofactrId|v2OrgPart.customId';
// Used to properly handle searching for CPID/cofactrId field on ServerSideDatagrids
// Currently, Flagship can't handle filtering by cofactrId, but part.id should be the same value
export const V2OrgPartFieldNameWithoutCofactrId =
  'v2OrgPart.mpn|v2OrgPart.manufacturer|v2OrgPart.description|v2OrgPart.customId|part.id';
export const V2OrgPartFieldNameWithDescription = `${V2OrgPartFieldName}|description`;
export const V2OrgPartsFieldName =
  'v2OrgParts.mpn|v2OrgParts.manufacturer|v2OrgParts.description|v2OrgParts.cofactrId|v2OrgParts.customId';

export const v2OrgPartColumnDef: ExtendedGridColDef = {
  field: V2OrgPartFieldName,
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  hideable: false,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartV2Cell({
      orgPart: params.row.v2OrgPart,
    }),
  valueGetter: (params: GridValueGetterParams) => params.row.v2OrgPart?.mpn,
  getApplyQuickFilterFn: getApplyFilterFnV2OrgParts,
  permissionScope: {
    app: ALL_APPS.PARTS.id,
  },
};

export const orgPartV2ColumnDef: ExtendedGridColDef = {
  field: 'orgPart',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartV2Cell({
      orgPart: params.row.orgPart,
      stockLot: 'stockLot' in params.row ? params.row.stockLot : undefined,
    }),
  valueGetter: nestedValueGetter,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PARTS.id,
  },
};

export const publicPartColumnDef: ExtendedGridColDef = {
  field: 'publicPart',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartV2Cell({
      publicPart: params.row,
      stockLot: 'stockLot' in params.row ? params.row.stockLot : undefined,
    }),
  valueGetter: nestedValueGetter,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PARTS.id,
  },
};
