import { useTheme } from '@mui/material/styles';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';

import { activeItem } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import _ from 'lodash';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { App, appPermissionStates } from 'types/apps';
import config from 'config';
import { useNavigate } from 'react-router-dom';
import { ALL_APP_IDS, ALL_VIEW_IDS } from 'constants/appConstants';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

const { borderRadius } = config;

const AppButton = ({
  app,
  currentAppVariant,
}: {
  app: App;
  currentAppVariant?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openItem } = useSelector((state) => state.menu);
  const { appsWithPermissions } = useAppAccessContext();

  const Icon = app?.icon!;

  const itemHandler = (id: string) => {
    dispatch(activeItem([id]));
  };

  const selected =
    _.includes(openItem, app.id as string) ||
    _.some(_.keys(app.views || {}), (key) => _.includes(openItem, key));

  const currentAppShortcuts = _.compact(
    (app?.shortcuts || []).map((shortcut) => {
      const splitShortcut = shortcut.split('.');
      return {
        shortcut: _.get(appsWithPermissions, splitShortcut, null),
        app: splitShortcut[0] as keyof typeof ALL_APP_IDS,
        view:
          splitShortcut.length > 1
            ? (splitShortcut[-1] as keyof typeof ALL_VIEW_IDS)
            : undefined,
      };
    })
  );

  return (
    <PopupState variant="popover" popupId="app-button-menu">
      {(popupState) => (
        <>
          {app.views ? (
            <Tooltip
              title={app.name}
              key={app.id}
              placement={currentAppVariant ? 'bottom' : 'right'}
            >
              <IconButton
                sx={{
                  borderRadius: `${borderRadius}px`,
                  width: '42px',
                  height: '42px',
                  backgroundColor: currentAppVariant
                    ? theme.palette.success.main
                    : selected
                    ? theme.palette.brand.moss
                    : 'transparent',
                  color: currentAppVariant
                    ? theme.palette.common.white
                    : selected
                    ? theme.palette.primary.main
                    : undefined,
                  '&:hover': currentAppVariant
                    ? {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.common.white,
                      }
                    : undefined,
                }}
                {...bindTrigger(popupState)}
                onClick={(e) => {
                  // To prevent clickthrough on the collapse action
                  e.stopPropagation();
                  bindTrigger(popupState).onClick(e);
                }}
              >
                <Icon stroke={1.5} size="1.3rem" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={app.name}
              key={app.id}
              placement={currentAppVariant ? 'bottom' : 'right'}
            >
              <IconButton
                sx={{
                  borderRadius: `${borderRadius}px`,
                  width: '42px',
                  height: '42px',
                  backgroundColor: currentAppVariant
                    ? theme.palette.success.main
                    : selected
                    ? theme.palette.brand.moss
                    : 'transparent',
                  color: currentAppVariant
                    ? theme.palette.common.white
                    : selected
                    ? theme.palette.primary.main
                    : undefined,
                  '&:hover': currentAppVariant
                    ? {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.common.white,
                      }
                    : undefined,
                }}
                onMouseDown={(event) => event.stopPropagation()}
                onTouchStart={(event) => event.stopPropagation()}
                onClick={(event) => {
                  navigate(app.path as string);
                  itemHandler(app.id);
                }}
              >
                <Icon stroke={1.5} size="1.3rem" />
              </IconButton>
            </Tooltip>
          )}
          <Menu
            {...bindMenu(popupState)}
            onClose={(e, reason) => {
              // Necessary because e may not be an HTML event
              if (reason === 'backdropClick') {
                if (
                  'stopPropagation' in e &&
                  e.stopPropagation &&
                  typeof e.stopPropagation === 'function'
                ) {
                  bindMenu(popupState).onClose();
                  e.stopPropagation();
                }
              }
            }}
            variant="selectedMenu"
          >
            {_.filter(
              _.values(app.views) || [],
              (v) => v?.permissions?.state === appPermissionStates.enabled
            ).map((v) => {
              const ViewIcon = v?.icon!;

              return (
                <MenuItem
                  key={v.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    navigate(v.path as string);
                    itemHandler(v.id);
                    bindMenu(popupState).onClose();
                  }}
                >
                  <ListItemIcon>
                    <ViewIcon />
                  </ListItemIcon>
                  <ListItemText>{v.name}</ListItemText>
                </MenuItem>
              );
            })}
            {currentAppShortcuts.length > 0 && currentAppVariant && <Divider />}
            {currentAppShortcuts.length > 0 &&
              currentAppVariant &&
              currentAppShortcuts.map((shortcut) => {
                const ShortcutIcon = shortcut.shortcut?.icon!;

                return (
                  <MenuItem
                    key={shortcut.shortcut.path}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      navigate(shortcut.shortcut.path);
                      itemHandler(shortcut.app);
                      bindMenu(popupState).onClose();
                    }}
                    disabled={
                      shortcut.shortcut.permissions?.state !==
                      appPermissionStates.enabled
                    }
                  >
                    <ListItemIcon>
                      <ShortcutIcon />
                    </ListItemIcon>
                    <ListItemText>{shortcut.shortcut.name}</ListItemText>
                  </MenuItem>
                );
              })}
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default AppButton;
