import logo from 'assets/images/cofactr_icon_logo.svg';

interface CofactrIconProps {
  size?: string | number;
}

const CofactrIcon = ({ size = '18' }: CofactrIconProps) => (
  <img src={logo} width={size} height={size} aria-label="Cofactr logo" />
);

export default CofactrIcon;
