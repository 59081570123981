import { FormControl, FormHelperText, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { Controller } from 'react-hook-form';
import { actionButtonSpacing } from 'constants/themeConstants';
import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { StockDocType, StockDocTypeIcons } from 'types/inventory';
import { UnsavedStockDocument } from 'ui-component/StockDocuments/UploadDocuments/InlineUploadDocumentsDropZone';

type HookFormDocumentsProps = {
  disabled?: boolean;
  helperText?: string;
} & HookFormComponentProps;

export const HookFormDocuments = ({
  errors,
  control,
  name = '',
  label = 'Attachments',
  disabled,
  helperText,
}: HookFormDocumentsProps) => {
  const theme = useTheme();

  return (
    <>
      <FormControl fullWidth>
        <Typography variant="h6" color="text.secondary">
          {label}
        </Typography>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Grid container spacing={actionButtonSpacing}>
                {(value || []).map((v: UnsavedStockDocument) => {
                  const Icon =
                    StockDocTypeIcons[v?.documentType || StockDocType.FILE];
                  return (
                    <Grid item key={v.file.name} xs="auto">
                      <Chip
                        chipcolor={ChipColor.primary}
                        label={v.file.name}
                        variant="outlined"
                        keepColorWhenDisabled
                        disabled={disabled}
                        onDelete={() => {
                          onChange(
                            value.filter(
                              (dv: UnsavedStockDocument) =>
                                dv.file.name !== v.file.name
                            )
                          );
                        }}
                        type="button"
                        avatar={
                          <Icon
                            stroke="1.5px"
                            color={theme.palette.primary.main}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {helperText && (
                <FormHelperText id={`${name}HelperText`} sx={{ ml: 0 }}>
                  {helperText}
                </FormHelperText>
              )}
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`} sx={{ ml: 0 }}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
    </>
  );
};
