import { RecursiveLite } from 'hooks/useServerSideQuery';
import {
  UseLazyQuery,
  LazyQueryTrigger,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query/react';
import { GenericResource, ListResponse, QueryParams } from 'types/api';

/**
 * `useServerSideLazyQuery` is a custom hook for type-safe lazy data fetching for our Server Side Query endpoints.
 * This hook is similar to useServerSideQuery but for lazy queries that are triggered manually.
 *
 * @param useLazyServerSideQueryHook - RTK Query lazy hook for data fetching.
 * @returns A tuple containing the trigger function and query state.
 *
 * The trigger function is type-safe and ensures the parameters match the expected schema.
 * The returned data is properly typed according to the ModifiedResource type.
 */
export const useServerSideLazyQuery = <
  BaseResource extends GenericResource,
  ModifiedResource extends RecursiveLite<BaseResource>
>(
  useLazyServerSideQueryHook: UseLazyQuery<
    QueryDefinition<
      QueryParams<BaseResource>,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      any,
      ListResponse<BaseResource>
    >
  >
) => {
  const [trigger, queryResult] = useLazyServerSideQueryHook();

  return [
    trigger as LazyQueryTrigger<
      QueryDefinition<
        QueryParams<BaseResource>,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
        any,
        ListResponse<ModifiedResource>
      >
    >,
    {
      ...queryResult,
      data: queryResult.data && {
        ...queryResult.data,
        data: queryResult.data.data as unknown as ModifiedResource[],
      },
    },
  ] as const;
};
