import { FC, useState } from 'react';
import { useGetStockLocationsServerSideQuery } from 'store/slices/apiV1/inventory';
import { StockLocation } from 'types/inventory';
import {
  HookQuerySearchSelect,
  HookQuerySearchSelectProps,
} from 'ui-component/HookFormComponents';
import { UseFormSetValue, FieldValues } from 'react-hook-form';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import ContextualCreateLocationDialog from 'views/inventory/components/ContextualCreateLocationDialog';
import CreateUpdateFacilityDialog from 'views/inventory/components/CreateUpdateFacilityDialog';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { MenuItem, Tooltip, ListItemIcon, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { IconHome } from '@tabler/icons-react';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSelector } from 'store';
import { GenericObject } from 'types';

type OmitHookQuerySearchSelectProps = Omit<
  HookQuerySearchSelectProps<StockLocation>,
  'schema' | 'useGetQuery' | 'getOptionLabel'
>;

type BaseHookFormStockLocationSelectProps = {
  multiple?: boolean;
  limitCreateLocationTypeToFacility?: boolean;
  indicateDefaultShipmentLocation?: boolean;
  getOptionLabel?: (option: StockLocation) => string;
} & HookFormComponentProps &
  OmitHookQuerySearchSelectProps;

type BaseHookFormStockLocationSelectPropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  handleChange?: (option: T) => void | never;
  disabled?: boolean | never;
  mb?: number;
  fullWidth?: boolean;
};

type ContextualCreateTrueProps = BaseHookFormStockLocationSelectProps & {
  contextualCreate: true;
  setValue: UseFormSetValue<FieldValues>;
  limitCreateLocationTypeToFacility?: boolean;
};

type ContextualCreateFalseProps = BaseHookFormStockLocationSelectProps & {
  contextualCreate?: false;
  setValue?: never;
  limitCreateLocationTypeToFacility?: never;
};

type HookFormStockLocationSelectProps =
  | ContextualCreateTrueProps
  | ContextualCreateFalseProps;

const HookFormStockLocationSelect: FC<HookFormStockLocationSelectProps> = ({
  errors,
  control,
  name,
  multiple = false,
  label,
  filters,
  limitCreateLocationTypeToFacility = false,
  contextualCreate = false,
  indicateDefaultShipmentLocation = false,
  getOptionLabel,
  setValue,
  sx,
  disabled,
  mb,
  shouldUnregister = false,
}) => {
  const { activeOrgId } = useSelector((state) => state.org);
  const [contextuallyCreatedOption, setContextuallyCreatedOption] = useState<
    StockLocation | undefined
  >(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: org } = useGetOrgQuery(
    indicateDefaultShipmentLocation && activeOrgId ? activeOrgId : skipToken
  );

  const QuerySearchSelect = (
    <HookQuerySearchSelect<StockLocation>
      shouldUnregister={shouldUnregister}
      sx={{ ...sx, width: '100%' }}
      mb={mb}
      name={name}
      label={label}
      useGetQuery={useGetStockLocationsServerSideQuery}
      filters={filters}
      multiple={multiple}
      schema={[
        'externalName',
        'name',
        'locationType',
        'customerLocation',
        'org',
        'address',
      ]}
      errors={errors}
      control={control}
      contextuallyCreatedOption={contextuallyCreatedOption}
      getOptionLabel={
        getOptionLabel ||
        ((option: StockLocation) => `${option.externalName || option.name}`)
      }
      {...(indicateDefaultShipmentLocation && {
        renderOption: (props, option: StockLocation) => (
          <MenuItem value={option.id} key={option.id} {...props}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {option.id === org?.defaultShippingDestinationLocation?.id && (
                <Tooltip title="The default shipping destination for your organization">
                  <ListItemIcon>
                    <IconHome />
                  </ListItemIcon>
                </Tooltip>
              )}
              <Typography>
                {option.externalName ? option.externalName : option.name}
              </Typography>
            </Stack>
          </MenuItem>
        ),
      })}
      disabled={disabled}
    />
  );

  return (
    <>
      {contextualCreate ? (
        <>
          <ContextualCreateButton
            onClick={() => setDialogOpen(true)}
            boxAlignItems="flex-start"
            buttonMarginTop={0.5}
          >
            {QuerySearchSelect}
          </ContextualCreateButton>
          {limitCreateLocationTypeToFacility ? (
            <CreateUpdateFacilityDialog
              dialogOpen={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onCreateSuccess={(value) => {
                setContextuallyCreatedOption(value);
                setValue && setValue(name, value.id);
              }}
            />
          ) : (
            <ContextualCreateLocationDialog
              dialogOpen={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onCreateSuccess={(value) => {
                setContextuallyCreatedOption(value);
                setValue && setValue(name, value.id);
              }}
            />
          )}
        </>
      ) : (
        QuerySearchSelect
      )}
    </>
  );
};

export default HookFormStockLocationSelect;
