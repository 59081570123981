import { Control, FieldErrors } from 'react-hook-form';
import { ReactNode } from 'react';

type BaseHookFormComponentProps = {
  disableRequiredProps?: boolean;
  name: string;
  errors?: FieldErrors;
  label?: string | ReactNode;
  // TODO: Replace with specific type in UI-2732
  control: Control<any, object>;
  shouldUnregister?: boolean;
};

type HookFormComponentDisableRequiredProps = {
  disableRequiredProps: true;
  name?: never;
  label?: never;
  errors?: never;
  control?: never;
  shouldUnregister?: never;
};

export type HookFormComponentProps =
  | BaseHookFormComponentProps
  | HookFormComponentDisableRequiredProps;

export enum CustomValidationType {
  WARNING = 'warning',
  ERROR = 'error',
}
