import { Grid, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UserInfoSize } from 'ui-component/UserInfoSection';
import { GenericRecord } from 'types/api';
import { IconPlug } from '@tabler/icons-react';
import { formatToDateTime } from 'utils/dates';

interface ExternallySyncedInfoSectionProps<T extends GenericRecord> {
  record?: T;
  size?: UserInfoSize;
}

const ExternallySyncedInfoSection = <T extends GenericRecord>({
  record,
  size = UserInfoSize.MD,
}: ExternallySyncedInfoSectionProps<T>) => {
  const theme = useTheme();
  if (!record || !record.externallySynced) {
    return <></>;
  }
  return (
    <Grid
      container
      columnSpacing={1.5}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs="auto">
        <Avatar
          sx={{
            bgcolor: theme.palette.primary.main,
            color: 'white',
            width: { sm: 24, md: 34 }[size],
            height: { sm: 24, md: 34 }[size],
            fontSize: { sm: 12, md: 16 }[size],
          }}
        >
          <IconPlug size={{ sm: 24, md: 34 }[size] - 6} />
        </Avatar>
      </Grid>
      <Grid item xs sx={{ overflow: 'hidden' }}>
        <Typography align="left" variant="subtitle1" noWrap>
          {record?.dataSource}
        </Typography>
        <Typography align="left" variant="subtitle2" noWrap>
          Last Sync:{' '}
          {record?.dataSourceSyncedAt
            ? formatToDateTime(record?.dataSourceSyncedAt)
            : 'Unknown'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ExternallySyncedInfoSection;
