import * as Yup from 'yup';

export const formConstants = {
  mpn: { name: 'mpn', label: 'MPN' },
  customId: { name: 'customId', label: 'Custom Part Number' },
  mfg: { name: 'manufacturerId', label: 'Manufacturer' },
  customMfg: { name: 'customManufacturerName' },
  classification: { name: 'classificationId', label: 'Classification' },
  customClassification: {
    name: 'customClassificationName',
  },
  msl: { name: 'msl', label: 'MSL' },
  description: { name: 'description', label: 'Description' },
  package: { name: 'package', label: 'Package' },
  terminations: { name: 'terminations', label: 'Number of Terminations' },
  terminationType: { name: 'terminationType', label: 'Mount Type' },
};

export const validationSchema = Yup.object().shape({
  [formConstants.mpn.name]: Yup.string().required(
    `${formConstants.mpn.label} is required`
  ),
  [formConstants.customId.name]: Yup.string(),
  [formConstants.mfg.name]: Yup.string().when([formConstants.customMfg.name], {
    is: (customMfg: string) => !customMfg,
    then: (schema) => schema.required(`${formConstants.mfg.label} is required`),
  }),
  [formConstants.classification.name]: Yup.string(),
  [formConstants.msl.name]: Yup.string(),
  [formConstants.description.name]: Yup.string(),
  [formConstants.package.name]: Yup.string(),
  [formConstants.terminations.name]: Yup.number()
    .nullable()
    .transform((currentValue, originalValue) =>
      originalValue === '' ? null : currentValue
    )
    .typeError(`${formConstants.terminations.label} must be a number`),
  [formConstants.terminationType.name]: Yup.string(),
});
