import { PATCHSchema, POSTSchema, UUID, Lite } from 'types/api';
import { Org } from 'types/org';
import {
  CustomPropertiesType,
  DateTimeString,
  GenericObject,
} from 'types/index';
import { Tag } from 'types/clientV2/tag';
import {
  Icon,
  IconBoxOff,
  IconCpu,
  IconGeometry,
  IconLayersUnion,
  IconListDetails,
  IconTools,
} from '@tabler/icons-react';

export enum PART_VERSION {
  v1 = 'v1',
  v2 = 'v2',
}

export type V2OrgPartQueryAndClientV2Types = {
  v2OrgPart?: OrgPartSchema | null;
  altV2OrgParts?: OrgPartSchema[] | null;
  unapprovedAltV2OrgParts?: OrgPartSchema[] | null;
};

export type V2OrgPartTypes = {
  v2OrgPart?: OrgPartSchema['id'] | null;
  altV2OrgParts?: OrgPartSchema['id'][] | null;
  unapprovedAltV2OrgParts?: OrgPartSchema['id'][] | null;
};

export enum MslType {
  MSL_1 = '1',
  MSL_2 = '2',
  MSL_2A = '2A',
  MSL_3 = '3',
  MSL_4 = '4',
  MSL_5 = '5',
  MSL_5A = '5A',
  MSL_6 = '6',
}

export enum RohsTypeOptions {
  ROHS_COMPLIANT = 'RoHS Compliant',
  ROHS3_COMPLIANT = 'RoHS3 Compliant',
  ROHS3_COMPLIANT_BY_EXEMPTION = 'RoHS3 Compliant by Exemption',
  ROHS_COMPLIANT_BY_EXEMPTION = 'RoHS Compliant by Exemption',
  ROHS_NON_COMPLIANT = 'RoHS Non-Compliant',
  ROHS_UNKNOWN = 'RoHS Unknown',
}

export enum ReachTypeOptions {
  AFFECTED = 'REACH Affected',
  COMPLIANT = 'REACH Compliant',
  NON_COMPLIANT = 'REACH Non-Compliant',
  UNAFFECTED = 'REACH Unaffected',
  UNKNOWN = 'REACH Unknown',
}

export enum AecTypeOptions {
  AEC_Q001 = 'AEC-Q001',
  AEC_Q002 = 'AEC-Q002',
  AEC_Q003 = 'AEC-Q003',
  AEC_Q004 = 'AEC-Q004',
  AEC_Q005 = 'AEC-Q005',
  AEC_Q006 = 'AEC-Q006',
  AEC_Q100 = 'AEC-Q100',
  AEC_Q101 = 'AEC-Q101',
  AEC_Q102 = 'AEC-Q102',
  AEC_Q103 = 'AEC-Q103',
  AEC_Q104 = 'AEC-Q104',
  AEC_Q200 = 'AEC-Q200',
  AEC_Q_UNKNOWN = 'AEC-Q unknown',
}

type PartSpecType = {
  custom: boolean;
  display: string;
  updatedAt: DateTimeString;
};

type PartSpecsType = { [key: string]: PartSpecType };

export enum LifecycleStatusOptions {
  EOL = 'EOL',
  NEW = 'New',
  NRFND = 'NRFND',
  OBSOLETE = 'Obsolete',
  PRODUCTION = 'Production',
}

enum UnlinkedFieldsOptions {
  MPN = 'mpn',
  ALIAS_MPNS = 'alias_mpns',
  MANUFACTURER = 'manufacturer',
  CLASSIFICATION = 'classification',
  DESCRIPTION = 'description',
  HERO_IMAGE = 'hero_image',
  LIFECYCLE_STATUS = 'lifecycle_status',
  PACKAGE = 'package',
  TERMINATIONS = 'terminations',
  TERMINATION_TYPE = 'termination_type',
  MSL = 'msl',
  REACH = 'reach',
  ROHS = 'rohs',
  AEC = 'aec',
  SPECS = 'specs',
}

export enum OrgPartType {
  OTS = 'ots',
  CUSTOM = 'custom',
  ASSEMBLY = 'assembly',
  SERVICE = 'service',
  NON_STOCKED = 'non_stocked',
  BUNDLED = 'bundled',
}

export const OrgPartIcons: { [key in OrgPartType]: Icon } = {
  [OrgPartType.OTS]: IconCpu,
  [OrgPartType.CUSTOM]: IconGeometry,
  [OrgPartType.ASSEMBLY]: IconListDetails,
  [OrgPartType.SERVICE]: IconTools,
  [OrgPartType.NON_STOCKED]: IconBoxOff,
  [OrgPartType.BUNDLED]: IconLayersUnion,
};

export type PublicPartSchema = {
  id: UUID;
  manufacturer: string;
  primaryClass: string;
  heroImage: string;
  msl: MslType;
  rohs: RohsTypeOptions;
  reach: ReachTypeOptions;
  aec: AecTypeOptions;
  specs: PartSpecsType;
  terminations: number;
  aliasMpns: string[];
  aliasMfgs: string[];
  terminationType: string | null;
  package: string;
  lifecycleStatus: LifecycleStatusOptions;
  cofactrId: string;
  mpn: string;
  description: string | null;
  deprecatedBy: string | null;
};

export type PublicPartSchemaLite = Lite<PublicPartSchema>;

export type OrgPartSchema = {
  id: UUID;
  org: Org;
  publicPart: PublicPartSchema | null;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  customProperties: CustomPropertiesType;
  customId: string | null;
  cofactrId: string | null;
  revision: string | null;
  partType: OrgPartType;
  isElectronicComponent: boolean;
  mpn: string | null;
  aliasMpns: string[];
  aliasMfgs: string[];
  manufacturer: string | null;
  classification: string | null;
  description: string | null;
  heroImageFile: string | null;
  heroImageUrl: string | null;
  lifecycleStatus: LifecycleStatusOptions | null;
  package: string | null;
  terminations: number | null;
  terminationType: string | null;
  msl: MslType | null;
  reach: ReachTypeOptions | null;
  rohs: RohsTypeOptions | null;
  aec: AecTypeOptions | null;
  specs: PartSpecsType;
  unlinkedFields: UnlinkedFieldsOptions[];
  lastSyncedAt: DateTimeString | null;
  tags: Tag['id'][] | null;
};

export type PublicClassificationSchema = {
  id: string;
  cofactrId: string;
  isElectronicComponent: boolean;
  name: string;
};

export type OrgPartSchemaLite = Lite<OrgPartSchema>;

export type OrgPartActionSchema<T extends GenericObject> = T & {
  ids: UUID[];
};

export type OrgPartSpecsPOSTSchema = {
  specs?: {
    [key: string]: string;
  };
};

export type OrgPartRemoveSpecsSchema = {
  specs: string[];
};

export type OrgPartHeroImageSchema = {
  heroImage: FormData;
};

export type OrgPartPOSTSchema = POSTSchema<
  OrgPartSchema &
    OrgPartSpecsPOSTSchema & {
      heroImage?: string | null;
      publicPartId?: string | null;
    },
  'org',
  | 'customId'
  | 'mpn'
  | 'aliasMpns'
  | 'aliasMfgs'
  | 'manufacturer'
  | 'classification'
  | 'description'
  | 'heroImage'
  | 'lifecycleStatus'
  | 'package'
  | 'terminations'
  | 'terminationType'
  | 'msl'
  | 'publicPartId'
  | 'unlinkedFields'
  | 'specs'
  | 'isElectronicComponent'
  | 'partType'
  | 'revision'
>;

export type OrgPartPATCHSchema = PATCHSchema<OrgPartPOSTSchema>;
