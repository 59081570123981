import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type OptionType = {
  value: string;
  label: string;
};

type BaseHookFormSelectProps = {
  children?: ReactNode;
  options?: OptionType[];
  defaultValue?: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  sx?: SxProps<Theme>;
  helperText?: string;
  mt?: number;
  fullWidth?: boolean;
  [props: string]: unknown;
  disableRequiredProps?: boolean;
  additionalOnChange?: (e: SelectChangeEvent) => void;
} & HookFormComponentProps;

type HookFormSelectWithOptionsProps = {
  disableRequiredProps?: false;
  options: OptionType[];
  children?: never;
} & BaseHookFormSelectProps;

type HookFormSelectWithChildrenProps = {
  disableRequiredProps?: false;
  children: ReactNode;
  options?: never;
} & BaseHookFormSelectProps;

type HookFormSelectWithDisableRequiredProps = {
  disableRequiredProps?: true;
} & BaseHookFormSelectProps;

export type HookFormSelectProps =
  | HookFormSelectWithDisableRequiredProps
  | HookFormSelectWithOptionsProps
  | HookFormSelectWithChildrenProps;

export const HookFormSelect = ({
  errors,
  control,
  name = '',
  label,
  children,
  options,
  defaultValue,
  disabled,
  multiple = false,
  sx,
  mt = 2,
  helperText,
  fullWidth = true,
  disableRequiredProps = false,
  additionalOnChange,
  shouldUnregister = false,
  ...rest
}: HookFormSelectProps) => (
  <FormControl fullWidth={fullWidth} sx={{ mt, ...sx }}>
    <InputLabel id={`${name}-label`}>{label}</InputLabel>
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field: { onChange, value } }) => (
        <>
          <Select
            labelId={`${name}-label`}
            id={name}
            label={label}
            onChange={(e) => {
              onChange(e);
              additionalOnChange && additionalOnChange(e);
            }}
            disabled={disabled}
            defaultValue={defaultValue}
            multiple={multiple}
            error={!!errors?.[name]}
            {...rest}
            value={value ?? (multiple ? [] : '')}
          >
            {children ||
              options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
          {helperText && (
            <FormHelperText id={`${name}HelperText`} sx={{ ml: 0 }}>
              {helperText}
            </FormHelperText>
          )}
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`} sx={{ ml: 0 }}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  </FormControl>
);
