import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface ToggleProps extends CheckboxProps {
  name: string;
  control: Control;
  disabled?: boolean;
  options: { value: string; label: string }[];
}

export const HookFormCheckboxGroup = ({
  name,
  control,
  defaultValue,
  options,
  disabled,
}: ToggleProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { value = [], onChange } }) => (
      <Stack>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={value.includes(option.value)}
                onChange={(e) =>
                  onChange(
                    e.target.checked
                      ? [...value, option.value]
                      : value.filter((v: string) => v !== option.value)
                  )
                }
                disabled={disabled}
                name={option.value}
              />
            }
            label={option.label}
          />
        ))}
      </Stack>
    )}
  />
);
