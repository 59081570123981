import { FieldValues, useForm } from 'react-hook-form';
import { useState } from 'react';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';
import { Box, MenuItem } from '@mui/material';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import { IconBandage, IconBug, IconTools } from '@tabler/icons-react';
import {
  useCreateBugReportMutation,
  useCreatePainPointMutation,
  useCreateManagedServicesIssueMutation,
} from 'store/slices/apiV1/utility';
import { handleErr } from 'utils/functions';
import {
  CreateBugReport,
  CreatePainPoint,
  LinearTicket,
  CreateManagedServicesIssue,
  WorkstationProcess,
} from 'types/utility';
import { IS_PROD, IS_QA } from 'constants/envConstants';
import { openReplayTracker } from 'utils/route-guard/AuthGuard';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import useSnackbar from 'hooks/useSnackbar';

enum TAB_NAMES {
  BUG = 'bug',
  PAIN_POINT = 'painPoint',
  MANAGED_SERVICES_ISSUE = 'managedServicesIssue',
}

type BugReportDialogProps = {
  open: boolean;
  onClose: () => void;
};

const BugReportDialog = ({ open, onClose }: BugReportDialogProps) => {
  const [error, setError] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState<TAB_NAMES>(TAB_NAMES.BUG);
  const openReplayFeature = useFeature(FeatureFlags.tempOpenReplay).on;
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [createBugReport, { isLoading: isLoadingCreateBugReport }] =
    useCreateBugReportMutation();

  const [createPainPoint, { isLoading: isLoadingCreatePainPoint }] =
    useCreatePainPointMutation();

  const [
    createManagedServicesIssue,
    { isLoading: isLoadingCreateManagedServicesIssue },
  ] = useCreateManagedServicesIssueMutation();

  const tabOptions = {
    bug: {
      name: TAB_NAMES.BUG,
      label: 'Bug Report',
      icon: <IconBug />,
    },
    painPoint: {
      name: TAB_NAMES.PAIN_POINT,
      label: 'Pain Point',
      icon: <IconBandage />,
    },
    managedServicesIssue: {
      name: TAB_NAMES.MANAGED_SERVICES_ISSUE,
      label: 'Managed Services Issue',
      icon: <IconTools />,
    },
  };

  const bugFormConstants = {
    summary: {
      name: 'summary',
      label: 'Summary',
    },
    severity: { name: 'severity', label: 'Severity' },
    stepsToReproduce: { name: 'stepsToReproduce', label: 'Steps to Reproduce' },
    expectedResult: {
      name: 'expectedResult',
      label: 'Expected Results vs Actual Results',
    },
    workAround: {
      name: 'workAround',
      label: 'If possible, what have you done to work around this issue?',
    },
    additionalInformation: {
      name: 'additionalInformation',
      label: 'Additional Info/Supporting Docs',
    },
    jamUrl: {
      name: 'jamUrl',
      label: 'Jam URL',
    },
  };

  const painPointFormConstants = {
    summary: {
      name: 'summary',
      label: 'Summary',
    },
    impact: {
      name: 'impact',
      label: 'Impact',
    },
    suggestion: { name: 'suggestion', label: 'Problem and Suggestion' },
    additionalInformation: {
      name: 'additionalInformation',
      label: 'Additional Info/Supporting Docs',
    },
    jamUrl: {
      name: 'jamUrl',
      label: 'Jam URL',
    },
  };

  const managedServicesFormConstants = {
    summary: {
      name: 'summary',
      label: 'Summary',
    },
    workstationOrProcess: {
      name: 'workstationOrProcess',
      label: 'Workstation or Process',
    },
    stockLotIdOrCfp: {
      name: 'stockLotIdOrCfp',
      label: 'Stock Lot ID or CFP',
    },
    additionalInformation: {
      name: 'additionalInformation',
      label: 'Additional Information',
    },
    jamUrl: {
      name: 'jamUrl',
      label: 'Jam URL',
    },
  };

  const severityOptions = [
    {
      name: 1,
      impactLabel: 'Extensive',
      severityLabel: 'Urgent (P0 Firefight)',
    },
    {
      name: 2,
      impactLabel: 'Significant',
      severityLabel: 'High (P1 Firefight)',
    },
    { name: 3, impactLabel: 'Moderate', severityLabel: 'Medium (Bug Report)' },
    { name: 4, impactLabel: 'Minor', severityLabel: 'Low (Bug Report)' },
  ];

  const workstationOptions = Object.values(WorkstationProcess);

  const bugDefaultValues = {
    [bugFormConstants.summary.name]: '',
    [bugFormConstants.severity.name]: '',
    [bugFormConstants.stepsToReproduce.name]: '',
    [bugFormConstants.expectedResult.name]: '',
    [bugFormConstants.workAround.name]: '',
    [bugFormConstants.additionalInformation.name]: '',
    [bugFormConstants.jamUrl.name]: '',
  };

  const painPointDefaultValues = {
    [painPointFormConstants.summary.name]: '',
    [painPointFormConstants.impact.name]: '',
    [painPointFormConstants.suggestion.name]: '',
    [painPointFormConstants.additionalInformation.name]: '',
    [painPointFormConstants.jamUrl.name]: '',
  };

  const managedServicesDefaultValues = {
    [managedServicesFormConstants.summary.name]: '',
    [managedServicesFormConstants.workstationOrProcess.name]: '',
    [managedServicesFormConstants.stockLotIdOrCfp.name]: '',
    [managedServicesFormConstants.additionalInformation.name]: '',
    [managedServicesFormConstants.jamUrl.name]: '',
  };

  const bugValidationSchema = yup.object().shape({
    [bugFormConstants.summary.name]: yup
      .string()
      .max(
        96,
        `${bugFormConstants.summary.label} must be 96 characters or fewer`
      )
      .required(`${bugFormConstants.summary.label} is a required field.`),
    [bugFormConstants.severity.name]: yup
      .mixed()
      .oneOf(
        _.map(Object.values(severityOptions), 'name'),
        `${bugFormConstants.severity.label} must be one of: ${_.map(
          severityOptions,
          'severityLabel'
        ).join(', ')}`
      )
      .required(`${bugFormConstants.severity.label} is a required field.`),
    [bugFormConstants.stepsToReproduce.name]: yup.string(),
    [bugFormConstants.expectedResult.name]: yup.string(),
    [bugFormConstants.workAround.name]: yup.string(),
    [bugFormConstants.additionalInformation.name]: yup.string(),
    [bugFormConstants.jamUrl.name]: yup
      .string()
      .nullable()
      .trim()
      .url('Please enter a valid URL'),
  });

  const painPointValidationSchema = yup.object().shape({
    [painPointFormConstants.summary.name]: yup
      .string()
      .max(
        96,
        `${painPointFormConstants.summary.label} must be 96 characters or fewer`
      )
      .required(`${painPointFormConstants.summary.label} is a required field.`),
    [painPointFormConstants.impact.name]: yup
      .mixed()
      .oneOf(
        _.map(Object.values(severityOptions), 'name'),
        `${painPointFormConstants.impact.label} must be one of: ${_.map(
          severityOptions,
          'impactLabel'
        ).join(', ')}`
      )
      .required(`${painPointFormConstants.impact.label} is a required field.`),
    [painPointFormConstants.suggestion.name]: yup.string(),
    [painPointFormConstants.additionalInformation.name]: yup.string(),
    [painPointFormConstants.jamUrl.name]: yup
      .string()
      .nullable()
      .trim()
      .url('Please enter a valid URL'),
  });

  const managedServicesValidationSchema = yup.object().shape({
    [managedServicesFormConstants.summary.name]: yup
      .string()
      .max(
        96,
        `${managedServicesFormConstants.summary.label} must be 96 characters or fewer`
      )
      .required(
        `${managedServicesFormConstants.summary.label} is a required field.`
      ),
    [managedServicesFormConstants.workstationOrProcess.name]: yup
      .string()
      .oneOf(
        workstationOptions,
        `${
          managedServicesFormConstants.workstationOrProcess.label
        } must be one of: ${workstationOptions.join(', ')}`
      )
      .required(
        `${managedServicesFormConstants.workstationOrProcess.label} is a required field.`
      ),
    [managedServicesFormConstants.stockLotIdOrCfp.name]: yup
      .string()
      .nullable(),
    [managedServicesFormConstants.additionalInformation.name]: yup
      .string()
      .nullable(),
    [managedServicesFormConstants.jamUrl.name]: yup
      .string()
      .nullable()
      .trim()
      .url('Please enter a valid URL'),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors: formErrors },
  } = useForm<FieldValues>({
    defaultValues: (() => {
      switch (currentView) {
        case TAB_NAMES.BUG:
          return bugDefaultValues;
        case TAB_NAMES.PAIN_POINT:
          return painPointDefaultValues;
        case TAB_NAMES.MANAGED_SERVICES_ISSUE:
          return managedServicesDefaultValues;
        default:
          return {};
      }
    })(),
    resolver: yupResolver(
      (() => {
        switch (currentView) {
          case TAB_NAMES.BUG:
            return bugValidationSchema;
          case TAB_NAMES.PAIN_POINT:
            return painPointValidationSchema;
          case TAB_NAMES.MANAGED_SERVICES_ISSUE:
            return managedServicesValidationSchema;
          default:
            return yup.object().shape({});
        }
      })()
    ),
    mode: 'all',
  });

  const onSubmit = async (data: FieldValues) => {
    let formConstants;

    switch (currentView) {
      case TAB_NAMES.BUG:
        formConstants = bugFormConstants;
        break;
      case TAB_NAMES.PAIN_POINT:
        formConstants = painPointFormConstants;
        break;
      case TAB_NAMES.MANAGED_SERVICES_ISSUE:
        formConstants = managedServicesFormConstants;
        break;
      default:
        formConstants = {};
        break;
    }

    const currentViewKeys: string[] = Object.keys(formConstants);

    const payload = _.chain({
      ...data,
      sessionUrl:
        openReplayFeature && (IS_PROD || IS_QA)
          ? openReplayTracker.getSessionURL({ withCurrentTime: true })
          : null,
      url: window.location.href,
    })
      .omitBy(
        (val, key) =>
          ![...currentViewKeys, 'sessionUrl', 'url'].includes(key) || val === ''
      )
      .omitBy(_.isNil)
      .value();

    try {
      let ticket: undefined | LinearTicket;

      switch (currentView) {
        case TAB_NAMES.BUG:
          ticket = await createBugReport(payload as CreateBugReport).unwrap();
          break;
        case TAB_NAMES.PAIN_POINT:
          ticket = await createPainPoint(payload as CreatePainPoint).unwrap();
          break;
        case TAB_NAMES.MANAGED_SERVICES_ISSUE:
          ticket = await createManagedServicesIssue(
            payload as CreateManagedServicesIssue
          ).unwrap();
          break;
        default:
          return;
      }

      const getSubmissionType = () => {
        switch (currentView) {
          case TAB_NAMES.BUG:
            return 'Bug Report';
          case TAB_NAMES.PAIN_POINT:
            return 'Pain Point';
          case TAB_NAMES.MANAGED_SERVICES_ISSUE:
            return 'Managed Services Issue';
          default:
            return '';
        }
      };

      dispatchSuccessSnackbar(
        `${getSubmissionType()} submitted`,
        undefined,
        ticket?.url
          ? {
              onClick: () => {
                window.open(ticket?.url, '_blank', 'noopener,noreferrer');
              },
              name: 'View Ticket in Linear',
            }
          : undefined
      );
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => setError(errMessage));
    }
  };

  const handleClose = () => {
    reset();
    setCurrentView(TAB_NAMES.BUG);
    onClose();
    setError(null);
  };

  return (
    <ExtendedDialog
      title="Submit Bug Report"
      onCloseDialog={handleClose}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      isForm
      formSubmitError={error}
      isSubmitting={
        isLoadingCreateBugReport ||
        isLoadingCreatePainPoint ||
        isLoadingCreateManagedServicesIssue
      }
    >
      <ExtendedTabs
        value={currentView}
        onClick={(tabName) => {
          setCurrentView(tabName as TAB_NAMES);
          reset();
        }}
        tabOptions={[
          {
            label: tabOptions.bug.label,
            icon: tabOptions.bug.icon,
            name: tabOptions.bug.name,
            contents: (
              <Box>
                <HookFormInput
                  control={control}
                  name={bugFormConstants.summary.name}
                  label={bugFormConstants.summary.label}
                  errors={formErrors}
                />
                <HookFormSelect
                  sx={{ mb: 2 }}
                  name={bugFormConstants.severity.name}
                  label={bugFormConstants.severity.label}
                  control={control}
                  errors={formErrors}
                  mt={0}
                >
                  {severityOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.severityLabel}
                    </MenuItem>
                  ))}
                </HookFormSelect>
                <HookFormInput
                  control={control}
                  name={bugFormConstants.stepsToReproduce.name}
                  label={bugFormConstants.stepsToReproduce.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.expectedResult.name}
                  label={bugFormConstants.expectedResult.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.workAround.name}
                  label={bugFormConstants.workAround.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.additionalInformation.name}
                  label={bugFormConstants.additionalInformation.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.jamUrl.name}
                  label={bugFormConstants.jamUrl.label}
                  errors={formErrors}
                />
              </Box>
            ),
          },
          {
            label: tabOptions.painPoint.label,
            icon: tabOptions.painPoint.icon,
            name: tabOptions.painPoint.name,
            contents: (
              <Box>
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.summary.name}
                  label={painPointFormConstants.summary.label}
                  errors={formErrors}
                />
                <HookFormSelect
                  sx={{ mb: 2 }}
                  name={painPointFormConstants.impact.name}
                  label={painPointFormConstants.impact.label}
                  control={control}
                  errors={formErrors}
                  mt={0}
                >
                  {severityOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.impactLabel}
                    </MenuItem>
                  ))}
                </HookFormSelect>
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.suggestion.name}
                  label={painPointFormConstants.suggestion.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.additionalInformation.name}
                  label={painPointFormConstants.additionalInformation.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.jamUrl.name}
                  label={painPointFormConstants.jamUrl.label}
                  errors={formErrors}
                />
              </Box>
            ),
          },
          {
            label: tabOptions.managedServicesIssue.label,
            icon: tabOptions.managedServicesIssue.icon,
            name: tabOptions.managedServicesIssue.name,
            contents: (
              <Box>
                <HookFormInput
                  control={control}
                  name={managedServicesFormConstants.summary.name}
                  label={managedServicesFormConstants.summary.label}
                  errors={formErrors}
                />
                <HookFormSelect
                  sx={{ mb: 2 }}
                  name={managedServicesFormConstants.workstationOrProcess.name}
                  label={
                    managedServicesFormConstants.workstationOrProcess.label
                  }
                  control={control}
                  errors={formErrors}
                  mt={0}
                >
                  {workstationOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </HookFormSelect>
                <HookFormInput
                  control={control}
                  name={managedServicesFormConstants.stockLotIdOrCfp.name}
                  label={managedServicesFormConstants.stockLotIdOrCfp.label}
                  errors={formErrors}
                />
                <HookFormInput
                  control={control}
                  name={managedServicesFormConstants.additionalInformation.name}
                  label={
                    managedServicesFormConstants.additionalInformation.label
                  }
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={managedServicesFormConstants.jamUrl.name}
                  label={managedServicesFormConstants.jamUrl.label}
                  errors={formErrors}
                />
              </Box>
            ),
          },
        ]}
      />
    </ExtendedDialog>
  );
};

export default BugReportDialog;
