import { Tooltip, TooltipProps } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { JSX } from 'react';

export const NoMaxWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export function detailTooltipRenderCell(
  params: GridRenderCellParams,
  tooltipContents: JSX.Element,
  cellContents?: JSX.Element,
  overrideDisableHover?: boolean
) {
  return (
    <NoMaxWidthTooltip
      title={tooltipContents}
      placement="bottom"
      disableHoverListener={!params.value && !overrideDisableHover}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'white',
            boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
            borderRadius: '12px',
            color: 'grey.500',
          },
        },
      }}
    >
      {<span>{cellContents}</span> || (
        <span className="table-cell-trucate">{params.formattedValue}</span>
      )}
    </NoMaxWidthTooltip>
  );
}
